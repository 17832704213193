import { __awaiter } from "tslib";
import React, { useEffect, useState } from 'react';
import { fetchReleaseNoteImage } from '@/pages/release-notes/api/release-notes.client';
import { ImageWithFallback } from '@/components/image-with-fallback';
import { useAuthToken } from '@/auth/hooks/authentication';
export const ReleaseNotesImage = ({ imageUrl }) => {
    const token = useAuthToken();
    const [imageSrc, setImageSrc] = useState(null);
    useEffect(() => {
        const fetchImage = () => __awaiter(void 0, void 0, void 0, function* () {
            if (imageUrl.includes('https')) {
                setImageSrc(imageUrl);
                return;
            }
            const response = yield fetchReleaseNoteImage(token, imageUrl);
            const blob = yield response.blob();
            const imageObjectURL = URL.createObjectURL(blob);
            setImageSrc(imageObjectURL);
        });
        void fetchImage();
    }, [imageUrl, setImageSrc, token]);
    if (!imageSrc) {
        return null;
    }
    return <ImageWithFallback src={imageSrc} alt={imageUrl}/>;
};

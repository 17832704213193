import React, { useState } from 'react';
import { Tooltip } from '@mui/material';
import { StyledTableCell, StyledTableCellWrapper } from './table-rows.styles';
export const TableCellComponent = ({ column, row, rowIndex }) => {
    const [open, setOpen] = useState(false);
    const { path, label, align, render, } = column;
    let children;
    if (render) {
        children = render(row, rowIndex);
    }
    if (typeof path === 'string') {
        children = row[path];
    }
    const onMouseEnter = (event) => {
        const el = event.target;
        setOpen(el.offsetWidth < el.scrollWidth);
    };
    const onClick = (e) => {
        if (column.cellClick) {
            e.stopPropagation();
            column.cellClick(row);
        }
    };
    return (<Tooltip arrow open={open} title={children} onClose={() => setOpen(false)}>
      <StyledTableCell width={column.width} minWidth={column.minWidth} maxWidth={column.maxWidth} key={`${row.id}_${label}`} align={align} onMouseEnter={onMouseEnter} onClick={onClick} style={column.cellStyles}>
        <StyledTableCellWrapper align={align}>
          {children}
        </StyledTableCellWrapper>
      </StyledTableCell>
    </Tooltip>);
};

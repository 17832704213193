import { __rest } from "tslib";
import React from 'react';
import { baseColors } from '@groupby/ui-components';
import SvgIcon from '@mui/material/SvgIcon';
export const CheckCircleIcon = (_a) => {
    var { width = 23, height = 22, color = baseColors.primaryBlueAccent500 } = _a, props = __rest(_a, ["width", "height", "color"]);
    return (<SvgIcon xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none" {...props}>
    <rect x="1.5" y="1" width={width - 3} height={height - 2} rx="10" fill={color} stroke={color} strokeWidth="1.5"/>
    <path d="M7.5 11L10.5 14L16.5 8" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  </SvgIcon>);
};
export default CheckCircleIcon;

import { __rest } from "tslib";
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
export const TemplateNavLinkIcon = (_a) => {
    var { width = 20, height = 20 } = _a, props = __rest(_a, ["width", "height"]);
    return (<SvgIcon width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M2 20C0.89543 20 -3.91406e-08 19.1046 -8.74228e-08 18L2 18L3.33333 18L7 18L7 2L2 2L2 18L-8.74228e-08 18L-7.86805e-07 2C-8.35087e-07 0.895432 0.895429 -3.91405e-08 2 -8.74228e-08L7 -3.0598e-07C8.10457 -3.54262e-07 9 0.895431 9 2L9 18C9 19.1046 8.10457 20 7 20L3.33333 20L2 20ZM13 9C11.8954 9 11 8.10457 11 7L11 2C11 0.895431 11.8954 -5.19966e-07 13 -5.68248e-07L18 -7.86805e-07C19.1046 -8.35087e-07 20 0.895431 20 2L20 7C20 8.10457 19.1046 9 18 9L14.3333 9L13 9ZM13 2L13 7L14.3333 7L18 7L18 2L13 2ZM11 18C11 19.1046 11.8954 20 13 20L14.3333 20L18 20C19.1046 20 20 19.1046 20 18L20 13C20 11.8954 19.1046 11 18 11L13 11C11.8954 11 11 11.8954 11 13L11 18ZM13 18L13 13L18 13L18 18L14.3333 18L13 18Z" fill="#919BAB"/>
  </SvgIcon>);
};
export default TemplateNavLinkIcon;

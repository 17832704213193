import get from 'lodash/get';
import { SortDirection } from '@/search-for-retail/models';
export const sortByLocalCompare = (fieldPath) => (direction) => (a, b) => {
    const A = get(a, fieldPath, null);
    const B = get(b, fieldPath, null);
    if (!A)
        return +1;
    if (!B)
        return -1;
    return direction === SortDirection.Ascending ? A.localeCompare(B) : B.localeCompare(A);
};
export const sortByNumber = (fieldPath) => (direction) => (a, b) => {
    const A = get(a, fieldPath, null);
    const B = get(b, fieldPath, null);
    if (!A)
        return +1;
    if (!B)
        return -1;
    return direction === SortDirection.Ascending ? Number(A) - Number(B) : Number(B) - Number(A);
};
export const sortByDate = (fieldPath) => (direction) => (a, b) => {
    const A = get(a, fieldPath, null);
    const B = get(b, fieldPath, null);
    if (!A)
        return +1;
    if (!B)
        return -1;
    return direction === SortDirection.Ascending ? A - B : B - A;
};
export const sortByBoolean = (condition) => (direction) => (a, b) => {
    const isAExperiment = Number(condition(a));
    const isBExperiment = Number(condition(b));
    if (direction === SortDirection.Ascending) {
        return isBExperiment - isAExperiment;
    }
    return isAExperiment - isBExperiment;
};

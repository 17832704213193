import React, { forwardRef } from 'react';
import { Button as ButtonV1, ButtonV2, } from '@groupby/ui-components';
import { FeatureFlag, useFeatureFlagsProvider } from '@/search-for-retail/feature-flags';
export const Button = forwardRef((props, ref) => {
    const { featureFlagsService } = useFeatureFlagsProvider();
    const isEnableNewButtonStyles = featureFlagsService.isFeatureEnabled(FeatureFlag.enableNewButtonStyles);
    if (isEnableNewButtonStyles) {
        const mappedProps = {};
        if (props.startIcon) {
            mappedProps.endIcon = props.startIcon;
            mappedProps.startIcon = null;
        }
        return <ButtonV2 {...props} {...mappedProps} ref={ref}/>;
    }
    return <ButtonV1 {...props} ref={ref}/>;
});

import { __awaiter } from "tslib";
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import tenantClient from '@/tenant/tenant.client';
import { addError, creators as S4RActionCreators } from '@/store/actions/creators';
import { SimpleLoading } from '@/components/loading';
import { logError } from '@/utils/logger';
export const TenantSettingsLoader = ({ children }) => {
    const dispatch = useDispatch();
    const [isLoadingTenantSettings, setIsLoadingTenantSettings] = useState(false);
    useEffect(() => {
        const loadTenantSettings = () => __awaiter(void 0, void 0, void 0, function* () {
            try {
                setIsLoadingTenantSettings(true);
                const settings = yield tenantClient.getSettings();
                dispatch(S4RActionCreators.s4rUpdateTenantSettings(settings));
            }
            catch (err) {
                const errorMessage = 'TASK_GET_TENANT_SETTINGS_ERROR';
                logError(errorMessage, err);
                dispatch(addError({ key: errorMessage }));
            }
            finally {
                setIsLoadingTenantSettings(false);
            }
        });
        void loadTenantSettings();
    }, [dispatch]);
    if (isLoadingTenantSettings) {
        return <SimpleLoading />;
    }
    return <React.Fragment>{children}</React.Fragment>;
};
export default TenantSettingsLoader;

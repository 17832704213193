import React from 'react';
import moment from 'moment';
class Timer extends React.PureComponent {
    constructor() {
        super(...arguments);
        this.timer = setTimeout(this.props.onComplete, this.props.timeout);
        this.clock = setInterval(() => this.forceUpdate(), 1000);
        this.expiration = Date.now() + this.props.timeout;
    }
    render() {
        const ms = Math.ceil(this.expiration - Date.now());
        return moment()
            .startOf('day')
            .milliseconds(ms > 0 ? ms : 0)
            .format('m:ss');
    }
    componentWillUnmount() {
        this.clearTimers();
    }
    clearTimers() {
        clearTimeout(this.timer);
        clearInterval(this.clock);
    }
}
export default Timer;

import React, { useCallback, useRef } from 'react';
import classNames from 'classnames';
import { useDrag, useDrop } from 'react-dnd';
import Tooltip from '@mui/material/Tooltip';
import { Icon } from '@groupby/ui-components';
import { useDispatch } from 'react-redux';
import { StyledDragIcon, StyledTableCell, StyledTableRow } from '../table-rows/table-rows.styles';
import { LastDropArea } from './last-drop-area.component';
import { useMostRecentlyUpdatedModel } from '@/store/selectors';
import { setMostRecentlyUpdatedModel } from '@/store/actions/creators';
import { useRowHighlighting } from '@/search-for-retail/generic';
import { useLocalization } from '@/localization';
export const TableDraggableRow = ({ id, index, moveItem, children, isSelected, isDraggable, onRuleClick, isLastRow, rowCount, hiddenRow, }) => {
    const dispatch = useDispatch();
    const { formatMessage: t } = useLocalization();
    const ref = useRef(null);
    const mostRecentlyUpdatedModel = useMostRecentlyUpdatedModel();
    const resetMostRecentlyUpdatedModel = useCallback(() => {
        dispatch(setMostRecentlyUpdatedModel(null));
    }, [dispatch]);
    useRowHighlighting({
        targetRef: ref,
        shouldRowBeHighlighted: (id, compareValue) => id === compareValue,
        value: id,
        compareValue: mostRecentlyUpdatedModel,
        className: 'row--highlighted',
        scrollToHighlightedItem: true,
        resetFunction: resetMostRecentlyUpdatedModel,
    });
    const [{ isOver, canDrop }, drop] = useDrop({
        accept: 'TABLE_DRAGGABLE_ROW',
        drop: ({ index, dropTarget }) => {
            if (dropTarget >= 0 && index !== dropTarget) {
                return moveItem === null || moveItem === void 0 ? void 0 : moveItem(index, dropTarget);
            }
        },
        hover(item) {
            if (!ref.current) {
                return;
            }
            const hoverIndex = index;
            item.dropTarget = hoverIndex;
        },
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
            droppingItem: monitor.getItem(),
        }),
    });
    const [{ isDragging }, drag, preview] = useDrag({
        type: 'TABLE_DRAGGABLE_ROW',
        item: { id, index },
        canDrag: isDraggable,
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });
    preview(drop(ref));
    return (<React.Fragment>
      <StyledTableRow id={id} ref={ref} hover onClick={onRuleClick} $isDropping={canDrop && isOver} className={classNames({ 'row--highlighted': isSelected })} style={{
        opacity: isDragging ? 0.5 : 1,
    }}>
        <StyledTableCell ref={drag} width="32px" sticky left={0}>
          <Tooltip disableHoverListener={isDraggable} arrow title={t({ key: 'REORDER_DISABLED' })}>
            <StyledDragIcon><Icon variant="drag"/></StyledDragIcon>
          </Tooltip>
        </StyledTableCell>
        {children}
      </StyledTableRow>
      {isLastRow && canDrop && (<LastDropArea rowCount={rowCount} handleMove={moveItem}>
          {isDraggable && (<StyledTableCell width="1.5rem"/>)}
          {hiddenRow}
        </LastDropArea>)}
    </React.Fragment>);
};

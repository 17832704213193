import styled from 'styled-components';
import { baseColors } from '@groupby/ui-components';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
export const StyledAccordion = styled(Accordion) ``;
export const StyledTitleTypography = styled(Typography) `
  && {
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 1rem;
  }
`;
export const StyledAccordionSummary = styled(AccordionSummary) `
    && {
      min-height: 3.5rem;
      &.Mui-expanded {
        min-height: 3.5rem
      }

      .MuiAccordionSummary-expandIconWrapper {
        position: absolute;
        top: 0.5rem;
        right: 1rem;
      }

      .MuiAccordionSummary-content {
        margin: 0;
        height: 1.5rem;
        display: flex;
        align-items: center;
        padding: 1rem 0;
      }

      .MuiButton-root {
        svg {
          path {
            fill: ${baseColors.gray300};
          }
        }
        &:hover {
          color: unset;
          background-color: unset;

          svg {
            path {
              fill: ${baseColors.gray300};
            }
          }
        }
      }
    }
`;
export const StyledAccordionDetails = styled(AccordionDetails) `
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(30%, 1fr));
`;

import { __rest } from "tslib";
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
export const AnalyticsEmptyIcon = (_a) => {
    var { width = 102, height = 100 } = _a, props = __rest(_a, ["width", "height"]);
    return (<SvgIcon width={width} height={height} viewBox="0 0 102 100" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M83.262 71.8509C93.2003 71.8509 101.257 63.7943 101.257 53.856C101.257 43.9178 93.2003 35.8612 83.262 35.8612C73.3237 35.8612 65.2672 43.9178 65.2672 53.856C65.2672 63.7943 73.3237 71.8509 83.262 71.8509Z" fill="#E8EBEC"/>
    <path d="M44.7017 30.8483C53.2202 30.8483 60.1259 23.9427 60.1259 15.4242C60.1259 6.90564 53.2202 0 44.7017 0C36.1832 0 29.2775 6.90564 29.2775 15.4242C29.2775 23.9427 36.1832 30.8483 44.7017 30.8483Z" fill="#E8EBEC"/>
    <path d="M26.4499 100C40.6474 100 52.1568 88.4906 52.1568 74.293C52.1568 60.0955 40.6474 48.5861 26.4499 48.5861C12.2523 48.5861 0.74292 60.0955 0.74292 74.293C0.74292 88.4906 12.2523 100 26.4499 100Z" fill="#E8EBEC"/>
    <path d="M91.3599 76.0925H9.09766V25.964C9.09766 23.7789 10.7686 22.1079 12.9537 22.1079H87.5038C89.6889 22.1079 91.3599 23.7789 91.3599 25.964V76.0925Z" fill="#BDC3D8"/>
    <path d="M83.6478 72.2365H9.09766V25.964C9.09766 23.7789 10.7686 22.1079 12.9537 22.1079H87.5038V68.3804C87.5038 70.437 85.7044 72.2365 83.6478 72.2365Z" fill="white"/>
    <path d="M91.3597 76.9923H9.09749C8.58335 76.9923 8.06921 76.6067 8.06921 75.964V25.8355C8.06921 23.1362 10.2543 20.9511 12.9535 20.9511H87.5037C90.2029 20.9511 92.388 23.1362 92.388 25.8355V75.964C92.2595 76.6067 91.8739 76.9923 91.3597 76.9923ZM9.99723 75.0643H90.3314V25.8355C90.3314 24.293 89.0461 22.8792 87.3751 22.8792H12.825C11.2826 22.8792 9.8687 24.1645 9.8687 25.8355V75.0643H9.99723Z" fill="#21365F"/>
    <path d="M68.2235 83.8046H32.2338V91.5167H68.2235V83.8046Z" fill="#BDC3D8"/>
    <path d="M64.3674 83.8046H32.2338V87.6607H63.0821C63.8533 87.6607 64.3674 87.1465 64.3674 86.3753V83.8046Z" fill="white"/>
    <path d="M68.2234 92.4165H32.2337C31.7195 92.4165 31.2054 92.0309 31.2054 91.3882V83.6761C31.2054 83.162 31.591 82.6478 32.2337 82.6478H68.2234C68.7375 82.6478 69.2517 83.0334 69.2517 83.6761V91.3882C69.1231 92.0309 68.7375 92.4165 68.2234 92.4165ZM33.1334 90.4885H67.1951V84.7044H33.1334V90.4885Z" fill="#21365F"/>
    <path d="M87.5038 83.8046H12.9537C10.7686 83.8046 9.09766 82.1337 9.09766 79.9486V76.0925H91.3599V79.9486C91.3599 82.0051 89.5604 83.8046 87.5038 83.8046Z" fill="#BDC3D8"/>
    <path d="M86.2185 79.9486H9.09766V76.0925H87.5038V78.6632C87.5038 79.3059 86.8612 79.9486 86.2185 79.9486Z" fill="white"/>
    <path d="M87.5037 84.7044H12.9535C10.2543 84.7044 8.06921 82.5193 8.06921 79.8201V75.964C8.06921 75.4499 8.45482 74.9357 9.09749 74.9357H91.3597C91.8739 74.9357 92.388 75.3213 92.388 75.964V79.8201C92.2595 82.5193 90.0744 84.7044 87.5037 84.7044ZM9.99723 76.9923V79.9486C9.99723 81.491 11.2826 82.9049 12.9535 82.9049H87.5037C89.0461 82.9049 90.46 81.6195 90.46 79.9486V76.9923H9.99723Z" fill="#21365F"/>
    <path d="M81.077 92.4165H19.3803C18.8662 92.4165 18.3521 92.0309 18.3521 91.3882C18.3521 90.7455 18.7377 90.3599 19.3803 90.3599H81.077C81.5911 90.3599 82.1053 90.7455 82.1053 91.3882C82.1053 92.0309 81.5911 92.4165 81.077 92.4165Z" fill="#21365F"/>
    <path d="M50.2286 81.2339C50.9385 81.2339 51.5139 80.6585 51.5139 79.9486C51.5139 79.2387 50.9385 78.6632 50.2286 78.6632C49.5187 78.6632 48.9432 79.2387 48.9432 79.9486C48.9432 80.6585 49.5187 81.2339 50.2286 81.2339Z" fill="#21365F"/>
    <path d="M79.7917 9.25446H20.6657V68.3804H79.7917V9.25446Z" fill="#BDC3D8"/>
    <path d="M75.9356 9.25446H20.6657V64.5244H73.3649C74.7788 64.5244 75.9356 63.3676 75.9356 61.9537V9.25446Z" fill="white"/>
    <path d="M79.7915 69.2802H20.6655C20.1514 69.2802 19.6373 68.8946 19.6373 68.2519V9.12594C19.6373 8.6118 20.0229 8.09766 20.6655 8.09766H79.7915C80.3057 8.09766 80.8198 8.48326 80.8198 9.12594V68.2519C80.6913 68.8946 80.3057 69.2802 79.7915 69.2802ZM21.5653 67.3522H78.7632V10.1542H21.5653V67.3522Z" fill="#21365F"/>
    <path d="M83.6477 69.2802H16.8096C16.2955 69.2802 15.7814 68.8946 15.7814 68.2519C15.7814 67.6092 16.167 67.2236 16.8096 67.2236H83.6477C84.1618 67.2236 84.676 67.6092 84.676 68.2519C84.676 68.8946 84.1618 69.2802 83.6477 69.2802Z" fill="#21365F"/>
    <path d="M83.6477 10.1542H16.8096C16.2955 10.1542 15.7814 9.76861 15.7814 9.12594C15.7814 8.6118 16.167 8.09766 16.8096 8.09766H83.6477C84.1618 8.09766 84.676 8.48326 84.676 9.12594C84.5475 9.76861 84.1618 10.1542 83.6477 10.1542Z" fill="#21365F"/>
    <path d="M28.3777 64.1388C27.8636 64.1388 27.3494 63.7532 27.3494 63.1105V14.2673C27.3494 13.7532 27.735 13.239 28.3777 13.239C29.0204 13.239 29.406 13.6247 29.406 14.2673V63.1105C29.2775 63.7532 28.8918 64.1388 28.3777 64.1388Z" fill="#21365F"/>
    <path d="M74.6502 61.5681H25.807C25.2928 61.5681 24.7787 61.1825 24.7787 60.5398C24.7787 60.0257 25.1643 59.5116 25.807 59.5116H74.6502C75.1643 59.5116 75.6784 59.8972 75.6784 60.5398C75.5499 61.1825 75.1643 61.5681 74.6502 61.5681Z" fill="#21365F"/>
    <path d="M28.3777 53.856H25.807C25.2928 53.856 24.7787 53.4704 24.7787 52.8278C24.7787 52.3136 25.1643 51.7995 25.807 51.7995H28.3777C28.8918 51.7995 29.4059 52.1851 29.4059 52.8278C29.2774 53.4704 28.8918 53.856 28.3777 53.856Z" fill="#21365F"/>
    <path d="M28.3777 46.1439H25.807C25.2928 46.1439 24.7787 45.7583 24.7787 45.1157C24.7787 44.6015 25.1643 44.0874 25.807 44.0874H28.3777C28.8918 44.0874 29.4059 44.473 29.4059 45.1157C29.2774 45.7583 28.8918 46.1439 28.3777 46.1439Z" fill="#21365F"/>
    <path d="M28.3777 38.4319H25.807C25.2928 38.4319 24.7787 38.0463 24.7787 37.4036C24.7787 36.8895 25.1643 36.3753 25.807 36.3753H28.3777C28.8918 36.3753 29.4059 36.7609 29.4059 37.4036C29.2774 38.0463 28.8918 38.4319 28.3777 38.4319Z" fill="#21365F"/>
    <path d="M28.3777 30.7198H25.807C25.2928 30.7198 24.7787 30.3342 24.7787 29.6915C24.7787 29.1774 25.1643 28.6632 25.807 28.6632H28.3777C28.8918 28.6632 29.4059 29.0488 29.4059 29.6915C29.2774 30.3342 28.8918 30.7198 28.3777 30.7198Z" fill="#21365F"/>
    <path d="M28.3777 23.0077H25.807C25.2928 23.0077 24.7787 22.6221 24.7787 21.9794C24.7787 21.4653 25.1643 20.9511 25.807 20.9511H28.3777C28.8918 20.9511 29.4059 21.3367 29.4059 21.9794C29.2774 22.6221 28.8918 23.0077 28.3777 23.0077Z" fill="#21365F"/>
    <path d="M36.8612 37.5321L28.3779 60.6684H45.2159L36.8612 37.5321Z" fill="#BDC3D8"/>
    <path d="M36.8612 37.5321L28.3779 60.6684H37.5038L36.8612 37.5321Z" fill="white"/>
    <path d="M45.2157 61.5681H28.3777C28.1206 61.5681 27.735 61.4396 27.6065 61.1825C27.4779 60.9254 27.3494 60.5398 27.4779 60.2828L35.9612 37.1465C36.0897 36.7609 36.4754 36.5038 36.861 36.5038C37.2466 36.5038 37.6322 36.7609 37.7607 37.1465L46.244 60.2828C46.3725 60.5398 46.3725 60.9254 46.1155 61.1825C45.8584 61.4396 45.6013 61.5681 45.2157 61.5681ZM29.7915 59.6401H43.9304L36.861 40.2314L29.7915 59.6401Z" fill="#21365F"/>
    <path d="M50.8714 22.1079L39.6888 60.6683H62.0539L50.8714 22.1079Z" fill="#BDC3D8"/>
    <path d="M50.8714 22.1079L39.6888 60.6683H51.7711L50.8714 22.1079Z" fill="white"/>
    <path d="M62.0539 61.5681H39.6889C39.4318 61.5681 39.0462 61.4396 38.9177 61.1825C38.7891 60.9255 38.6606 60.6684 38.7891 60.2828L49.9717 21.7224C50.1002 21.3368 50.4858 21.0797 50.8714 21.0797C51.257 21.0797 51.6426 21.3368 51.7711 21.7224L62.9537 60.2828C63.0822 60.5399 62.9537 60.9255 62.8251 61.1825C62.5681 61.4396 62.311 61.5681 62.0539 61.5681ZM40.9742 59.6401H60.7686L50.8714 25.4499L40.9742 59.6401Z" fill="#21365F"/>
    <path d="M63.0821 29.82L51.514 60.6684H74.6502L63.0821 29.82Z" fill="#BDC3D8"/>
    <path d="M63.0821 29.82L51.514 60.6684H65.6528L63.0821 29.82Z" fill="white"/>
    <path d="M74.6503 61.5681H51.514C51.2569 61.5681 50.8713 61.4396 50.7428 61.1825C50.6143 60.9254 50.4857 60.5398 50.6143 60.2828L62.1824 29.4344C62.3109 29.0488 62.6965 28.7917 63.0821 28.7917C63.4677 28.7917 63.8534 29.0488 63.9819 29.4344L75.55 60.2828C75.6785 60.5398 75.6785 60.9254 75.4215 61.1825C75.2929 61.4396 74.9073 61.5681 74.6503 61.5681ZM52.9279 59.6401H73.2364L63.0821 32.5193L52.9279 59.6401Z" fill="#21365F"/>
    <path d="M7.81225 19.1517H2.67086C2.15672 19.1517 1.64258 18.7661 1.64258 18.1234C1.64258 17.4807 2.02818 17.0951 2.67086 17.0951H7.81225C8.32639 17.0951 8.84052 17.4807 8.84052 18.1234C8.84052 18.7661 8.32639 19.1517 7.81225 19.1517Z" fill="#21365F"/>
    <path d="M5.2416 21.7223C4.72746 21.7223 4.21332 21.3367 4.21332 20.6941V15.5527C4.21332 15.0385 4.59892 14.5244 5.2416 14.5244C5.88427 14.5244 6.26987 14.91 6.26987 15.5527V20.6941C6.14134 21.3367 5.75574 21.7223 5.2416 21.7223Z" fill="#21365F"/>
    <path d="M5.2416 26.9923C5.8095 26.9923 6.26987 26.5319 6.26987 25.964C6.26987 25.3961 5.8095 24.9357 5.2416 24.9357C4.6737 24.9357 4.21332 25.3961 4.21332 25.964C4.21332 26.5319 4.6737 26.9923 5.2416 26.9923Z" fill="#21365F"/>
    <path d="M5.2416 30.8483C5.8095 30.8483 6.26987 30.3879 6.26987 29.82C6.26987 29.2521 5.8095 28.7917 5.2416 28.7917C4.6737 28.7917 4.21332 29.2521 4.21332 29.82C4.21332 30.3879 4.6737 30.8483 5.2416 30.8483Z" fill="#21365F"/>
    <path d="M5.2416 35.9897C5.8095 35.9897 6.26987 35.5294 6.26987 34.9614C6.26987 34.3935 5.8095 33.9332 5.2416 33.9332C4.6737 33.9332 4.21332 34.3935 4.21332 34.9614C4.21332 35.5294 4.6737 35.9897 5.2416 35.9897Z" fill="#21365F"/>
    <path d="M5.2416 43.7018C5.8095 43.7018 6.26987 43.2414 6.26987 42.6735C6.26987 42.1056 5.8095 41.6452 5.2416 41.6452C4.6737 41.6452 4.21332 42.1056 4.21332 42.6735C4.21332 43.2414 4.6737 43.7018 5.2416 43.7018Z" fill="#21365F"/>
    <path d="M97.7865 88.3033H92.6452C92.131 88.3033 91.6169 87.9177 91.6169 87.275C91.6169 86.7609 92.0025 86.2468 92.6452 86.2468H97.7865C98.3007 86.2468 98.8148 86.6324 98.8148 87.275C98.6863 87.7892 98.3007 88.3033 97.7865 88.3033Z" fill="#21365F"/>
    <path d="M95.2158 90.874C94.7017 90.874 94.1876 90.4884 94.1876 89.8457V84.7044C94.1876 84.1902 94.5732 83.6761 95.2158 83.6761C95.8585 83.6761 96.2441 84.0617 96.2441 84.7044V89.8457C96.1156 90.3599 95.73 90.874 95.2158 90.874Z" fill="#21365F"/>
    <path d="M95.2158 80.5912C95.7837 80.5912 96.2441 80.1309 96.2441 79.563C96.2441 78.9951 95.7837 78.5347 95.2158 78.5347C94.6479 78.5347 94.1876 78.9951 94.1876 79.563C94.1876 80.1309 94.6479 80.5912 95.2158 80.5912Z" fill="#21365F"/>
    <path d="M95.2158 76.7352C95.7837 76.7352 96.2441 76.2748 96.2441 75.7069C96.2441 75.139 95.7837 74.6786 95.2158 74.6786C94.6479 74.6786 94.1876 75.139 94.1876 75.7069C94.1876 76.2748 94.6479 76.7352 95.2158 76.7352Z" fill="#21365F"/>
    <path d="M95.2158 71.5938C95.7837 71.5938 96.2441 71.1334 96.2441 70.5655C96.2441 69.9976 95.7837 69.5373 95.2158 69.5373C94.6479 69.5373 94.1876 69.9976 94.1876 70.5655C94.1876 71.1334 94.6479 71.5938 95.2158 71.5938Z" fill="#21365F"/>
    <path d="M95.2158 63.8817C95.7837 63.8817 96.2441 63.4213 96.2441 62.8534C96.2441 62.2855 95.7837 61.8252 95.2158 61.8252C94.6479 61.8252 94.1876 62.2855 94.1876 62.8534C94.1876 63.4213 94.6479 63.8817 95.2158 63.8817Z" fill="#21365F"/>
  </SvgIcon>);
};
export default AnalyticsEmptyIcon;

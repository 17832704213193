/* eslint-disable indent */
import moize from 'moize';
import * as Actions from './types/merchandising';
import { createAction } from './utils';
export const MODEL_ACTION_TYPE = Symbol('__MODEL_ACTION__');
export const creators = Object.assign({}, mapActionCreators({
    storeOneModel: (model) => createAction(Actions.STORE_ONE_MODEL, model),
    storeAllModels: (models) => createAction(Actions.STORE_ALL_MODELS, models),
    stopHeartbeat: () => createAction(Actions.STOP_HEARTBEAT),
}, modelAction));
// eslint-disable-next-line space-before-function-paren
export function mapActionCreators(actionCreators, wrapper) {
    return Object.keys(actionCreators).reduce((acc, key) => {
        acc[key] = wrapper(actionCreators[key]);
        return acc;
    }, {});
}
export function modelAction(creator) {
    return moize((modelType, ...args) => {
        const action = creator(...args);
        return Object.assign(Object.assign({}, action), { meta: Object.assign(Object.assign({}, action.meta), { modelType, [MODEL_ACTION_TYPE]: true }) });
    });
}
export function withArea(area) {
    return (action) => {
        if (area) {
            return Object.assign(Object.assign({}, action), { meta: Object.assign(Object.assign({}, action.meta), { area }) });
        }
        return action;
    };
}
export function isModelAction(action) {
    return action.meta && action.meta[MODEL_ACTION_TYPE];
}

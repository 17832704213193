import styled from 'styled-components';
export const StyledDateFilterContainer = styled.div `
  && {
    padding: ${({ theme }) => theme.spacing(1)};;
    .react-datepicker {
      box-shadow: none;
    }

    .react-datepicker__day-names {
      gap: unset;
    }

    .react-datepicker__day-name {
      margin: unset;
    }
  }

`;
export const StyledDateFilterActionContainer = styled.div `
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

import { assocPath, hasPath, isNil, mergeDeepRight, path as ramdaPath, } from 'ramda';
export default function persistSubsetSelector(state, pathList) {
    /**
     * Returns subset for sourceSlice by the given path
     * I.e. ['a', 'b', 'c'] for path and { a: { b: { c: 42, d: 43 } e: 44 } f: 45 } for sourceSlice,
     * should return { a: { b: { c: 42 }
    */
    function createSubset(path, sourceSlice) {
        if (!hasPath(path, sourceSlice))
            return null;
        const value = ramdaPath(path, sourceSlice);
        if (isNil(value))
            return null;
        return assocPath(path, value, {});
    }
    return pathList
        .map((path) => {
        const keys = path.split('.');
        const targetSlice = createSubset(keys, state);
        if (targetSlice)
            return targetSlice;
        return null;
    })
        .reduce((acc, item) => mergeDeepRight(acc, item || {}), {});
}

import { __rest } from "tslib";
import React from 'react';
import { baseColors } from '@groupby/ui-components';
import SvgIcon from '@mui/material/SvgIcon';
export const CatalogControlsNavLinkIcon = (_a) => {
    var { height = 24, width = 24, color = baseColors.primaryBlueMed400 } = _a, props = __rest(_a, ["height", "width", "color"]);
    return (<SvgIcon width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect x="4" y="2" width="16" height="20" rx="4" stroke={color} strokeWidth="1.5"/>
    <path d="M8 7H16" stroke={color} strokeWidth="1.5" strokeLinecap="round"/>
    <path d="M8 12H16" stroke={color} strokeWidth="1.5" strokeLinecap="round"/>
    <path d="M8 17H12" stroke={color} strokeWidth="1.5" strokeLinecap="round"/>
  </SvgIcon>);
};
export default CatalogControlsNavLinkIcon;

import { __rest } from "tslib";
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { compose } from 'ramda';
import { setDisplayName, wrapDisplayName } from 'recompose';
import * as Selectors from '@/store/selectors';
import { connect } from '@/utils';
import { SearchForRetailUtilities } from '@/search-for-retail/utilities';
import { Migration } from '@/constants';
const selectors = {
    activeUser: Selectors.activeUserSelector,
};
const enhance = (Component) => compose(setDisplayName(wrapDisplayName(Component, 'authorized')), connect(selectors));
export default function authorized(roles) {
    return (Component) => (enhance(Component)(function AuthorizedComponent(_a) {
        var { activeUser } = _a, props = __rest(_a, ["activeUser"]);
        const navigate = useNavigate();
        if (roles && !roles.every((role) => { var _a; return (_a = activeUser === null || activeUser === void 0 ? void 0 : activeUser.allowedAccess) === null || _a === void 0 ? void 0 : _a.includes(role); })) {
            // TODO: refactor this after "S4R-4160_Migrate-to-data-routes" branch is merged and tested for a regression
            navigate(SearchForRetailUtilities.navigateTo(), { state: Migration.REDUX_ROUTER_STATE });
            // redirect(navigateTo());
            return null;
        }
        return <Component {...props}/>;
    }));
}

import { __rest } from "tslib";
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
export const VisitorsIcon = (_a) => {
    var { width = 72, height = 80 } = _a, props = __rest(_a, ["width", "height"]);
    return (<SvgIcon xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 72 80" fill="none" {...props}>
    <path d="M26.9 28.0978C34.632 28.0978 40.9 21.8298 40.9 14.0978C40.9 6.36582 34.632 0.0978088 26.9 0.0978088C19.168 0.0978088 12.9 6.36582 12.9 14.0978C12.9 21.8298 19.168 28.0978 26.9 28.0978Z" fill="#E8EBEC"/>
    <path d="M12 62.1978C18.6274 62.1978 24 56.8252 24 50.1978C24 43.5704 18.6274 38.1978 12 38.1978C5.37258 38.1978 0 43.5704 0 50.1978C0 56.8252 5.37258 62.1978 12 62.1978Z" fill="#E8EBEC"/>
    <path d="M52 79.7978C63.0457 79.7978 72 70.8435 72 59.7978C72 48.7521 63.0457 39.7978 52 39.7978C40.9543 39.7978 32 48.7521 32 59.7978C32 70.8435 40.9543 79.7978 52 79.7978Z" fill="#E8EBEC"/>
    <path d="M61 64.7978H5V71.7978H61V64.7978Z" fill="#BDC3D8"/>
    <path d="M56 68.7978H5V64.7978H57V67.7978C57 68.3978 56.5 68.7978 56 68.7978Z" fill="white"/>
    <path d="M61.0001 72.5978H5.00007C4.60007 72.5978 4.20007 72.2978 4.20007 71.7978V64.7978C4.20007 64.3978 4.50007 63.9978 5.00007 63.9978H61.0001C61.4001 63.9978 61.8001 64.2978 61.8001 64.7978V71.7978C61.7001 72.1978 61.4001 72.5978 61.0001 72.5978ZM5.70007 71.0978H60.2001V65.5978H5.70007V71.0978Z" fill="#21365F"/>
    <path d="M65 64.7978H1L9 55.7978H57L65 64.7978Z" fill="#BDC3D8"/>
    <path d="M56.9001 61.7978H3.6001L8.9001 55.7978H52.6001L57.2001 60.9978C57.5001 61.2978 57.3001 61.7978 56.9001 61.7978Z" fill="white"/>
    <path d="M65.0001 65.5978H1.00006C0.700061 65.5978 0.40006 65.3978 0.30006 65.1978C0.20006 64.8978 0.200061 64.5978 0.400061 64.3978L8.40006 55.3978C8.50006 55.1978 8.70006 55.0978 9.00006 55.0978H57.0001C57.2001 55.0978 57.4001 55.1978 57.6001 55.3978L65.6001 64.3978C65.8001 64.5978 65.8001 64.8978 65.7001 65.1978C65.5001 65.3978 65.3001 65.5978 65.0001 65.5978ZM2.70006 64.0978H63.4001L56.7001 56.5978H9.30006L2.70006 64.0978Z" fill="#21365F"/>
    <path d="M31.7 48.1978L28.1 47.2978H24H19.9L16.3 48.1978C14.9 48.5978 14 49.7978 14 51.1978V55.7978H34V51.1978C34 49.7978 33 48.5978 31.7 48.1978Z" fill="white"/>
    <path d="M32.4 48.4978C30.9 50.6978 28.4 52.5978 25.2 53.4978C20.9 54.6978 16.5 53.7978 14 51.3978V55.7978H24H34V51.1978C34 50.0978 33.4 49.0978 32.4 48.4978Z" fill="#BDC3D8"/>
    <path d="M34.0001 56.5978H14.0001C13.6001 56.5978 13.2001 56.2978 13.2001 55.7978V51.1978C13.2001 49.4978 14.4001 47.8978 16.1001 47.4978L19.7001 46.5978C19.8001 46.5978 19.8001 46.5978 19.9001 46.5978H28.2001C28.3001 46.5978 28.3001 46.5978 28.4001 46.5978L32.0001 47.4978C33.7001 47.8978 34.9001 49.4978 34.9001 51.1978V55.7978C34.7001 56.1978 34.4001 56.5978 34.0001 56.5978ZM14.7001 55.0978H33.2001V51.2978C33.2001 50.1978 32.5001 49.2978 31.5001 48.9978L28.0001 48.0978H19.9001L16.4001 48.9978C15.4001 49.2978 14.7001 50.1978 14.7001 51.2978V55.0978Z" fill="#21365F"/>
    <path d="M24 52.1978C24 52.1978 28.1 48.9978 28.1 47.2978C27.2 46.8978 26.6 45.9978 26.4 45.0978C26.4 44.9978 26.4 43.9978 26.3 43.8978H21.6C21.6 44.0978 21.6 44.9978 21.5 45.1978C21.3 46.1978 20.7 46.8978 19.8 47.3978C19.9 48.9978 24 52.1978 24 52.1978Z" fill="white"/>
    <path d="M27.0001 49.3978C27.7001 48.5978 28.2001 47.8978 28.2001 47.2978C27.3001 46.8978 26.7001 45.9978 26.5001 45.0978C26.5001 44.9978 26.5001 43.9978 26.4001 43.8978H24.1001C24.1001 44.2978 24.2001 45.4978 24.2001 45.7978C24.5001 47.2978 25.5001 48.6978 27.0001 49.3978Z" fill="#BDC3D8"/>
    <path d="M24.0001 52.9978C23.8001 52.9978 23.7001 52.8978 23.5001 52.7978C22.5001 51.9978 19.1001 49.1978 19.1001 47.2978C19.1001 46.9978 19.3001 46.6978 19.5001 46.5978C20.2001 46.2978 20.6001 45.6978 20.8001 44.9978C20.8001 44.8978 20.8001 44.5978 20.8001 44.2978C20.8001 44.0978 20.8001 43.7978 20.8001 43.7978C20.8001 43.3978 21.2001 43.0978 21.5001 43.0978H26.2001C26.6001 43.0978 26.9001 43.3978 26.9001 43.7978C26.9001 43.8978 26.9001 44.0978 26.9001 44.2978C26.9001 44.5978 26.9001 44.7978 26.9001 44.9978C27.0001 45.6978 27.5001 46.2978 28.2001 46.6978C28.5001 46.7978 28.6001 47.0978 28.6001 47.3978C28.6001 49.2978 25.2001 52.0978 24.2001 52.8978C24.3001 52.8978 24.1001 52.9978 24.0001 52.9978ZM20.7001 47.6978C21.1001 48.5978 22.5001 50.0978 24.0001 51.2978C25.5001 50.0978 26.9001 48.5978 27.3001 47.6978C26.5001 47.0978 25.9001 46.1978 25.7001 45.1978C25.7001 45.0978 25.7001 44.8978 25.7001 44.5978H22.4001C22.4001 44.8978 22.4001 45.1978 22.3001 45.2978C22.1001 46.2978 21.5001 47.0978 20.7001 47.6978Z" fill="#21365F"/>
    <path d="M28.8001 39.7978C28.8001 36.5978 26.6001 34.7978 24.0001 34.7978C21.4001 34.7978 19.2001 36.5978 19.2001 39.7978C19.2001 42.9978 21.4001 45.5978 24.0001 45.5978C26.6001 45.5978 28.8001 42.9978 28.8001 39.7978Z" fill="white"/>
    <path d="M25.1001 34.8978C25.5001 35.5978 25.7001 36.4978 25.7001 37.4978C25.7001 40.6978 23.5001 43.2978 20.9001 43.2978C20.6001 43.2978 20.3001 43.2978 20.1001 43.1978C21.0001 44.6978 22.4001 45.5978 24.0001 45.5978C26.7001 45.5978 28.8001 42.9978 28.8001 39.7978C28.8001 37.0978 27.2001 35.3978 25.1001 34.8978Z" fill="#BDC3D8"/>
    <path d="M24 46.2978C20.9 46.2978 18.4 43.3978 18.4 39.7978C18.4 36.3978 20.7 33.9978 24 33.9978C27.3 33.9978 29.6 36.3978 29.6 39.7978C29.5 43.3978 27 46.2978 24 46.2978ZM24 35.5978C21.5 35.5978 19.9 37.2978 19.9 39.8978C19.9 42.6978 21.7 44.8978 24 44.8978C26.2 44.8978 28.1 42.5978 28.1 39.8978C28 37.1978 26.4 35.5978 24 35.5978Z" fill="#21365F"/>
    <path d="M49.7 46.1978L46.1 45.2978H42H37.9L34.3 46.1978C32.9 46.5978 32 47.7978 32 49.1978V55.7978H52V49.1978C52 47.7978 51 46.5978 49.7 46.1978Z" fill="white"/>
    <path d="M52 49.1978C52 47.7978 51.1001 46.5978 49.7001 46.1978L49.3 46.0978C49.1 48.9978 47.3 53.8978 44.3 55.7978H52V49.1978Z" fill="#BDC3D8"/>
    <path d="M52.0001 56.5978H32.0001C31.6001 56.5978 31.2001 56.2978 31.2001 55.7978V49.1978C31.2001 47.4978 32.4001 45.8978 34.1001 45.4978L37.7001 44.5978C37.8001 44.5978 37.8001 44.5978 37.9001 44.5978H46.2001C46.3001 44.5978 46.3001 44.5978 46.4001 44.5978L50.0001 45.4978C51.7001 45.8978 52.9001 47.4978 52.9001 49.1978V55.7978C52.7001 56.1978 52.4001 56.5978 52.0001 56.5978ZM32.7001 55.0978H51.2001V49.2978C51.2001 48.1978 50.5001 47.2978 49.5001 46.9978L46.0001 46.0978H37.9001L34.4001 46.9978C33.4001 47.2978 32.7001 48.1978 32.7001 49.2978V55.0978Z" fill="#21365F"/>
    <path d="M42 48.1978C42 48.1978 46.1 46.9978 46.1 45.2978C45.2 44.8978 44.6 43.9978 44.4 43.0978C44.4 42.9978 44.4 41.9978 44.3 41.8978H39.6C39.6 42.0978 39.6 42.9978 39.5 43.1978C39.3 44.1978 38.7 44.8978 37.8 45.3978C37.9 46.9978 42 48.1978 42 48.1978Z" fill="white"/>
    <path d="M46.1 45.2978C45.2 44.8978 44.6 43.9978 44.4 43.0978C44.4 42.9978 44.4 41.9978 44.3 41.8978L42 42.2978C42 42.4978 42.1 43.7978 42.1 43.9978C42.4 45.3978 43.2 46.4978 44.4 47.0978C45.3 46.6978 46.1 45.9978 46.1 45.2978Z" fill="#BDC3D8"/>
    <path d="M42.0001 48.9978C41.9001 48.9978 41.9001 48.9978 41.8001 48.9978C41.0001 48.7978 37.2001 47.4978 37.1001 45.3978C37.1001 45.0978 37.3001 44.7978 37.5001 44.6978C38.2001 44.3978 38.6001 43.7978 38.8001 43.0978C38.8001 42.9978 38.8001 42.6978 38.8001 42.3978C38.8001 42.1978 38.8001 41.8978 38.8001 41.8978C38.8001 41.4978 39.2001 41.1978 39.5001 41.1978H44.2001C44.6001 41.1978 44.9001 41.4978 44.9001 41.8978C44.9001 41.9978 44.9001 42.1978 44.9001 42.3978C44.9001 42.6978 44.9001 42.8978 44.9001 43.0978C45.0001 43.7978 45.5001 44.3978 46.2001 44.7978C46.5001 44.8978 46.6001 45.1978 46.6001 45.4978C46.6001 47.5978 42.7001 48.8978 41.9001 49.0978C42.1001 48.8978 42.1001 48.9978 42.0001 48.9978ZM38.7001 45.5978C39.2001 46.1978 40.6001 46.8978 41.9001 47.3978C43.3001 46.9978 44.7001 46.1978 45.1001 45.5978C44.3001 44.9978 43.7001 44.0978 43.5001 43.1978C43.5001 43.0978 43.5001 42.8978 43.5001 42.5978H40.2001C40.2001 42.8978 40.2001 43.1978 40.1001 43.2978C40.1001 44.1978 39.5001 45.0978 38.7001 45.5978Z" fill="#21365F"/>
    <path d="M46.8001 37.7978C46.8001 34.5978 44.6001 32.7978 42.0001 32.7978C39.4001 32.7978 37.2001 34.5978 37.2001 37.7978C37.2001 40.9978 39.4001 43.5978 42.0001 43.5978C44.6001 43.5978 46.8001 40.9978 46.8001 37.7978Z" fill="white"/>
    <path d="M43.1001 32.8978C43.5001 33.5978 43.7001 34.4978 43.7001 35.4978C43.7001 38.6978 41.5001 41.2978 38.9001 41.2978C38.6001 41.2978 38.3001 41.2978 38.1001 41.1978C39.0001 42.6978 40.4001 43.5978 42.0001 43.5978C44.7001 43.5978 46.8001 40.9978 46.8001 37.7978C46.8001 35.0978 45.2001 33.3978 43.1001 32.8978Z" fill="#BDC3D8"/>
    <path d="M42 44.2978C38.9 44.2978 36.4 41.3978 36.4 37.7978C36.4 34.3978 38.7 31.9978 42 31.9978C45.3 31.9978 47.6 34.3978 47.6 37.7978C47.5 41.3978 45 44.2978 42 44.2978ZM42 33.5978C39.5 33.5978 37.9 35.2978 37.9 37.8978C37.9 40.6978 39.7 42.8978 42 42.8978C44.2 42.8978 46.1 40.5978 46.1 37.8978C46 35.1978 44.4 33.5978 42 33.5978Z" fill="#21365F"/>
    <path d="M42.0001 56.5978C41.6001 56.5978 41.2001 56.2978 41.2001 55.7978V48.1978C41.2001 47.7978 41.5001 47.3978 42.0001 47.3978C42.5001 47.3978 42.8001 47.6978 42.8001 48.1978V55.7978C42.7001 56.1978 42.4001 56.5978 42.0001 56.5978Z" fill="#21365F"/>
    <path d="M7 13.7978V27.7978C7 28.8978 7.9 29.7978 9 29.7978H13L12 35.7978L21 29.7978H33C34.1 29.7978 35 28.8978 35 27.7978V13.7978C35 12.6978 34.1 11.7978 33 11.7978H9C7.9 11.7978 7 12.6978 7 13.7978Z" fill="#BDC3D8"/>
    <path d="M7 13.7978V27.7978C7 28.8978 7.9 29.7978 9 29.7978H13L12.7 31.6978L20 26.7978H26C27.1 26.7978 28 25.8978 28 24.7978V11.7978H9C7.9 11.7978 7 12.6978 7 13.7978Z" fill="white"/>
    <path d="M12 36.5978C11.9 36.5978 11.7 36.5978 11.6 36.4978C11.3 36.2978 11.2 35.9978 11.3 35.6978L12.2 30.5978H9.10005C7.60005 30.5978 6.30005 29.3978 6.30005 27.7978V13.7978C6.30005 12.2978 7.50005 10.9978 9.10005 10.9978H33.1C34.6 10.9978 35.9 12.1978 35.9 13.7978V27.7978C35.9 29.2978 34.7 30.5978 33.1 30.5978H21.2L12.4 36.4978C12.3 36.4978 12.1 36.5978 12 36.5978ZM9.00005 12.5978C8.30005 12.5978 7.70005 13.1978 7.70005 13.8978V27.8978C7.70005 28.5978 8.30005 29.1978 9.00005 29.1978H13C13.2 29.1978 13.4 29.2978 13.6 29.4978C13.7 29.6978 13.8 29.8978 13.8 30.0978L13.1 34.3978L20.7 29.3978C20.8 29.2978 21 29.2978 21.1 29.2978H33.1C33.8 29.2978 34.4 28.6978 34.4 27.9978V13.9978C34.4 13.2978 33.8 12.6978 33.1 12.6978H9.00005V12.5978Z" fill="#21365F"/>
    <path d="M13.4001 19.5978H12.0001C11.6001 19.5978 11.2001 19.2978 11.2001 18.7978C11.2001 18.2978 11.5001 17.9978 12.0001 17.9978H13.4001C13.8001 17.9978 14.2001 18.2978 14.2001 18.7978C14.2001 19.2978 13.8001 19.5978 13.4001 19.5978Z" fill="#21365F"/>
    <path d="M18 19.5978H16.6C16.2 19.5978 15.8 19.2978 15.8 18.7978C15.8 18.2978 16.1 17.9978 16.6 17.9978H18C18.4 17.9978 18.8 18.2978 18.8 18.7978C18.8 19.2978 18.4 19.5978 18 19.5978Z" fill="#21365F"/>
    <path d="M15.0001 22.5978C14.6001 22.5978 14.2001 22.2978 14.2001 21.7978V20.3978C14.2001 19.9978 14.5001 19.5978 15.0001 19.5978C15.5001 19.5978 15.8001 19.8978 15.8001 20.3978V21.7978C15.7001 22.1978 15.4001 22.5978 15.0001 22.5978Z" fill="#21365F"/>
    <path d="M15.0001 17.8978C14.6001 17.8978 14.2001 17.5978 14.2001 17.0978V15.6978C14.2001 15.2978 14.5001 14.8978 15.0001 14.8978C15.5001 14.8978 15.8001 15.1978 15.8001 15.6978V17.0978C15.7001 17.5978 15.4001 17.8978 15.0001 17.8978Z" fill="#21365F"/>
    <path d="M23.4001 24.5978H22.0001C21.6001 24.5978 21.2001 24.2978 21.2001 23.7978C21.2001 23.2978 21.5001 22.9978 22.0001 22.9978H23.4001C23.8001 22.9978 24.2001 23.2978 24.2001 23.7978C24.2001 24.2978 23.8001 24.5978 23.4001 24.5978Z" fill="#21365F"/>
    <path d="M28 24.5978H26.6C26.2 24.5978 25.8 24.2978 25.8 23.7978C25.8 23.2978 26.1 22.9978 26.6 22.9978H28C28.4 22.9978 28.8 23.2978 28.8 23.7978C28.8 24.2978 28.4 24.5978 28 24.5978Z" fill="#21365F"/>
    <path d="M25.0001 27.5978C24.6001 27.5978 24.2001 27.2978 24.2001 26.7978V25.3978C24.2001 24.9978 24.5001 24.5978 25.0001 24.5978C25.5001 24.5978 25.8001 24.8978 25.8001 25.3978V26.7978C25.7001 27.1978 25.4001 27.5978 25.0001 27.5978Z" fill="#21365F"/>
    <path d="M25.0001 22.8978C24.6001 22.8978 24.2001 22.5978 24.2001 22.0978V20.6978C24.2001 20.2978 24.5001 19.8978 25.0001 19.8978C25.5001 19.8978 25.8001 20.1978 25.8001 20.6978V22.0978C25.7001 22.5978 25.4001 22.8978 25.0001 22.8978Z" fill="#21365F"/>
    <path d="M31 9.79779V23.7978C31 24.8978 31.9 25.7978 33 25.7978H45L54 31.7978L53 25.7978H57C58.1 25.7978 59 24.8978 59 23.7978V9.79779C59 8.69779 58.1 7.79779 57 7.79779H33C31.9 7.79779 31 8.69779 31 9.79779Z" fill="#BDC3D8"/>
    <path d="M31 9.79779V22.7978H54C55.1 22.7978 56 21.8978 56 20.7978V7.79779H33C31.9 7.79779 31 8.69779 31 9.79779Z" fill="white"/>
    <path d="M54.0001 32.5978C53.9001 32.5978 53.7001 32.5978 53.6001 32.4978L44.8001 26.5978H33.0001C31.5001 26.5978 30.2001 25.3978 30.2001 23.7978V9.79781C30.2001 8.29781 31.4001 6.9978 33.0001 6.9978H57.0001C58.5001 6.9978 59.8001 8.19781 59.8001 9.79781V23.7978C59.8001 25.2978 58.6001 26.5978 57.0001 26.5978H53.9001L54.8001 31.6978C54.8001 31.9978 54.7001 32.2978 54.5001 32.4978C54.3001 32.4978 54.1001 32.5978 54.0001 32.5978ZM33.0001 8.59781C32.3001 8.59781 31.7001 9.19781 31.7001 9.89781V23.8978C31.7001 24.5978 32.3001 25.1978 33.0001 25.1978H45.0001C45.1001 25.1978 45.3001 25.1978 45.4001 25.2978L53.0001 30.2978L52.3001 25.9978C52.3001 25.7978 52.3001 25.5978 52.5001 25.3978C52.6001 25.1978 52.9001 25.0978 53.1001 25.0978H57.1001C57.8001 25.0978 58.4001 24.4978 58.4001 23.7978V9.79781C58.4001 9.09781 57.8001 8.4978 57.1001 8.4978H33.0001V8.59781Z" fill="#21365F"/>
    <path d="M49.4001 15.5978H48.0001C47.6001 15.5978 47.2001 15.2978 47.2001 14.7978C47.2001 14.2978 47.5001 13.9978 48.0001 13.9978H49.4001C49.8001 13.9978 50.2001 14.2978 50.2001 14.7978C50.2001 15.2978 49.8001 15.5978 49.4001 15.5978Z" fill="#21365F"/>
    <path d="M54 15.5978H52.6C52.2 15.5978 51.8 15.2978 51.8 14.7978C51.8 14.2978 52.1 13.9978 52.6 13.9978H54C54.4 13.9978 54.8 14.2978 54.8 14.7978C54.8 15.2978 54.4 15.5978 54 15.5978Z" fill="#21365F"/>
    <path d="M51.0001 18.5978C50.6001 18.5978 50.2001 18.2978 50.2001 17.7978V16.3978C50.2001 15.9978 50.5001 15.5978 51.0001 15.5978C51.5001 15.5978 51.8001 15.8978 51.8001 16.3978V17.7978C51.7001 18.1978 51.4001 18.5978 51.0001 18.5978Z" fill="#21365F"/>
    <path d="M51.0001 13.8978C50.6001 13.8978 50.2001 13.5978 50.2001 13.0978V11.6978C50.2001 11.2978 50.5001 10.8978 51.0001 10.8978C51.5001 10.8978 51.8001 11.1978 51.8001 11.6978V13.0978C51.7001 13.5978 51.4001 13.8978 51.0001 13.8978Z" fill="#21365F"/>
    <path d="M38.4001 18.5978H37.0001C36.6001 18.5978 36.2001 18.2978 36.2001 17.7978C36.2001 17.2978 36.5001 16.9978 37.0001 16.9978H38.4001C38.8001 16.9978 39.2001 17.2978 39.2001 17.7978C39.2001 18.2978 38.8001 18.5978 38.4001 18.5978Z" fill="#21365F"/>
    <path d="M43 18.5978H41.6C41.2 18.5978 40.8 18.2978 40.8 17.7978C40.8 17.2978 41.1 16.9978 41.6 16.9978H43C43.4 16.9978 43.8 17.2978 43.8 17.7978C43.8 18.2978 43.4 18.5978 43 18.5978Z" fill="#21365F"/>
    <path d="M40.0001 21.5978C39.6001 21.5978 39.2001 21.2978 39.2001 20.7978V19.3978C39.2001 18.9978 39.5001 18.5978 40.0001 18.5978C40.5001 18.5978 40.8001 18.8978 40.8001 19.3978V20.7978C40.7001 21.1978 40.4001 21.5978 40.0001 21.5978Z" fill="#21365F"/>
    <path d="M40.0001 16.8978C39.6001 16.8978 39.2001 16.5978 39.2001 16.0978V14.6978C39.2001 14.2978 39.5001 13.8978 40.0001 13.8978C40.5001 13.8978 40.8001 14.1978 40.8001 14.6978V16.0978C40.7001 16.5978 40.4001 16.8978 40.0001 16.8978Z" fill="#21365F"/>
  </SvgIcon>);
};
export default VisitorsIcon;

import { pick } from 'ramda';
export class CommonFeatureFlagsService {
    constructor() {
        this.isFeatureFlagKey = (key) => this.featureFlags.has(key);
        this.featureFlags = new Set();
        this.persistedFlags = new Set();
        this.featureFlagCache = {};
    }
    registerFeatureFlags(flags) {
        flags.forEach((featureFlag) => {
            this.featureFlags.add(featureFlag);
        });
    }
    persistFeatureFlags(flags) {
        flags.forEach((featureFlag) => {
            this.persistedFlags.add(featureFlag);
        });
    }
    resetFeatureFlagsStorage() {
        this.featureFlagCache = pick(Array.from(this.persistedFlags.values()), this.featureFlagCache);
    }
}

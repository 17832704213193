import { __awaiter } from "tslib";
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { useLocation } from '@/routing/hooks';
import * as Actions from '@/store/actions/types';
import Alert from '@/components/alert';
import Timer from '@/components/timer';
import { getAuth0Audience } from '@/auth/utilities';
import { LocalizedMessage } from '@/localization';
import { SESSION_INACTIVE_TIMEOUT } from '@/constants';
import { inactiveSessionSelector } from '@/store/selectors';
import { useAuthLogout } from '@/auth/hooks/authentication';
import { useExpireToken } from '@/auth/hooks/use-expire-token';
import { useValidateToken } from '@/auth/hooks/use-validate-token';
import { FeatureFlag, useFeatureFlagsProvider } from '@/search-for-retail/feature-flags';
import { getTenantName } from '@/utils';
const IdleAlert = () => {
    const inactive = useSelector(inactiveSessionSelector) || false;
    const { pathname, search } = useLocation();
    const tenantName = getTenantName();
    const { logout } = useAuthLogout();
    const expireToken = useExpireToken();
    const validateToken = useValidateToken();
    const { featureFlagsService } = useFeatureFlagsProvider();
    const isAuth0Enabled = featureFlagsService.isFeatureEnabled(FeatureFlag.Auth0);
    const { getAccessTokenSilently } = useAuth0();
    const dispatch = useDispatch();
    const handleClick = () => __awaiter(void 0, void 0, void 0, function* () {
        if (isAuth0Enabled) {
            yield getAccessTokenSilently({
                cacheMode: 'off',
                authorizationParams: {
                    audience: getAuth0Audience(tenantName),
                },
            });
            dispatch({ type: Actions.SET_ACTIVE });
        }
        else {
            yield validateToken(pathname, search);
        }
    });
    const handleLogout = () => {
        if (isAuth0Enabled) {
            logout();
        }
        else {
            expireToken(pathname, search);
        }
    };
    return (<Alert title={<span>
          <LocalizedMessage messageKey="LOGIN_SESSION_TIMEOUT_IN"/>
          {' '}
          <Timer timeout={SESSION_INACTIVE_TIMEOUT} onComplete={handleLogout}/>
        </span>} primaryButton={{
        label: <LocalizedMessage messageKey="LOGIN_SESSION_STAY_LOGGED_IN"/>,
        onClick: handleClick,
    }} cancelLabel={<LocalizedMessage messageKey="LOGIN_SESSION_LOGOUT"/>} open={inactive} onCancel={handleLogout}/>);
};
export default IdleAlert;

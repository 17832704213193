import React, { useEffect, useRef, useState } from 'react';
import { StyledBulkActionButtonsContainer, StyledBulkActionContainer, StyledTableHeadCell } from '@/search-for-retail/generic/tableV2/partials/table-header/table-header.styled';
import { useLocalization } from '@/localization';
export const HeaderBulkActions = ({ children, count, selectedCount, bulkActions, tableHasScrollY, }) => {
    const [containerWidth, setContainerWidth] = useState('100%');
    const bulkActionsRef = useRef(null);
    const { formatMessage: t } = useLocalization();
    useEffect(() => {
        if (bulkActionsRef.current) {
            const rect = bulkActionsRef.current.getBoundingClientRect();
            // 24px - is our page padding
            // 16px - is a scroll bar.
            setContainerWidth(`calc(100vw - ${rect.left}px - 24px - ${tableHasScrollY ? '16px' : '0px'})`);
        }
    }, [tableHasScrollY]);
    return (<StyledBulkActionContainer ref={bulkActionsRef} width={containerWidth}>
      {children}
      <StyledTableHeadCell width="100%" minWidth="100%" maxWidth="100%">
        <div style={{ paddingLeft: '1rem' }}>
          {t({ key: 'ITEMS_SELECTED', values: { count: selectedCount, all: count } })}
        </div>
      </StyledTableHeadCell>
      <StyledTableHeadCell align='right' width="300px">
        {bulkActions && (<StyledBulkActionButtonsContainer>
            {bulkActions}
          </StyledBulkActionButtonsContainer>)}
      </StyledTableHeadCell>
    </StyledBulkActionContainer>);
};

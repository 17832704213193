import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { FeatureFlag } from './feature-flags.constants';
import { useActiveArea, useCollectionName } from '@/store/selectors';
import { useFeatureFlagsProvider } from './feature-flags.provider';
import { getTenantName } from '@/utils';
import { useActiveUser } from '@/auth/hooks/authentication';
import { isGbiUser } from './feature-flags.utils';
export const FeatureFlagsUpdateContext = ({ children }) => {
    const { featureFlagsService } = useFeatureFlagsProvider();
    const user = useActiveUser();
    const areaName = useActiveArea();
    const tenantName = getTenantName();
    const auth0Enabled = featureFlagsService.isFeatureEnabled(FeatureFlag.Auth0);
    const collection = useCollectionName();
    const { user: auth0User } = useAuth0();
    const [flagContextUser, setFlagContextUser] = useState();
    useEffect(() => {
        void featureFlagsService.updateFeatureFlagsContext({
            user: flagContextUser,
            configuration: {
                area: areaName,
                collection,
            },
        });
    }, [featureFlagsService, areaName, collection, flagContextUser]);
    useEffect(() => {
        if (auth0Enabled && auth0User && tenantName) {
            const contextUser = {
                name: auth0User.name,
                email: auth0User.email,
                tenantName,
                isGBIUser: isGbiUser(auth0User.email),
            };
            setFlagContextUser(contextUser);
        }
        else if (user) {
            setFlagContextUser(Object.assign(Object.assign({}, user), { isGBIUser: isGbiUser(user.email) }));
        }
    }, [
        auth0Enabled,
        tenantName,
        auth0User,
        user,
    ]);
    return children;
};

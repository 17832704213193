export var RuleFilterValueType;
(function (RuleFilterValueType) {
    RuleFilterValueType["Textual"] = "TEXTUAL";
    RuleFilterValueType["Numeric"] = "NUMERIC";
})(RuleFilterValueType || (RuleFilterValueType = {}));
export var RuleType;
(function (RuleType) {
    RuleType["Regular"] = "REGULAR";
    RuleType["Experiment"] = "EXPERIMENT";
})(RuleType || (RuleType = {}));
export var Status;
(function (Status) {
    Status["Published"] = "published";
    Status["Expired"] = "expired";
    Status["Scheduled"] = "scheduled";
    Status["Draft"] = "draft";
    Status["All"] = "all";
})(Status || (Status = {}));

import React, { useEffect, useRef, useState } from 'react';
import { Table as MuiTable } from '@mui/material';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { StyledTable, StyledTableContainer, StyledTablePaginationContainer } from './table.styles';
import { TableFilterBar } from './partials/table-filter-bar';
import { TableAppliedFilterBar } from './partials/table-applied-filter-bar';
import { TableHeader } from './partials/table-header';
import { TableRows } from './partials/table-rows';
import { TableRowMenu } from './partials/table-row-menu';
import { TablePagination } from '@/search-for-retail/generic';
export const Table = ({ data, paginatedData, isDraggable, isSelectable = true, isMoreOption = false, onRuleClick, columns, menuItems, total, pageNumber, rowsPerPage, setPage, setPerPage, handleMove, selected, setSelected, filter, updateFilter, resetFilter, sort, updateSort, resetSort, setOrder, quickFilters, bulkActions, }) => {
    const tableRef = useRef(null);
    const [tableMaxHeight, setTableMaxHeight] = useState(null);
    const [tableHasScrollY, setTableHasScrollY] = useState(false);
    const [moreMenuAnchorEl, setMoreMenuAnchorEl] = useState(null);
    const [currentIndexMenuOpen, setCurrentIndexMenuOpen] = useState(null);
    const handleMoreOptionsOpen = (index) => (e) => {
        const el = e.currentTarget;
        setMoreMenuAnchorEl(el);
        setCurrentIndexMenuOpen(index);
    };
    const handleMoreOptionsClose = () => {
        setMoreMenuAnchorEl(null);
        setCurrentIndexMenuOpen(null);
    };
    const isSelected = Boolean(selected.length);
    useEffect(() => {
        if (tableRef.current && !tableMaxHeight) {
            const rect = tableRef.current.getBoundingClientRect();
            setTableMaxHeight(`calc(100vh - ${rect.y}px - 1.5rem)`);
        }
    }, [tableMaxHeight]);
    useEffect(() => {
        setTableHasScrollY(tableRef.current.scrollHeight > tableRef.current.clientHeight);
    }, [paginatedData.length, selected.length]);
    const onSelectAll = () => {
        setSelected(selected.length > 0 ? [] : paginatedData);
    };
    return (<StyledTable>
      <TableFilterBar filter={filter} columns={columns} setOrder={setOrder} updateFilter={updateFilter} quickFilters={quickFilters}/>
      <TableAppliedFilterBar filter={filter} updateFilter={updateFilter} resetFilter={resetFilter} sort={sort} resetSort={resetSort} columns={columns}/>

      <TableRowMenu menuItems={menuItems} row={paginatedData[currentIndexMenuOpen]} currentIndexMenuOpen={currentIndexMenuOpen} anchorEl={moreMenuAnchorEl} open={currentIndexMenuOpen !== null && Boolean(moreMenuAnchorEl)} onClose={handleMoreOptionsClose}/>

      <DndProvider backend={HTML5Backend}>
        <StyledTableContainer ref={tableRef} sx={{ maxHeight: tableMaxHeight }}>
          <MuiTable>
            <TableHeader columns={columns} data={data} isIndeterminate={isSelected} count={data.length} selectedCount={selected.length} bulkActions={bulkActions} isSelectedAll={paginatedData.length > 0 && paginatedData.length === selected.length} handleSelectAll={onSelectAll} isSelectable={isSelectable} isMoreOption={isMoreOption} filter={filter} updateFilter={updateFilter} sort={sort} updateSort={updateSort} tableHasScrollY={tableHasScrollY}/>
            <TableRows handleMove={handleMove} isDraggable={isDraggable && !(filter === null || filter === void 0 ? void 0 : filter.search)} isSelectable={isSelectable} isMoreOption={isMoreOption} data={paginatedData} selected={selected} setSelected={setSelected} onRuleClick={onRuleClick} columns={columns} handleMoreOptionsOpen={handleMoreOptionsOpen}/>
          </MuiTable>
          {paginatedData.length > 0 && (<StyledTablePaginationContainer>
              <TablePagination itemsCount={total} pageNumber={pageNumber} rowsPerPage={rowsPerPage} onPageChange={setPage} onRowsPerPageChange={setPerPage}/>
            </StyledTablePaginationContainer>)}
        </StyledTableContainer>
      </DndProvider>
    </StyledTable>);
};

import { __rest } from "tslib";
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { baseColors } from '@groupby/ui-components';
export const UploadCloudIcon = (_a) => {
    var { width = 48, height = 48, color = baseColors.primaryGreen300 } = _a, props = __rest(_a, ["width", "height", "color"]);
    return (<SvgIcon height={height} width={width} viewBox={`0 0 ${width} ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M32 32.0039L24 24.0039L16 32.0039" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M24 24.0039V42.0039" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M40.78 36.7839C42.7307 35.7204 44.2717 34.0376 45.1597 32.0011C46.0478 29.9646 46.2324 27.6903 45.6844 25.5373C45.1364 23.3842 43.887 21.4749 42.1333 20.1108C40.3797 18.7466 38.2217 18.0053 36 18.0039H33.48C32.8746 15.6624 31.7463 13.4885 30.1799 11.6459C28.6135 9.80318 26.6497 8.33957 24.4362 7.36509C22.2227 6.3906 19.8171 5.93059 17.4002 6.01964C14.9834 6.10869 12.6181 6.74447 10.4823 7.87921C8.34657 9.01394 6.49582 10.6181 5.06924 12.571C3.64266 14.524 2.67738 16.7749 2.24596 19.1547C1.81454 21.5344 1.92821 23.9809 2.57842 26.3104C3.22864 28.6399 4.39848 30.7916 6 32.6039" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M32 32.0039L24 24.0039L16 32.0039" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </SvgIcon>);
};
export default UploadCloudIcon;

import { __awaiter } from "tslib";
import { SearchForRetail } from '@/client/api';
import { s4rAuthenticated } from '@/client/utils';
import { parseCollectionImageMetaData } from './collections.parsers';
const COLLECTION_PATH = `${SearchForRetail.API_URL}/project/config/collections`;
export const getCollections = (token, options) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield s4rAuthenticated(token, Object.assign({ url: COLLECTION_PATH, method: 'GET' }, options));
    return response.result;
});
export const getCollectionByName = (token, collectionName) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield s4rAuthenticated(token, {
        url: `${COLLECTION_PATH}/${collectionName}`,
        method: 'GET',
    });
    return response.result;
});
export const updateCollection = (token, collectionName, data) => __awaiter(void 0, void 0, void 0, function* () {
    yield s4rAuthenticated(token, {
        url: `${COLLECTION_PATH}/${collectionName}`,
        method: 'PUT',
        body: data,
    });
});
export const getCollectionImageMetaData = (token, collectionName) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield s4rAuthenticated(token, {
        url: `${COLLECTION_PATH}/${collectionName}/metadata`,
        method: 'GET',
    });
    return parseCollectionImageMetaData(response.result);
});

import styled from 'styled-components';
import { baseColors } from '@groupby/ui-components';
import ListItemButton from '@mui/material/ListItemButton';
export const StyledDrawerItem = styled.div `
  padding: 0 0.5rem 0 1rem;
`;
export const StyledNavigationDrawerSublinkItem = styled(ListItemButton) `
  && {
    display: flex;
    min-height: 2rem;
    padding: 0;
    border: none;
    border-radius: ${({ theme }) => theme.spacing(0.5)};
    margin-left: ${({ $level }) => ($level - 1) * 2}rem;
    width: calc(100% - ${({ $level }) => ($level - 1) * 2}rem);
    font-size: ${({ theme }) => theme.fontSize.formControl};

    &.with-badge {
      display: flex;
    }

    .MuiListItemText-root {
      border-radius: ${({ theme }) => theme.spacing(0.5)};
      padding: 0.5rem 0.5rem 0;
      margin: 0 0 0 ${({ theme }) => theme.spacing(0.5)};
      min-height: 1.5rem;
      width: min-content;
    }

    .MuiTypography-root {
      line-height: 1.1rem;
    }

    &:hover,
    &:focus {
      color: ${baseColors.primaryBlueDark500};
      font-weight: 700;
      background-color: ${baseColors.primaryTeal100};

      .MuiTypography-root {
        font-weight: 700;
      }
    }

    &.active {
      color: ${baseColors.primaryBlueDark500};

      .MuiTypography-root {
        font-weight: 700;
      }
    }
  }

  .hidden {
    display: none;
  }

  .MuiListItemText-root {
    max-width: 100%;
  }
`;
export const StyledVerticalRule = styled.div `
  position: absolute;
  background: ${baseColors.primaryBlueMed600};
  width: 1px;
  top: 0;
  bottom: 0;
  left: ${({ $level }) => $level - 1}rem;
`;

import styled from 'styled-components';
import { baseColors, Box } from '@groupby/ui-components';
export const StyledBox = styled(Box) `
  cursor: default;
  padding: ${({ theme }) => `${theme.spacing(1.376)} ${theme.spacing(2)}`};
  margin: ${({ theme }) => `${theme.spacing(2)} ${theme.spacing(2)} 0 ${theme.spacing(2)}`};
  background-color: ${baseColors.primaryBlueAccent050};
  border: 1px solid ${baseColors.primaryBlueAccent500};
  border-radius: ${({ theme }) => `${theme.shape.borderRadius}px`};

  .title.MuiTypography-gutterBottom {
    margin-bottom: ${({ theme }) => theme.spacing(0.25)};
  }

  .sub-description {
    margin-top: ${({ theme }) => theme.spacing(1)};
  }

  .content-container {
    display: flex;
    align-items: center;
    gap: ${({ theme }) => theme.spacing(2)};

    svg path {
      fill: ${baseColors.primaryBlueAccent500};
    }

    .description-block {
      display: flex;
      flex-direction: column;
    }
  }
`;

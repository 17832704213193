import { __rest } from "tslib";
import React from 'react';
import { baseColors } from '@groupby/ui-components';
import SvgIcon from '@mui/material/SvgIcon';
export const ImageIcon = (_a) => {
    var { width = 16, height = 16, color = baseColors.primaryBlueMed200 } = _a, props = __rest(_a, ["width", "height", "color"]);
    return (<SvgIcon width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect x="1.3335" y="1.33301" width="13.3333" height="13.3333" rx="3" stroke="#C8CDD5" strokeWidth="1.2"/>
    <path d="M1.66687 11.6663L2.7594 10.886C3.47541 10.3745 4.45623 10.4557 5.07842 11.0779L5.15168 11.1511C5.62031 11.6198 6.3801 11.6198 6.84873 11.1511L9.50454 8.49534C10.1628 7.83705 11.2145 7.78934 11.9297 8.38533L14.6669 10.6663" stroke="#C8CDD5" strokeWidth="1.2" strokeLinecap="round"/>
    <circle cx="1.33333" cy="1.33333" r="1.33333" transform="matrix(-1 0 0 1 6.66699 4)" stroke="#C8CDD5" strokeWidth="1.2"/>
  </SvgIcon>);
};
export default ImageIcon;

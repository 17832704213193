import { useCallback, useEffect, useMemo, useState, } from 'react';
import { filterTableDataV2 } from '../utility/table-filter.utils';
import { useTablePersistedData } from './use-table-persisted-data.hook';
export const useTableFilter = (data, columns, { persisted }) => {
    const [initialized, setInitialized] = useState(false);
    const [filter, setFilter] = useState(undefined);
    const { persistedData, setPersistedData } = useTablePersistedData();
    useEffect(() => {
        if (persisted && persistedData.filter) {
            setFilter(persistedData.filter);
        }
        setInitialized(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const setNewPersistedData = useCallback((newFilters) => {
        if (persisted)
            setPersistedData({ filter: newFilters });
    }, [persisted, setPersistedData]);
    const updateFilter = useCallback((newFilter) => {
        setFilter((oldFilter) => {
            const newFilters = (Object.assign(Object.assign({}, oldFilter), newFilter));
            setNewPersistedData(newFilters);
            return newFilters;
        });
    }, [setNewPersistedData]);
    const resetFilter = useCallback((filterKeys) => {
        setFilter((oldFilter) => {
            var _a;
            const newFilters = !(filterKeys === null || filterKeys === void 0 ? void 0 : filterKeys.length)
                ? { search: (_a = oldFilter === null || oldFilter === void 0 ? void 0 : oldFilter.search) !== null && _a !== void 0 ? _a : '', includes: undefined, range: undefined }
                : filterKeys.reduce((acc, name) => (Object.assign(Object.assign({}, acc), { [name]: undefined })), oldFilter);
            setNewPersistedData(newFilters);
            return newFilters;
        });
    }, [setNewPersistedData]);
    const filteredRows = useMemo(() => {
        let _filter;
        if (initialized) {
            _filter = filter;
        }
        else if (persisted) {
            _filter = (persistedData === null || persistedData === void 0 ? void 0 : persistedData.filter) ? persistedData.filter : filter;
        }
        return filterTableDataV2(data, _filter, columns);
    }, [initialized, persisted, persistedData.filter, data, columns, filter]);
    return {
        filteredRows,
        filter,
        actions: { updateFilter, resetFilter },
    };
};
export default useTableFilter;

import { __awaiter } from "tslib";
import { is, isNil } from 'ramda';
import EventEmitter from 'eventemitter3';
import { LaunchDarklyFeatureFlagService } from './launch-darkly-feature-flags-service';
import { EventType, FeatureFlag, LAST_FETCH_TIME_KEY, LD_CLIENT_SIDE_ID_KEY, ONE_HOUR, ProviderType, SINGLE_TENANT_NAME_KEY, } from './feature-flag-service.constants';
import { QueryParamsFeatureFlagsService } from './query-params-feature-flags-service';
import { FeatureFlagPrefix } from '../feature-flags.constants';
import { ApiFeatureFlagsService } from './api-feature-flags-service';
import { logError } from '@/utils/logger';
import { getFeatures } from './feature-flags-service.client';
export class FeatureFlagsService {
    constructor(priorityServiceList) {
        this.priorityServiceList = priorityServiceList;
        this.excludedFlagsFromNext = new Set();
        this.eventManager = new EventEmitter();
        this.serviceMap = new Map();
    }
    initialize(baseContext) {
        return __awaiter(this, void 0, void 0, function* () {
            const featuresResponse = yield this.loadFeatures();
            this.extractAndPersistClientIdFromFeatureResponse(featuresResponse);
            const clientId = this.getClientId();
            const queryParamsFeatureFlagsService = new QueryParamsFeatureFlagsService({ sessionStoragePrefix: FeatureFlagPrefix.QueryParam });
            const launchDarklyFeatureFlagsService = new LaunchDarklyFeatureFlagService();
            try {
                launchDarklyFeatureFlagsService.initialize(clientId, baseContext);
            }
            catch (error) {
                logError(error.message, error);
            }
            const apiFeatureFlagsService = new ApiFeatureFlagsService({ sessionStoragePrefix: FeatureFlagPrefix.FeaturesApi });
            this.serviceMap.set(ProviderType.QueryParams, queryParamsFeatureFlagsService);
            this.serviceMap.set(ProviderType.FeaturesApi, apiFeatureFlagsService);
            this.serviceMap.set(ProviderType.LaunchDarkly, launchDarklyFeatureFlagsService);
            this.setFeaturesResponse(featuresResponse);
        });
    }
    isFeatureEnabledViaNext(flag) {
        if (flag === FeatureFlag.Next) {
            return false;
        }
        return !this.excludedFlagsFromNext.has(flag) && this.isFeatureEnabled(FeatureFlag.Next);
    }
    applyToAllServices(callback) {
        this.serviceMap.forEach((service) => {
            try {
                callback(service);
            }
            catch (error) {
                logError(error.message, error);
            }
        });
    }
    clearFeaturesLastFetchTime() {
        window.sessionStorage.removeItem(LAST_FETCH_TIME_KEY);
    }
    extractAndPersistClientIdFromFeatureResponse(featuresResponse) {
        const { clientSideId } = featuresResponse !== null && featuresResponse !== void 0 ? featuresResponse : {};
        if (clientSideId) {
            window.sessionStorage.setItem(LD_CLIENT_SIDE_ID_KEY, clientSideId);
        }
    }
    getClientId() {
        return window.sessionStorage.getItem(LD_CLIENT_SIDE_ID_KEY);
    }
    setFeaturesResponse(featuresResponse) {
        this.applyToAllServices((service) => service.setFeaturesResponse(featuresResponse));
    }
    loadFeatures() {
        return __awaiter(this, void 0, void 0, function* () {
            const lastFetchTime = Number(window.sessionStorage.getItem(LAST_FETCH_TIME_KEY));
            const now = Date.now();
            const fetchCoolDown = now - lastFetchTime;
            if (lastFetchTime && fetchCoolDown <= ONE_HOUR) {
                return null;
            }
            try {
                const featuresResponse = yield getFeatures();
                if (!isNil(featuresResponse)
                    && is(Object, featuresResponse)
                    && !Array.isArray(featuresResponse)) {
                    window.sessionStorage.setItem(LAST_FETCH_TIME_KEY, String(Date.now()));
                    return featuresResponse;
                }
            }
            catch (error) {
                logError('Failed to load feature flags', error);
                return {};
            }
        });
    }
    registerFeatureFlags(flags) {
        this.applyToAllServices((service) => service.registerFeatureFlags(flags));
    }
    persistFlags(flags) {
        this.applyToAllServices((service) => service.persistFeatureFlags(flags));
    }
    excludeFlagsFromNext(flags) {
        flags.forEach((flag) => this.excludedFlagsFromNext.add(flag));
    }
    setFeatureFlags() {
        return __awaiter(this, void 0, void 0, function* () {
            const results = yield Promise.allSettled(Array.from(this.serviceMap.values()).map((service) => service.setFeatureFlags()));
            results.forEach((result) => {
                var _a;
                if (result.status === 'rejected') {
                    logError((_a = result.reason) === null || _a === void 0 ? void 0 : _a.message, result.reason);
                }
            });
            this.eventManager.emit(EventType.FeatureFlagsSet);
        });
    }
    isFeatureEnabled(flag) {
        var _a;
        for (const serviceName of this.priorityServiceList) {
            const isFeatureEnabledViaNext = this.isFeatureEnabledViaNext(flag);
            if (isFeatureEnabledViaNext) {
                return true;
            }
            const featureFlagValue = (_a = this.serviceMap.get(serviceName)) === null || _a === void 0 ? void 0 : _a.getFeatureFlagValue(flag);
            if (!isNil(featureFlagValue)) {
                return featureFlagValue;
            }
        }
        return false;
    }
    getSingleTenantName() {
        return window.sessionStorage.getItem(SINGLE_TENANT_NAME_KEY);
    }
    subscribe(event, callback) {
        this.eventManager.on(event, callback);
    }
    unsubscribe(event, callback) {
        this.eventManager.off(event, callback);
    }
    updateFeatureFlagsContext(context) {
        return __awaiter(this, void 0, void 0, function* () {
            if (context.user) {
                try {
                    yield Promise.all(Array.from(this.serviceMap.values()).map((service) => service.updateFeatureFlagsContext(context)));
                    this.eventManager.emit(EventType.ContextUpdated);
                }
                catch (error) {
                    logError(error.message, error);
                }
            }
        });
    }
    reset() {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.loadFeatures();
            this.extractAndPersistClientIdFromFeatureResponse(response);
            this.setFeaturesResponse(response);
            yield this.setFeatureFlags();
        });
    }
    clear() {
        this.applyToAllServices((service) => service.clearFeatureFlagsStorage());
        this.clearFeaturesLastFetchTime();
    }
}

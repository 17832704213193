import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
export const useLocationChanged = (handler, shouldHandle) => {
    const prevLocationRef = useRef();
    const location = useLocation();
    useEffect(() => {
        var _a;
        // console.log('useLocationChanged =>');
        if ((shouldHandle === null || shouldHandle === void 0 ? void 0 : shouldHandle(location, prevLocationRef.current)) || location.pathname !== ((_a = prevLocationRef.current) === null || _a === void 0 ? void 0 : _a.pathname)) {
            handler(location, prevLocationRef.current);
        }
        prevLocationRef.current = location;
    }, [shouldHandle, handler, location]);
};

import styled from 'styled-components';
export const StyledInitialSelectionPageContainer = styled.div `
  display: flex;
  flex-direction: column;
  margin: auto;
  max-width: 50%;
  align-items: center;

  .initial-selection-content-text {
    font-size: 20px;
    margin: ${({ theme }) => `${theme.spacing(3.75)} 0 ${theme.spacing(2)} 0`}
  }

  .initial-selection-content-icon {
    width: 155px;
    height: 140px;
  }
`;

import { __rest } from "tslib";
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
export const SetUpExperimentIcon = (_a) => {
    var { width = 135, height = 126 } = _a, props = __rest(_a, ["width", "height"]);
    return (<SvgIcon width={width} height={height} viewBox="0 0 135 126" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M48.6713 45.2308C61.1614 45.2308 71.2867 35.1055 71.2867 22.6154C71.2867 10.1253 61.1614 0 48.6713 0C36.1812 0 26.0559 10.1253 26.0559 22.6154C26.0559 35.1055 36.1812 45.2308 48.6713 45.2308Z" fill="#E8EAED"/>
    <path d="M115.129 81.1892C125.834 81.1892 134.513 72.5104 134.513 61.8045C134.513 51.0987 125.834 42.4199 115.129 42.4199C104.423 42.4199 95.7439 51.0987 95.7439 61.8045C95.7439 72.5104 104.423 81.1892 115.129 81.1892Z" fill="#E8EAED"/>
    <path d="M32.3077 126C50.1507 126 64.6154 111.535 64.6154 93.6922C64.6154 75.8492 50.1507 61.3845 32.3077 61.3845C14.4646 61.3845 0 75.8492 0 93.6922C0 111.535 14.4646 126 32.3077 126Z" fill="#E8EAED"/>
    <path d="M22.8252 26.2661H116.517C119.199 26.2661 121.364 28.4307 121.364 31.1123V94.1123H17.979V31.1123C17.979 28.4307 20.1436 26.2661 22.8252 26.2661Z" fill="#C3CEFF"/>
    <path d="M22.8252 26.25H110.056V82.7885H17.979V31.0962C17.979 28.4146 20.1436 26.25 22.8252 26.25Z" fill="white"/>
    <path d="M121.364 95.3239H17.9791C17.3168 95.3239 16.7676 94.7747 16.7676 94.1124V31.1124C16.7676 27.7685 19.4814 25.0547 22.8253 25.0547H116.518C119.861 25.0547 122.575 27.7685 122.575 31.1124V94.1124C122.575 94.7747 122.026 95.3239 121.364 95.3239ZM19.1907 92.9008H120.152V31.1124C120.152 29.1093 118.521 27.4778 116.518 27.4778H22.8253C20.8222 27.4778 19.1907 29.1093 19.1907 31.1124V92.9008Z" fill="#081E42"/>
    <path d="M92.2867 103.805H47.0559V113.497H92.2867V103.805Z" fill="#C3CEFF"/>
    <path d="M47.0559 103.805H87.4405V107.035C87.4405 107.924 86.7136 108.651 85.8251 108.651H47.0559V103.805Z" fill="white"/>
    <path d="M92.2868 114.708H47.056C46.3937 114.708 45.8445 114.159 45.8445 113.497V103.805C45.8445 103.142 46.3937 102.593 47.056 102.593H92.2868C92.9491 102.593 93.4983 103.142 93.4983 103.805V113.497C93.4983 114.159 92.9491 114.708 92.2868 114.708ZM48.2676 112.285H91.0752V105.016H48.2676V112.285Z" fill="#081E42"/>
    <path d="M17.979 94.1123H121.364V98.9585C121.364 101.64 119.199 103.805 116.517 103.805H22.8252C20.1436 103.805 17.979 101.64 17.979 98.9585V94.1123Z" fill="#C3CEFF"/>
    <path d="M114.902 98.9585H17.979V94.1123H116.517V97.3431C116.517 98.2315 115.791 98.9585 114.902 98.9585Z" fill="white"/>
    <path d="M116.518 105.016H22.8253C19.4814 105.016 16.7676 102.302 16.7676 98.9586V94.1124C16.7676 93.4501 17.3168 92.9009 17.9791 92.9009H121.364C122.026 92.9009 122.575 93.4501 122.575 94.1124V98.9586C122.575 102.302 119.861 105.016 116.518 105.016ZM19.1907 95.324V98.9586C19.1907 100.962 20.8222 102.593 22.8253 102.593H116.518C118.521 102.593 120.152 100.962 120.152 98.9586V95.324H19.1907Z" fill="#081E42"/>
    <path d="M108.441 114.708H30.9022C30.2399 114.708 29.6907 114.159 29.6907 113.497C29.6907 112.835 30.2399 112.285 30.9022 112.285H108.441C109.103 112.285 109.652 112.835 109.652 113.497C109.652 114.159 109.103 114.708 108.441 114.708Z" fill="#081E42"/>
    <path d="M69.6713 100.574C70.5634 100.574 71.2867 99.8506 71.2867 98.9584C71.2867 98.0662 70.5634 97.343 69.6713 97.343C68.7791 97.343 68.0559 98.0662 68.0559 98.9584C68.0559 99.8506 68.7791 100.574 69.6713 100.574Z" fill="#081E42"/>
    <path d="M63.2097 11.3076H40.5944C39.932 11.3076 39.3828 10.7584 39.3828 10.0961C39.3828 9.43375 39.932 8.88452 40.5944 8.88452H63.2097C63.872 8.88452 64.4213 9.43375 64.4213 10.0961C64.4213 10.7584 63.872 11.3076 63.2097 11.3076Z" fill="#081E42"/>
    <path d="M51.9024 51.2561C58.8324 48.9946 67.4586 47.7346 74.0009 50.2707C71.7878 45.3761 68.0078 41.3375 63.2909 38.8175C61.2232 37.7191 59.9794 35.5222 59.9794 33.196V10.1123H43.8255V33.196C43.8255 35.5545 42.5332 37.7352 40.4494 38.8499C35.2155 41.6606 31.1448 46.3291 29.1094 51.9829C35.6678 54.8906 44.2617 53.7599 51.9024 51.2561Z" fill="white"/>
    <path d="M58.4444 40.433C62.1921 42.436 65.3259 45.3922 67.5713 48.9622C69.8328 49.1076 72.0136 49.4954 74.0005 50.2546C71.7875 45.36 68.0075 41.3214 63.2905 38.8014C61.2228 37.7029 59.979 35.5061 59.979 33.1799V10.0962H55.1328V34.7953C55.1328 37.1376 56.3767 39.3183 58.4444 40.4168V40.433Z" fill="#C3CEFF"/>
    <path d="M38.1876 54.9232C34.9083 54.9232 31.6291 54.4062 28.6406 53.0816C28.0591 52.8232 27.7845 52.1607 27.9945 51.563C30.1268 45.6507 34.3591 40.7563 39.8999 37.784C41.5799 36.8794 42.6299 35.1184 42.6299 33.1961V10.1124C42.6299 9.45011 43.1791 8.90088 43.8414 8.90088H59.9953C60.6576 8.90088 61.2068 9.45011 61.2068 10.1124V33.1961C61.2068 35.1346 62.2245 36.8793 63.8722 37.7516C68.8153 40.3847 72.8053 44.6654 75.1153 49.77C75.3091 50.2223 75.2283 50.7393 74.8891 51.0947C74.5499 51.4501 74.0329 51.5631 73.5645 51.3854C68.266 49.35 60.5122 49.7054 52.2899 52.4031C48.2837 53.7115 43.2276 54.9232 38.1876 54.9232ZM30.6922 51.3047C37.3799 53.744 45.6507 52.0315 51.5307 50.1092C55.2137 48.9138 63.9045 46.5717 71.5776 48.284C69.4291 44.7624 66.3599 41.8385 62.7091 39.8838C60.2699 38.5915 58.7675 36.023 58.7675 33.18V11.3078H45.0368V33.18C45.0368 36.0069 43.5022 38.5754 41.0307 39.9C36.3622 42.4039 32.7114 46.4263 30.676 51.2886L30.6922 51.3047Z" fill="#081E42"/>
    <path d="M51.9022 51.2559C44.2775 53.7598 35.6676 54.8905 29.1091 51.9828C28.1883 54.5512 27.6714 57.2975 27.6714 60.1891C27.6714 73.5644 38.5268 84.4198 51.9022 84.4198C65.2775 84.4198 76.1329 73.5644 76.1329 60.1891C76.1329 56.6514 75.3576 53.3075 74.0007 50.2706C67.4583 47.7506 58.8322 48.9944 51.9022 51.2559Z" fill="white"/>
    <path d="M74.0008 50.2708C71.5616 49.3339 68.8316 48.9139 66.0046 48.8816C67.3131 51.8539 68.0562 55.117 68.0562 58.5739C68.0562 71.9493 57.2008 82.8047 43.8254 82.8047C43.5992 82.8047 43.3892 82.7723 43.1631 82.7723C45.8769 83.8223 48.8169 84.4201 51.9023 84.4201C65.2777 84.4201 76.1331 73.5647 76.1331 60.1893C76.1331 56.6516 75.3577 53.3077 74.0008 50.2708Z" fill="#C3CEFF"/>
    <path d="M51.9023 85.6316C37.8807 85.6316 26.46 74.2109 26.46 60.1893C26.46 57.2655 26.9769 54.3738 27.9784 51.5792C28.0915 51.2561 28.3338 51.0139 28.6407 50.8847C28.9477 50.7555 29.3031 50.7555 29.61 50.8847C36.4754 53.9378 45.3115 52.1446 51.5308 50.1253C55.6661 48.7684 66.1823 45.9738 74.4369 49.1561C74.7277 49.2692 74.97 49.4954 75.0992 49.7862C76.5854 53.0977 77.3446 56.5868 77.3446 60.2053C77.3446 74.2268 65.9238 85.6476 51.9023 85.6476V85.6316ZM29.8684 53.5984C29.2223 55.7469 28.883 57.9601 28.883 60.1893C28.883 72.8863 39.2053 83.2086 51.9023 83.2086C64.5992 83.2086 74.9215 72.8863 74.9215 60.1893C74.9215 57.1039 74.3077 54.0831 73.0962 51.24C67.8139 49.3823 60.27 49.8185 52.29 52.4192C46.0223 54.4708 37.2184 56.2477 29.8684 53.5984Z" fill="#081E42"/>
    <path d="M39.2054 66.6022C40.9897 66.6022 42.4361 65.1557 42.4361 63.3714C42.4361 61.5871 40.9897 60.1406 39.2054 60.1406C37.4211 60.1406 35.9746 61.5871 35.9746 63.3714C35.9746 65.1557 37.4211 66.6022 39.2054 66.6022Z" fill="white"/>
    <path d="M39.2055 67.8138C36.7501 67.8138 34.7632 65.8269 34.7632 63.3715C34.7632 60.9161 36.7501 58.9292 39.2055 58.9292C41.6609 58.9292 43.6478 60.9161 43.6478 63.3715C43.6478 65.8269 41.6609 67.8138 39.2055 67.8138ZM39.2055 61.3523C38.0909 61.3523 37.1863 62.2569 37.1863 63.3715C37.1863 64.4861 38.0909 65.3907 39.2055 65.3907C40.3201 65.3907 41.2247 64.4861 41.2247 63.3715C41.2247 62.2569 40.3201 61.3523 39.2055 61.3523Z" fill="#081E42"/>
    <path d="M64.0176 68.8802C65.8019 68.8802 67.2484 67.4338 67.2484 65.6495C67.2484 63.8652 65.8019 62.4187 64.0176 62.4187C62.2333 62.4187 60.7869 63.8652 60.7869 65.6495C60.7869 67.4338 62.2333 68.8802 64.0176 68.8802Z" fill="white"/>
    <path d="M64.0175 70.0916C61.5621 70.0916 59.5752 68.1047 59.5752 65.6493C59.5752 63.194 61.5621 61.207 64.0175 61.207C66.4729 61.207 68.4598 63.194 68.4598 65.6493C68.4598 68.1047 66.4729 70.0916 64.0175 70.0916ZM64.0175 63.6301C62.9029 63.6301 61.9983 64.5347 61.9983 65.6493C61.9983 66.764 62.9029 67.6686 64.0175 67.6686C65.1321 67.6686 66.0367 66.764 66.0367 65.6493C66.0367 64.5347 65.1321 63.6301 64.0175 63.6301Z" fill="#081E42"/>
    <path d="M49.8829 76.6984C51.6672 76.6984 53.1136 75.2519 53.1136 73.4676C53.1136 71.6833 51.6672 70.2368 49.8829 70.2368C48.0986 70.2368 46.6521 71.6833 46.6521 73.4676C46.6521 75.2519 48.0986 76.6984 49.8829 76.6984Z" fill="white"/>
    <path d="M49.883 77.91C47.4276 77.91 45.4407 75.9231 45.4407 73.4677C45.4407 71.0123 47.4276 69.0254 49.883 69.0254C52.3384 69.0254 54.3253 71.0123 54.3253 73.4677C54.3253 75.9231 52.3384 77.91 49.883 77.91ZM49.883 71.4485C48.7684 71.4485 47.8638 72.3531 47.8638 73.4677C47.8638 74.5823 48.7684 75.4869 49.883 75.4869C50.9976 75.4869 51.9022 74.5823 51.9022 73.4677C51.9022 72.3531 50.9976 71.4485 49.883 71.4485Z" fill="#081E42"/>
    <path d="M110.072 84.42H84.2261C83.3376 84.42 82.6107 83.6931 82.6107 82.8046V66.6508L71.303 56.9585H110.072C110.961 56.9585 111.688 57.6854 111.688 58.5739V82.8046C111.688 83.6931 110.961 84.42 110.072 84.42Z" fill="#C3CEFF"/>
    <path d="M110.072 47.25H84.2261C83.3376 47.25 82.6107 46.5231 82.6107 45.6347V19.7885L71.303 10.0962H110.072C110.961 10.0962 111.688 10.8231 111.688 11.7116V45.6347C111.688 46.5231 110.961 47.25 110.072 47.25Z" fill="#C3CEFF"/>
    <path d="M105.226 79.5739H82.6107V66.6508L71.303 56.9585H106.841V77.9585C106.841 78.847 106.115 79.5739 105.226 79.5739Z" fill="white"/>
    <path d="M105.226 42.4039H82.6107V19.7885L71.303 10.0962H106.841V40.7885C106.841 41.677 106.115 42.4039 105.226 42.4039Z" fill="white"/>
    <path d="M110.072 85.6314H84.226C82.6752 85.6314 81.3991 84.3553 81.3991 82.8045V67.216L70.5114 57.879C70.1237 57.556 69.9944 57.0068 70.1721 56.5383C70.3498 56.0699 70.8021 55.7468 71.3029 55.7468H110.072C111.623 55.7468 112.899 57.023 112.899 58.5737V82.8045C112.899 84.3553 111.623 85.6314 110.072 85.6314ZM74.5821 58.1699L83.4021 65.7298C83.6767 65.9559 83.8221 66.2953 83.8221 66.6507V82.8045C83.8221 83.0307 83.9998 83.2084 84.226 83.2084H110.072C110.298 83.2084 110.476 83.0307 110.476 82.8045V58.5737C110.476 58.3476 110.298 58.1699 110.072 58.1699H74.5821Z" fill="#081E42"/>
    <path d="M110.072 48.4614H84.226C82.6752 48.4614 81.3991 47.1853 81.3991 45.6345V20.3537L70.5114 11.0167C70.1237 10.6937 69.9944 10.1445 70.1721 9.67604C70.3498 9.20758 70.8021 8.88452 71.3029 8.88452H110.072C111.623 8.88452 112.899 10.1607 112.899 11.7114V45.6345C112.899 47.1853 111.623 48.4614 110.072 48.4614ZM74.5821 11.3076L83.4021 18.8675C83.6767 19.0936 83.8221 19.433 83.8221 19.7884V45.6345C83.8221 45.8607 83.9998 46.0384 84.226 46.0384H110.072C110.298 46.0384 110.476 45.8607 110.476 45.6345V11.7114C110.476 11.4853 110.298 11.3076 110.072 11.3076H74.5821Z" fill="#081E42"/>
    <path d="M104.418 67.4585H89.88C89.2177 67.4585 88.6685 66.9092 88.6685 66.2469C88.6685 65.5846 89.2177 65.0354 89.88 65.0354H104.418C105.081 65.0354 105.63 65.5846 105.63 66.2469C105.63 66.9092 105.081 67.4585 104.418 67.4585Z" fill="#081E42"/>
    <path d="M99.5723 76.343H89.88C89.2177 76.343 88.6685 75.7938 88.6685 75.1315C88.6685 74.4692 89.2177 73.9199 89.88 73.9199H99.5723C100.235 73.9199 100.784 74.4692 100.784 75.1315C100.784 75.7938 100.235 76.343 99.5723 76.343Z" fill="#081E42"/>
    <path d="M104.418 29.8845H89.88C89.2177 29.8845 88.6685 29.3353 88.6685 28.673C88.6685 28.0107 89.2177 27.4614 89.88 27.4614H104.418C105.081 27.4614 105.63 28.0107 105.63 28.673C105.63 29.3353 105.081 29.8845 104.418 29.8845Z" fill="#081E42"/>
    <path d="M104.418 21H89.88C89.2177 21 88.6685 20.4508 88.6685 19.7884C88.6685 19.1261 89.2177 18.5769 89.88 18.5769H104.418C105.081 18.5769 105.63 19.1261 105.63 19.7884C105.63 20.4508 105.081 21 104.418 21Z" fill="#081E42"/>
    <path d="M99.5723 38.7693H89.88C89.2177 38.7693 88.6685 38.22 88.6685 37.5577C88.6685 36.8954 89.2177 36.3462 89.88 36.3462H99.5723C100.235 36.3462 100.784 36.8954 100.784 37.5577C100.784 38.22 100.235 38.7693 99.5723 38.7693Z" fill="#081E42"/>
    <path d="M16.3638 109.862H9.90221C9.2399 109.862 8.69067 109.313 8.69067 108.651C8.69067 107.988 9.2399 107.439 9.90221 107.439H16.3638C17.0261 107.439 17.5753 107.988 17.5753 108.651C17.5753 109.313 17.0261 109.862 16.3638 109.862Z" fill="#081E42"/>
    <path d="M13.1329 113.093C12.4706 113.093 11.9214 112.544 11.9214 111.882V105.42C11.9214 104.758 12.4706 104.208 13.1329 104.208C13.7952 104.208 14.3445 104.758 14.3445 105.42V111.882C14.3445 112.544 13.7952 113.093 13.1329 113.093Z" fill="#081E42"/>
    <path d="M13.1329 100.17C13.802 100.17 14.3445 99.6275 14.3445 98.9584C14.3445 98.2893 13.802 97.7468 13.1329 97.7468C12.4638 97.7468 11.9214 98.2893 11.9214 98.9584C11.9214 99.6275 12.4638 100.17 13.1329 100.17Z" fill="#081E42"/>
    <path d="M13.1329 95.3076C13.802 95.3076 14.3445 94.7652 14.3445 94.0961C14.3445 93.4269 13.802 92.8845 13.1329 92.8845C12.4638 92.8845 11.9214 93.4269 11.9214 94.0961C11.9214 94.7652 12.4638 95.3076 13.1329 95.3076Z" fill="#081E42"/>
    <path d="M13.1329 88.8462C13.802 88.8462 14.3445 88.3037 14.3445 87.6346C14.3445 86.9655 13.802 86.4231 13.1329 86.4231C12.4638 86.4231 11.9214 86.9655 11.9214 87.6346C11.9214 88.3037 12.4638 88.8462 13.1329 88.8462Z" fill="#081E42"/>
    <path d="M13.1329 79.1538C13.802 79.1538 14.3445 78.6114 14.3445 77.9423C14.3445 77.2731 13.802 76.7307 13.1329 76.7307C12.4638 76.7307 11.9214 77.2731 11.9214 77.9423C11.9214 78.6114 12.4638 79.1538 13.1329 79.1538Z" fill="#081E42"/>
    <path d="M129.441 21H122.979C122.317 21 121.768 20.4508 121.768 19.7884C121.768 19.1261 122.317 18.5769 122.979 18.5769H129.441C130.103 18.5769 130.652 19.1261 130.652 19.7884C130.652 20.4508 130.103 21 129.441 21Z" fill="#081E42"/>
    <path d="M126.21 24.2308C125.548 24.2308 124.998 23.6816 124.998 23.0193V16.5577C124.998 15.8954 125.548 15.3462 126.21 15.3462C126.872 15.3462 127.421 15.8954 127.421 16.5577V23.0193C127.421 23.6816 126.872 24.2308 126.21 24.2308Z" fill="#081E42"/>
    <path d="M126.21 30.6924C126.879 30.6924 127.421 30.1499 127.421 29.4808C127.421 28.8117 126.879 28.2693 126.21 28.2693C125.541 28.2693 124.998 28.8117 124.998 29.4808C124.998 30.1499 125.541 30.6924 126.21 30.6924Z" fill="#081E42"/>
    <path d="M126.21 35.5547C126.879 35.5547 127.421 35.0122 127.421 34.3431C127.421 33.674 126.879 33.1316 126.21 33.1316C125.541 33.1316 124.998 33.674 124.998 34.3431C124.998 35.0122 125.541 35.5547 126.21 35.5547Z" fill="#081E42"/>
    <path d="M126.21 42.0161C126.879 42.0161 127.421 41.4737 127.421 40.8046C127.421 40.1354 126.879 39.593 126.21 39.593C125.541 39.593 124.998 40.1354 124.998 40.8046C124.998 41.4737 125.541 42.0161 126.21 42.0161Z" fill="#081E42"/>
    <path d="M126.21 51.7085C126.879 51.7085 127.421 51.1661 127.421 50.4969C127.421 49.8278 126.879 49.2854 126.21 49.2854C125.541 49.2854 124.998 49.8278 124.998 50.4969C124.998 51.1661 125.541 51.7085 126.21 51.7085Z" fill="#081E42"/>
  </SvgIcon>);
};
export default SetUpExperimentIcon;

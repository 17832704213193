import React from 'react';
import { baseColors, Icon } from '@groupby/ui-components';
import { Button } from '@/components/button';
import { StyledAccordion, StyledAccordionDetails, StyledAccordionSummary, StyledTitleTypography, } from './actionable-insights.styles';
import { useLocalization } from '@/localization';
import { Insight } from './partials/insight';
import { useActionableInsightData } from './useActionableInsightData';
export const ActionableInsights = (props) => {
    const { formatMessage: t } = useLocalization();
    const data = useActionableInsightData(props);
    if (data.length === 0)
        return null;
    return (<div className="actionable-insights">
      <StyledAccordion defaultExpanded>
        <StyledAccordionSummary expandIcon={<Button icon={<Icon variant="chevronDown" color={baseColors.primaryBlueMed400}/>}/>}>
          <StyledTitleTypography>
            {String(t({ key: 'INSIGHTS' })).toUpperCase()}
          </StyledTitleTypography>
        </StyledAccordionSummary>
        <StyledAccordionDetails>
          {data.map((insight) => (<Insight key={insight.actionableInsightType} {...insight}/>))}
        </StyledAccordionDetails>
      </StyledAccordion>
    </div>);
};

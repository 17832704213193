import { __rest } from "tslib";
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
export const EvenlyDistributeIcon = (_a) => {
    var { width = 16, height = 16, fill = 'currentColor', stroke = 'currentColor' } = _a, props = __rest(_a, ["width", "height", "fill", "stroke"]);
    return (<SvgIcon width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect x="2" y="2" width="2.66667" height="12" rx="0.25" stroke={stroke} strokeWidth="1.5"/>
    <rect x="6.66675" y="2" width="2.66667" height="12" rx="0.25" stroke={stroke} strokeWidth="1.5"/>
    <rect x="11.3333" y="2" width="2.66667" height="12" rx="0.25" stroke={stroke} strokeWidth="1.5"/>
  </SvgIcon>);
};
export default EvenlyDistributeIcon;

export const getPaginationValues = ({ itemsCount, pageNumber, pageSize, }) => {
    const pagesCount = Math.ceil(itemsCount / pageSize);
    const startRange = (pageNumber - 1) * pageSize + 1;
    const endRange = (pageNumber === pagesCount ? itemsCount : startRange + pageSize - 1);
    return {
        pagesCount,
        startRange,
        endRange,
    };
};

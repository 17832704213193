import { __awaiter, __rest } from "tslib";
import React, { useCallback, useEffect, useState } from 'react';
import { useBlocker } from 'react-router-dom';
import Confirmation from './confirmation';
import { getIdFromQueryString } from '@/routing';
import { isFormNavigationState } from '@/types';
const isPathnameTheSame = (path1, path2) => path1.replace('/_create', '') === path2.replace('/_create', '');
const isPdp = (location) => getIdFromQueryString(location.search) || location.pathname.includes('/_create');
export const NavigationPrompt = ({ isDirty }) => {
    const [dialogConfig, setDialogConfig] = useState(null);
    const { open, message, hideCancel, title, } = dialogConfig !== null && dialogConfig !== void 0 ? dialogConfig : {};
    const openDialog = (config) => setDialogConfig(Object.assign({ open: true }, config));
    const closeDialog = () => setDialogConfig(null);
    const onConfirm = () => {
        closeDialog();
        dialogConfig.actionCallback(true);
    };
    const onDismiss = () => {
        closeDialog();
        dialogConfig.actionCallback(false);
    };
    const blocker = useBlocker(({ currentLocation, nextLocation, }) => {
        if (!isDirty()) {
            return false;
        }
        if (!isPathnameTheSame(currentLocation.pathname, nextLocation.pathname)) {
            return true;
        }
        if (isFormNavigationState(nextLocation.state)) {
            if (nextLocation.state.action === 'save') {
                return false;
            }
        }
        return isPdp(currentLocation) && !isPdp(nextLocation);
    });
    useEffect(() => {
        if (blocker.state === 'blocked' && !isDirty()) {
            blocker.reset();
        }
    }, [blocker, isDirty]);
    const getPrompt = useCallback((_a) => {
        var options = __rest(_a, []);
        return new Promise((resolve) => {
            openDialog(Object.assign({ actionCallback: resolve }, options));
        });
    }, []);
    useEffect(() => {
        function processNavigation() {
            var _a, _b;
            return __awaiter(this, void 0, void 0, function* () {
                if (blocker.state === 'blocked') {
                    const confirmed = yield getPrompt({
                        title: 'You have made some changes',
                        message: (<div>Do you want to discard all the changes you have made?<br />This
              cannot be reversed.</div>),
                    });
                    if (confirmed) {
                        (_a = blocker.proceed) === null || _a === void 0 ? void 0 : _a.call(blocker);
                    }
                    else {
                        (_b = blocker.reset) === null || _b === void 0 ? void 0 : _b.call(blocker);
                    }
                }
            });
        }
        void processNavigation();
    }, [blocker, getPrompt]);
    return dialogConfig && open ? (<Confirmation open={open} title={title} primaryButton={{ onClick: onConfirm, label: 'Discard changes' }} onCancel={onDismiss} showCancel={!hideCancel}>
      {message}
    </Confirmation>) : null;
};
export default NavigationPrompt;

import { TARGET_CUSTOMER } from '@/config/global';
import { FeatureFlag, featureFlagsService } from '@/search-for-retail/feature-flags';
import { logError } from '@/utils/logger';
const KEY = 'gbi:tenantName';
const checkIfAuth0Enabled = () => featureFlagsService.isFeatureEnabled(FeatureFlag.Auth0);
export function getTenantName() {
    return checkIfAuth0Enabled() ? window.localStorage.getItem(KEY) : TARGET_CUSTOMER;
}
export function setTenantName(tenantName) {
    if (!checkIfAuth0Enabled())
        return;
    try {
        window.localStorage.setItem(KEY, tenantName);
    }
    catch (e) {
        logError('Could not store tenant name:', e);
    }
}
export function clearTenantName() {
    if (!checkIfAuth0Enabled())
        return;
    window.localStorage.removeItem(KEY);
}

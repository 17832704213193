import { __awaiter } from "tslib";
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { isEmpty } from 'ramda';
import { useAuth0 } from '@auth0/auth0-react';
import { decodeJWT, getTenantName } from '@/utils';
import { getAuth0Audience } from '@/auth/utilities';
import { setIsLoggingIn } from '@/store/actions/creators';
import { syncAuth0UserProfile } from '@/search-for-retail/user/user.client';
import { AuthContext } from './auth.context';
import { useAuthLogout } from '@/auth/hooks/authentication';
import { getNextPath } from '@/routing/hooks';
import useRefreshUserProfile from './hooks/use-refresh-user-profile';
export const Auth0LoginLoaderProvider = ({ onLogin, loginId, tenant, email, webAppCallFailed, children, }) => {
    const refreshUserProfile = useRefreshUserProfile();
    const [auth0AccessToken, setAuth0AccessToken] = useState();
    const [showAccessAlert, setShowAccessAlert] = useState(false);
    const [showPermissionAccessNotification, setShowPermissionAccessNotification] = useState(false);
    const [isTokenPermissionsEmpty, setIsTokenPermissionsEmpty] = useState(true);
    // state required to force a render
    // state is redundant with syncAttemptsRemainingRef
    const [isSyncing, setIsSyncing] = useState(false);
    // using ref to persist across re-renders but to avoid triggering a re-render which can cause an infinite loop with the sync call
    const MAX_SYNC_ATTEMPTS = 4;
    const syncAttemptsRemainingRef = useRef(MAX_SYNC_ATTEMPTS);
    const tenantName = getTenantName();
    const nextPath = getNextPath(window.location.search);
    const { getAccessTokenSilently, isLoading, isAuthenticated, loginWithRedirect, } = useAuth0();
    const dispatch = useDispatch();
    const { logout } = useAuthLogout();
    // constants for login context
    const attemptedLoginKey = 'gbi:timeOfAttemptedLogin';
    const intervalToShowAccessAlert = 1000 * 60 * 3; // three minutes
    const isSyncRetriesExhausted = syncAttemptsRemainingRef.current <= 0;
    // app loading state considers auth0's loading state, the status of a sync user profile call, and an expression to handle
    // numerous situations where the app should be loading. Test every auth scenario carefully before changing this.
    const isAppLoading = isLoading
        || isSyncing
        || (!isSyncRetriesExhausted && isAuthenticated && isTokenPermissionsEmpty && Boolean(tenantName));
    useEffect(() => {
        if (isAuthenticated && !tenantName && !isLoading) {
            logout();
        }
    }, [isAuthenticated, tenantName, isLoading, logout, isAppLoading]);
    useEffect(() => {
        if (!loginId)
            return;
        const login = () => __awaiter(void 0, void 0, void 0, function* () {
            yield loginWithRedirect({
                appState: {
                    returnTo: nextPath,
                    tenant,
                },
                authorizationParams: {
                    audience: getAuth0Audience(tenant),
                    login_hint: email,
                },
            });
        });
        void login();
    }, [loginId, email, loginWithRedirect, tenant, nextPath]);
    useEffect(() => {
        if (!webAppCallFailed)
            return;
        if (webAppCallFailed) {
            setShowPermissionAccessNotification(true);
        }
    }, [webAppCallFailed]);
    useEffect(() => {
        if (!tenantName)
            return;
        const getAuth0AccessToken = ({ skipCache }) => __awaiter(void 0, void 0, void 0, function* () {
            try {
                // `cacheMode` ensures token is not cached so refresh token has updated permissions from sync call
                const accessToken = yield getAccessTokenSilently({
                    cacheMode: skipCache ? 'off' : 'on',
                    authorizationParams: {
                        audience: getAuth0Audience(tenantName),
                    },
                });
                const decodedToken = decodeJWT(accessToken);
                if (isEmpty(decodedToken.permissions)) {
                    try {
                        if (syncAttemptsRemainingRef.current > 0) {
                            setIsSyncing(true);
                            yield syncAuth0UserProfile(accessToken);
                            syncAttemptsRemainingRef.current -= 1;
                        }
                        else {
                            setShowPermissionAccessNotification(true);
                            setIsSyncing(false);
                            syncAttemptsRemainingRef.current = 0;
                            return; // break out of sync attempts call loop
                        }
                        void getAuth0AccessToken({ skipCache: true });
                    }
                    catch (error) {
                        setIsSyncing(false);
                        syncAttemptsRemainingRef.current = 0;
                        // If server cannot find active user by email, for example with a new groupby user who is not in the database
                        // call returns 401 and this is used to display notifcation to alert administrator on welcome screen
                        setShowPermissionAccessNotification(true);
                        // dispatch(setIsLoggingIn(false));
                    }
                }
                else {
                    setAuth0AccessToken(accessToken);
                    setIsTokenPermissionsEmpty(false);
                    setIsSyncing(false);
                    dispatch(setIsLoggingIn(false));
                    syncAttemptsRemainingRef.current = 0;
                }
            }
            catch (e) {
                setIsSyncing(false);
                // dispatch(setIsLoggingIn(false));
                syncAttemptsRemainingRef.current = 0;
                // If Auth0 related error, handle as expected, otherwise throw error for details
                if (['login_required', 'consent_required', 'unauthorized', 'access_denied'].includes(e.error)) {
                    const attemptedLoginTime = Number(window.localStorage.getItem(attemptedLoginKey));
                    if (Date.now() - attemptedLoginTime < intervalToShowAccessAlert) {
                        setShowAccessAlert(true);
                    }
                }
                else {
                    throw e;
                }
            }
            finally {
                // clear local storage time interval for reset check
                window.localStorage.removeItem(attemptedLoginKey);
            }
        });
        void getAuth0AccessToken({ skipCache: false });
    }, [getAccessTokenSilently, tenantName, intervalToShowAccessAlert, webAppCallFailed, dispatch]);
    // Call to GET User Profile
    useEffect(() => {
        const fetchUserProfile = () => __awaiter(void 0, void 0, void 0, function* () {
            if (!auth0AccessToken)
                return;
            try {
                yield refreshUserProfile();
            }
            catch (error) {
                // If deleted GBI user tries to log in display message to notify admin
                if (error.statusCode === 401 || error.statusCode === 403) {
                    setShowPermissionAccessNotification(true);
                }
            }
        });
        void fetchUserProfile();
    }, [auth0AccessToken, dispatch, refreshUserProfile]);
    return (<AuthContext.Provider value={{
        showAccessAlert,
        showPermissionAccessNotification,
        isAppLoading,
        isTokenPermissionsEmpty,
        authenticate: onLogin,
    }}>
      {children}
    </AuthContext.Provider>);
};
export default Auth0LoginLoaderProvider;

import React from 'react';
import { baseColors, Typography } from '@groupby/ui-components';
import { IconButton } from '@mui/material';
import { StyledChevronButtonWrapper, StyledDoubleChevronIcon } from '@/navigation/drawer/drawer.styles';
import { StyledDrawerRouteList } from './primary-drawer-route-list.styles';
import { PrimaryDrawerSectionLink } from './primary-drawer-section-link.component';
import { Routing } from '@/constants';
import { getHomePath, useActivePluginPath, useGoHome, usePathname, } from '@/routing/hooks/utils';
import { HomeNavLinkIcon } from '@/components/icons/svg';
import { useLocalization } from '@/localization';
import { useNavigation } from '@/navigation/navigation.provider';
import { NavigationListItem } from './navigation-list-item.component';
import { hasEnrichAccessOnly } from '@/config/utils';
import { NavigationType } from '@/navigation/types';
const DrawerHomeLink = () => {
    const goToHome = useGoHome();
    const { setNavigationType } = useNavigation();
    const activeRoute = usePathname();
    const { formatMessage: t } = useLocalization();
    return (<NavigationListItem active={activeRoute === Routing.MERCHANDISING_PATH} text={t({ key: 'HOME' })} icon={<HomeNavLinkIcon />} hasSubNav={false} disabled={hasEnrichAccessOnly} onClick={() => {
        if (getHomePath() !== activeRoute) {
            setNavigationType(NavigationType.PRIMARY);
            goToHome();
        }
    }}/>);
};
export const PrimaryDrawerRouteList = ({ onItemClick }) => {
    const { sections, setDrawerOpen, isDrawerOpen, visibleRouteSection, } = useNavigation();
    const { formatMessage: t } = useLocalization();
    const pluginPath = useActivePluginPath();
    const isActiveSection = (section) => (section.plugin ? section.path === pluginPath : section.path === (visibleRouteSection === null || visibleRouteSection === void 0 ? void 0 : visibleRouteSection.path));
    const handleChevronClick = () => {
        setDrawerOpen();
    };
    return (<StyledDrawerRouteList>
      <div>
        <DrawerHomeLink onClick={onItemClick}/>

        {sections.map((section) => (<PrimaryDrawerSectionLink key={section.path} section={section} active={isActiveSection(section)} onClick={onItemClick}/>))}
      </div>
      <StyledChevronButtonWrapper>
        <IconButton onClick={handleChevronClick} size="large">
          <StyledDoubleChevronIcon variant="doubleChevron" color={baseColors.background200} $openedDrawer={isDrawerOpen}/>
          {isDrawerOpen && (<Typography sx={{ color: baseColors.background200, ml: 1 }}>
              {t({ key: 'COLLAPSE' })}
            </Typography>)}
        </IconButton>

      </StyledChevronButtonWrapper>
    </StyledDrawerRouteList>);
};
export default PrimaryDrawerRouteList;

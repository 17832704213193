import styled from 'styled-components';
import { PageContainer } from '@/search-for-retail/generic';
export const StyledEmptyPageContainer = styled(PageContainer) `
  flex-direction: column;
  flex: 1;

  .empty-box-container {
    margin: auto;
    width: 500px;
    gap: ${({ theme }) => theme.spacing(2)};

    .text-block {
      gap: ${({ theme }) => theme.spacing(2)};
    }

    .text-block-title {
      display: flex;
      flex-direction: column;
      gap: ${({ theme }) => theme.spacing(1)};
    }
  }

  .icon-wrapper-class svg {
    transform: rotate(-90deg);
  }
`;

import { useEffect, useMemo, useState } from 'react';
import { SortDirection } from '@/search-for-retail/models';
import { useTablePersistedData } from '@/search-for-retail/generic/tableV2/hooks/use-table-persisted-data.hook';
const swapDirrection = (prevDirection) => {
    if (prevDirection === SortDirection.Descending)
        return SortDirection.Ascending;
    return SortDirection.Descending;
};
export const useTableSort = (data, columns, { initialSortingKey = undefined, persisted }) => {
    const initialSort = useMemo(() => {
        var _a;
        if (!initialSortingKey) {
            return null;
        }
        return ({
            key: initialSortingKey,
            default: true,
            direction: SortDirection.Ascending,
            label: (_a = columns.find(({ sortConfig }) => sortConfig && sortConfig.key === initialSortingKey)) === null || _a === void 0 ? void 0 : _a.label,
        });
    }, [initialSortingKey, columns]);
    const [sort, setSort] = useState(initialSort);
    const { persistedData, setPersistedData } = useTablePersistedData();
    useEffect(() => {
        if (persisted && persistedData.sort) {
            setSort(persistedData.sort);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const sortedRows = useMemo(() => {
        var _a;
        const config = (_a = columns.find(({ sortConfig }) => sortConfig && sortConfig.key === (sort === null || sort === void 0 ? void 0 : sort.key))) === null || _a === void 0 ? void 0 : _a.sortConfig;
        if (config === null || config === void 0 ? void 0 : config.handler) {
            return [...data].sort(config.handler(sort.direction));
        }
        return data;
    }, [data, sort, columns]);
    const updateSort = (sortKey) => {
        const config = columns.find(({ sortConfig }) => sortConfig && sortConfig.key === sortKey);
        if (!sortKey) {
            setSort({});
            setPersistedData({ sort: {} });
            return;
        }
        setSort((prev = {}) => {
            const newSort = (prev === null || prev === void 0 ? void 0 : prev.key) === sortKey ? (Object.assign(Object.assign({}, prev), { label: config.label, direction: swapDirrection(prev.direction) })) : ({
                key: sortKey,
                label: config.label,
                direction: SortDirection.Ascending,
            });
            newSort.default = newSort.key === initialSortingKey && newSort.direction === SortDirection.Ascending;
            setPersistedData({ sort: newSort });
            return newSort;
        });
    };
    const resetSort = () => {
        setSort(initialSort);
        setPersistedData({ sort: initialSort });
    };
    return ({
        sort,
        sortedRows,
        actions: { updateSort, resetSort },
    });
};

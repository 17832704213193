import { __rest } from "tslib";
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
export const WarningIcon = (_a) => {
    var { width = 16, height = 16 } = _a, props = __rest(_a, ["width", "height"]);
    return (<SvgIcon width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M13.8115 11.3945L9.6123 2.9953C8.94883 1.66824 7.05504 1.66823 6.39157 2.9953L2.19237 11.3945C1.59388 12.5916 2.46438 14 3.80274 14H12.2011C13.5395 14 14.41 12.5916 13.8115 11.3945ZM7.46514 3.53204C7.6863 3.08968 8.31756 3.08969 8.53872 3.53204L12.7379 11.9312C12.9374 12.3303 12.6472 12.7997 12.2011 12.7997H3.80274C3.35661 12.7997 3.06645 12.3302 3.26595 11.9312L7.46514 3.53204ZM7.9998 4.89168C7.60795 4.89168 7.29476 5.23154 7.31042 5.63977L7.43972 9.00995C7.45174 9.32339 7.69894 9.57102 7.9998 9.57102C8.30066 9.57102 8.54787 9.32339 8.55989 9.00995L8.68919 5.63977C8.70485 5.23154 8.39166 4.89168 7.9998 4.89168ZM7.17408 11.3492C7.17408 10.884 7.53576 10.5069 7.98192 10.5069C8.42808 10.5069 8.78976 10.884 8.78976 11.3492C8.78976 11.8144 8.42808 12.1915 7.98192 12.1915C7.53576 12.1915 7.17408 11.8144 7.17408 11.3492Z" fill="currentColor"/>
  </SvgIcon>);
};
export default WarningIcon;

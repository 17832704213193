import styled from 'styled-components';
export const StyledDrawerRouteList = styled.div `
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: ${({ theme }) => theme.spacing(2)};
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
StyledDrawerRouteList.displayName = 'StyledDrawerRouteList';

import styled from 'styled-components';
import { baseColors } from '@groupby/ui-components';
export const StyledCard = styled.div `
  box-sizing: border-box;
  padding: ${({ noPadding }) => (noPadding ? '0' : '1.5rem')};

  position: relative;
  box-shadow: ${({ theme, noShadow }) => (noShadow ? 'unset' : theme.analyticsCards.shadow)};
  border-radius: 0.5rem;
  background-color: ${baseColors.white};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
export const StyledCardTitle = styled.div `
  display: flex;
  cursor: default;
  justify-content: space-between;
  margin-bottom: ${({ theme, noMargin }) => (noMargin ? 0 : theme.spacing(1.2))};
  font-weight:  ${({ theme }) => theme.typography.fontWeightMedium};
  font-size: 0.875rem;
  color: ${({ theme }) => theme.palette.accent.main};
  align-items: center;

  &.title--secondary {
    font-size: 0.75rem;
    color: ${({ theme }) => theme.palette.text.secondary};
  }

  &.title--uppercase {
    text-transform: uppercase;
  }
`;

import React from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Icon } from '@groupby/ui-components';
import { useTheme } from 'styled-components';
import { AlertWarningIcon } from '@/components/icons';
import { StyledIconButton, StyledInformationNotification, StyledText } from './information-notification.styles';
export const InformationNotification = ({ onCancel, text, icon, negativeNotification, openModal, }) => {
    const theme = useTheme();
    return (<StyledInformationNotification $openModal={openModal} $isNegativeNotification={negativeNotification} $hasCancelIcon={!!onCancel}>
      {icon || (negativeNotification ? <AlertWarningIcon /> : <InfoOutlinedIcon />)}
      <StyledText className="preview-panel--rule-trigger">{text}</StyledText>
      {onCancel && <StyledIconButton onClick={onCancel} size="large"><Icon color={theme.palette.accent.main} variant="cancel"/></StyledIconButton>}
    </StyledInformationNotification>);
};
export default InformationNotification;

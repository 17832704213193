export function mapReleaseNotesContentItemMapToReleaseNotesContentItemList(releaseNotesMap) {
    if (!releaseNotesMap.allIds.length)
        return [];
    return releaseNotesMap.allIds.map((id) => releaseNotesMap.byId[id]);
}
export function mapReleaseNotesContentItemMapToSearchDocumentList(releaseNotesMap) {
    if (!releaseNotesMap.allIds.length)
        return [];
    return mapReleaseNotesContentItemMapToReleaseNotesContentItemList(releaseNotesMap)
        .map(mapReleaseNotesContentItemToSearchDocument);
}
export function mapReleaseNotesContentItemToSearchDocument(releaseNote) {
    if (!releaseNote.content) {
        throw new Error('releaseNote should have content property initialized!');
    }
    return {
        fileName: releaseNote.fileName,
        content: releaseNote.content,
        releaseDate: releaseNote.releaseDate,
        releaseName: releaseNote.releaseName,
        highlightedContent: releaseNote.content,
    };
}
export const highlightContent = (content, phrase) => {
    const escapeRegExp = (string) => string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    const escapedPhrase = escapeRegExp(phrase);
    const regex = new RegExp(escapedPhrase, 'gi');
    return content.replace(regex, '<mark>$&</mark>'); // Replace matches with highlighted HTML
};
export function filterAllReleaseNotesBySearchResults(all, phrase, search) {
    var _a;
    function getAllItems() {
        return all.allIds.map((key) => all.byId[key]);
    }
    function withoutUndefined(items) {
        return items.filter(Boolean); // .filter(Boolean) removes any falsy value. We needed to remove all undefined values.
    }
    function checkMatch(content, phrase) {
        if (!content)
            return false;
        return content.toLowerCase().includes(phrase.toLowerCase());
    }
    function resetHighlightedContent(items) {
        items.forEach((item) => {
            item.highlightedContent = item.content;
            item.isReleaseDateHighlighted = false;
            item.isReleaseNameHighlighted = false;
        });
    }
    if (!phrase) {
        resetHighlightedContent(getAllItems());
        return withoutUndefined(getAllItems());
    }
    const items = (_a = search(phrase)) === null || _a === void 0 ? void 0 : _a.items;
    const res = (items === null || items === void 0 ? void 0 : items.length) ? items.map((r) => {
        const item = all.byId[r.document.fileName];
        if (item) {
            item.highlightedContent = highlightContent(item.content, phrase);
            item.isReleaseDateHighlighted = checkMatch(item.releaseDate, phrase);
            item.isReleaseNameHighlighted = checkMatch(item.releaseName, phrase);
        }
        return item;
    })
        : [];
    return withoutUndefined(res);
}

import { baseColors } from '@groupby/ui-components';
import { Localization } from '@/localization';
import { axisLabelStyle, chartLabelsParams } from './chart.constants';
export const getXAxisSettings = (xAxisLabels) => ({
    axisBorder: {
        show: false,
    },
    tickAmount: xAxisLabels.length,
    labels: {
        rotate: 0,
        style: axisLabelStyle,
    },
    crosshairs: {
        width: 0,
    },
    categories: xAxisLabels,
});
export const getLineChartSettings = (id) => ({
    id,
    toolbar: {
        show: false,
    },
});
export const getCustomTooltip = (value, date, seriesNames = '') => (`<div class="home-dashboard__chart__tooltip">
    <span class="home-dashboard__chart__tooltip__value">${value}</span>
    <span class="home-dashboard__chart__tooltip__date">${date}</span>
    ${seriesNames}
  </div>`);
export const COMMON_TOOLTIP_SETTINGS = {
    enabled: true,
    shared: false,
    x: {
        show: false,
    },
};
export const COMMON_GRID_SETTINGS = {
    show: true,
    borderColor: baseColors.primaryBlueMed200,
    position: 'back',
    yaxis: {
        lines: {
            show: true,
        },
    },
    xaxis: {
        lines: {
            show: true,
        },
    },
    padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 10,
    },
};
export function formatChartLabels(formatDate) {
    return function (date, index, array) {
        if (array.length > chartLabelsParams.LONG_LIST_BREAKPOINT) {
            return index % chartLabelsParams.LONG_LIST_DIVIDER === 0 ? formatDate(date, Localization.ShortDateUTCFormat) : '';
        }
        if (array.length > chartLabelsParams.MEDIUM_LIST_BREAKPOINT) {
            return index % chartLabelsParams.MEDIUM_LIST_DIVIDER === 0 ? formatDate(date, Localization.ShortDateUTCFormat) : '';
        }
        return formatDate(date, Localization.ShortDateUTCFormat);
    };
}

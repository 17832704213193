import React from 'react';
import { TableBody, TableCell } from '@mui/material';
import { ButtonV2, Checkbox, Icon } from '@groupby/ui-components';
import { StyledTableCell, StyledTableRow } from './table-rows.styles';
import { TableCellComponent } from './table-cell.component';
import { NoResults } from '@/search-for-retail/generic/no-results';
import { TableDraggableRow } from '../table-draggable-row';
export const TableRows = ({ data, columns, handleMove, isDraggable, isSelectable, isMoreOption, onRuleClick, handleMoreOptionsOpen, selected, setSelected, }) => {
    const onCheckBoxClick = (e) => {
        e.stopPropagation();
    };
    const onMoreButtonClick = (index) => (e) => {
        e.stopPropagation();
        handleMoreOptionsOpen(index)(e);
    };
    const onSelectRow = (row) => (e, checked) => {
        e.stopPropagation();
        if (checked) {
            setSelected((prev) => [...prev, row]);
        }
        else {
            setSelected((prev) => prev.filter(({ id }) => row.id !== id));
        }
    };
    const Cells = (row, index, checked) => (<React.Fragment>
      {isSelectable && (<StyledTableCell width="32px" padding="checkbox" sticky left={50}>
          <Checkbox onClick={onCheckBoxClick} handleChange={onSelectRow(row)} checked={checked}/>
        </StyledTableCell>)}
      {columns.filter(({ hidden }) => !hidden).map((column) => <TableCellComponent key={`${row.id}_${column.label}`} column={column} row={row} rowIndex={index}/>)}
      {isMoreOption && (<StyledTableCell width="2rem" padding="none" align="right">
          <ButtonV2 disableRipple disableTouchRipple className="more-button" onClick={onMoreButtonClick(index)} icon={<Icon variant="more" className="more-button-icon"/>}/>
        </StyledTableCell>)}
    </React.Fragment>);
    const HiddenRow = (<React.Fragment>
      {isSelectable && (<StyledTableCell width="3rem" padding="checkbox"/>)}
      {columns.filter(({ hidden }) => !hidden).map((column) => (<StyledTableCell key={`${column.label}_hidden`} width={column.width}/>))}
      {isMoreOption && (<StyledTableCell width="2rem" padding="none" align="right"/>)}
    </React.Fragment>);
    return (<TableBody>
      {data.map((row, index) => {
        const isSelected = selected.find(({ id }) => id === row.id);
        return (<TableDraggableRow id={row.id} key={row.id} index={index} moveItem={handleMove} hiddenRow={HiddenRow} rowCount={data.length} isSelected={isSelected} isDraggable={isDraggable} onRuleClick={() => onRuleClick(row)} isLastRow={data.length - 1 === index}>
            {Cells(row, index, isSelected)}
          </TableDraggableRow>);
    })}

      {data.length === 0 && (<StyledTableRow>
          <TableCell colSpan={columns.length + 2}>
            <NoResults />
          </TableCell>
        </StyledTableRow>)}
    </TableBody>);
};

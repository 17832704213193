import { __rest } from "tslib";
import React from 'react';
import Grid from '@mui/material/Grid';
import classNames from 'classnames';
import { withStyles } from '@/decorators';
import { exposeWrapped } from '@/utils';
export const styles = {
    flexGrow: {
        flexGrow: 1,
    },
    fitContent: {
        flexBasis: 0,
    },
};
const ResponsiveContainer = withStyles(styles)((_a) => {
    var { fitContent, flexGrow, className, classes } = _a, props = __rest(_a, ["fitContent", "flexGrow", "className", "classes"]);
    return (<Grid item classes={{ item: classNames({ [classes.flexGrow]: flexGrow, [classes.fitContent]: fitContent }, className) }} {...props}/>);
});
export const responsive = exposeWrapped((Component) => (function ResponsiveComponent(_a) {
    var { xs, sm, md, lg, xl, container, justify, fitContent = false, flexGrow, responsiveClassName } = _a, props = __rest(_a, ["xs", "sm", "md", "lg", "xl", "container", "justify", "fitContent", "flexGrow", "responsiveClassName"]);
    return (<ResponsiveContainer xs={xs} sm={sm} md={md} lg={lg} xl={xl} container={container} justify={justify} flexGrow={flexGrow} fitContent={fitContent} {...(container ? { spacing: 0 } : {})} className={responsiveClassName}>
          <Component {...props}/>
        </ResponsiveContainer>);
}));

import React from 'react';
import cn from 'classnames';
import { Skeleton } from '@groupby/ui-components';
import { StyledCard, StyledCardTitle } from './analytics-card.styles';
export const AnalyticsCard = ({ title, showNoData, Component, noDataElement, noPadding, noMargin, noShadow, isLoading, className, secondary, actions, children, transformTitleToUppercase, skeletonHeight, }) => {
    let content;
    const CardSkeleton = () => <Skeleton height={skeletonHeight !== null && skeletonHeight !== void 0 ? skeletonHeight : 269} sx={{ width: '100%' }} animation="wave" variant="rectangular"/>;
    if (isLoading) {
        content = <CardSkeleton />;
    }
    else if (showNoData) {
        content = noDataElement;
    }
    else {
        content = Component ? <Component /> : children;
    }
    return (<StyledCard className={className} noShadow={noShadow} noPadding={noPadding}>
      <StyledCardTitle noMargin={noMargin} className={cn({ 'title--secondary': secondary, 'title--uppercase': transformTitleToUppercase })}>
        {title || ''}
        {actions || ''}
      </StyledCardTitle>
      {content}
    </StyledCard>);
};
export default AnalyticsCard;

import React from 'react';
import { NoDataWrapper, StyledNoDataWarning } from '../rule/form/analytics/analytics.styles';
import { AnalyticsEmptyIcon } from '@/components/icons';
import { useLocalization } from '@/localization';
const SplashScreen = () => {
    const { formatMessage: t } = useLocalization();
    return (<NoDataWrapper style={{ height: '100vh' }}>
      <StyledNoDataWarning icon={<AnalyticsEmptyIcon />} title={t({
        key: 'NO_ANALYTICS_DATA_AVAILABLE',
        values: { b: (chunks) => <React.Fragment>{chunks}<br /></React.Fragment> },
    })}/>
    </NoDataWrapper>);
};
export default SplashScreen;

import { __awaiter } from "tslib";
import { stringify } from 'qs';
import { s4rFind } from '@/client/api';
import { authenticated } from '@/client/utils';
export const getFolder = (token) => __awaiter(void 0, void 0, void 0, function* () {
    const { result } = yield authenticated(token, () => s4rFind('analytics/folders/default', undefined, false))();
    return result;
});
export const getEmbedSsoUrl = (token, params) => __awaiter(void 0, void 0, void 0, function* () {
    const { result } = yield authenticated(token, 
    // disabled encoding option as backend does not decode encoded slashes
    () => s4rFind(`analytics/embedSsoUrl?${stringify(params)}`, undefined, true))();
    return result.url;
});
export default {
    getFolder,
    getEmbedSsoUrl,
};

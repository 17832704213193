import React from 'react';
import { baseColors, Icon, ToolTip, Typography, } from '@groupby/ui-components';
import { InsightContainer, InsightDetails, InsightSuggestion, InsightTitleTypography, } from './insight.styles';
import { useLocalization } from '@/localization';
const MAX_LIMIT_OF_DETAILS = 2;
const TOOLTIP_STYLES_OVERRIDES = { tooltip: { sx: { maxWidth: '240px' } } };
export const Insight = (props) => {
    const { formatMessage: t } = useLocalization();
    const messageTitleKey = `INSIGHTS_${props.actionableInsightType.toUpperCase()}_TITLE`;
    const messageKey = `INSIGHTS_${props.actionableInsightType.toUpperCase()}`;
    return (<InsightContainer>
      <InsightTitleTypography>{t({ key: messageTitleKey })}</InsightTitleTypography>
      {props.details.slice(0, MAX_LIMIT_OF_DETAILS).map((product) => {
        var _a;
        return (<InsightDetails key={product.rank} className='insight-details' $isDeltaNegative={(_a = product.delta) === null || _a === void 0 ? void 0 : _a.includes('-')}>
            {product.delta && (<React.Fragment>
                <Icon variant="arrowPaginationNext" color={baseColors.primaryBlueMed400}/>
                <Typography className="insight-delta">{product.delta}</Typography>
              </React.Fragment>)}
            <ToolTip placement="bottom-start" componentsProps={TOOLTIP_STYLES_OVERRIDES} arrow title={<span>{product.text}</span>}>
              <span>
                <Typography>{product.text}</Typography>
              </span>
            </ToolTip>
          </InsightDetails>);
    })}
      <ToolTip arrow title={<div>
          <span>{t({ key: 'INSIGHTS_SUGGESTION' })}{': '}</span>
          <span>{t({ key: messageKey })}</span>
        </div>}>
        <InsightSuggestion>
          <Typography className="insight-suggestion-drop-cap">
            {t({ key: 'INSIGHTS_SUGGESTION' })}{': '}
          </Typography>
          <Typography>
            {t({ key: messageKey })}
          </Typography>
        </InsightSuggestion>
      </ToolTip>
    </InsightContainer>);
};

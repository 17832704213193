import * as RuleActions from '@/store/actions/types/rule.types';
const DEFAULT = { ruleAnalytics: {} };
export default (state = DEFAULT, action) => {
    switch (action.type) {
        case RuleActions.SAVE_RULE_ANALYTICS:
            return Object.assign(Object.assign({}, state), { ruleAnalytics: action.payload.ruleAnalytics.reduce((agg, ra) => {
                    agg[ra.ruleId] = ra;
                    return agg;
                }, {}) });
        default:
            return state;
    }
};

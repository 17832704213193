import { baseColors } from '@groupby/ui-components';
import Chip from '@mui/material/Chip';
import styled from 'styled-components';
export const TitleBadge = styled(Chip) `
  background-color: ${({ $background }) => $background !== null && $background !== void 0 ? $background : baseColors.maroon};
  color: ${({ $textColor }) => $textColor !== null && $textColor !== void 0 ? $textColor : baseColors.white};
  font-size: 0.75rem;
  font-weight: 500;
  margin-right: ${(props) => props.theme.spacing(1)};
`;

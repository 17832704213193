import React, { useCallback, useMemo } from 'react';
import { useLocalization } from '@/localization';
import { ContextSelector } from '@/search-for-retail/generic/page-context-selection';
export const CollectionSelector = ({ collections, disabled, selectedCollectionName, defaultCollection, onSelectCollection, }) => {
    const { formatMessage: t } = useLocalization();
    const collectionOptions = useMemo(() => collections.map(({ collection }) => collection), [collections]);
    const handleSwitchCollection = useCallback((collectionName) => {
        const collection = collections.find((value) => value.collection === collectionName);
        onSelectCollection(collection);
    }, [onSelectCollection, collections]);
    return (<ContextSelector label={t({ key: 'SELECT_COLLECTION' })} selectedOption={selectedCollectionName !== null && selectedCollectionName !== void 0 ? selectedCollectionName : ''} switchOption={handleSwitchCollection} options={collectionOptions} defaultOption={defaultCollection} primaryCopy={t({ key: 'COLLECTION' })} defaultDropdownText={t({ key: 'SELECT_COLLECTION' })} disabled={disabled}/>);
};
export default CollectionSelector;

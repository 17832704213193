import { __rest } from "tslib";
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
export const SmileIcon = (_a) => {
    var { width = 32, height = 32 } = _a, props = __rest(_a, ["width", "height"]);
    return (<SvgIcon width={width} height={height} viewBox="0 0 32 32" fill="none" {...props}>
    <g clipPath="url(#clip0_305_916)">
      <path d="M16 0C13.2208 0.00312662 10.4904 0.730101 8.07763 2.10933C5.66485 3.48855 3.65293 5.47246 2.24002 7.86567C0.827101 10.2589 0.0619211 12.9788 0.019835 15.7577C-0.0222511 18.5365 0.660208 21.2784 2 23.7133L0 32L8.08667 29.9067C10.216 31.1182 12.5952 31.8245 15.0407 31.9713C17.4862 32.118 19.9327 31.7012 22.1916 30.753C24.4506 29.8048 26.4616 28.3505 28.0697 26.5022C29.6778 24.654 30.84 22.4611 31.4667 20.0928C32.0933 17.7244 32.1678 15.2437 31.6842 12.842C31.2007 10.4403 30.1721 8.18174 28.6777 6.24039C27.1833 4.29905 25.2631 2.7268 23.0651 1.64485C20.867 0.562901 18.4499 0.000156964 16 0V0ZM16 30.6667C13.3705 30.6667 10.7893 29.9598 8.52667 28.62L8.28667 28.4733L1.88667 30.1133L3.41333 23.5267L3.27333 23.2867C1.67039 20.4866 1.03548 17.2361 1.46693 14.0387C1.89838 10.8413 3.37213 7.87532 5.65989 5.60026C7.94765 3.32521 10.9218 1.86801 14.1215 1.45437C17.3213 1.04073 20.5682 1.69373 23.3593 3.31224C26.1504 4.93075 28.3299 7.42443 29.5603 10.407C30.7906 13.3897 31.0031 16.6947 30.1649 19.8104C29.3267 22.926 27.4845 25.6783 24.9237 27.6409C22.3629 29.6036 19.2264 30.6671 16 30.6667Z" fill="black"/>
      <path d="M16 21.3333C14.4087 21.3333 12.8825 20.7012 11.7573 19.576C10.6321 18.4507 9.99996 16.9246 9.99996 15.3333H8.66663C8.66663 17.2782 9.43924 19.1435 10.8145 20.5188C12.1898 21.894 14.055 22.6666 16 22.6666C17.9449 22.6666 19.8101 21.894 21.1854 20.5188C22.5607 19.1435 23.3333 17.2782 23.3333 15.3333H22C22 16.9246 21.3678 18.4507 20.2426 19.576C19.1174 20.7012 17.5913 21.3333 16 21.3333Z" fill="black"/>
    </g>
    <defs>
      <clipPath id="clip0_305_916">
        <rect width="32" height="32" fill="white"/>
      </clipPath>
    </defs>
  </SvgIcon>);
};
export default SmileIcon;

import { baseColors } from '@groupby/ui-components';
import ListItem from '@mui/material/ListItem';
import styled from 'styled-components';
export const StyledNavigationSectionHeader = styled(ListItem) `
  color: ${baseColors.primaryBlueDark500};
  position: sticky;
  top: 0;
  min-height: ${({ theme }) => theme.pageContent.formHeaderHeight};

  .MuiListItemIcon-root {
    min-width: 20px;
    margin-right: 20px;
  }

  .MuiListItemText-primary {
    font-size: 1.2rem;
    line-height: 1.6rem;
    font-weight: 500;
  }

  .MuiListItemText-root {
    max-width: 100%;
  }

  .hidden {
    display: none;
  }
`;

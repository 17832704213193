import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@/decorators';
export const styles = ({ appHeader, breakpoints, typography: { pxToRem } }) => ({
    content: {
        width: '100%',
        [breakpoints.down('sm')]: {
            top: appHeader.heightMobile,
            height: `calc(100vh - ${appHeader.heightMobile})`,
        },
        [breakpoints.down('xs')]: {
            top: appHeader.heightXSMobile,
            height: `calc(100vh - ${appHeader.heightXSMobile})`,
        },
    },
    s4rContent: {
        display: 'flex',
        height: '100%',
        // this is a temporary fix and should be resolved in https://groupby.atlassian.net/browse/CUE-1559
        '& #ui-toggle-collapse-button': {
            left: pxToRem(225),
        },
    },
});
const FrameContent = withStyles(styles)(function FrameContent({ classes, children }) {
    return (<main className={classNames(classes.content, classes.s4rContent)}>
      {children}
    </main>);
});
export default FrameContent;

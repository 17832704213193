import { useEffect } from 'react';
export const FormikObserver = ({ dirty, isValid, onDirtyChange, onIsValidChange, }) => {
    useEffect(() => {
        onDirtyChange === null || onDirtyChange === void 0 ? void 0 : onDirtyChange(dirty);
    }, [dirty, onDirtyChange]);
    useEffect(() => {
        onIsValidChange === null || onIsValidChange === void 0 ? void 0 : onIsValidChange(isValid);
    }, [isValid, onIsValidChange]);
    return null;
};
export default FormikObserver;

import { __rest } from "tslib";
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
export const AddProductGraphic = (_a) => {
    var { width = 80, height = 79 } = _a, props = __rest(_a, ["width", "height"]);
    return (<SvgIcon xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={`0 0 ${height} ${width}`} fill="none" {...props}>
    <path d="M14 67.9497C21.732 67.9497 28 61.6817 28 53.9497C28 46.2177 21.732 39.9497 14 39.9497C6.26801 39.9497 0 46.2177 0 53.9497C0 61.6817 6.26801 67.9497 14 67.9497Z" fill="#E2E6EC"/>
    <path d="M48.9502 78.1997C55.5776 78.1997 60.9502 72.8271 60.9502 66.1997C60.9502 59.5723 55.5776 54.1997 48.9502 54.1997C42.3228 54.1997 36.9502 59.5723 36.9502 66.1997C36.9502 72.8271 42.3228 78.1997 48.9502 78.1997Z" fill="#E2E6EC"/>
    <path d="M59.7002 40.7998C70.7459 40.7998 79.7002 31.8455 79.7002 20.7998C79.7002 9.75411 70.7459 0.799805 59.7002 0.799805C48.6545 0.799805 39.7002 9.75411 39.7002 20.7998C39.7002 31.8455 48.6545 40.7998 59.7002 40.7998Z" fill="#E2E6EC"/>
    <path d="M65.8799 23.1997H15.4899C14.4699 23.1997 13.6099 23.9697 13.4999 24.9897L9.41992 64.1997H71.96L67.8699 24.9897C67.7599 23.9697 66.9099 23.1997 65.8799 23.1997Z" fill="white"/>
    <path d="M71.96 64.1997L67.8699 24.9897C67.7599 23.9697 66.9099 23.1997 65.8799 23.1997H63.1L66.72 57.9897C66.84 59.1697 65.92 60.1997 64.73 60.1997H9.81995L9.3999 64.1997H71.9399H71.96Z" fill="#C3CEFF"/>
    <path d="M71.9599 64.9497H9.41987C9.20987 64.9497 8.99981 64.8597 8.85981 64.6997C8.71981 64.5397 8.64987 64.3297 8.66987 64.1197L12.7498 24.9097C12.8998 23.4997 14.0698 22.4497 15.4898 22.4497H65.8798C67.2898 22.4497 68.4698 23.5097 68.6198 24.9197L72.7099 64.1297C72.7299 64.3397 72.6598 64.5497 72.5198 64.7097C72.3798 64.8697 72.1799 64.9597 71.9599 64.9597V64.9497ZM10.2498 63.4497H71.1298L67.1298 25.0697C67.0598 24.4297 66.5298 23.9497 65.8898 23.9497H15.4998C14.8598 23.9497 14.3198 24.4297 14.2598 25.0697L10.2598 63.4497H10.2498Z" fill="#081E42"/>
    <path d="M9.40997 64.1997L9.02997 67.8897C8.84997 69.6597 10.2299 71.1997 12.0099 71.1997H69.35C71.13 71.1997 72.52 69.6597 72.33 67.8897L71.95 64.1997H9.40997Z" fill="white"/>
    <path d="M71.9601 64.1997H67.27L67.4601 65.9897C67.5801 67.1697 66.6601 68.1997 65.4701 68.1997H9.04004C9.04004 69.8297 10.35 71.1997 12.02 71.1997H69.36C71.14 71.1997 72.5301 69.6597 72.3401 67.8897L71.9601 64.1997Z" fill="#C3CEFF"/>
    <path d="M69.3598 71.9497H12.0198C10.9598 71.9497 9.93991 71.4997 9.22991 70.7097C8.51991 69.9197 8.17984 68.8697 8.28984 67.8097L8.66985 64.1197C8.70985 63.7397 9.02985 63.4497 9.41985 63.4497H71.9599C72.3399 63.4497 72.6699 63.7397 72.7099 64.1197L73.0899 67.8097C73.1999 68.8597 72.8598 69.9197 72.1498 70.7097C71.4398 71.4997 70.4298 71.9497 69.3598 71.9497ZM10.0899 64.9497L9.77983 67.9697C9.70983 68.6097 9.9099 69.2297 10.3499 69.7097C10.7799 70.1897 11.3798 70.4497 12.0198 70.4497H69.3598C70.0098 70.4497 70.5998 70.1897 71.0298 69.7097C71.4598 69.2297 71.6599 68.6097 71.5999 67.9697L71.2898 64.9497H10.0999H10.0899Z" fill="#081E42"/>
    <path d="M26.6799 35.6997C28.6129 35.6997 30.1799 34.1327 30.1799 32.1997C30.1799 30.2667 28.6129 28.6997 26.6799 28.6997C24.7469 28.6997 23.1799 30.2667 23.1799 32.1997C23.1799 34.1327 24.7469 35.6997 26.6799 35.6997Z" fill="white"/>
    <path d="M29.1398 29.6997C29.1598 29.8597 29.1898 30.0297 29.1898 30.1997C29.1898 32.1297 27.6198 33.6997 25.6898 33.6997C24.7298 33.6997 23.8697 33.3197 23.2397 32.6997C23.4797 34.3997 24.9298 35.6997 26.6898 35.6997C28.6198 35.6997 30.1898 34.1297 30.1898 32.1997C30.1898 31.2197 29.7898 30.3397 29.1398 29.6997Z" fill="#C3CEFF"/>
    <path d="M26.6799 36.4497C24.3399 36.4497 22.4299 34.5397 22.4299 32.1997C22.4299 29.8597 24.3399 27.9497 26.6799 27.9497C29.0199 27.9497 30.9299 29.8597 30.9299 32.1997C30.9299 34.5397 29.0199 36.4497 26.6799 36.4497ZM26.6799 29.4497C25.1599 29.4497 23.9299 30.6797 23.9299 32.1997C23.9299 33.7197 25.1599 34.9497 26.6799 34.9497C28.1999 34.9497 29.4299 33.7197 29.4299 32.1997C29.4299 30.6797 28.1999 29.4497 26.6799 29.4497Z" fill="#081E42"/>
    <path d="M54.6799 35.6997C56.6129 35.6997 58.1799 34.1327 58.1799 32.1997C58.1799 30.2667 56.6129 28.6997 54.6799 28.6997C52.7469 28.6997 51.1799 30.2667 51.1799 32.1997C51.1799 34.1327 52.7469 35.6997 54.6799 35.6997Z" fill="white"/>
    <path d="M57.1299 29.6997C57.1499 29.8597 57.1799 30.0297 57.1799 30.1997C57.1799 32.1297 55.6099 33.6997 53.6799 33.6997C52.7199 33.6997 51.86 33.3197 51.23 32.6997C51.47 34.3997 52.9199 35.6997 54.6799 35.6997C56.6099 35.6997 58.1799 34.1297 58.1799 32.1997C58.1799 31.2197 57.7799 30.3397 57.1299 29.6997Z" fill="#C3CEFF"/>
    <path d="M54.6799 36.4497C52.3399 36.4497 50.4299 34.5397 50.4299 32.1997C50.4299 29.8597 52.3399 27.9497 54.6799 27.9497C57.0199 27.9497 58.9299 29.8597 58.9299 32.1997C58.9299 34.5397 57.0199 36.4497 54.6799 36.4497ZM54.6799 29.4497C53.1599 29.4497 51.9299 30.6797 51.9299 32.1997C51.9299 33.7197 53.1599 34.9497 54.6799 34.9497C56.1999 34.9497 57.4299 33.7197 57.4299 32.1997C57.4299 30.6797 56.1999 29.4497 54.6799 29.4497Z" fill="#081E42"/>
    <path d="M40.6798 32.8198L44.4198 40.3898L52.7798 41.5998L46.7299 47.4998L48.1598 55.8198L40.6798 51.8898L33.2098 55.8198L34.6398 47.4998L28.5898 41.5998L36.9498 40.3898L40.6798 32.8198Z" fill="white"/>
    <path d="M46.73 47.4997L52.78 41.6097L48.3699 40.9697L44.03 45.1997C43.34 45.8697 43.03 46.8297 43.19 47.7797L44.22 53.7597L48.16 55.8297L46.73 47.5097V47.4997Z" fill="#C3CEFF"/>
    <path d="M33.21 56.5698C33.05 56.5698 32.9 56.5198 32.77 56.4298C32.54 56.2598 32.4201 55.9798 32.4701 55.6998L33.83 47.7698L28.07 42.1498C27.87 41.9498 27.79 41.6498 27.88 41.3798C27.97 41.1098 28.2 40.9098 28.49 40.8698L36.45 39.7098L40.01 32.4898C40.14 32.2298 40.4 32.0698 40.68 32.0698C40.97 32.0698 41.2301 32.2298 41.3501 32.4898L44.91 39.7098L52.87 40.8698C53.15 40.9098 53.3901 41.1098 53.4801 41.3798C53.5701 41.6498 53.49 41.9498 53.29 42.1498L47.53 47.7698L48.89 55.6998C48.94 55.9798 48.8201 56.2698 48.5901 56.4298C48.3601 56.5998 48.05 56.6198 47.8 56.4898L40.68 52.7398L33.56 56.4898C33.45 56.5498 33.33 56.5798 33.21 56.5798V56.5698ZM40.68 51.1398C40.8 51.1398 40.92 51.1698 41.03 51.2298L47.16 54.4498L45.99 47.6298C45.95 47.3898 46.03 47.1398 46.21 46.9698L51.17 42.1398L44.32 41.1398C44.07 41.0998 43.86 40.9498 43.75 40.7298L40.69 34.5198L37.63 40.7298C37.52 40.9498 37.31 41.1098 37.06 41.1398L30.21 42.1398L35.17 46.9698C35.35 47.1398 35.43 47.3898 35.39 47.6298L34.2201 54.4498L40.3501 51.2298C40.4601 51.1698 40.58 51.1398 40.7 51.1398H40.68Z" fill="#081E42"/>
    <path d="M54.6799 31.9497C54.2699 31.9497 53.9299 31.6097 53.9299 31.1997V20.1997C53.9299 13.4497 48.4299 7.94971 41.6799 7.94971H39.6799C32.9299 7.94971 27.4299 13.4497 27.4299 20.1997V31.1997C27.4299 31.6097 27.0899 31.9497 26.6799 31.9497C26.2699 31.9497 25.9299 31.6097 25.9299 31.1997V20.1997C25.9299 12.6197 32.0999 6.44971 39.6799 6.44971H41.6799C49.2599 6.44971 55.4299 12.6197 55.4299 20.1997V31.1997C55.4299 31.6097 55.0899 31.9497 54.6799 31.9497Z" fill="#081E42"/>
    <path d="M68.08 17.9497H66.7C66.29 17.9497 65.95 17.6097 65.95 17.1997C65.95 16.7897 66.29 16.4497 66.7 16.4497H68.08C68.49 16.4497 68.83 16.7897 68.83 17.1997C68.83 17.6097 68.49 17.9497 68.08 17.9497Z" fill="#081E42"/>
    <path d="M72.6998 17.9497H71.3198C70.9098 17.9497 70.5698 17.6097 70.5698 17.1997C70.5698 16.7897 70.9098 16.4497 71.3198 16.4497H72.6998C73.1098 16.4497 73.4498 16.7897 73.4498 17.1997C73.4498 17.6097 73.1098 17.9497 72.6998 17.9497Z" fill="#081E42"/>
    <path d="M69.7 20.9498C69.29 20.9498 68.95 20.6098 68.95 20.1998V18.8198C68.95 18.4098 69.29 18.0698 69.7 18.0698C70.11 18.0698 70.45 18.4098 70.45 18.8198V20.1998C70.45 20.6098 70.11 20.9498 69.7 20.9498Z" fill="#081E42"/>
    <path d="M69.7 16.3297C69.29 16.3297 68.95 15.9897 68.95 15.5797V14.1997C68.95 13.7897 69.29 13.4497 69.7 13.4497C70.11 13.4497 70.45 13.7897 70.45 14.1997V15.5797C70.45 15.9897 70.11 16.3297 69.7 16.3297Z" fill="#081E42"/>
    <path d="M59.08 53.9497H57.7C57.29 53.9497 56.95 53.6097 56.95 53.1997C56.95 52.7897 57.29 52.4497 57.7 52.4497H59.08C59.49 52.4497 59.83 52.7897 59.83 53.1997C59.83 53.6097 59.49 53.9497 59.08 53.9497Z" fill="#081E42"/>
    <path d="M63.6998 53.9497H62.3198C61.9098 53.9497 61.5698 53.6097 61.5698 53.1997C61.5698 52.7897 61.9098 52.4497 62.3198 52.4497H63.6998C64.1098 52.4497 64.4498 52.7897 64.4498 53.1997C64.4498 53.6097 64.1098 53.9497 63.6998 53.9497Z" fill="#081E42"/>
    <path d="M60.7 56.9498C60.29 56.9498 59.95 56.6098 59.95 56.1998V54.8198C59.95 54.4098 60.29 54.0698 60.7 54.0698C61.11 54.0698 61.45 54.4098 61.45 54.8198V56.1998C61.45 56.6098 61.11 56.9498 60.7 56.9498Z" fill="#081E42"/>
    <path d="M60.7 52.34C60.29 52.34 59.95 52 59.95 51.59V50.21C59.95 49.8 60.29 49.46 60.7 49.46C61.11 49.46 61.45 49.8 61.45 50.21V51.59C61.45 52 61.11 52.34 60.7 52.34Z" fill="#081E42"/>
    <path d="M19.08 49.9497H17.7C17.29 49.9497 16.95 49.6097 16.95 49.1997C16.95 48.7897 17.29 48.4497 17.7 48.4497H19.08C19.49 48.4497 19.83 48.7897 19.83 49.1997C19.83 49.6097 19.49 49.9497 19.08 49.9497Z" fill="#081E42"/>
    <path d="M23.6901 49.9497H22.3101C21.9001 49.9497 21.5601 49.6097 21.5601 49.1997C21.5601 48.7897 21.9001 48.4497 22.3101 48.4497H23.6901C24.1001 48.4497 24.4401 48.7897 24.4401 49.1997C24.4401 49.6097 24.1001 49.9497 23.6901 49.9497Z" fill="#081E42"/>
    <path d="M20.6899 52.9498C20.2799 52.9498 19.9399 52.6098 19.9399 52.1998V50.8198C19.9399 50.4098 20.2799 50.0698 20.6899 50.0698C21.0999 50.0698 21.4399 50.4098 21.4399 50.8198V52.1998C21.4399 52.6098 21.0999 52.9498 20.6899 52.9498Z" fill="#081E42"/>
    <path d="M20.6899 48.34C20.2799 48.34 19.9399 48 19.9399 47.59V46.21C19.9399 45.8 20.2799 45.46 20.6899 45.46C21.0999 45.46 21.4399 45.8 21.4399 46.21V47.59C21.4399 48 21.0999 48.34 20.6899 48.34Z" fill="#081E42"/>
    <path d="M59.08 10.9497H57.7C57.29 10.9497 56.95 10.6097 56.95 10.1997C56.95 9.78971 57.29 9.44971 57.7 9.44971H59.08C59.49 9.44971 59.83 9.78971 59.83 10.1997C59.83 10.6097 59.49 10.9497 59.08 10.9497Z" fill="#081E42"/>
    <path d="M63.6998 10.9497H62.3198C61.9098 10.9497 61.5698 10.6097 61.5698 10.1997C61.5698 9.78971 61.9098 9.44971 62.3198 9.44971H63.6998C64.1098 9.44971 64.4498 9.78971 64.4498 10.1997C64.4498 10.6097 64.1098 10.9497 63.6998 10.9497Z" fill="#081E42"/>
    <path d="M60.7 13.9498C60.29 13.9498 59.95 13.6098 59.95 13.1998V11.8198C59.95 11.4098 60.29 11.0698 60.7 11.0698C61.11 11.0698 61.45 11.4098 61.45 11.8198V13.1998C61.45 13.6098 61.11 13.9498 60.7 13.9498Z" fill="#081E42"/>
    <path d="M60.7 9.32971C60.29 9.32971 59.95 8.98971 59.95 8.57971V7.19971C59.95 6.78971 60.29 6.44971 60.7 6.44971C61.11 6.44971 61.45 6.78971 61.45 7.19971V8.57971C61.45 8.98971 61.11 9.32971 60.7 9.32971Z" fill="#081E42"/>
    <path d="M10.0699 16.9497H8.68994C8.27994 16.9497 7.93994 16.6097 7.93994 16.1997C7.93994 15.7897 8.27994 15.4497 8.68994 15.4497H10.0699C10.4799 15.4497 10.8199 15.7897 10.8199 16.1997C10.8199 16.6097 10.4799 16.9497 10.0699 16.9497Z" fill="#081E42"/>
    <path d="M14.6901 16.9497H13.3101C12.9001 16.9497 12.5601 16.6097 12.5601 16.1997C12.5601 15.7897 12.9001 15.4497 13.3101 15.4497H14.6901C15.1001 15.4497 15.4401 15.7897 15.4401 16.1997C15.4401 16.6097 15.1001 16.9497 14.6901 16.9497Z" fill="#081E42"/>
    <path d="M11.6899 19.9498C11.2799 19.9498 10.9399 19.6098 10.9399 19.1998V17.8198C10.9399 17.4098 11.2799 17.0698 11.6899 17.0698C12.0999 17.0698 12.4399 17.4098 12.4399 17.8198V19.1998C12.4399 19.6098 12.0999 19.9498 11.6899 19.9498Z" fill="#081E42"/>
    <path d="M11.6899 15.3297C11.2799 15.3297 10.9399 14.9897 10.9399 14.5797V13.1997C10.9399 12.7897 11.2799 12.4497 11.6899 12.4497C12.0999 12.4497 12.4399 12.7897 12.4399 13.1997V14.5797C12.4399 14.9897 12.0999 15.3297 11.6899 15.3297Z" fill="#081E42"/>
    <path d="M20.08 11.9497H18.7C18.29 11.9497 17.95 11.6097 17.95 11.1997C17.95 10.7897 18.29 10.4497 18.7 10.4497H20.08C20.49 10.4497 20.83 10.7897 20.83 11.1997C20.83 11.6097 20.49 11.9497 20.08 11.9497Z" fill="#081E42"/>
    <path d="M24.6901 11.9497H23.3101C22.9001 11.9497 22.5601 11.6097 22.5601 11.1997C22.5601 10.7897 22.9001 10.4497 23.3101 10.4497H24.6901C25.1001 10.4497 25.4401 10.7897 25.4401 11.1997C25.4401 11.6097 25.1001 11.9497 24.6901 11.9497Z" fill="#081E42"/>
    <path d="M21.6899 14.9498C21.2799 14.9498 20.9399 14.6098 20.9399 14.1998V12.8198C20.9399 12.4098 21.2799 12.0698 21.6899 12.0698C22.0999 12.0698 22.4399 12.4098 22.4399 12.8198V14.1998C22.4399 14.6098 22.0999 14.9498 21.6899 14.9498Z" fill="#081E42"/>
    <path d="M21.6899 10.3297C21.2799 10.3297 20.9399 9.98971 20.9399 9.57971V8.19971C20.9399 7.78971 21.2799 7.44971 21.6899 7.44971C22.0999 7.44971 22.4399 7.78971 22.4399 8.19971V9.57971C22.4399 9.98971 22.0999 10.3297 21.6899 10.3297Z" fill="#081E42"/>
  </SvgIcon>);
};
export default AddProductGraphic;

import styled from 'styled-components';
import { baseColors } from '@groupby/ui-components';
import { Divider } from '@mui/material';
import { AnalyticsCard } from '@/search-for-retail/generic/analytics';
import { NoDataFallback } from '@/search-for-retail/common/no-data-fallback';
export const Wrapper = styled.div `
  flex: 1;
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 2fr minmax(240px, 260px);
  gap: ${({ theme }) => theme.spacing(3)};
`;
export const ChartsWrapper = styled.div `
  display: flex;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacing(2)};

  > div {
    width: calc(50% - 0.5rem);

    &:nth-of-type(n+3) {
      width: calc(33.33% - 0.7rem);
    }
  }
`;
export const CardWrapper = styled.div `
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 3px 3px 0px 0px;
`;
export const StyledAnalyticsCard = styled(AnalyticsCard) `
  padding-top: ${({ theme }) => theme.spacing(2)};
  padding-bottom: ${({ theme }) => theme.spacing(2)};
  gap: ${({ theme }) => theme.spacing(1)};
  box-shadow: none;
  border: 1px solid ${baseColors.primaryBlueMed200};
  justify-content: flex-start;

  > div:first-of-type {
    margin: 7px 0 0;
  }
`;
export const StyledChartContainer = styled.div `
  margin-top: ${({ theme }) => theme.spacing(1.5)};
  margin-bottom: ${({ theme }) => theme.spacing(-3)};
  & .apexcharts-xaxistooltip {
    display: none;
  }

  .apexcharts-line {
    stroke-width: 4;
  }
`;
export const StyledNoDataFallback = styled(NoDataFallback) `
  .text-block-title {
    font-size: 0.875rem;
    color: ${({ theme }) => theme.palette.accent.main}
  }

  .icon svg {
    width: ${({ theme }) => theme.spacing(10.5)};
    height: ${({ theme }) => theme.spacing(10.5)};
  }
`;
export const AnalyticsCardValue = styled.div `
  font-size: 2rem;
`;
export const InfoCard = styled.div `
  position: relative;
  box-shadow: ${({ theme }) => theme.analyticsCards.shadow};
  border-radius: 0.5rem;
  background-color: ${baseColors.white};
  display: flex;
  flex-direction: column;
  padding: 1.1rem ${({ theme }) => theme.spacing(2)};
  font-size: 0.875rem;
  gap: 13px;
`;
export const InfoCardTitle = styled.div `
  cursor: default;
  font-size: 0.75rem;
  font-weight: 500;
  text-transform: uppercase;
  color: ${baseColors.primaryBlueMed600};
`;
export const InfoCardItem = styled.div `
  cursor: default;
  display: flex;
  align-items: center;

  span {
    margin-left: auto;
    font-weight: 700;
  }
`;
export const InfoCardDivider = styled(Divider) `
  margin: 4px 0;
`;
export const NoDataWrapper = styled.div `
  position: relative;
`;
export const BlurredWrapper = styled(Wrapper) `
  filter: blur(4px);
`;
export const EmptyCard = styled(InfoCard) `
  height: ${({ height }) => height}px;
`;
export const StyledNoDataWarning = styled(NoDataFallback) `
  position: absolute;
  z-index: 1;
  top: 40%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);

  .text-block-title {
    line-height: 1.2;
    color: ${({ theme }) => theme.palette.accent.main};
    text-align: center;
    margin-top: 7px;
  }

  .icon svg {
    width: 6.5rem;
    height: 6.5rem;
  }
`;

import { __rest } from "tslib";
import React from 'react';
import AlignIcon from '@mui/icons-material/Sort';
import { useLocalization } from '@/localization';
import { StyledAdvancedFiltersButton } from './advanced-filters-button.styles';
export const AdvancedFiltersButton = (_a) => {
    var { hidden } = _a, props = __rest(_a, ["hidden"]);
    const { formatMessage: t } = useLocalization();
    return (<StyledAdvancedFiltersButton rounded variant="outlined" startIcon={<AlignIcon />} title={t({ key: 'FILTERS' })} $hidden={hidden} {...props}/>);
};
export default AdvancedFiltersButton;

import * as Actions from '../types/ui';
import { createAction } from '../utils';
import State from '@/store/state';
import { cuid } from '@/utils';
export const scrollToTop = () => createAction(Actions.SCROLL_TO_TOP);
export const hideAlert = () => createAction(Actions.HIDE_ALERT);
export const showError = (id, message) => createAction(Actions.SHOW_ERROR, { id, message });
export const clearError = (id) => createAction(Actions.CLEAR_ERROR, id);
export const addError = (message, details, timeout) => createAction(Actions.ADD_NOTIFICATION, Object.assign({ message,
    timeout, type: State.Notification.Type.ERROR, id: cuid() }, (details ? { details } : {})));
export const addSuccess = (message, details, timeout) => createAction(Actions.ADD_NOTIFICATION, Object.assign({ message,
    timeout, type: State.Notification.Type.SUCCESS, id: cuid() }, (details ? { details } : {})));
export const closeNotification = (id) => createAction(Actions.CLOSE_NOTIFICATION, id);
export const removeNotification = (id) => createAction(Actions.REMOVE_NOTIFICATION, id);
export const clearNotifications = () => createAction(Actions.CLEAR_NOTIFICATIONS);

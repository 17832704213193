import styled from 'styled-components';
import { baseColors } from '@groupby/ui-components';
export const StyledNoResultsContainer = styled.div `
  && {
    padding: 2rem;
    background: ${baseColors.white};

    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;
export default StyledNoResultsContainer;

import styled from 'styled-components';
import { StyledSearchQueryInput } from '@/search-for-retail/common/preview-panel/preview-panel-drawer.styles';
export const CustomSearchQueryInput = styled(StyledSearchQueryInput) `
  flex: 0 1 500px !important;
  background: #fff;

  && {
    flex: none;
  }

  .MuiInputBase-root {
    height: 48px;
    line-height: 48px;
    max-height: none !important;
  }

  label {
    margin-top: 7px;
  }

  fieldset {
    inset: 0px;
    height: 48px !important;
  }
`;

import styled from 'styled-components';
import { baseColors, MenuItem, MenuList, TextField, } from '@groupby/ui-components';
export const StyledFilterMenuSearchContainer = styled(MenuItem) `
  && {
    padding: unset !important;
  }
`;
export const StyledFilterMenuSearch = styled.div `
  position: sticky;
  top: 0;
  z-index: 1;
  padding: ${({ theme }) => theme.spacing(1)};
  background-color: ${baseColors.white};
`;
export const StyledFilterMenuActions = styled.div `
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: ${({ theme }) => theme.spacing(1)};
`;
export const StyledMenuList = styled(MenuList) `
  && {
    button {
      white-space: nowrap;
    }

    ul {
      padding: 0;
    }

    li {
      padding: ${({ theme }) => theme.spacing(1, 2)};
      margin: 0;
      height: 3rem;

      span {
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }
`;
export const StyledMenuSearch = styled(TextField) `
  && {
    .MuiInputBase-root {
      min-height: 2.875rem;
    }

    input {
      padding: unset;
    }
  }
`;

import { __rest } from "tslib";
import React from 'react';
import { StyledTooltip } from './tooltip.styles';
export const Tooltip = (_a) => {
    var { title, tooltipClassName, placement, disableHoverListener, children } = _a, props = __rest(_a, ["title", "tooltipClassName", "placement", "disableHoverListener", "children"]);
    return (<StyledTooltip title={title || ''} enterDelay={300} leaveDelay={300} {...props} disableHoverListener={disableHoverListener} placement={placement || 'top-start'}>
    {children}
  </StyledTooltip>);
};
export default Tooltip;

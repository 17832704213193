import styled from 'styled-components';
export const StyledSearchRowContainer = styled.form `
  display: flex;
  justify-content: flex-start;
  gap: ${({ theme }) => theme.spacing(1)};

  & .MuiInput-underline {
    height: 46px;
  }

  & .MuiButton-root {
    height: 48px;
    border-radius: 8px;
    padding: ${({ theme }) => `${theme.spacing(1)} ${theme.spacing(2.4)}`};
    flex-shrink: 0;
  }

  .show-analytics-container {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    align-items: center;
    font-size: 0.875rem;
    white-space: nowrap;

    .MuiFormControlLabel-root {
      margin-right: ${({ theme }) => theme.spacing(1)};
    }

    .info-icon-wrapper {
      height: 1rem;
    }

    svg {
      font-size: 1rem;
      fill: transparent;
    }
  }
`;

import { __awaiter } from "tslib";
import { JSON_HEADERS, SearchForRetail } from '@/client/api';
import fetch from '@/client/fetch';
import { buildCustomerHeaders, s4rAuthenticated } from '@/client/utils';
import { TARGET_CUSTOMER } from '@/config/global';
import { formatTenantSettingsResponse } from './tenant.formatters';
const TENANT_PATH = `${SearchForRetail.API_URL}/${SearchForRetail.TENANT_PATH}`;
export const getSettings = () => __awaiter(void 0, void 0, void 0, function* () {
    const { result } = yield fetch(`${SearchForRetail.API_URL}/${SearchForRetail.TENANT_PATH}/${TARGET_CUSTOMER}/${SearchForRetail.SETTINGS_PATH}`, {
        method: 'GET',
        headers: Object.assign(Object.assign({}, buildCustomerHeaders()), JSON_HEADERS),
        expiry: false,
        skipCache: false,
    });
    return formatTenantSettingsResponse(result);
});
export const getClientKey = (token, id, options) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield s4rAuthenticated(token, Object.assign({ url: `${TENANT_PATH}/${id}`, method: 'GET', expiry: false, skipCache: false }, options));
    return response.result;
});
export default {
    getSettings,
    getClientKey,
};

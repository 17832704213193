import { __rest } from "tslib";
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
export const SuccessIcon = (_a) => {
    var { width = 24, height = 24, color = 'currentColor' } = _a, props = __rest(_a, ["width", "height", "color"]);
    return (<SvgIcon width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx="12" cy="12" r="10" fill={color}/>
    <path d="M16.7372 9.67573C17.1104 9.26861 17.0829 8.63604 16.6757 8.26285C16.2686 7.88966 15.6361 7.91716 15.2629 8.32428L10.5359 13.481L8.76825 11.3598C8.41468 10.9355 7.78412 10.8782 7.35984 11.2318C6.93556 11.5853 6.87824 12.2159 7.2318 12.6402L9.7318 15.6402C9.91652 15.8618 10.188 15.9929 10.4764 15.9997C10.7649 16.0065 11.0422 15.8884 11.2372 15.6757L16.7372 9.67573Z" fill="white"/>
  </SvgIcon>);
};
export default SuccessIcon;

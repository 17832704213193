import moize from 'moize';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
export function collectionSelector({ collections }) {
    return (collections === null || collections === void 0 ? void 0 : collections.sort((a, b) => { var _a; return (_a = a === null || a === void 0 ? void 0 : a.collection) === null || _a === void 0 ? void 0 : _a.localeCompare(b === null || b === void 0 ? void 0 : b.collection); })) || [];
}
export const collectionById = moize((id) => createSelector(collectionSelector, (collections) => collections.find((collection) => collection.id === id)));
export const collectionIdByName = moize((collectionName) => createSelector(collectionSelector, (collections) => { var _a; return (_a = collections.find(({ collection }) => collection === collectionName)) === null || _a === void 0 ? void 0 : _a.id; }));
export const useCollectionsSelector = () => useSelector(collectionSelector);
export const useCollectionById = (id) => useSelector(collectionById(id));
export const collectionNameByProjectId = moize((id) => createSelector(collectionSelector, (collections) => collections.find(({ projectId }) => projectId === id).collection));
export const useCollectionNameByPojectIdSelector = (id) => useSelector(collectionNameByProjectId(id));

import { __rest } from "tslib";
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
export const NavigationIcon = (_a) => {
    var { width = 24, height = 24 } = _a, props = __rest(_a, ["width", "height"]);
    return (<SvgIcon height={height} width={width} viewBox={`0 0 ${width} ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_542_42883)">
      <path d="M12.5 14.0059L12.5 22.0059" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M12.5 2.00586L12.5 6.00586" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M4.5 14.0059L17.6843 14.0059C18.3408 14.0059 18.9555 13.6837 19.329 13.1438L21.5 10.0059L19.329 6.86794C18.9555 6.32805 18.3408 6.00586 17.6843 6.00586L4.5 6.00586L6.87209 10.0059L4.5 14.0059Z" stroke="currentColor" fill="transparent" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </g>
    <defs>
      <clipPath id="clip0_542_42883">
        <rect width="24" height="24" fill="white" transform="translate(0.5 0.00585938)"/>
      </clipPath>
    </defs>
  </SvgIcon>);
};
export default NavigationIcon;

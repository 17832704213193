import React from 'react';
import { AuthContext, AuthContextDefaults } from './auth.context';
import Auth0ProviderWithNavigate from './auth0-provider-with-navigate.component';
import { FeatureFlag, useFeatureFlagsProvider } from '@/search-for-retail/feature-flags';
export const AuthProvider = ({ children }) => {
    const { featureFlagsService } = useFeatureFlagsProvider();
    const isAuth0Enabled = featureFlagsService.isFeatureEnabled(FeatureFlag.Auth0);
    return (<React.Fragment>
      {isAuth0Enabled ? (<Auth0ProviderWithNavigate>
          {children}
        </Auth0ProviderWithNavigate>) : (<AuthContext.Provider value={Object.assign({}, AuthContextDefaults)}>
          {children}
        </AuthContext.Provider>)}
    </React.Fragment>);
};
export default AuthProvider;

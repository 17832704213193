import styled from 'styled-components';
import { baseColors, Checkbox, Icon } from '@groupby/ui-components';
import { TableCell, TableRow, TableSortLabel } from '@mui/material';
export const StyledTableHeadRow = styled(TableRow) `
  && {
    background: ${(props) => (props.$variant === 'dark' ? baseColors.background700 : baseColors.background100)};
    padding: 0 1rem;
    height: 2.5rem;
  }
`;
export const StyledTableHeadCell = styled(TableCell) `
  && {
    cursor: default;
    padding: 0 1rem;
    font-family: 'Open Sans', sans-serif;
    font-size: 0.75rem;
    font-weight: 600;
    color: ${(props) => (props.$variant === 'dark' ? baseColors.primaryBlueMed200 : baseColors.primaryBlueMed600)};
    width: ${(props) => props.$width};
    white-space: ${(props) => (props.$noWrap ? 'nowrap' : 'unset')};

    &.checkbox-cell {
      white-space: nowrap;
      padding: 0.25rem 1rem
    }

    &.initial-buttons-cell {
      width: 70px;
      padding: 0;
    }

    &.end-buttons-cell {
      width: 80px;

      span {
        margin-top: 4px;
        /* margin-left: -16px; */
      }
    }
  }
`;
export const StyledCheckbox = styled(Checkbox) `
  && {
    &.MuiCheckbox-colorPrimary {
      color: ${(props) => (props.$variant === 'dark' ? baseColors.primaryBlueMed200 : baseColors.primaryBlueMed600)} !important;
    }
  }
`;
export const StyledDragIconPlaceholder = styled(Icon).attrs({ variant: 'drag' }) `
  && {
    transform: scale(0.75);
    visibility: hidden;
  }
`;
export const StyledTableSortLabel = styled(TableSortLabel) `
  &.MuiTableSortLabel-root.MuiTableSortLabel-active,
  &&.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon,
  &.MuiTableSortLabel-root:hover {
    color: ${baseColors.white};
  }

  &.MuiTableSortLabel-root.MuiTableSortLabel-active,
  &&.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon,
  &.Mui-active {
    color: #6EE9E1;

    .MuiTableSortLabel-iconDirectionDesc .sort-icon-desc {
      color: #6EE9E1;
    }

    .MuiTableSortLabel-iconDirectionAsc .sort-icon-asc {
      color: #6EE9E1;
    }
  }

  &.MuiTableSortLabel-active {
    .MuiTableSortLabel-iconDirectionAsc .sort-icon-desc,
    .MuiTableSortLabel-iconDirectionDesc .sort-icon-asc {
      display: none;
    }
  }
`;

import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import * as Selectors from '@/store/selectors';
import { useValidateToken } from '@/auth/hooks/use-validate-token';
import { FeatureFlag, useFeatureFlagsProvider } from '@/search-for-retail/feature-flags';
import { useLocationChanged } from '@/routing/hooks';
import { SKIP_AUTHENTICATION } from '@/config/global';
import { Migration } from '@/constants';
export const useHandleAuthOnLocationChanged = () => {
    const isDirtyAuthenticated = useSelector(Selectors.isDirtyAuthenticatedSelector);
    const validateToken = useValidateToken();
    const { featureFlagsService } = useFeatureFlagsProvider();
    const isAuth0Enabled = featureFlagsService.isFeatureEnabled(FeatureFlag.Auth0);
    const handleReauthentication = useCallback((location) => {
        if (!isAuth0Enabled && isDirtyAuthenticated) {
            void validateToken(location.pathname, location.search);
        }
    }, [isAuth0Enabled, isDirtyAuthenticated, validateToken]);
    const onLocationChanged = useCallback((location) => {
        if (SKIP_AUTHENTICATION) {
            return;
        }
        // TODO: refactor this after "S4R-4160_Migrate-to-data-routes" branch is merged and tested for a regression
        if (location.state === Migration.REDUX_ROUTER_STATE) {
            handleReauthentication(location);
        }
    }, [handleReauthentication]);
    useLocationChanged(onLocationChanged);
};

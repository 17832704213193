import React from 'react';
import { Button } from '@/components/button';
import { StyledBox, StyledMaintenanceIcon, StyledTypography } from './error-screen.styles';
import { useLocalization } from '@/localization';
const FullScreenErrorOverlay = () => {
    const { formatMessage: t } = useLocalization();
    return (<StyledBox>
      <StyledMaintenanceIcon />
      <StyledTypography>
        {t({ key: 'NEW_VERSION_REFRESH_PAGE' })}
      </StyledTypography>
      <Button variant="contained" color="primary" onClick={() => window.location.reload()}>
        {t({ key: 'REFRESH' })}
      </Button>
    </StyledBox>);
};
export default FullScreenErrorOverlay;

import { useLocation as routerUseLocation, useMatch as routerUseMatch, useNavigate as routerUseNavigate, useParams as routerUseParams, } from 'react-router-dom';
// hack to allow stubbing in tests
export const useLocation = routerUseLocation;
export const useParams = routerUseParams;
export const useNavigate = routerUseNavigate;
export const useMatch = routerUseMatch;
export * from './utils';
export * from './location-changed';
export * from './handle-auth-on-location-changed';
export * from './handle-model-type-on-location-changed';
export * from './update-area-on-location-changed';

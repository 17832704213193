import { FormControlLabel } from '@mui/material';
import styled from 'styled-components';
import { baseColors } from '@groupby/ui-components';
export const StyledFormControlLabel = styled(FormControlLabel) `
  color: ${baseColors.primaryBlueMed700};
  font-size: ${({ $size }) => $size === 'small' && '14px'};
  .checkbox-label {
    font-weight: 500;
    font-size: 1em;
    white-space: nowrap;
  }
`;
export default StyledFormControlLabel;

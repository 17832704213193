import { __awaiter, __rest } from "tslib";
import LDClient from './launch-darkly';
import { CommonFeatureFlagsService } from '../common-feature-flags-service';
export class LaunchDarklyFeatureFlagService extends CommonFeatureFlagsService {
    updateCacheWithNewFeatureFlags() {
        var _a;
        const flags = (_a = this.ldClient) === null || _a === void 0 ? void 0 : _a.allFlags();
        this.featureFlagCache = Object.assign({}, flags);
    }
    initialize(envKey, context) {
        if (!envKey) {
            throw new Error('Client ID is empty');
        }
        this.ldClient = LDClient.initialize(envKey, context);
    }
    getFeatureFlagValue(flag) {
        var _a;
        return (_a = this.featureFlagCache[flag]) !== null && _a !== void 0 ? _a : null;
    }
    setFeatureFlags() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.ldClient) {
                throw new Error('Launch Darkly Client is not defined');
            }
            try {
                yield this.ldClient.waitForInitialization();
                this.updateCacheWithNewFeatureFlags();
            }
            catch (error) {
                throw new Error(`Failed to initialize Launch Darkly service: ${error}`);
            }
        });
    }
    updateFeatureFlagsContext(_a) {
        var _b, _c;
        var { user, configuration } = _a, context = __rest(_a, ["user", "configuration"]);
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.ldClient) {
                throw new Error('Launch Darkly Client is not defined');
            }
            const baseContext = this.ldClient.getContext();
            const updatedContext = Object.assign(Object.assign(Object.assign({}, baseContext), context), { user: {
                    key: user.email,
                    name: user.name,
                    isGBI: user.isGBIUser,
                }, configuration: Object.assign(Object.assign(Object.assign({}, baseContext.configuration), configuration), { collection: (_b = configuration.collection) !== null && _b !== void 0 ? _b : null, area: (_c = configuration.area) !== null && _c !== void 0 ? _c : null, name: user.tenantName }) });
            try {
                yield this.ldClient.identify(updatedContext, null);
                this.resetFeatureFlagsStorage();
            }
            catch (error) {
                // options parameter was added in typescript@4.9
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                throw new Error('Failed to update feature flag context', { cause: error });
            }
        });
    }
    resetFeatureFlagsStorage() {
        this.updateCacheWithNewFeatureFlags();
    }
    clearFeatureFlagsStorage() {
        this.featureFlagCache = {};
    }
    setFeaturesResponse(_featuresResponse) { }
}

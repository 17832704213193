import styled from 'styled-components';
import { TableDraggableRow } from '@groupby/ui-components';
export const StyledTableDraggableRow = styled(TableDraggableRow) `
  && {
    background-color: red;
    .MuiTableCell-root {
      cursor: default;
    }
  }
`;

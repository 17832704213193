import { __rest } from "tslib";
import React, { useState } from 'react';
import { ImageIcon } from '@/components/icons';
import { StyledImageIconContainer } from './image-with-fallback.styles';
export const ImageWithFallback = (_a) => {
    var { src, alt, width, height } = _a, props = __rest(_a, ["src", "alt", "width", "height"]);
    const [isError, setIsError] = useState(false);
    const onError = () => {
        if (!isError) {
            setIsError(true);
        }
    };
    return ((isError || !src) ? (<StyledImageIconContainer width={width} height={height}>
        <ImageIcon />
      </StyledImageIconContainer>) : (<img src={src} alt={alt} width={width} height={height} onError={onError} {...props}/>));
};

import { baseColors } from '@groupby/ui-components';
import { TableBody, TableContainer } from '@mui/material';
import styled from 'styled-components';
export const StyledTableContainer = styled(TableContainer) `
  &.draggable thead th:first-of-type {
    padding-left: 2.5rem;
  }
`;
export const StyledTableBody = styled(TableBody) `
  position: relative;

  ${({ $loading }) => $loading && `
    &::before {
      position: absolute;
      z-index: 1;
      display: block;
      content: '';
      width: 100%;
      height: 100%;
      background: ${baseColors.white};
    }
  `}
`;
export const StyledTableLoader = styled.div `
  position: absolute;
  z-index: 2;
  width: 100%;
  padding: ${({ theme }) => theme.spacing(2)} 0;
`;

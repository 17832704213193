import styled from 'styled-components';
import { Menu } from '@mui/material';
import { MenuItem } from '@groupby/ui-components';
export const StyledMenu = styled(Menu) `
  & .MuiList-padding {
    padding: 0;
  }

  && .MuiMenu-paper {
    ${({ $type }) => $type === 'user' && `
      left: unset !important;
      top: 60px !important;`};
    right: 24px;
    color: ${({ theme }) => theme.palette.accent.main};
    min-width: 228px;
    ${({ open, theme }) => open && `border: 4px solid ${theme.palette.background.pageContextControl}`};
    border-radius: 0px 0px 8px 8px;
    max-width: 400px;

    .sign-out {
      color: ${({ theme }) => theme.palette.error.dark};
    }
  }
`;
export const StyledMenuItem = styled(MenuItem) `
  && {
    margin: 8px 8px;
    border-radius: 0px;
    max-height: 48px;
  }
`;
export const StyledSelectedLanguageText = styled.div `
  font-size: 0.75rem;
  color: ${({ theme }) => theme.palette.grey[500]}
`;

import * as Actions from '@/store/actions/types';
import * as NotificationsReducers from './notifications';
export default (state = [], action) => {
    switch (action.type) {
        case Actions.ADD_NOTIFICATION:
            return NotificationsReducers.addNotificationReducer(state, action);
        case Actions.CLOSE_NOTIFICATION:
            return NotificationsReducers.closeNotificationReducer(state, action);
        case Actions.REMOVE_NOTIFICATION:
            return NotificationsReducers.removeNotificationReducer(state, action);
        case Actions.CLEAR_NOTIFICATIONS:
            return NotificationsReducers.clearNotificationsReducer();
        default:
            return state;
    }
};
export function addNotificationReducer(state, { payload }) {
    return [...state, Object.assign(Object.assign({}, payload), { visible: true })];
}
export function closeNotificationReducer(state, { payload }) {
    const index = state.findIndex(({ id }) => id === payload);
    return [...state.slice(0, index), Object.assign(Object.assign({}, state[index]), { visible: false }), ...state.slice(index + 1)];
}
export function removeNotificationReducer(state, { payload }) {
    return state.filter(({ id }) => id !== payload);
}
export function clearNotificationsReducer() {
    return [];
}

import { __awaiter } from "tslib";
import React, { useEffect, useRef, useState } from 'react';
import { EnrichFeatureFlag, EnrichRouter, useUserRoutePermissions } from '@groupby/enrich-console';
import { EnrichEmptyPage } from '@/pages/enrich-empty-page';
import { EnrichUserRole } from '@/routing/constants';
import { SimpleLoading } from '@/components/loading';
import { logError } from '@/utils/logger';
import { useAuthToken } from '@/auth/hooks/authentication';
import { useFeatureFlagsProvider } from '@/search-for-retail/feature-flags';
export const Enrich = () => {
    const { getUserRole } = useUserRoutePermissions();
    // getUserRole function will be recreated every time the component is re-render, which will trigger useEffect
    // TODO: wrap this function into useMemo in enrich-console
    const getUserRoleRef = useRef(getUserRole);
    const token = useAuthToken();
    const { featureFlagsService } = useFeatureFlagsProvider();
    const [hasEnrichAccess, setHasEnrichAccess] = useState(false);
    const [isEnrichRoleLoading, setIsEnrichRoleLoading] = useState(false);
    useEffect(() => {
        const loadEnrichRole = () => __awaiter(void 0, void 0, void 0, function* () {
            setIsEnrichRoleLoading(true);
            try {
                const isEnrichAIEnabled = featureFlagsService.isFeatureEnabled(EnrichFeatureFlag.EnrichAI);
                const enrichRole = yield getUserRoleRef.current();
                setHasEnrichAccess(isEnrichAIEnabled || enrichRole !== EnrichUserRole.Unlicensed);
            }
            catch (e) {
                logError('failed to load enrich role', e);
            }
            finally {
                setIsEnrichRoleLoading(false);
            }
        });
        if (token) {
            void loadEnrichRole();
        }
    }, [token, featureFlagsService]);
    if (isEnrichRoleLoading) {
        return <SimpleLoading />;
    }
    return (hasEnrichAccess ? <EnrichRouter /> : <EnrichEmptyPage />);
};

import { any, cond, endsWith, includes, is, isEmpty, isNil, pipe, startsWith, toLower, } from 'ramda';
import { endOfDay, isAfter, isBefore, startOfDay, subDays, } from 'date-fns';
import { AdvancedFilterBy, AdvancedFilterOption, } from './table-filter.models';
export const RECENT_PERIOD_DAYS = 30;
const getObjectsNames = cond([
    [is(Array), (values) => values.map((value) => value.name || '')],
    [is(Object), (value) => [value.name || '']],
    [is(String), (value) => [value]],
    [isNil, () => []],
]);
export const isValueContainsTerm = (term) => pipe(toLower, includes(toLower(term)));
const isRecentlyUpdated = ({ dateModified, dateCreated, }, dateBound) => {
    const dateLastUpdated = dateModified !== null && dateModified !== void 0 ? dateModified : dateCreated;
    if (!dateLastUpdated) {
        return false;
    }
    return isAfter(new Date(dateLastUpdated), dateBound);
};
const isInSearchTerm = (item, search, searchProps = ['name']) => searchProps.some((searchProperty) => {
    const values = getObjectsNames(item[searchProperty]);
    return any(isValueContainsTerm(search), values);
});
const isEmptyFilter = (filter) => {
    if (!filter) {
        return true;
    }
    return Object.keys(filter).every((filterKey) => !filter[filterKey]);
};
const isCreatedByActiveUser = ({ createdByEmail }, user) => (user === null || user === void 0 ? void 0 : user.email) && createdByEmail === user.email;
const isInAdvancedFilters = (item, advancedFilters) => {
    const filterOptions = {
        [AdvancedFilterOption.STARTS_WITH]: (value, searchValue) => startsWith(toLower(searchValue), toLower(value)),
        [AdvancedFilterOption.ENDS_WITH]: (value, searchValue) => endsWith(toLower(searchValue), toLower(value)),
        [AdvancedFilterOption.CONTAINS]: (value, searchValue) => includes(toLower(searchValue), toLower(value)),
        [AdvancedFilterOption.BEFORE]: (value, searchValue) => isBefore(value, endOfDay(searchValue[0])),
        [AdvancedFilterOption.AFTER]: (value, searchValue) => isAfter(value, startOfDay(searchValue[0])),
        [AdvancedFilterOption.BETWEEN]: (value, searchValue) => isAfter(value, startOfDay(searchValue[0]))
            && isBefore(value, endOfDay(searchValue[1])),
    };
    return advancedFilters.every((advancedFilter) => {
        var _a;
        if (!advancedFilter.filterValue || isEmpty(advancedFilter.filterValue)) {
            return true;
        }
        const search = filterOptions[advancedFilter.filterOption];
        if (advancedFilter.filterBy === AdvancedFilterBy.TAG) {
            return (_a = item.tagList) === null || _a === void 0 ? void 0 : _a.some((tag) => search(tag.name, advancedFilter.filterValue));
        }
        if (advancedFilter.filterBy === AdvancedFilterBy.NAME) {
            return search(item.name, advancedFilter.filterValue);
        }
        if (advancedFilter.filterBy === AdvancedFilterBy.DATE_CREATED) {
            return search(new Date(item.dateCreated), advancedFilter.filterValue);
        }
        if (advancedFilter.filterBy === AdvancedFilterBy.DATE_MODIFIED) {
            return search(new Date(item.dateModified), advancedFilter.filterValue);
        }
        return false;
    });
};
export const filterTableData = (rows, filter, filterOptions) => {
    if (isEmptyFilter(filter)) {
        return rows;
    }
    const { recentlyUpdated, onlyMyStuff, search, advancedFilters, } = filter;
    const dateBound = recentlyUpdated ? subDays(endOfDay(new Date()), RECENT_PERIOD_DAYS) : undefined;
    return rows === null || rows === void 0 ? void 0 : rows.filter((item) => {
        if (dateBound && !isRecentlyUpdated(item, dateBound)) {
            return false;
        }
        if (onlyMyStuff && !isCreatedByActiveUser(item, filterOptions === null || filterOptions === void 0 ? void 0 : filterOptions.activeUser)) {
            return false;
        }
        if (search && !isInSearchTerm(item, search, filterOptions === null || filterOptions === void 0 ? void 0 : filterOptions.searchPropNames)) {
            return false;
        }
        if (advancedFilters && !isInAdvancedFilters(item, advancedFilters)) {
            return false;
        }
        return true;
    });
};
export default filterTableData;

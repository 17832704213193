import { modelManagerRoute } from '@/routing';
export const getSectionRoutes = (section) => { var _a; return (_a = section.categories) === null || _a === void 0 ? void 0 : _a.reduce((acc, { routes }) => [...acc, ...routes], []); };
export const getSectionsRoutes = (sections) => sections.reduce((routes, section) => [...routes, ...getSectionRoutes(section)], []);
export const hasRoutes = (section) => { var _a; return (_a = section.categories) === null || _a === void 0 ? void 0 : _a.some((c) => c.routes.length); };
export const getDefaultRoute = (routes) => { var _a; return (_a = routes === null || routes === void 0 ? void 0 : routes.find((route) => !route.disabled)) !== null && _a !== void 0 ? _a : null; };
export const getPath = (route, collection, area) => (route.isDynamicRoute ? route.path : modelManagerRoute(route.name, collection, area));
export const getSectionLink = (sectionPath, defaultRoute, collection, area) => {
    if (!defaultRoute) {
        return sectionPath;
    }
    return getPath(defaultRoute, collection, area);
};
export const getDrawerRouteForPath = (routes, path, collection, area) => {
    var _a;
    let result = null;
    for (let i = 0; i < routes.length; i++) {
        const { path: routesPath, name, subroutes, isDynamicRoute, } = routes[i];
        if (isDynamicRoute && path === routesPath) {
            result = routes[i];
            break;
        }
        const routePath = modelManagerRoute(name, collection, area);
        if (path.startsWith(routePath)) {
            result = routes[i];
            break;
        }
        if (subroutes === null || subroutes === void 0 ? void 0 : subroutes.length) {
            result = (_a = getDrawerRouteForPath(subroutes, path)) !== null && _a !== void 0 ? _a : null;
            if (result)
                break;
        }
    }
    return result;
};

import { __rest } from "tslib";
import React from 'react';
import { baseColors } from '@groupby/ui-components';
import SvgIcon from '@mui/material/SvgIcon';
export const AnalyticsNavLinkIcon = (_a) => {
    var { width = 24, height = 24, color = baseColors.primaryBlueMed400 } = _a, props = __rest(_a, ["width", "height", "color"]);
    return (<SvgIcon width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M4.5 21C3.67157 21 3 20.3284 3 19.5L3 14.5C3 13.6716 3.67157 13 4.5 13L7.5 13C8.32843 13 9 13.6716 9 14.5L9 21L4.5 21Z" stroke={color} strokeWidth="1.5"/>
    <path d="M9 21L9 9.5C9 8.67157 9.67157 8 10.5 8L13.5 8C14.3284 8 15 8.67157 15 9.5L15 21L9 21Z" stroke={color} strokeWidth="1.5"/>
    <path d="M15 21L15 4.5C15 3.67157 15.6716 3 16.5 3L19.5 3C20.3284 3 21 3.67157 21 4.5L21 19.5C21 20.3284 20.3284 21 19.5 21L15 21Z" stroke={color} strokeWidth="1.5"/>
  </SvgIcon>);
};
export default AnalyticsNavLinkIcon;

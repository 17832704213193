export var SolutionTypes;
(function (SolutionTypes) {
    SolutionTypes["SolutionTypeUnspecified"] = "SOLUTION_TYPE_UNSPECIFIED";
    SolutionTypes["SolutionTypeRecommendation"] = "SOLUTION_TYPE_RECOMMENDATION";
    SolutionTypes["SolutionTypeSearch"] = "SOLUTION_TYPE_SEARCH";
})(SolutionTypes || (SolutionTypes = {}));
export var ControlTypes;
(function (ControlTypes) {
    ControlTypes["OneWaySynonyms"] = "ONE_WAY_SYNONYM";
    ControlTypes["TwoWaySynonyms"] = "TWO_WAY_SYNONYM";
    ControlTypes["DoNotAssociate"] = "DO_NOT_ASSOCIATE";
    ControlTypes["IgnoreAction"] = "IGNORE";
    ControlTypes["Replacement"] = "REPLACEMENT";
})(ControlTypes || (ControlTypes = {}));

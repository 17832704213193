import React from 'react';
import Alert from '@/components/alert';
import { useLocalization } from '@/localization';
import { InfoList } from './partials';
export const ConfirmationAlert = ({ open, title, message, infoList, onConfirm, onDismiss, hideCancel, confirmActionProps, }) => {
    const { formatMessage: t } = useLocalization();
    return (<Alert open={open} title={title !== null && title !== void 0 ? title : t({ key: 'CONFIRMATION' })} primaryButton={Object.assign({ onClick: onConfirm }, confirmActionProps)} onCancel={onDismiss} showCancel={!hideCancel}>
      {message}

      {infoList && <InfoList infoList={infoList}/>}

    </Alert>);
};
export default ConfirmationAlert;

const IMAGE_DATA_FIELDS = [
    'imagePrefix',
    'imageSuffix',
    'imageAttributePath',
    'imagePrefixRecommendations',
    'imageSuffixRecommendations',
    'imageAttributePathRecommendations',
];
export const parseCollectionImageMetaData = (metaData) => metaData === null || metaData === void 0 ? void 0 : metaData.reduce((imageData, { field, value }) => {
    if (IMAGE_DATA_FIELDS.includes(field)) {
        imageData[field] = value;
    }
    return imageData;
}, {});

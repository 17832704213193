import { __awaiter } from "tslib";
import React, { createContext, useContext, useEffect, useState, } from 'react';
import { isNil } from 'ramda';
import { listAdhocMessages } from './adhoc-message.client';
import { TARGET_CUSTOMER } from '@/config/global';
const AdhocMessageContext = createContext({
    adhocMessages: [],
    dismissAdhocMessage: () => { },
});
const ADHOC_MESSAGES_KEY = 'gbi:message';
export const loadDismissedMessageIdsFromLocalStorage = () => {
    const dismissedMessageIdsStr = localStorage.getItem(ADHOC_MESSAGES_KEY);
    if (isNil(dismissedMessageIdsStr)) {
        return [];
    }
    try {
        const dismissedMessageIds = JSON.parse(dismissedMessageIdsStr);
        return dismissedMessageIds;
    }
    catch (_a) {
        return [];
    }
};
export const saveDismissedMessageIdsToLocalStorage = (dismissedMessageIds) => {
    localStorage.setItem(ADHOC_MESSAGES_KEY, JSON.stringify(dismissedMessageIds));
};
export const AdhocMessageProvider = ({ children }) => {
    const [adhocMessages, setAdhocMessages] = useState([]);
    const [dismissedMessageIds, setDismissedMessageIds] = useState(loadDismissedMessageIdsFromLocalStorage());
    const dismissAdhocMessage = (adhocMessageToRemove) => {
        const updatedDismissedMessageIds = [...dismissedMessageIds, adhocMessageToRemove.id];
        setDismissedMessageIds(updatedDismissedMessageIds);
        saveDismissedMessageIdsToLocalStorage(updatedDismissedMessageIds);
        setAdhocMessages(adhocMessages.filter((adhocMessage) => adhocMessage.id !== adhocMessageToRemove.id));
    };
    useEffect(() => {
        const action = () => __awaiter(void 0, void 0, void 0, function* () {
            const foundAdhocMessages = yield listAdhocMessages(TARGET_CUSTOMER);
            setAdhocMessages(foundAdhocMessages.filter((adhocMessage) => !dismissedMessageIds.includes(adhocMessage.id)));
        });
        void action();
        const timer = setInterval(() => {
            void action();
        }, 1000 * 60 * 1);
        return () => clearInterval(timer);
    }, [dismissedMessageIds]);
    return (<AdhocMessageContext.Provider value={{
        adhocMessages,
        dismissAdhocMessage,
    }}>
      {children}
    </AdhocMessageContext.Provider>);
};
export const useAdhocMessages = () => useContext(AdhocMessageContext);

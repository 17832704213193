import { ENRICH_STATE_PATHS_TO_PERSIST_IN_LOCAL_STORAGE } from '@groupby/enrich-console';
import persistSubsetSelector from '@/store/selectors/persist';
export const STATE_PATHS_TO_PERSIST = [
    'auth.token',
    'auth.refreshToken',
    'auth.user',
    'session.areaName',
    'session.collectionName',
    'ui.drawer.collapsed',
    ...ENRICH_STATE_PATHS_TO_PERSIST_IN_LOCAL_STORAGE,
];
export const ls = {
    getItem: (key) => {
        try {
            const data = localStorage.getItem(key);
            return JSON.parse(data);
        }
        catch (e) {
            void e;
        }
    },
    setItem: (key, value) => {
        try {
            const serialized = JSON.stringify(value);
            localStorage.setItem(key, serialized);
        }
        catch (e) {
            void e;
        }
    },
    clear(key) {
        try {
            if (key) {
                localStorage.removeItem(key);
            }
            else {
                localStorage.clear();
            }
        }
        catch (e) {
            void e;
        }
    },
};
export function persist(state, key) {
    const value = persistSubsetSelector(state, STATE_PATHS_TO_PERSIST);
    ls.setItem(key, value);
}

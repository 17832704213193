import styled from 'styled-components';
import Divider from '@mui/material/Divider';
import { baseColors, ButtonV2, Chip } from '@groupby/ui-components';
import Grid from '@mui/material/Grid';
export const StyledTableAppliedFilterGridContainer = styled(Grid) `
  cursor: default;
  font-size: 12px;
  font-weight: 600;
  color: ${baseColors.primaryBlueMed600};
`;
export const StyledDivider = styled(Divider) `
  margin: 0 0.5rem;
`;
export const StyledAppliedFilterChip = styled(Chip) `
  && {
    margin: 0.25rem;
  }
`;
export const StyledAppliedFilterButton = styled(ButtonV2) `
  && {
    white-space: nowrap;
    color: ${baseColors.primaryBlueDark500};

    svg {
      ${({ $open }) => $open && 'transform: rotate(180deg);'};
      path {
        fill: ${baseColors.primaryBlueDark500}
      }
    }
  }
`;

import { __rest } from "tslib";
import React from 'react';
import { baseColors } from '@groupby/ui-components';
import SvgIcon from '@mui/material/SvgIcon';
export const ConfigurationsNavLinkIcon = (_a) => {
    var { height = 24, width = 24, color = baseColors.primaryBlueMed400 } = _a, props = __rest(_a, ["height", "width", "color"]);
    return (<SvgIcon width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M3.31839 6.36247C3.37886 6.19795 3.58825 6.15891 3.71219 6.28285L5.892 8.46266C6.23475 8.80541 6.69533 8.99286 7.17733 8.99286C8.17882 8.99286 8.99286 8.17882 8.99286 7.17733C8.99286 6.69533 8.80006 6.23475 8.46266 5.892L6.282 3.71134C6.15829 3.58763 6.19693 3.37872 6.36109 3.3182C6.91445 3.11422 7.51499 3 8.14133 3C10.9798 3 13.2827 5.30289 13.2827 8.14133C13.2827 8.66081 13.2023 9.15888 13.0631 9.63552L20.2877 16.8602C20.7429 17.3154 21 17.9313 21 18.5739C21 19.9128 19.9128 21 18.5739 21C17.9313 21 17.3154 20.7429 16.8602 20.2877L9.63553 13.0631C9.16424 13.2077 8.66082 13.2827 8.14133 13.2827C5.30289 13.2827 3 10.9798 3 8.14133C3 7.5148 3.11429 6.91777 3.31839 6.36247Z" stroke={color} strokeWidth="1.5" strokeMiterlimit="10"/>
  </SvgIcon>);
};
export default ConfigurationsNavLinkIcon;

import React from 'react';
import Grid from '@mui/material/Grid';
import { IconButton, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { Icon } from '@groupby/ui-components';
import { StyledDropDownLabel, StyledDropDownValueCount, StyledQuickFilterButton, StyledSearchField, TableFilterBarGridContainer, } from './table-filter-bar.styles';
import { useLocalization } from '@/localization';
import { HeaderFilterMenu } from '../table-header/partials/header-filter-menu';
import { TableCustomizeView } from '../table-customize-view';
export const TableFilterBar = ({ filter, columns, updateFilter, quickFilters = [], setOrder, }) => {
    var _a;
    const { formatMessage: t } = useLocalization();
    const [menuName, setMenuName] = React.useState(null);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (name, e) => {
        setMenuName(name);
        setAnchorEl(e.currentTarget);
        e.stopPropagation();
    };
    const handleClose = (e) => {
        setMenuName(null);
        setAnchorEl(null);
        e.stopPropagation();
    };
    const updateIncludesFilter = (newValue, filterConfig) => {
        if (newValue.length === 0) {
            const _filter = filter;
            delete _filter.includes[filterConfig.key];
            updateFilter(filter);
        }
        else {
            updateFilter(Object.assign(Object.assign({}, filter), { includes: Object.assign(Object.assign({}, ((filter === null || filter === void 0 ? void 0 : filter.includes) || {})), { [filterConfig.key]: filterConfig.set(newValue) }) }));
        }
    };
    const handleSearchChange = (newValue) => {
        updateFilter(Object.assign(Object.assign({}, filter), { search: newValue }));
    };
    return (<TableFilterBarGridContainer container spacing={2}>
      <Grid item display="flex" xs={3}>
        <StyledSearchField fullWidth placeholder={t({ key: 'SEARCH' })} variant="outlined" value={filter === null || filter === void 0 ? void 0 : filter.search} onChange={(e) => handleSearchChange(e.target.value)} InputProps={{
        startAdornment: (<InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>),
        endAdornment: ((_a = filter === null || filter === void 0 ? void 0 : filter.search) === null || _a === void 0 ? void 0 : _a.length) > 0 && (<InputAdornment position="end">
                <IconButton onClick={() => handleSearchChange('')}>
                  <CloseIcon />
                </IconButton>
              </InputAdornment>),
    }}/>
      </Grid>

      {quickFilters.map(({ label, filterConfig }) => {
        const value = filterConfig.get(filter);
        const options = filterConfig.options();
        return (<Grid key={label} item display="flex">
            <StyledQuickFilterButton endIcon={<Icon variant="chevronDown"/>} $open={menuName === label} onClick={(e) => handleClick(label, e)}>
              <StyledDropDownLabel>
                <div>{label}</div>
                {value.length > 0 && (<StyledDropDownValueCount>
                    {value.length}
                  </StyledDropDownValueCount>)}
              </StyledDropDownLabel>
            </StyledQuickFilterButton>
            <HeaderFilterMenu anchorEl={anchorEl} onClose={handleClose} open={menuName === label} searchable={filterConfig.searchable} menuItems={options} value={value} updateFilter={(newValue) => updateIncludesFilter(newValue, filterConfig)}/>
          </Grid>);
    })}
      <Grid item xs/>
      <Grid display="flex" alignItems="center" justifyContent="flex-end" item xs={2}>
        <TableCustomizeView columns={columns} setOrder={setOrder}/>
      </Grid>
    </TableFilterBarGridContainer>);
};

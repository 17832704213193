import { __rest } from "tslib";
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
export const WandIcon = (_a) => {
    var { width = 24, height = 24 } = _a, props = __rest(_a, ["width", "height"]);
    return (<SvgIcon height={height} width={width} viewBox={`0 0 ${width} ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_542_42121)">
      <path d="M12.9184 5.32134C12.9259 4.89474 13.4299 4.67298 13.7495 4.95564L15.6725 6.6563C15.8027 6.7715 15.9835 6.81075 16.1498 6.75995L18.6049 6.00997C19.0129 5.88532 19.3796 6.29615 19.2095 6.68745L18.1863 9.04181C18.117 9.20129 18.1355 9.38533 18.2352 9.5278L19.7072 11.631C19.9518 11.9805 19.6744 12.4562 19.2497 12.4154L16.6944 12.1698C16.5213 12.1531 16.352 12.2276 16.2473 12.3665L14.7019 14.4163C14.4451 14.757 13.907 14.6401 13.8145 14.2236L13.2585 11.7175C13.2208 11.5477 13.0977 11.4097 12.9332 11.3531L10.5062 10.5167C10.1028 10.3777 10.0477 9.82986 10.4153 9.61324L12.6269 8.30995C12.7767 8.22167 12.8699 8.06189 12.873 7.88802L12.9184 5.32134Z" stroke="currentColor" fill="transparent" strokeWidth="1.5" strokeLinejoin="round"/>
      <path d="M12.5 11.9814L5.5 19.002" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </g>
    <defs>
      <clipPath id="clip0_530_42121">
        <rect width="24" height="24" fill="white" transform="translate(0.5 0.00195312)"/>
      </clipPath>
    </defs>
  </SvgIcon>);
};
export default WandIcon;

import { baseColors, Icon } from '@groupby/ui-components';
import styled from 'styled-components';
import { Tab, Tabs } from '@mui/material';
export const StyledTabs = styled(Tabs) `
  position: relative;

  .MuiTabs-indicator {
    z-index: 1;
  }

  .MuiPaper-root {
    box-shadow: none;
  }

  .MuiTabs-indicator {
    background-color: ${baseColors.primaryBlueMed700};
    min-width: 7.5rem;
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    border-bottom: 2px solid ${baseColors.primaryBlueMed200};
    height: 0.125rem;
    width: 100%;
  }
`;
export const StyledTab = styled(Tab) `
  && {
    position: relative;
    min-width: 7.5rem;
    min-height: unset;

    &.Mui-disabled {
      color: ${baseColors.primaryBlueMed400};
    }
  }


  &.MuiButtonBase-root {
    color: ${baseColors.primaryBlueMed600};
    font-size: 0.875rem;
    min-width: 7.5rem;

    svg {
      width: 1rem;
      height: 1rem;
    }
  }

  &.MuiTab-textColorPrimary {
    color: ${baseColors.primaryBlueMed600};

    &.Mui-selected {
      color: ${baseColors.primaryBlueDark500};
    }
  }

  &.tab--has-errors {
    color: ${baseColors.darkRed};

    &.Mui-selected {
      color: ${baseColors.darkRed};
    }

    .MuiTabs-indicator {
      background-color: ${baseColors.darkRed};
    }
  }
`;
export const StyledTabRight = styled.div `
  padding: ${({ theme }) => theme.spacing(1, 1, 1, 0)};
  margin-left: auto;
`;
export const StyledIcon = styled(Icon) `
  && {
    position: relative;
    top: 0.125rem;
    left: 0.125rem;
    transform: scale(0.7);

    path {
      fill: ${baseColors.primaryGreen500};
    }
  }`;

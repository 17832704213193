import React from 'react';
import { useLocalization } from '@/localization';
import { StyledContainer } from './total-counter.styles';
export const TotalCounter = ({ total }) => {
    const { formatMessage: t } = useLocalization();
    return (<StyledContainer>
      <span className="counter-title">{t({ key: 'TOTAL_CREATED' })}</span>
      <span className="counter-info">{total !== null && total !== void 0 ? total : 0}</span>
    </StyledContainer>);
};
export default TotalCounter;

import { __rest } from "tslib";
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { baseColors } from '@groupby/ui-components';
export const EnrichNavLinkIcon = (_a) => {
    var { color = baseColors.primaryBlueMed400, height = 24, width = 24 } = _a, props = __rest(_a, ["color", "height", "width"]);
    return (<SvgIcon width={width} height={height} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_1312_4990)">
      <path d="M11 14H6L9.5 2H16L13 10H18L10 22L11 14Z" stroke={color} strokeWidth="1.5" strokeLinejoin="round"/>
    </g>
    <defs>
      <clipPath id="clip0_1312_4990">
        <rect width={width} height={height} fill={color}/>
      </clipPath>
    </defs>
  </SvgIcon>);
};
export default EnrichNavLinkIcon;

import { __awaiter } from "tslib";
import React from 'react';
import { createRoot } from 'react-dom/client';
import { createTheme, ThemeProvider as MuiThemeProvider, StyledEngineProvider, } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Provider as ReduxProvider } from 'react-redux';
import { Provider as UnstatedProvider } from 'unstated';
import * as Sentry from '@sentry/react';
import Hotjar from '@hotjar/browser';
import { ThemeProvider } from 'styled-components';
import { theme as componentsTheme } from '@groupby/ui-components';
import { deepmerge } from '@mui/utils';
import TagManager from 'react-gtm-module';
import { TenantSettingsLoader } from '@/loaders/tenant-settings-loader.component';
import createStore from './store';
import { ccThemeOptions } from './theme';
import { FeatureFlagsProvider, featureFlagsService, initializeFeatureFlagsService } from '@/search-for-retail/feature-flags';
import { App } from '@/app/app';
import { setStore } from '@/store/store-proxy';
import { isCustomerEnv } from '@/utils/common';
Sentry.init({
    dsn: 'https://407cff966cecce719a8c771c39517250@o4506151900872704.ingest.sentry.io/4506151914897408',
    release: document.querySelector('meta[name="version"]').content,
    environment: window.location.hostname,
});
if (isCustomerEnv())
    Hotjar.init(3084596, 6);
const store = createStore();
setStore(store);
void (() => __awaiter(void 0, void 0, void 0, function* () {
    yield initializeFeatureFlagsService();
    // Initialize Google Tag Manager
    TagManager.initialize({ gtmId: 'GTM-KSSS5ZL' });
    const theme = createTheme(deepmerge(componentsTheme, ccThemeOptions));
    const container = document.getElementById('app');
    const root = createRoot(container);
    root.render(
    // Strict Mode will be enabled in separate ticket
    // <React.StrictMode>
    <FeatureFlagsProvider featureFlagsService={featureFlagsService}>
      <DndProvider backend={HTML5Backend}>
        <StyledEngineProvider injectFirst>
          <MuiThemeProvider theme={theme}>
            <ThemeProvider theme={theme}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <UnstatedProvider>
                  <ReduxProvider store={store}>
                    <TenantSettingsLoader>
                      <App />
                    </TenantSettingsLoader>
                  </ReduxProvider>
                </UnstatedProvider>
              </LocalizationProvider>
            </ThemeProvider>
          </MuiThemeProvider>
        </StyledEngineProvider>
      </DndProvider>
    </FeatureFlagsProvider>
    // </React.StrictMode>
    );
}))();

import styled from 'styled-components';
import { baseColors, FilledInput } from '@groupby/ui-components';
import { AdvancedFilter } from './advanced-filter.component';
export const StyledAdvancedFilters = styled.div `
  width: 100%;

  margin-left: 0.5rem;

  & .MuiSelect-select {
    &:focus {
      background-color: transparent;
    }
  }
`;
export const ItemWrapper = styled.div `
  display: flex;
  flex-wrap: wrap;

  margin-right: 1.3rem;

  font-size: 1.1rem;
`;
export const StyledAdvancedFilter = styled(AdvancedFilter) `
  margin-top: 0.6rem;
`;
export const StyledInput = styled(FilledInput) `
  height: 48px;
  width: 100%;
  padding: 0.5rem 1.2rem;
  background-color: ${baseColors.primaryBlueMed050};
  border-radius: 4px 4px 0 0;

  .MuiFilledInput-input {
    padding: 0;
  }

  .MuiInput-underline {
    &:hover:not(.Mui-disabled) {
    &::before {
        border-bottom-color: ${baseColors.primaryBlueMed700};
      }
    }
  }

  .delete-icon {
    cursor: pointer;
  }

  &::before,
  &:hover::before,
  &::after,
  &:hover::after {
    border-bottom-width: 2px;
  }

  &::before,
  &:hover::before {
    border-bottom-color: ${baseColors.primaryBlueMed500};
  }

  &::after,
  &:hover::after {
    border-bottom-color: ${baseColors.primaryGreen500}; // sets active state bottom border color
  }
`;

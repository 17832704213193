export var AttributeType;
(function (AttributeType) {
    AttributeType["Numerical"] = "NUMERICAL";
    AttributeType["Textual"] = "TEXTUAL";
    AttributeType["Unknown"] = "UNKNOWN";
})(AttributeType || (AttributeType = {}));
export var BiasType;
(function (BiasType) {
    BiasType["Numeric"] = "NUMERIC";
    BiasType["Textual"] = "TEXTUAL";
})(BiasType || (BiasType = {}));
export var AttributeGroup;
(function (AttributeGroup) {
    AttributeGroup["System"] = "SYSTEM";
    AttributeGroup["Custom"] = "CUSTOM";
    AttributeGroup["Inventory"] = "INVENTORY";
})(AttributeGroup || (AttributeGroup = {}));

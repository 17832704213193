import React, { forwardRef, useState } from 'react';
import { baseColors, Icon, MenuItem, Switch, } from '@groupby/ui-components';
import { Divider as MuiDivider, Tooltip } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import { withStyles } from '@/decorators';
import { LocalizedMessage } from '@/localization';
export const styles = ({ spacing }) => ({
    divider: {
        margin: spacing(1, 2),
    },
});
export const Divider = withStyles(styles)(function Divider({ classes }) {
    return <MuiDivider classes={{ root: classes.divider }}/>;
});
export const DeleteModelMenuItem = forwardRef(({ onClick, disabled, disabledTooltip }, ref) => {
    const [showTooltip, setShowTooltip] = useState(false);
    return (<Tooltip title={disabled ? disabledTooltip : null} open={showTooltip} disableHoverListener>
      
      <div onMouseEnter={() => setShowTooltip(true)} onMouseLeave={() => setShowTooltip(false)}>
        <MenuItem ref={ref} onClick={onClick} copy={<LocalizedMessage messageKey="DELETE"/>} classes={{ root: 'delete-menu-item' }} disabled={disabled} iconStart={<Icon variant="delete" color={baseColors.primaryBlueMed500} additionalClasses="icon-left"/>}/>
      </div>
    </Tooltip>);
});
DeleteModelMenuItem.displayName = 'DeleteModelMenuItem';
export const DuplicateModelMenuItem = forwardRef(({ onClick }, ref) => (<MenuItem ref={ref} onClick={onClick} copy={<LocalizedMessage messageKey="DUPLICATE"/>} iconStart={<Icon variant="duplicate" color={baseColors.primaryBlueMed500} additionalClasses="icon-left"/>}/>));
DuplicateModelMenuItem.displayName = 'DuplicateModelMenuItem';
export const EditModelMenuItem = forwardRef(({ onClick }, ref) => (<MenuItem ref={ref} onClick={onClick} copy={<LocalizedMessage messageKey="EDIT"/>} iconStart={<Icon variant="editPen" color={baseColors.primaryBlueMed500} additionalClasses="icon-left"/>}/>));
EditModelMenuItem.displayName = 'EditModelMenuItem';
export const MoveAboveBelowModelMenuItem = forwardRef(({ onClick }, ref) => (<MenuItem ref={ref} onClick={onClick} copy={<LocalizedMessage messageKey="MOVE_ABOVE_BELOW"/>} iconStart={<Icon variant="moveUpAndDown" color={baseColors.primaryBlueMed500} additionalClasses="icon-left"/>}/>));
MoveAboveBelowModelMenuItem.displayName = 'MoveAboveBelowModelMenuItem';
export const MoveAnotherHereModelMenuItem = forwardRef(({ onClick }, ref) => (<MenuItem ref={ref} onClick={onClick} copy={<LocalizedMessage messageKey="MOVE_ANOTHER_HERE"/>} iconStart={<Icon variant="moveHere" color={baseColors.primaryBlueMed500} additionalClasses="icon-left"/>}/>));
MoveAnotherHereModelMenuItem.displayName = 'MoveAnotherHereModelMenuItem';
export const PromoteModelMenuItemV2 = forwardRef(({ onClick, data, onTogglePromotion }, ref) => (<React.Fragment>
    <MenuItem ref={ref} onClick={() => { }} copy={<FormControlLabel control={<Switch onChange={onTogglePromotion(data)} checked={data === null || data === void 0 ? void 0 : data.copyable}/>} label={<LocalizedMessage messageKey="COPY_TO_OTHER_AREAS"/>}/>}/>
    {(data === null || data === void 0 ? void 0 : data.copyable) && (<PromoteModelMenuItem onClick={onClick}/>)}
  </React.Fragment>));
export const PromoteModelMenuItem = forwardRef(({ onClick }, ref) => (<MenuItem ref={ref} onClick={onClick} copy={<LocalizedMessage messageKey="COPY_TO_AREA"/>} iconStart={<Icon 
// "promote" variant does not exist in ui-components
variant="moveFolder" color={baseColors.primaryBlueMed500} additionalClasses="icon-left"/>}/>));
PromoteModelMenuItem.displayName = 'PromoteModelMenuItem';
export const SwitchToAreaMenuItem = forwardRef(({ onClick, disabled }, ref) => (<MenuItem ref={ref} onClick={onClick} disabled={disabled} copy={<LocalizedMessage messageKey="SWITCH_TO_AREA"/>} iconStart={<Icon variant="editPen" color={baseColors.primaryBlueMed500} additionalClasses="icon-left"/>}/>));
SwitchToAreaMenuItem.displayName = 'SwitchToAreaMenuItem';
export const ViewAuditLogsMenuItem = forwardRef(({ onClick, disabled }, ref) => (<MenuItem ref={ref} onClick={onClick} disabled={disabled} copy={<LocalizedMessage messageKey="VIEW_AUDIT_LOG"/>} iconStart={<Icon variant="paper" color={baseColors.primaryBlueMed500} additionalClasses="icon-left"/>}/>));
ViewAuditLogsMenuItem.displayName = 'ViewAuditLogsMenuItem';
export const ViewModelMenuItem = forwardRef(({ onClick }, ref) => (<MenuItem ref={ref} onClick={onClick} copy={<LocalizedMessage messageKey="VIEW"/>} iconStart={<Icon variant="eye" color={baseColors.primaryBlueMed500} additionalClasses="icon-left"/>}/>));
ViewModelMenuItem.displayName = 'ViewModelMenuItem';
export const RestoreRuleMenuItem = forwardRef(({ onClick }, ref) => (<MenuItem ref={ref} onClick={onClick} copy={<LocalizedMessage messageKey="RESTRORE_RULE"/>} iconStart={<Icon variant="historyIcon" color={baseColors.primaryBlueMed500} additionalClasses="icon-left"/>}/>));
RestoreRuleMenuItem.displayName = 'RestoreRuleMenuItem';

import { __rest } from "tslib";
import React from 'react';
import { Checkbox } from '@groupby/ui-components';
import { StyledFormControlLabel } from './filter-button.styles';
export const FilterButton = (_a) => {
    var { name, size } = _a, props = __rest(_a, ["name", "size"]);
    return (<StyledFormControlLabel $size={size} control={<Checkbox name={name} size={size}/>} labelPlacement="start" classes={{
        'label': 'checkbox-label',
    }} {...props}/>);
};
export default FilterButton;

import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import createPathMatcher from 'path-match';
import { useLocation, useNavigate, useParams } from './index';
import { Migration, Routing } from '@/constants';
import { isAnalyticsChartPage } from '@/search-for-retail/analytics';
import { createModelRoute, editModelRoute, getAreaNameFromPath, getCollectionNameFromPath, getIdFromQueryString, getModelPathFromPathname, getModelTypeFromModelPath, getParamFromQueryString, isHomePage, modelManagerRoute, } from '../lib';
import { managerModelSelect, merchandisingManagerSelector, useActiveArea, useActiveAreaNameSelector, useActiveModelType, useCollectionName, useMerchandisingSelector, } from '@/store/selectors';
import { getBasePath, parseSearch } from '@/utils';
import { switchModelType } from '@/store/actions/creators';
import * as ActionCreators from '@/store/actions/creators';
export const useShouldDisplayAnalytics = (modelPath) => {
    const isAnalyticsPath = isAnalyticsChartPage(modelPath);
    return isAnalyticsPath;
};
export const usePathname = () => {
    const { pathname } = useLocation();
    return pathname;
};
export const useFormDefaults = () => {
    const { state } = useLocation();
    return state === null || state === void 0 ? void 0 : state.defaultValues;
};
export const useActiveModelPath = () => {
    const pathname = usePathname();
    return getModelPathFromPathname(pathname);
};
// this assumes that all plugins begin with the name of the plugin in the url path
export const useActivePluginPath = () => {
    const pathname = usePathname();
    return pathname.split('/')[1];
};
export const useGoToUrl = () => {
    const navigate = useNavigate();
    return (url, state) => {
        navigate(url, { state });
    };
};
export const getHomePath = () => Routing.MERCHANDISING_PATH;
export const useGoHome = () => {
    const goToUrl = useGoToUrl();
    return (state) => {
        goToUrl(getHomePath(), state);
    };
};
export const useGoToManager = (modelType) => {
    const goToUrl = useGoToUrl();
    const activeCollectionName = useCollectionName();
    const activeAreaName = useActiveAreaNameSelector();
    return (state) => {
        goToUrl(modelManagerRoute(modelType, activeCollectionName, activeAreaName), state);
    };
};
export const useGoToEditor = (modelType) => {
    const goToUrl = useGoToUrl();
    const activeCollectionName = useCollectionName();
    const activeAreaName = useActiveAreaNameSelector();
    return (modelId, areaName) => {
        goToUrl(editModelRoute(modelType, activeCollectionName, areaName !== null && areaName !== void 0 ? areaName : activeAreaName, String(modelId)));
    };
};
export const useGoToLoginWithNext = () => {
    const navigate = useNavigate();
    return (pathname, search) => {
        navigate(`${Routing.LOGIN_PATH}?next=${pathname}${search ? encodeURIComponent(search) : ''}`, {
            replace: true,
            // TODO: refactor this after "S4R-4160_Migrate-to-data-routes" branch is merged and tested for a regression
            state: Migration.REDUX_ROUTER_STATE,
        });
    };
};
export const useGoToDuplicator = (modelType) => {
    const goToUrl = useGoToUrl();
    const activeCollectionName = useCollectionName();
    const activeAreaName = useActiveAreaNameSelector();
    return (defaultValues) => {
        goToUrl(createModelRoute(modelType, activeCollectionName, activeAreaName), {
            defaultValues,
            modelType,
            persistCurrentPage: true,
            action: 'copy',
        });
    };
};
export const useQueryParam = (paramName) => {
    const { search } = useLocation();
    return getParamFromQueryString(search, paramName);
};
export const useIdQueryParam = () => {
    const { search } = useLocation();
    return getIdFromQueryString(search);
};
export const useIsHomePage = () => {
    const pathName = usePathname();
    return isHomePage(pathName);
};
export const useCollectionNameFromUrl = () => {
    const { pathname } = useLocation();
    return getCollectionNameFromPath(pathname);
};
export const useAreaNameFromUrl = () => {
    const { pathname } = useLocation();
    return getAreaNameFromPath(pathname);
};
export const getNextPath = (search) => (parseSearch(search).next || '/').replace('%3F', '?');
export const useNextPath = () => {
    const { search } = useLocation();
    return getNextPath(search);
};
export const useSwitchToOtherArea = () => {
    const dispatch = useDispatch();
    const goHome = useGoHome();
    return (collectionName, areaName) => {
        dispatch(ActionCreators.setCollectionName(collectionName));
        dispatch(ActionCreators.switchArea(areaName));
        goHome();
    };
};
export const useGoToNewArea = () => {
    const goToUrl = useGoToUrl();
    const activePath = usePathname();
    const basePath = getBasePath(activePath);
    const activeModelType = useActiveModelType();
    const { dashboardTitle } = useParams();
    return (collectionName, areaName) => {
        if (`/${basePath}` === Routing.MERCHANDISING_PATH) {
            let url = modelManagerRoute(activeModelType, collectionName, areaName);
            if (dashboardTitle) {
                url = `${url}/${dashboardTitle}`;
            }
            goToUrl(url);
        }
    };
};
export const useActiveQuery = () => {
    const { search } = useLocation();
    return parseSearch(search);
};
export const useActiveManagerModel = () => {
    const { id } = useActiveQuery();
    const area = useActiveArea();
    const modelType = useActiveModelType();
    const merchandising = useMerchandisingSelector();
    const managerState = merchandisingManagerSelector(merchandising, modelType, area);
    return managerModelSelect(managerState, id);
};
export const useSwitchModelTypeIfNoActiveOne = (modelPath) => {
    const dispatch = useDispatch();
    const activeModelType = useActiveModelType();
    useEffect(() => {
        if (!activeModelType) {
            const modelType = getModelTypeFromModelPath(modelPath);
            if (modelType) {
                dispatch(switchModelType(modelType));
            }
        }
    });
};
export const useIsEditingModel = () => {
    const { pathname, search } = useLocation();
    return !!createPathMatcher({})(Routing.MODEL_MANAGER_PATH)(pathname) && !!parseSearch(search).id;
};
export const useIsCreatingModel = () => {
    const { pathname } = useLocation();
    return !!createPathMatcher({})(Routing.MODEL_CREATOR_PATH)(pathname);
};
export default {
    useShouldDisplayAnalytics,
    useQueryParam,
    useGoToManager,
    useGoToEditor,
    useGoToLoginWithNext,
    useGoToDuplicator,
    useIsHomePage,
    useCollectionNameFromUrl,
    useAreaNameFromUrl,
    useNextPath,
    useGoToNewArea,
    useActiveQuery,
    useActiveManagerModel,
    useIsEditingModel,
    useIsCreatingModel,
};

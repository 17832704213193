import styled from 'styled-components';
import { BackgroundCard, baseColors, Typography } from '@groupby/ui-components';
export const StyledReleaseNotesPageContainer = styled.div `
  padding: ${({ theme }) => theme.spacing(2, 3, 3, 3)};
  position: relative;
  z-index: ${({ theme }) => theme.zIndex.tableCntr};
  flex-grow: 1;
`;
export const StyledReleaseNotesCard = styled(BackgroundCard) `
  margin: ${({ theme }) => theme.spacing(2, 0, 0, 0)};
`;
export const HighlightedText = styled.span `
  background-color: #fff8c5;
`;
export const TypographyShowResults = styled(Typography) `
  font-style: normal;
  font-weight: 400;
  line-height: 0.831;
  color= ${baseColors.background600};
  padding-top: ${({ theme }) => theme.spacing(2)};
`;

import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
export const useLocationChanged = (callback, condition) => {
    const prevLocation = useRef();
    const location = useLocation();
    useEffect(() => {
        if (!prevLocation.current) {
            prevLocation.current = location;
        }
        else if ((condition === null || condition === void 0 ? void 0 : condition(prevLocation.current, location)) || prevLocation.current.pathname !== location.pathname) {
            callback(prevLocation.current, location);
            prevLocation.current = location;
        }
    }, [callback, location, condition]);
};

import React from 'react';
import { Icon } from '@groupby/ui-components';
import { sub } from 'date-fns';
import { StyledFilterButton } from './table-header.styled';
import { HeaderFilterMenu } from './partials/header-filter-menu';
import { HeaderDateFilter } from './partials/header-date-filter';
export const FilterButton = ({ data, updateFilter, filter, column, }) => {
    var _a, _b, _c, _d;
    const [menuName, setMenuName] = React.useState(null);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (name, e) => {
        setMenuName(name);
        setAnchorEl(e.currentTarget);
        e.stopPropagation();
    };
    const handleClose = (e) => {
        setMenuName(null);
        setAnchorEl(null);
        e.stopPropagation();
    };
    const updateIncludesFilter = (newValue, filterConfig) => {
        if (newValue.length === 0) {
            const _filter = filter;
            delete _filter.includes[filterConfig.key];
            updateFilter(filter);
        }
        else {
            updateFilter(Object.assign(Object.assign({}, filter), { includes: Object.assign(Object.assign({}, ((filter === null || filter === void 0 ? void 0 : filter.includes) || {})), { [filterConfig.key]: filterConfig.set(newValue) }) }));
        }
    };
    const updateDateRangeFilter = (newValue, filterConfig) => {
        updateFilter(Object.assign(Object.assign({}, filter), { range: Object.assign(Object.assign({}, ((filter === null || filter === void 0 ? void 0 : filter.range) || {})), { [filterConfig.key]: filterConfig.setRange(newValue) }) }));
    };
    const value = (_b = (_a = column.filterConfig) === null || _a === void 0 ? void 0 : _a.get) === null || _b === void 0 ? void 0 : _b.call(_a, filter);
    const range = (_d = (_c = column.filterConfig) === null || _c === void 0 ? void 0 : _c.getRange) === null || _d === void 0 ? void 0 : _d.call(_c, filter);
    const isIncludesFilter = Array.isArray(value);
    const isDateRangeFilter = typeof range !== 'undefined';
    return (<React.Fragment>
      <StyledFilterButton size="small" $isActive={(value === null || value === void 0 ? void 0 : value.length) > 0 || ((range === null || range === void 0 ? void 0 : range.startAt) && range.endAt)} icon={<Icon variant="filter"/>} onClick={(e) => handleClick(column.label, e)}/>
      {isIncludesFilter && (<HeaderFilterMenu value={value} anchorEl={anchorEl} onClose={handleClose} open={menuName === column.label} searchable={column.filterConfig.searchable} groupActions={column.filterConfig.groupActions} menuItems={column.filterConfig.options(data)} updateFilter={(newValue) => updateIncludesFilter(newValue, column.filterConfig)}/>)}
      {isDateRangeFilter && (<HeaderDateFilter anchorEl={anchorEl} onClose={handleClose} open={menuName === column.label} startDate={(range === null || range === void 0 ? void 0 : range.startAt) || Number(sub(new Date(), { months: 1 }))} endDate={(range === null || range === void 0 ? void 0 : range.endAt) || Number(new Date())} updateFilter={(newValue) => updateDateRangeFilter(newValue, column.filterConfig)}/>)}
    </React.Fragment>);
};

import { useCallback, useMemo, useState } from 'react';
import { filterTableData } from './table-filter.utils';
import { useActiveUser } from '@/auth/hooks/authentication';
export const useTableFilter = (data, options) => {
    const [filter, setFilter] = useState(undefined);
    const activeUser = useActiveUser();
    const updateFilter = useCallback((newFilter) => {
        setFilter((oldFilter) => (Object.assign(Object.assign({}, oldFilter), newFilter)));
    }, []);
    const resetFilter = useCallback((filterKeys) => {
        setFilter((oldFilter) => (!(filterKeys === null || filterKeys === void 0 ? void 0 : filterKeys.length)
            ? undefined
            : filterKeys.reduce((acc, name) => (Object.assign(Object.assign({}, acc), { [name]: undefined })), oldFilter)));
    }, []);
    const filteredRows = useMemo(() => (filterTableData(data, filter, Object.assign({ activeUser }, options))), [data, filter, options, activeUser]);
    return {
        filteredRows,
        filter,
        actions: { updateFilter, resetFilter },
    };
};
export default useTableFilter;

import React from 'react';
import { LocalizedMessage as GBUILocalizedMessage } from '@groupby/ui-components';
import { isNil } from 'ramda';
import MessageDefinitions from '../messages/message-definitions';
export const LocalizedMessage = ({ messageKey, values }) => {
    const messageDefinition = MessageDefinitions[messageKey];
    if (isNil(messageDefinition)) {
        return <React.Fragment>{messageKey}</React.Fragment>;
    }
    return <GBUILocalizedMessage message={messageDefinition} values={values}/>;
};

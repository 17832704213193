import * as Actions from '@/store/actions/types';
const DEFAULT = [];
export default (state = DEFAULT, action) => {
    switch (action.type) {
        case Actions.STORE_ALL_COLLECTIONS:
            return [...action.payload];
        default:
            return state;
    }
};

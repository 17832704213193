import { __rest } from "tslib";
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { baseColors } from '@groupby/ui-components';
export const AlertWarningIcon = (_a) => {
    var { width = 24, height = 24, color = baseColors.darkRed } = _a, props = __rest(_a, ["width", "height", "color"]);
    return (<SvgIcon width={width} height={height} viewBox={`0 0 ${height} ${width}`} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M20.7172 17.0917L14.4184 4.49295C13.4232 2.50236 10.5826 2.50234 9.58735 4.49295L3.28855 17.0917C2.39081 18.8874 3.69656 21 5.7041 21H18.3017C20.3092 21 21.615 18.8874 20.7172 17.0917Z" fill={color}/>
    <path d="M11.9998 7.33789C11.412 7.33789 10.9423 7.84768 10.9657 8.46004L11.1597 13.5153C11.1777 13.9855 11.5485 14.3569 11.9998 14.3569C12.4511 14.3569 12.8219 13.9855 12.84 13.5153L13.0339 8.46004C13.0574 7.84768 12.5876 7.33789 11.9998 7.33789Z" fill="white"/>
    <path d="M10.7612 17.0241C10.7612 16.3264 11.3038 15.7607 11.973 15.7607C12.6422 15.7607 13.1848 16.3264 13.1848 17.0241C13.1848 17.7219 12.6422 18.2876 11.973 18.2876C11.3038 18.2876 10.7612 17.7219 10.7612 17.0241Z" fill="white"/>
  </SvgIcon>);
};
export default AlertWarningIcon;

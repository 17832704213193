import styled from 'styled-components';
import { baseColors } from '@groupby/ui-components';
export const StyledImageIconContainer = styled.div `
  width: ${({ width }) => (typeof width === 'string' ? width : `${width}px`)};
  min-width: ${({ width }) => (typeof width === 'string' ? width : `${width}px`)};

  height: ${({ height }) => (typeof height === 'string' ? height : `${height}px`)};
  min-height: ${({ height }) => (typeof height === 'string' ? height : `${height}px`)};

  background-color: ${baseColors.background000};
  border: 1px solid ${baseColors.background100};
  border-radius: 4px;

  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    fill: ${baseColors.background000};
  }
`;

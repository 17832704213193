import Cookie from 'js-cookie';
import * as AuthReducers from './auth';
import * as UserSettingsReducers from './user-settings';
import { E2E_COOKIE_AUTHENTICATON, SKIP_AUTHENTICATION } from '@/config/global';
import { Role } from '@/constants';
import * as Actions from '@/store/actions/types';
import { decodeJWT } from '@/utils';
import { extractAreaPermission } from '@/pages/login/utils';
const AREAS = ([
    {
        name: 'Production',
        biasingProfile: null,
        wildcardSearchEnabled: true,
        suggestionStrength: 7.3,
        metadata: [],
        engineIds: ['mock'],
        intelligentNavigationEnabled: false,
    },
    {
        name: 'development',
        biasingProfile: null,
        wildcardSearchEnabled: false,
        suggestionStrength: 2.6,
        metadata: [{ key: 'brand', value: 'Nike' }],
        engineIds: ['mock'],
        intelligentNavigationEnabled: true,
    },
]);
export const DEFAULT = (() => {
    switch (true) {
        case !!SKIP_AUTHENTICATION:
            return {
                isLoggingIn: false,
                isAuthenticated: true,
                token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjAsImVtYWlsIjoiam9obmRvZUBleGFtcGxlLmNvbSIsImV4cCI6MjE0NzQ4MzY0N30.kLOZhDp3UzqArFIYyv6Twhqhs20x-EqZ6yu3cJc4mJM',
                refreshToken: 'eyJhbGciOiJIUzI1NiJ9.NGQ4OWQ2OGItZDkzMC00MzlhLTljNDctZWU2ZDg1YTZmMThm.SvzGq42sIrZh8Cm4rt36bWWDY-LC3w5rZdEWy6CRbH0',
                externalProviders: [],
                user: {
                    allowedAreas: AREAS.map(({ name }) => name),
                    allowedAccess: [Role.MERCHANDISING, Role.QUERY_REWRITE, Role.ENGINE_CONFIGURATION, Role.ADMIN],
                },
            };
        case !!E2E_COOKIE_AUTHENTICATON:
            return {
                isLoggingIn: false,
                isAuthenticated: false,
                user: null,
                externalProviders: [],
                token: Cookie.get('E2E_INJECTED_TOKEN') || null,
            };
        default:
            return {
                isLoggingIn: false,
                isAuthenticated: false,
                token: null,
                refreshToken: null,
                externalProviders: [],
                user: null,
            };
    }
})();
export default (state = DEFAULT, action) => {
    switch (action.type) {
        case Actions.SET_LOGGING_IN:
            return AuthReducers.setLoggingInReducer(state, action);
        case Actions.ACKNOWLEDGE_AUTHENTICATION:
            return AuthReducers.acknowledgeAuthenticationReducer(state, action);
        case Actions.CLEAR_AUTHENTICATION:
            return AuthReducers.clearAuthenticationReducer(state);
        case Actions.UPDATE_TOKEN:
            return AuthReducers.updateTokenReducer(state, action);
        case Actions.STORE_USER_PREFERRED_LANGUAGE:
            return AuthReducers.storeUserPreferredLanguage(state, action);
        // TODO: Wait until S4R-5964 is tested for a regression. After that - remove.
        // case Actions.STORE_AUTH0_REGION:
        //   return AuthReducers.storeAuth0Region(state, action);
        // END_TODO
        case Actions.STORE_USER_SETTINGS:
            return UserSettingsReducers.storeUserSettings(state, action);
        case Actions.STORE_USER_ANALYTIC_DASHBOARDS_SETTINGS:
            return UserSettingsReducers.storeUserAnalyticDashboardsSettings(state, action);
        default:
            return state;
    }
};
export function clearAuthenticationReducer(state) {
    return Object.assign(Object.assign({}, state), { isLoggingIn: false, isAuthenticated: false, token: null, user: null, refreshToken: null });
}
export function setLoggingInReducer(state, { payload: { value } }) {
    return Object.assign(Object.assign({}, state), { isLoggingIn: value });
}
export function acknowledgeAuthenticationReducer(state, { payload: { token, user, refreshToken } }) {
    var _a;
    let userWithImplicitAreaPermissions = user;
    if (isJwtFormattedToken(token)) {
        const decodedToken = decodeJWT(token);
        const implicitPermissions = (_a = decodedToken === null || decodedToken === void 0 ? void 0 : decodedToken.implicitRoles) === null || _a === void 0 ? void 0 : _a.map((permission) => extractAreaPermission(permission));
        userWithImplicitAreaPermissions = Object.assign(Object.assign({}, user), { areaPermissions: [...user.areaPermissions, ...(implicitPermissions || [])] });
    }
    return Object.assign(Object.assign({}, state), { token,
        refreshToken, user: userWithImplicitAreaPermissions, isAuthenticated: true });
}
export function updateTokenReducer(state, { payload: token }) {
    return Object.assign(Object.assign({}, state), { token });
}
export function storeUserPreferredLanguage(state, { payload: preferredLanguage }) {
    return Object.assign(Object.assign({}, state), { user: Object.assign(Object.assign({}, state.user), { settings: Object.assign(Object.assign({}, state.user.settings), { preferredLanguage }) }) });
}
function isJwtFormattedToken(token) {
    var _a;
    return token && ((_a = token.split('.')) === null || _a === void 0 ? void 0 : _a.length) === 3;
}

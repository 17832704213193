import { __awaiter } from "tslib";
import { InternalFeatureFlagsService } from '../internal-feature-flag-service';
export class ApiFeatureFlagsService extends InternalFeatureFlagsService {
    constructor() {
        super(...arguments);
        this.featuresResponse = {};
        this.saveApiFeatureFlags = (features) => {
            const apiFeatureFlags = {};
            Object.keys(features).forEach((key) => {
                const value = features[key];
                if (this.isFeatureFlagKey(key)) {
                    if (value !== null) {
                        apiFeatureFlags[key] = value;
                    }
                }
            });
            super.saveFeatureFlags(apiFeatureFlags);
        };
    }
    setFeatureFlags() {
        return __awaiter(this, void 0, void 0, function* () {
            this.saveApiFeatureFlags(this.featuresResponse);
            this.updateCacheWithNewFeatureFlags(Array.from(this.featureFlags));
            return Promise.resolve();
        });
    }
    setFeaturesResponse(featuresResponse) {
        if (featuresResponse) {
            this.featuresResponse = featuresResponse;
        }
    }
    clearFeatureFlagsStorage() {
        super.clearFeatureFlagsStorage();
        this.featuresResponse = {};
    }
}

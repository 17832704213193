import { camelCase } from 'voca';
import { DashboardType } from './analytics.types';
import { ModelType, Routing } from '@/constants';
import { useIsLoadingAnalyticsDashboards } from '@/store/selectors';
import { PIPELINE_HEALTH_TITLE_GOOGLE, PIPELINE_HEALTH_TITLE_WEBSITE } from '../pipeline-health/pipeline-health.constants';
export const excludedDashboardsFromRoute = [PIPELINE_HEALTH_TITLE_WEBSITE, PIPELINE_HEALTH_TITLE_GOOGLE];
export const normalizeDashboardTitle = camelCase;
export const dashboardTitleToLocalizedMessageKey = (title) => {
    switch (normalizeDashboardTitle(title)) {
        case DashboardType.SEARCH_PERFORMANCE:
            return 'SEARCH_PERFORMANCE';
        case DashboardType.SEARCH_TERM_ANALYSIS:
            return 'SEARCH_TERM_ANALYSIS';
        case DashboardType.NAVIGATION_ENGAGEMENTS:
            return 'NAVIGATION_ENGAGEMENTS';
        case DashboardType.NULL_SEARCH_ANALYTICS:
            return 'NULL_SEARCH_ANALYTICS';
        case DashboardType.RULE_ANALYTICS:
            return 'RULE_ANALYTICS';
        case DashboardType.BIASING_PROFILE:
            return 'BIASING_PROFILE';
        default:
            return title; // unlocalized
    }
};
export const useGetAnalyticsDashboardType = (modelPath, paths) => {
    const isLoadingAnalytics = useIsLoadingAnalyticsDashboards();
    if (!isAnalyticsChartPage(modelPath) || isLoadingAnalytics)
        return undefined;
    const pathDepthIsSectionPage = paths.length < 5;
    // show default dashboard if on homepage or on analytics route with no dashboard type path
    if (modelPath === ModelType.HOME_DASHBOARDS || pathDepthIsSectionPage)
        return DEFAULT_DASHBOARD_TYPE;
    return paths.pop();
};
export const isAnalyticsChartPage = (modelPath) => modelPath === Routing.MODEL_PATHS[ModelType.ANALYTICS];
export const DEFAULT_DASHBOARD_TYPE = DashboardType.SEARCH_PERFORMANCE;

import React from 'react';
import { Typography } from '@groupby/ui-components';
import { StyledBox } from './description-box.styles';
export const DescriptionBox = ({ title, description, subDescription, icon, className, }) => (<StyledBox className={className}>
    {title && (<Typography gutterBottom variant="subtitle1" className="title" strong>{title}</Typography>)}
    <div className="content-container">
      {icon}
      <div className="description-block">
        <Typography variant="body2">
          {description}
        </Typography>

        {subDescription && (<Typography variant="body2" className="sub-description">
            {subDescription}
          </Typography>)}
      </div>
    </div>
  </StyledBox>);
export default DescriptionBox;

import React, { useEffect, useRef, useState } from 'react';
import Grid from '@mui/material/Grid';
import { Icon } from '@groupby/ui-components';
import { differenceInDays } from 'date-fns';
import { StyledAppliedFilterButton, StyledAppliedFilterChip, StyledDivider, StyledTableAppliedFilterGridContainer, } from './table-applied-filter-bar.styles';
import { formatDate } from '@/utils';
import { useLocalization } from '@/localization';
const FilterChip = ({ title, deletable, onClick }) => (<StyledAppliedFilterChip type="blue" label={title} onDelete={deletable ? onClick : undefined}/>);
const INITIAL_ELEMENTS_SHOWN = 6;
export const TableAppliedFilterBar = ({ filter, updateFilter, resetFilter, sort, resetSort, columns, }) => {
    var _a, _b;
    const { formatMessage: t } = useLocalization();
    const ranges = Object.values((_a = filter === null || filter === void 0 ? void 0 : filter.range) !== null && _a !== void 0 ? _a : {}) || [];
    const includes = Object.values((_b = filter === null || filter === void 0 ? void 0 : filter.includes) !== null && _b !== void 0 ? _b : {}) || [];
    const [collapsed, setCollapsed] = useState(true);
    const [truncated, setTruncated] = useState(false);
    const [shownCount, setShownCount] = useState(INITIAL_ELEMENTS_SHOWN);
    const filtersGridRef = useRef(null);
    const onRemoveFilterValue = (field, filterValue) => {
        var _a;
        const newValue = filter.includes[field].list.filter((item) => item !== filterValue);
        if (newValue.length > 0) {
            updateFilter(Object.assign(Object.assign({}, filter), { includes: Object.assign(Object.assign({}, filter.includes), { [field]: Object.assign(Object.assign({}, filter.includes[field]), { list: newValue }) }) }));
        }
        else {
            const _filters = filter;
            (_a = _filters === null || _filters === void 0 ? void 0 : _filters.includes) === null || _a === void 0 ? true : delete _a[field];
            updateFilter(_filters);
        }
    };
    const onRemoveFilterRange = (field) => {
        var _a;
        const _filters = filter;
        (_a = _filters === null || _filters === void 0 ? void 0 : _filters.range) === null || _a === void 0 ? true : delete _a[field];
        updateFilter(_filters);
    };
    const filterChips = [...includes, ...ranges].reduce((acc, filterItem) => {
        if (filterItem.list) {
            const { list, label, field, } = filterItem;
            list.forEach((filterValue) => {
                var _a;
                const filterConfig = (_a = columns.find(({ filterConfig }) => ((filterConfig === null || filterConfig === void 0 ? void 0 : filterConfig.key) === filterItem.field))) === null || _a === void 0 ? void 0 : _a.filterConfig;
                const text = (filterConfig === null || filterConfig === void 0 ? void 0 : filterConfig.translate) ? filterConfig === null || filterConfig === void 0 ? void 0 : filterConfig.translate(filterValue) : filterValue;
                const title = typeof filterValue === 'boolean'
                    ? `${label}`
                    : `${label}: ${text}`;
                acc.push(<FilterChip deletable title={title} key={`${field}_${filterValue}`} onClick={() => onRemoveFilterValue(field, filterValue)}/>);
            });
        }
        if (filterItem.startAt) {
            const { startAt, endAt, label, field, } = filterItem;
            const description = differenceInDays(startAt, endAt) === 0
                ? formatDate(startAt)
                : `${formatDate(startAt)} - ${formatDate(endAt)}`;
            acc.push(<FilterChip deletable title={`${label}: ${description}`} key={`${field}_${startAt}`} onClick={() => onRemoveFilterRange(field)}/>);
        }
        return acc;
    }, []);
    useEffect(() => {
        setTruncated(filterChips.length > shownCount);
        if (filtersGridRef.current) {
            const element = filtersGridRef.current;
            if (element.scrollWidth > element.offsetWidth) {
                setShownCount((prev) => prev - 1);
            }
        }
    }, [filterChips.length, shownCount]);
    return (<StyledTableAppliedFilterGridContainer container padding='16px' direction="row" justifyContent="flex-start" alignItems="flex-start">
      {sort && (<React.Fragment>
          <Grid item display="flex" alignItems="center">
            <div>{`${t({ key: 'SORT' })}:`}</div>
            <FilterChip onClick={resetSort} deletable={!sort.default} title={`${sort.label}: ${t({ key: sort.direction })}`}/>
            <StyledDivider orientation="vertical" flexItem/>
          </Grid>
        </React.Fragment>)}
      {(includes.length > 0 || ranges.length > 0) && (<Grid ref={filtersGridRef} item xs display="flex" alignItems="center" {...(collapsed ? ({
        overflow: 'hidden',
    }) : ({
        flexWrap: 'wrap',
    }))}>
          <div>{`${t({ key: 'FILTERS' })}:`}</div>
          {collapsed ? filterChips.slice(0, shownCount) : filterChips}
          {(truncated || !collapsed) && (<StyledAppliedFilterButton size="small" onClick={() => setCollapsed((prev) => !prev)} endIcon={<Icon variant="chevronDown"/>} $open={!collapsed}>
              {collapsed && (filterChips.length - shownCount) > 0 && `+${filterChips.length - shownCount} `}
              {t({ key: collapsed ? 'MORE' : 'COLLAPSE' })}
            </StyledAppliedFilterButton>)}
          <StyledAppliedFilterButton size="small" onClick={() => {
        setCollapsed(true);
        resetFilter();
    }}>
            {t({ key: 'CLEAR' })}
          </StyledAppliedFilterButton>
        </Grid>)}
    </StyledTableAppliedFilterGridContainer>);
};

import { createSelector } from 'reselect';
import { isNil } from 'ramda';
import { useReferenceApplicationSelector as useRefAppSelector } from './store.utils';
const requestOptionsSelector = (state) => state.requestOptions;
export const useIsLoading = () => useRefAppSelector((state) => state.isLoading);
export const useRecords = () => useRefAppSelector((state) => { var _a; return (_a = state.searchResponse) === null || _a === void 0 ? void 0 : _a.records; });
export const useSearchResponse = () => useRefAppSelector((state) => state.searchResponse);
export const useAvailableNavigations = () => useRefAppSelector((state) => { var _a; return (_a = state.searchResponse) === null || _a === void 0 ? void 0 : _a.availableNavigation; });
export const useRequestOptions = () => useRefAppSelector(requestOptionsSelector);
export const paginationSelector = createSelector(requestOptionsSelector, (state) => { var _a, _b; return (_b = (_a = state.searchResponse) === null || _a === void 0 ? void 0 : _a.totalRecordCount) !== null && _b !== void 0 ? _b : 0; }, ({ page, pageSize }, total) => ({ page, pageSize, total }));
export const usePaginationSelector = () => useRefAppSelector(paginationSelector);
export const useVisitorId = () => useRefAppSelector((state) => state.requestOptions.visitorId);
export const requestRefinementsSelector = createSelector(requestOptionsSelector, (requestOptions) => { var _a; return (_a = requestOptions.refinements) !== null && _a !== void 0 ? _a : []; });
export const useRequestRefinements = () => useRefAppSelector(requestRefinementsSelector);
export const useSelectedRefinements = () => useRefAppSelector((state) => state.selectedRefinements);
export const useOpenNavigations = () => useRefAppSelector((state) => state.openNavigations);
export const useImageMetaDataSettings = (collection) => useRefAppSelector((state) => state.imageSettings[collection]);
export const requestQuerySelector = createSelector(requestOptionsSelector, (requestOptions) => requestOptions.query);
export const useRequestQuery = () => useRefAppSelector(requestQuerySelector);
export const useIsAnalyticsEnabled = (modelType) => {
    var _a;
    const isEnabled = useRefAppSelector((state) => state.isAnalyticsEnabled[modelType]);
    if (isNil(isEnabled)) {
        const settings = JSON.parse(localStorage.getItem('isAnalyticsEnabled') || '{}');
        return (_a = settings[modelType]) !== null && _a !== void 0 ? _a : false;
    }
    return isEnabled;
};
export const useEvaluationPageType = () => useRefAppSelector((state) => state.evaluationPageType);

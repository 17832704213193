import React from 'react';
import { Navigate } from 'react-router-dom';
import { FeatureFlag, useFeatureFlagsProvider } from '@/search-for-retail/feature-flags';
import { useDirtyAuthenticatedSelector } from '@/store/selectors';
import Loading from '@/components/loading';
import { useShowAuthenticatedView } from '@/auth/hooks/use-show-authenticated-view';
import { StyledLoadingContainer } from './auth-guard.styles';
export const AuthGuard = (props) => {
    const { children, fallbackPath, pathType } = props;
    const { featureFlagsService } = useFeatureFlagsProvider();
    const isAuth0Enabled = featureFlagsService.isFeatureEnabled(FeatureFlag.Auth0);
    const { showAuthenticatedView, isLoading: isShowAuthViewLoading } = useShowAuthenticatedView();
    const isDirtyAuthenticated = useDirtyAuthenticatedSelector();
    const isLoading = (isAuth0Enabled ? false : isDirtyAuthenticated) || isShowAuthViewLoading;
    if (isLoading) {
        return (<React.Fragment>
        <StyledLoadingContainer>
          <Loading isLoading={true} label="Loading"/>
        </StyledLoadingContainer>
      </React.Fragment>);
    }
    const fallbackCondition = (pathType === 'protected' && !showAuthenticatedView)
        || (pathType === 'public' && showAuthenticatedView);
    if (fallbackCondition) {
        return <Navigate to={fallbackPath}/>;
    }
    return <React.Fragment>{children}</React.Fragment>;
};
export default AuthGuard;

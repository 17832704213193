import React from 'react';
import { AdminNavLinkIcon, AnalyticsNavLinkIcon, CatalogControlsNavLinkIcon, ConfigurationsNavLinkIcon, EnrichNavLinkIcon, MerchandisingNavLinkIcon, RecommendationsNavLinkIcon, } from '@/components/icons';
import { Routing } from '@/constants';
import { useActiveAreaNameSelector, useCollectionName } from '@/store/selectors';
import { useLocalization } from '@/localization';
import { NavigationListItem } from './navigation-list-item.component';
import { getSectionRoutes } from '@/navigation/drawer/lib/utils';
import { getDefaultRoute, getSectionLink } from '@/navigation/drawer/lib';
const NavIcon = ({ section }) => {
    const { path } = section;
    switch (path) {
        case Routing.SectionPath.ANALYTICS:
            return <AnalyticsNavLinkIcon />;
        case Routing.SectionPath.MERCHANDISING:
            return <MerchandisingNavLinkIcon />;
        case Routing.SectionPath.RECOMMENDATIONS:
            return <RecommendationsNavLinkIcon />;
        case Routing.SectionPath.TEMPLATE:
            return <ConfigurationsNavLinkIcon />;
        case Routing.SectionPath.ADMIN:
            return <AdminNavLinkIcon />;
        case Routing.SectionPath.ATTRIBUTE_MANAGEMENT:
            return <CatalogControlsNavLinkIcon />;
        case Routing.SectionPath.ENRICH:
            return <EnrichNavLinkIcon />;
        default:
            return null;
    }
};
export const PrimaryDrawerSectionLink = ({ active = false, section, onClick, key, }) => {
    const { formatMessage: t } = useLocalization();
    const activeCollection = useCollectionName();
    const activeArea = useActiveAreaNameSelector();
    const routes = getSectionRoutes(section);
    if (!(routes === null || routes === void 0 ? void 0 : routes.length) && !section.showAlways) {
        return null;
    }
    const defaultRoute = getDefaultRoute(routes);
    const sectionLink = getSectionLink(section.path, defaultRoute, activeCollection, activeArea);
    const handleClick = () => onClick(section, sectionLink);
    const isDisabled = !section.showAlways && (section.disabled || !defaultRoute);
    return (<NavigationListItem key={key} active={active} text={t({ key: section.label })} icon={<NavIcon section={section}/>} onClick={handleClick} hasSubNav={Boolean(routes.length)} disabled={isDisabled}/>);
};
export default PrimaryDrawerSectionLink;

import { __awaiter } from "tslib";
import { JSON_HEADERS, SearchForRetail } from '@/client/api';
import { buildCustomerHeaders } from '@/client/utils';
import fetch from '@/client/fetch';
export const getFeatures = () => __awaiter(void 0, void 0, void 0, function* () {
    const { result } = yield fetch(`${SearchForRetail.API_URL}/features`, {
        method: 'GET',
        headers: Object.assign(Object.assign({}, buildCustomerHeaders()), JSON_HEADERS),
        expiry: false,
        skipCache: false,
    });
    return result;
});

import moize from 'moize';
import { isNil } from 'ramda';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { activeUserSelector } from './auth';
import { managerModelsSelector, managerSelector } from './merchandising';
import { activeAreaNameSelector, activeAreaSelector } from './session';
import { EDITING_ALLOWED_PERMISSION_ROLES, ModelType, Role, SearchForRetail, SearchForRetailRole, } from '@/constants';
import { useActiveModelPath } from '@/routing/hooks/utils';
import { collectionIdByName, useActiveModelType } from '@/store/selectors';
import { getModelTypeFromModelPath } from '@/routing';
export const useToken = () => useSelector((state) => state.auth.token);
export const useAllAccessibleAreas = () => useSelector((state) => {
    var _a;
    const activeUser = state.auth.user;
    const allAreas = (_a = state.merchandising.global.area) === null || _a === void 0 ? void 0 : _a.byId;
    if (isNil(activeUser) || isNil(allAreas)) {
        return [];
    }
    return Object.values(allAreas).filter(({ id }) => { var _a; return (_a = activeUser.areaPermissions) === null || _a === void 0 ? void 0 : _a.some((areaPermission) => areaPermission.areaId === id); });
});
export const useCanViewUsers = () => useSelector((state) => {
    const activeUser = state.auth.user;
    if (isNil(activeUser)) {
        return false;
    }
    return activeUser.allowedAccess.includes(Role.ADMIN);
});
export const areaIdByName = moize((collectionName, areaName) => createSelector(collectionIdByName(collectionName), managerSelector(ModelType.AREA), (collectionId, normalizedModel) => {
    const { allIds = [], byId } = normalizedModel !== null && normalizedModel !== void 0 ? normalizedModel : {};
    return allIds.find((id) => { var _a, _b; return ((_a = byId === null || byId === void 0 ? void 0 : byId[id]) === null || _a === void 0 ? void 0 : _a.collectionId) === collectionId && ((_b = byId === null || byId === void 0 ? void 0 : byId[id]) === null || _b === void 0 ? void 0 : _b.name) === areaName; });
}));
export const areaIdSelector = (state) => areaIdByName(state.session.collectionName, state.session.areaName)(state);
export const collectionIdSelector = (state) => collectionIdByName(state.session.collectionName)(state);
export const isEditingAllowedSelector = createSelector(activeUserSelector, activeAreaSelector, managerSelector(ModelType.AREA), ({ areaPermissions }, area, normalizedModel) => {
    var _a, _b;
    const { allIds, byId } = normalizedModel !== null && normalizedModel !== void 0 ? normalizedModel : {};
    const areaId = allIds === null || allIds === void 0 ? void 0 : allIds.find((id) => { var _a; return ((_a = byId === null || byId === void 0 ? void 0 : byId[id]) === null || _a === void 0 ? void 0 : _a.name) === area; });
    const areaPermission = (_b = (_a = areaPermissions === null || areaPermissions === void 0 ? void 0 : areaPermissions.find((permission) => permission.areaId === areaId)) === null || _a === void 0 ? void 0 : _a.role) === null || _b === void 0 ? void 0 : _b.name;
    return EDITING_ALLOWED_PERMISSION_ROLES.includes(areaPermission);
});
export const s4rAllowedAreasSelector = createSelector(activeUserSelector, (activeUser) => { var _a, _b; return (_b = (_a = activeUser === null || activeUser === void 0 ? void 0 : activeUser.areaPermissions) === null || _a === void 0 ? void 0 : _a.map(({ areaId }) => String(areaId))) !== null && _b !== void 0 ? _b : []; });
export const useAllowedAreasSelector = () => useSelector(s4rAllowedAreasSelector);
export const useIsEditingAllowed = () => {
    const activeUserRole = useSelector((state) => state.auth.user.role);
    const isAdminUser = activeUserRole === SearchForRetailRole.Admin;
    const activeModelType = useActiveModelType();
    const shouldBeAreaMerchandiser = SearchForRetail.PAGE_TYPE_MAP[activeModelType] === SearchForRetail.PageType.AREA;
    const isAreaMerchandiserUser = useSelector(isEditingAllowedSelector);
    return isAdminUser || (shouldBeAreaMerchandiser && isAreaMerchandiserUser);
};
export const allowedAreasToPromoteSelector = createSelector(activeAreaNameSelector, activeUserSelector, managerSelector(ModelType.AREA), (currentAreaName, user, normalizedAreas) => {
    var _a;
    const { byId, allIds = [] } = normalizedAreas !== null && normalizedAreas !== void 0 ? normalizedAreas : {};
    const userAllowedAreas = new Set((_a = user.areaPermissions) === null || _a === void 0 ? void 0 : _a.map(({ areaId }) => areaId));
    return allIds.reduce((acc, areaId) => {
        var _a;
        const { id, name, collectionId } = (_a = byId === null || byId === void 0 ? void 0 : byId[areaId]) !== null && _a !== void 0 ? _a : {};
        if (!userAllowedAreas.has(areaId)) {
            return acc;
        }
        if (currentAreaName === name) {
            return acc;
        }
        return [...acc, { label: name, id: String(id), collectionId }];
    }, []);
});
export const areasSelector = createSelector(managerModelsSelector(ModelType.AREA, undefined), (areas) => ((areas === null || areas === void 0 ? void 0 : areas.sort((a, b) => { var _a; return (_a = a === null || a === void 0 ? void 0 : a.name) === null || _a === void 0 ? void 0 : _a.localeCompare(b === null || b === void 0 ? void 0 : b.name); })) || []));
export const useAreasSelector = () => useSelector(areasSelector);
export const useAllowedAreasToPromote = () => useSelector(allowedAreasToPromoteSelector);
export const useAreaIdSelector = () => useSelector(areaIdSelector);
export const useCollectionIdSelector = () => useSelector(collectionIdSelector);
export const useTenantIdSelector = () => useSelector((state) => { var _a; return (_a = state.auth.user) === null || _a === void 0 ? void 0 : _a.tenantId; });
export const useGetConfigPageType = () => {
    const modelPath = useActiveModelPath();
    const modelType = getModelTypeFromModelPath(modelPath);
    return SearchForRetail.PAGE_TYPE_MAP[modelType];
};

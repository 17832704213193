import React from 'react';
import { baseColors, Icon } from '@groupby/ui-components';
import { TableHead as MuiTableHead } from '@mui/material';
import { StyledCheckbox, StyledTableHeadCell, StyledTableHeadRow, StyledTableSortLabel, } from './table-head.styles';
import { SortIcons } from './partials/sort-icons.component';
import { SortDirection } from '@/search-for-retail/models';
export const TableHead = ({ columns, onSelectAllClick, onExpandAllClick, onSort, sortParam, selectedRowCount, rowCount, variant = 'dark', expandable, hasSelect = true, hasMoreOptions = true, disabled = false, guestUser = false, }) => {
    const handleSortClick = (id) => {
        const isDescending = sortParam && sortParam.propertyName === id && sortParam.direction === SortDirection.Ascending;
        const direction = isDescending ? SortDirection.Descending : SortDirection.Ascending;
        onSort({ propertyName: id, direction });
    };
    return (<MuiTableHead>
      <StyledTableHeadRow $variant={variant}>
        {(!guestUser && hasSelect) && (<StyledTableHeadCell $width="5%" $variant={variant} className="checkbox-cell">
            {hasSelect && (<StyledCheckbox indeterminate={selectedRowCount > 0 && selectedRowCount < rowCount} checked={rowCount > 0 && selectedRowCount === rowCount} onChange={onSelectAllClick} $variant={variant} disabled={disabled}/>)}
          </StyledTableHeadCell>)}
        {columns.map(({ sortable, align, width, label, id, noWrap, }, index) => (<StyledTableHeadCell key={index} align={align || 'left'} $variant={variant} $width={width || 'auto'} $noWrap={noWrap}>
            {sortable ? (<StyledTableSortLabel active={(sortParam === null || sortParam === void 0 ? void 0 : sortParam.propertyName) === id} direction={(sortParam === null || sortParam === void 0 ? void 0 : sortParam.propertyName) === id && (sortParam === null || sortParam === void 0 ? void 0 : sortParam.direction) === SortDirection.Descending ? 'desc' : 'asc'} onClick={() => handleSortClick(id)} IconComponent={SortIcons}>
                {label}
              </StyledTableSortLabel>) : label}
          </StyledTableHeadCell>))}
        {expandable ? (<StyledTableHeadCell align="left" $variant={variant} className="end-buttons-cell">
            <Icon variant="arrowCircleDown" onClick={onExpandAllClick} showHover color={variant === 'dark' ? baseColors.primaryBlueMed200 : baseColors.primaryBlueMed600}/>
          </StyledTableHeadCell>) : ((!guestUser && hasMoreOptions) ? <StyledTableHeadCell /> : null)}
      </StyledTableHeadRow>
    </MuiTableHead>);
};
export default TableHead;

import { __awaiter } from "tslib";
import React, { createContext, useContext, useEffect, useMemo, useState, } from 'react';
import { isNil } from 'ramda';
import { useDispatch } from 'react-redux';
import { Localization } from '@/localization';
import { addError, creators as S4RActionCreators } from '@/store/actions/creators';
import { useDefaultTenantLanguageSelector, usePreferredLanguageSelector } from '@/store/selectors';
import tenantClient from '@/tenant/tenant.client';
import { FeatureFlag, useFeatureFlagsProvider } from '@/search-for-retail/feature-flags';
import { SimpleLoading } from '@/components/loading';
import { logError } from '@/utils/logger';
const LocaleContext = createContext({
    locale: Localization.DEFAULT_LANGUAGE_CODE,
});
export const LocaleProvider = ({ children }) => {
    const dispatch = useDispatch();
    const { featureFlagsService } = useFeatureFlagsProvider();
    const isEmojiLangEnabled = featureFlagsService.isFeatureEnabled(FeatureFlag.EmojiLang);
    const [isLoadingTenantSettings, setIsLoadingTenantSettings] = useState(false);
    const userPreferredLanguage = usePreferredLanguageSelector();
    const defaultTenantLanguage = useDefaultTenantLanguageSelector();
    const preferredLocale = useMemo(() => {
        var _a;
        return (isEmojiLangEnabled
            ? Localization.QALanguage.EMOJI
            : (_a = userPreferredLanguage !== null && userPreferredLanguage !== void 0 ? userPreferredLanguage : defaultTenantLanguage) !== null && _a !== void 0 ? _a : Localization.DEFAULT_LANGUAGE_CODE);
    }, [userPreferredLanguage, defaultTenantLanguage, isEmojiLangEnabled]);
    useEffect(() => {
        const action = () => __awaiter(void 0, void 0, void 0, function* () {
            if (isNil(userPreferredLanguage)) {
                try {
                    setIsLoadingTenantSettings(true);
                    const settings = yield tenantClient.getSettings();
                    dispatch(S4RActionCreators.s4rUpdateTenantSettings(settings));
                }
                catch (err) {
                    const errorMessage = 'TASK_GET_TENANT_SETTINGS_ERROR';
                    logError(errorMessage, err);
                    dispatch(addError({ key: errorMessage }));
                }
                finally {
                    setIsLoadingTenantSettings(false);
                }
            }
        });
        void action();
    }, [dispatch, userPreferredLanguage]);
    if (isLoadingTenantSettings) {
        return <SimpleLoading />;
    }
    return (<LocaleContext.Provider value={{ locale: preferredLocale }}>
      {children}
    </LocaleContext.Provider>);
};
export const useLocale = () => useContext(LocaleContext);

import { __awaiter, __rest } from "tslib";
import Cache from './cache';
import * as FetchModule from './fetch';
export const DEFAULT_EXPIRY = 10000;
export const SERVER_FAILURE_ERROR = 'Server replied with an error.';
export const PARSE_ERROR_MESSAGE = 'Unable to parse response.';
export const SQL_MESSAGE_INDICATOR = 'SQL';
export class NetworkException extends Error {
    constructor(msg, statusCode, errors, warnings, explanation) {
        super(msg);
        this.statusCode = statusCode;
        this.errors = errors;
        this.warnings = warnings;
        this.explanation = explanation;
    }
}
export function checkStatus(response) {
    return __awaiter(this, void 0, void 0, function* () {
        if (response.status >= 200 && response.status < 300) {
            return response;
        }
        const result = yield FetchModule.parseJSON(response);
        let errors = [];
        let warnings = [];
        try {
            errors = result.errors || [];
            warnings = result.warnings || [];
        }
        catch (_a) {
            errors = getErrorMessages(response, result);
        }
        throw new FetchModule.NetworkException(response.statusText, response.status, errors, warnings, result && result.message ? result.message : SERVER_FAILURE_ERROR);
    });
}
export function parseJSON(response) {
    return __awaiter(this, void 0, void 0, function* () {
        if (response.status === 204) {
            return null;
        }
        const text = yield response.text();
        try {
            return JSON.parse(text);
        }
        catch (_a) {
            return null;
        }
    });
}
export function extractHeaders(response) {
    const headers = {};
    // eslint-disable-next-line no-return-assign
    response.headers.forEach((value, key) => (headers[key] = value));
    return headers;
}
export function buildResponse(response) {
    return __awaiter(this, void 0, void 0, function* () {
        const result = yield FetchModule.parseJSON(response);
        const headers = FetchModule.extractHeaders(response);
        return { result, headers, statusCode: response.status };
    });
}
export function cacheResponses(fetch) {
    const cache = new Cache();
    return (url, _a = {}) => {
        var { expiry = DEFAULT_EXPIRY, skipCache } = _a, config = __rest(_a, ["expiry", "skipCache"]);
        const key = Cache.generateKey(url, config);
        if (!skipCache && cache.has(key)) {
            return Promise.resolve(cache.get(key));
        }
        return fetch(url, config)
            .then(FetchModule.checkStatus)
            .then((response) => FetchModule.buildResponse(response))
            .then((resp) => (expiry === false ? resp : cache.set(key, resp, expiry)));
    };
}
export const uncachedFetch = (url, config = {}) => window.fetch(url, config)
    .then(checkStatus)
    .then((response) => buildResponse(response));
export function getErrorMessages(response, result) {
    if (response.status >= 400 && response.status < 500 && (result === null || result === void 0 ? void 0 : result.message) && !result.message.includes(SQL_MESSAGE_INDICATOR)) {
        return [`${result.message} (${response.status})`];
    }
    return [SERVER_FAILURE_ERROR];
}
export default cacheResponses(window.fetch);

import styled from 'styled-components';
export const StyledLoading = styled.div `
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  justify-content: center;

  &.top {
    flex-direction: column-reverse;
  }

  &.right {
    flex-direction: row;

    span {
      margin-right: 1rem;
    }
  }

  &.bottom {
    flex-direction: column;
  }

  &.left {
    flex-direction: row-reverse;

    span {
      margin-left: 1rem;
    }
  }
`;
export default StyledLoading;

import { __decorate, __rest } from "tslib";
import React from 'react';
// NOTE: Used by Advanced Filters (as of 2022-05-04)
import Input from '@mui/material/Input';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import MaterialSelect from '@mui/material/Select';
import Tooltip from '@mui/material/Tooltip';
import CallMadeIcon from '@mui/icons-material/CallMade';
import classNames from 'classnames';
import { equals, identity } from 'ramda';
import IconButton from '@/components/controls/icon-button';
import { disabling } from '@/components/disabling';
import { labeled } from '@/components/labeled';
import { responsive } from '@/components/responsive';
import { withStyles } from '@/decorators';
import * as SelectModule from '.';
import SelectLabel from './label';
export const MAX_ITEM_LENGTH = 14;
export const styles = ({ fontSize, palette, spacing, table, breakpoints, typography: { pxToRem }, }) => ({
    root: {
        fontSize: fontSize.formControl,
    },
    primaryColor: {
        '& $text': {
            color: palette.mainFont,
        },
        '& $icon': {
            color: palette.primary.main,
            height: spacing(4),
            width: spacing(4),
            top: -2,
        },
    },
    rangeWidth: {
        maxWidth: '50%',
        minWidth: '30%',
    },
    text: {},
    icon: {},
    defaultOption: {
        color: palette.grey[500],
        fontStyle: 'italic',
    },
    defaultOptionSelected: {
        color: palette.grey[600],
    },
    outlined: {
        borderRadius: spacing(1 / 2, 0, 0, 1 / 2),
        background: table.border,
        border: 'none',
    },
    outlinedInput: {
        padding: `${pxToRem(10)} ${spacing(4)} ${pxToRem(9)} ${pxToRem(9)}`,
        fontSize: spacing(1.5),
        transition: 'background-color 250ms',
        color: palette.mainFont,
        [breakpoints.down('md')]: {
            paddingRight: spacing(2),
        },
        '&:hover': {
            background: '#d5d5d5',
            borderRadius: spacing(1 / 2, 0, 0, 1 / 2),
        },
    },
    menuItem: {
        paddingRight: 0,
        justifyContent: 'space-between',
    },
    menuItemContainer: { width: spacing(30) },
    tooltip: {
        backgroundColor: palette.accentColours[50],
        color: palette.common.black,
        fontSize: fontSize.formControl,
        width: spacing(30),
        wordWrap: 'break-word',
        wordBreak: 'break-all',
    },
});
let Select = class Select extends React.PureComponent {
    constructor() {
        super(...arguments);
        this.state = { options: [] };
        this.onChange = ({ target: { value: index } }) => this.props.onChange(Number(index) === -1
            ? this.placeholder.value
            : this.state.options[index].value);
    }
    static getDerivedStateFromProps({ options }) {
        return {
            options: SelectModule.extractItems(options),
        };
    }
    get placeholder() {
        const { placeholder } = this.props;
        const hasPlaceholder = 'placeholder' in this.props;
        return (hasPlaceholder && typeof placeholder === 'object') ? placeholder : { label: placeholder, value: null };
    }
    render() {
        const { options } = this.state;
        const _a = this.props, { selected, onChange, options: _options, id, className, placeholder, primaryColor, renderValue = identity, outlined, menuClassName, menuContainer, menuItemClassName, belowHeader, rangeWidth, classes } = _a, props = __rest(_a, ["selected", "onChange", "options", "id", "className", "placeholder", "primaryColor", "renderValue", "outlined", "menuClassName", "menuContainer", "menuItemClassName", "belowHeader", "rangeWidth", "classes"]);
        const hasPlaceholder = 'placeholder' in this.props;
        const { label: placeholderLabel } = this.placeholder;
        const selectedIndex = options.findIndex(({ value }) => equals(value, selected));
        return (<MaterialSelect variant="standard" className={classNames(classes.root, {
            [classes.primaryColor]: primaryColor,
            [classes.rangeWidth]: rangeWidth,
        })} classes={{ select: classNames(classes.text, className), icon: classes.icon }} onChange={this.onChange} value={String(selectedIndex)} renderValue={(value) => (Number(value) === -1 ? (hasPlaceholder ? (<em>{renderValue(placeholderLabel)}</em>) : null) : options[Number(value)].special ? (<em>{renderValue(options[Number(value)].label)}</em>) : (renderValue(options[Number(value)].label)))} input={outlined ? (<OutlinedInput id={id} classes={{ root: classes.outlined, notchedOutline: classes.outlined, input: classes.outlinedInput }} fullWidth/>) : (<Input id={id}/>)} MenuProps={Object.assign({ container: menuContainer, className: classNames(classes.menuItemContainer, menuClassName) }, (belowHeader
            ? {
                getContentAnchorEl: null,
                anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
                transformOrigin: { vertical: 'top', horizontal: 'left' },
            }
            : {}))} {...props}>
        {hasPlaceholder && (<MenuItem value={-1} className={classes.defaultOption} classes={{ selected: classes.defaultOptionSelected }}>
            {placeholderLabel}
          </MenuItem>)}
        {options.map(({ label, special, url }, index) => {
            const itemProps = special
                ? {
                    className: classes.defaultOption,
                    classes: { selected: classes.defaultOptionSelected },
                }
                : {};
            const styledLabel = <SelectLabel special={special}>{label}</SelectLabel>;
            return (<MenuItem value={index} key={label} {...itemProps}>
              {label.length > MAX_ITEM_LENGTH ? (<Tooltip placement="bottom-start" title={label} classes={{ tooltip: classes.tooltip }}>
                  {styledLabel}
                </Tooltip>) : (styledLabel)}
              {url && (
            // eslint-disable-next-line react/jsx-no-target-blank
            <a href={url} target="_blank">
                  <IconButton size="large">
                    <CallMadeIcon />
                  </IconButton>
                </a>)}
            </MenuItem>);
        })}
      </MaterialSelect>);
    }
};
Select = __decorate([
    responsive,
    labeled,
    disabling,
    (withStyles(styles))
], Select);
export default Select;
export function extractItems(options) {
    return options.map((option) => (typeof option === 'object' ? option : { value: option, label: option }));
}

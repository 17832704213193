import { isEmpty } from 'ramda';
import { isAfter, isBefore } from 'date-fns';
const handleIncludes = (item, filter = {}, columns) => {
    const filterValues = Object.values(filter);
    return filterValues.every(({ field, list, subField, }) => {
        var _a;
        const config = (_a = columns.find(({ filterConfig }) => (filterConfig === null || filterConfig === void 0 ? void 0 : filterConfig.key) === field)) === null || _a === void 0 ? void 0 : _a.filterConfig;
        if (list.length === 0)
            return true;
        const itemField = item[field];
        if (config === null || config === void 0 ? void 0 : config.condition) {
            return list.includes(config.condition(item));
        }
        if (Array.isArray(itemField)) {
            return itemField.some((subItem) => {
                const subItemField = subItem[subField];
                return list.includes(subItemField);
            });
        }
        return list.includes(itemField);
    });
};
const handleRanges = (item, filter) => {
    const filterValues = Object.values(filter);
    return filterValues.every(({ field, startAt, endAt, }) => {
        if (!startAt && !endAt)
            return true;
        const before = isBefore(item[field], endAt);
        const after = isAfter(item[field], startAt);
        return !!((before && after));
    });
};
const handleSearch = (item, search, columns) => columns.some(({ filterConfig }) => ((filterConfig === null || filterConfig === void 0 ? void 0 : filterConfig.search) ? filterConfig.search(item, search) : false));
export const filterTableDataV2 = (rows, filter, columns) => {
    const { includes = {}, range = {}, search = '' } = filter !== null && filter !== void 0 ? filter : {};
    if (isEmpty(includes) && isEmpty(range) && search === '')
        return rows;
    return rows.filter((item) => {
        if (handleSearch(item, search, columns)
            && handleIncludes(item, includes, columns)
            && handleRanges(item, range)) {
            return true;
        }
        return false;
    });
};

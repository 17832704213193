import { __rest } from "tslib";
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
export const SearchPerformanceIcon = (_a) => {
    var { width = 80, height = 79 } = _a, props = __rest(_a, ["width", "height"]);
    return (<SvgIcon xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 80 79" fill="none" {...props}>
    <path d="M65.1 56.1978C72.832 56.1978 79.1 49.9298 79.1 42.1978C79.1 34.4658 72.832 28.1978 65.1 28.1978C57.368 28.1978 51.1 34.4658 51.1 42.1978C51.1 49.9298 57.368 56.1978 65.1 56.1978Z" fill="#E8EBEC"/>
    <path d="M35.1 24.2978C41.7274 24.2978 47.1 18.9252 47.1 12.2978C47.1 5.67037 41.7274 0.297791 35.1 0.297791C28.4726 0.297791 23.1 5.67037 23.1 12.2978C23.1 18.9252 28.4726 24.2978 35.1 24.2978Z" fill="#E8EBEC"/>
    <path d="M20.9 78.0978C31.9457 78.0978 40.9 69.1435 40.9 58.0978C40.9 47.0521 31.9457 38.0978 20.9 38.0978C9.85433 38.0978 0.900024 47.0521 0.900024 58.0978C0.900024 69.1435 9.85433 78.0978 20.9 78.0978Z" fill="#E8EBEC"/>
    <path d="M71.4 59.4978H7.40002V20.4978C7.40002 18.7978 8.70002 17.4978 10.4 17.4978H68.4C70.1 17.4978 71.4 18.7978 71.4 20.4978V59.4978Z" fill="#BDC3D8"/>
    <path d="M65.4 56.4978H7.40002V20.4978C7.40002 18.7978 8.70002 17.4978 10.4 17.4978H68.4V53.4978C68.4 55.0978 67 56.4978 65.4 56.4978Z" fill="white"/>
    <path d="M71.4 60.1978H7.39998C6.99998 60.1978 6.59998 59.8978 6.59998 59.3978V20.3978C6.59998 18.2978 8.29997 16.5978 10.4 16.5978H68.4C70.5 16.5978 72.2 18.2978 72.2 20.3978V59.3978C72.1 59.8978 71.8 60.1978 71.4 60.1978ZM8.09998 58.6978H70.6V20.3978C70.6 19.1978 69.6 18.0978 68.3 18.0978H10.3C9.09998 18.0978 7.99998 19.0978 7.99998 20.3978V58.6978H8.09998Z" fill="#21365F"/>
    <path d="M53.4 65.4978H25.4V71.4978H53.4V65.4978Z" fill="#BDC3D8"/>
    <path d="M50.4 65.4978H25.4V68.4978H49.4C50 68.4978 50.4 68.0978 50.4 67.4978V65.4978Z" fill="white"/>
    <path d="M53.4 72.1978H25.4C25 72.1978 24.6 71.8978 24.6 71.3978V65.3978C24.6 64.9978 24.9 64.5978 25.4 64.5978H53.4C53.8 64.5978 54.2 64.8978 54.2 65.3978V71.3978C54.1 71.8978 53.8 72.1978 53.4 72.1978ZM26.1 70.6978H52.6V66.1978H26.1V70.6978Z" fill="#21365F"/>
    <path d="M68.4 65.4978H10.4C8.70002 65.4978 7.40002 64.1978 7.40002 62.4978V59.4978H71.4V62.4978C71.4 64.0978 70 65.4978 68.4 65.4978Z" fill="#BDC3D8"/>
    <path d="M67.4 62.4978H7.40002V59.4978H68.4V61.4978C68.4 61.9978 67.9 62.4978 67.4 62.4978Z" fill="white"/>
    <path d="M68.4 66.1978H10.4C8.29997 66.1978 6.59998 64.4978 6.59998 62.3978V59.3978C6.59998 58.9978 6.89998 58.5978 7.39998 58.5978H71.4C71.8 58.5978 72.2 58.8978 72.2 59.3978V62.3978C72.1 64.4978 70.4 66.1978 68.4 66.1978ZM8.09998 60.1978V62.4978C8.09998 63.6978 9.09997 64.7978 10.4 64.7978H68.4C69.6 64.7978 70.7 63.7978 70.7 62.4978V60.1978H8.09998Z" fill="#21365F"/>
    <path d="M63.4 72.1978H15.4C15 72.1978 14.6 71.8978 14.6 71.3978C14.6 70.8978 14.9 70.5978 15.4 70.5978H63.4C63.8 70.5978 64.2 70.8978 64.2 71.3978C64.2 71.8978 63.8 72.1978 63.4 72.1978Z" fill="#21365F"/>
    <path d="M39.4 63.4978C39.9523 63.4978 40.4 63.0501 40.4 62.4978C40.4 61.9455 39.9523 61.4978 39.4 61.4978C38.8477 61.4978 38.4 61.9455 38.4 62.4978C38.4 63.0501 38.8477 63.4978 39.4 63.4978Z" fill="#21365F"/>
    <path d="M62.4 7.4978H16.4V53.4978H62.4V7.4978Z" fill="#BDC3D8"/>
    <path d="M59.4 7.4978H16.4V50.4978H57.4C58.5 50.4978 59.4 49.5978 59.4 48.4978V7.4978Z" fill="white"/>
    <path d="M62.4 54.1978H16.4C16 54.1978 15.6 53.8978 15.6 53.3978V7.39778C15.6 6.99778 15.9 6.59778 16.4 6.59778H62.4C62.8 6.59778 63.2 6.89778 63.2 7.39778V53.3978C63.1 53.8978 62.8 54.1978 62.4 54.1978ZM17.1 52.6978H61.6V8.19778H17.1V52.6978Z" fill="#21365F"/>
    <path d="M65.4 54.1978H13.4C13 54.1978 12.6 53.8978 12.6 53.3978C12.6 52.8978 12.9 52.5978 13.4 52.5978H65.4C65.8 52.5978 66.2 52.8978 66.2 53.3978C66.2 53.8978 65.8 54.1978 65.4 54.1978Z" fill="#21365F"/>
    <path d="M65.4 8.19778H13.4C13 8.19778 12.6 7.89778 12.6 7.39778C12.6 6.99778 12.9 6.59778 13.4 6.59778H65.4C65.8 6.59778 66.2 6.89778 66.2 7.39778C66.1 7.89778 65.8 8.19778 65.4 8.19778Z" fill="#21365F"/>
    <path d="M22.4 50.1978C22 50.1978 21.6 49.8978 21.6 49.3978V11.3978C21.6 10.9978 21.9 10.5978 22.4 10.5978C22.9 10.5978 23.2 10.8978 23.2 11.3978V49.3978C23.1 49.8978 22.8 50.1978 22.4 50.1978Z" fill="#21365F"/>
    <path d="M58.4 48.1978H20.4C20 48.1978 19.6 47.8978 19.6 47.3978C19.6 46.9978 19.9 46.5978 20.4 46.5978H58.4C58.8 46.5978 59.2 46.8978 59.2 47.3978C59.1 47.8978 58.8 48.1978 58.4 48.1978Z" fill="#21365F"/>
    <path d="M22.4 42.1978H20.4C20 42.1978 19.6 41.8978 19.6 41.3978C19.6 40.9978 19.9 40.5978 20.4 40.5978H22.4C22.8 40.5978 23.2 40.8978 23.2 41.3978C23.1 41.8978 22.8 42.1978 22.4 42.1978Z" fill="#21365F"/>
    <path d="M22.4 36.1978H20.4C20 36.1978 19.6 35.8978 19.6 35.3978C19.6 34.9978 19.9 34.5978 20.4 34.5978H22.4C22.8 34.5978 23.2 34.8978 23.2 35.3978C23.1 35.8978 22.8 36.1978 22.4 36.1978Z" fill="#21365F"/>
    <path d="M22.4 30.1978H20.4C20 30.1978 19.6 29.8978 19.6 29.3978C19.6 28.9978 19.9 28.5978 20.4 28.5978H22.4C22.8 28.5978 23.2 28.8978 23.2 29.3978C23.1 29.8978 22.8 30.1978 22.4 30.1978Z" fill="#21365F"/>
    <path d="M22.4 24.1978H20.4C20 24.1978 19.6 23.8978 19.6 23.3978C19.6 22.9978 19.9 22.5978 20.4 22.5978H22.4C22.8 22.5978 23.2 22.8978 23.2 23.3978C23.1 23.8978 22.8 24.1978 22.4 24.1978Z" fill="#21365F"/>
    <path d="M22.4 18.1978H20.4C20 18.1978 19.6 17.8978 19.6 17.3978C19.6 16.9978 19.9 16.5978 20.4 16.5978H22.4C22.8 16.5978 23.2 16.8978 23.2 17.3978C23.1 17.8978 22.8 18.1978 22.4 18.1978Z" fill="#21365F"/>
    <path d="M29 29.4978L22.4 47.4978H35.5L29 29.4978Z" fill="#BDC3D8"/>
    <path d="M29 29.4978L22.4 47.4978H29.5L29 29.4978Z" fill="white"/>
    <path d="M35.5 48.1978H22.4C22.2 48.1978 21.9 48.0978 21.8 47.8978C21.7 47.6978 21.6 47.3978 21.7 47.1978L28.3 29.1978C28.4 28.8978 28.7 28.6978 29 28.6978C29.3 28.6978 29.6 28.8978 29.7 29.1978L36.3 47.1978C36.4 47.3978 36.4 47.6978 36.2 47.8978C36 48.0978 35.8 48.1978 35.5 48.1978ZM23.5 46.6978H34.5L29 31.5978L23.5 46.6978Z" fill="#21365F"/>
    <path d="M39.8999 17.4978L31.2 47.4978H48.6L39.8999 17.4978Z" fill="#BDC3D8"/>
    <path d="M39.8999 17.4978L31.2 47.4978H40.5999L39.8999 17.4978Z" fill="white"/>
    <path d="M48.5999 48.1978H31.1999C30.9999 48.1978 30.6999 48.0978 30.5999 47.8978C30.4999 47.6978 30.3999 47.4978 30.4999 47.1978L39.1999 17.1978C39.2999 16.8978 39.5999 16.6978 39.8999 16.6978C40.1999 16.6978 40.4999 16.8978 40.5999 17.1978L49.2999 47.1978C49.3999 47.3978 49.2999 47.6978 49.1999 47.8978C48.9999 48.0978 48.7999 48.1978 48.5999 48.1978ZM32.1999 46.6978H47.5999L39.8999 20.0978L32.1999 46.6978Z" fill="#21365F"/>
    <path d="M49.4 23.4978L40.4 47.4978H58.4L49.4 23.4978Z" fill="#BDC3D8"/>
    <path d="M49.4 23.4978L40.4 47.4978H51.4L49.4 23.4978Z" fill="white"/>
    <path d="M58.4 48.1978H40.4C40.2 48.1978 39.9 48.0978 39.8 47.8978C39.7 47.6978 39.6 47.3978 39.7 47.1978L48.7 23.1978C48.8 22.8978 49.1 22.6978 49.4 22.6978C49.7 22.6978 50 22.8978 50.1 23.1978L59.1 47.1978C59.2 47.3978 59.2 47.6978 59 47.8978C58.9 48.0978 58.6 48.1978 58.4 48.1978ZM41.5 46.6978H57.3L49.4 25.5978L41.5 46.6978Z" fill="#21365F"/>
    <path d="M6.39998 15.1978H2.39998C1.99998 15.1978 1.59998 14.8978 1.59998 14.3978C1.59998 13.8978 1.89998 13.5978 2.39998 13.5978H6.39998C6.79998 13.5978 7.19998 13.8978 7.19998 14.3978C7.19998 14.8978 6.79998 15.1978 6.39998 15.1978Z" fill="#21365F"/>
    <path d="M4.39998 17.1978C3.99998 17.1978 3.59998 16.8978 3.59998 16.3978V12.3978C3.59998 11.9978 3.89998 11.5978 4.39998 11.5978C4.89998 11.5978 5.19998 11.8978 5.19998 12.3978V16.3978C5.09998 16.8978 4.79998 17.1978 4.39998 17.1978Z" fill="#21365F"/>
    <path d="M4.39998 21.2978C4.8418 21.2978 5.19998 20.9396 5.19998 20.4978C5.19998 20.0559 4.8418 19.6978 4.39998 19.6978C3.95815 19.6978 3.59998 20.0559 3.59998 20.4978C3.59998 20.9396 3.95815 21.2978 4.39998 21.2978Z" fill="#21365F"/>
    <path d="M4.39998 24.2978C4.8418 24.2978 5.19998 23.9396 5.19998 23.4978C5.19998 23.0559 4.8418 22.6978 4.39998 22.6978C3.95815 22.6978 3.59998 23.0559 3.59998 23.4978C3.59998 23.9396 3.95815 24.2978 4.39998 24.2978Z" fill="#21365F"/>
    <path d="M4.39998 28.2978C4.8418 28.2978 5.19998 27.9396 5.19998 27.4978C5.19998 27.0559 4.8418 26.6978 4.39998 26.6978C3.95815 26.6978 3.59998 27.0559 3.59998 27.4978C3.59998 27.9396 3.95815 28.2978 4.39998 28.2978Z" fill="#21365F"/>
    <path d="M4.39998 34.2978C4.8418 34.2978 5.19998 33.9396 5.19998 33.4978C5.19998 33.0559 4.8418 32.6978 4.39998 32.6978C3.95815 32.6978 3.59998 33.0559 3.59998 33.4978C3.59998 33.9396 3.95815 34.2978 4.39998 34.2978Z" fill="#21365F"/>
    <path d="M76.4 68.9978H72.4C72 68.9978 71.6 68.6978 71.6 68.1978C71.6 67.7978 71.9 67.3978 72.4 67.3978H76.4C76.8 67.3978 77.2 67.6978 77.2 68.1978C77.1 68.5978 76.8 68.9978 76.4 68.9978Z" fill="#21365F"/>
    <path d="M74.4 70.9978C74 70.9978 73.6 70.6978 73.6 70.1978V66.1978C73.6 65.7978 73.9 65.3978 74.4 65.3978C74.9 65.3978 75.2 65.6978 75.2 66.1978V70.1978C75.1 70.5978 74.8 70.9978 74.4 70.9978Z" fill="#21365F"/>
    <path d="M74.4 62.9978C74.8418 62.9978 75.2 62.6396 75.2 62.1978C75.2 61.7559 74.8418 61.3978 74.4 61.3978C73.9581 61.3978 73.6 61.7559 73.6 62.1978C73.6 62.6396 73.9581 62.9978 74.4 62.9978Z" fill="#21365F"/>
    <path d="M74.4 59.9978C74.8418 59.9978 75.2 59.6396 75.2 59.1978C75.2 58.7559 74.8418 58.3978 74.4 58.3978C73.9581 58.3978 73.6 58.7559 73.6 59.1978C73.6 59.6396 73.9581 59.9978 74.4 59.9978Z" fill="#21365F"/>
    <path d="M74.4 55.9978C74.8418 55.9978 75.2 55.6396 75.2 55.1978C75.2 54.7559 74.8418 54.3978 74.4 54.3978C73.9581 54.3978 73.6 54.7559 73.6 55.1978C73.6 55.6396 73.9581 55.9978 74.4 55.9978Z" fill="#21365F"/>
    <path d="M74.4 49.9978C74.8418 49.9978 75.2 49.6396 75.2 49.1978C75.2 48.7559 74.8418 48.3978 74.4 48.3978C73.9581 48.3978 73.6 48.7559 73.6 49.1978C73.6 49.6396 73.9581 49.9978 74.4 49.9978Z" fill="#21365F"/>
  </SvgIcon>);
};
export default SearchPerformanceIcon;

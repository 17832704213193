import React, { useEffect } from 'react';
import { Outlet, useOutletContext } from 'react-router-dom';
import { useGetAnalyticsDashboardType } from '@/search-for-retail/analytics';
import { usePathname, useSwitchModelTypeIfNoActiveOne } from '@/routing/hooks/utils';
import { getModelPathFromPathname } from '@/routing';
export const SearchForRetailLayout = () => {
    const { setAnalyticsDashboardType: setSelectedDashboardType } = useOutletContext();
    const pathname = usePathname();
    const modelPath = getModelPathFromPathname(pathname);
    useSwitchModelTypeIfNoActiveOne(modelPath);
    const paths = pathname.split('/');
    const chartType = useGetAnalyticsDashboardType(modelPath, paths);
    useEffect(() => {
        setSelectedDashboardType(chartType);
    }, [pathname, chartType, setSelectedDashboardType]);
    return <Outlet />;
};

import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAreasSelector, useCollectionsSelector, useUserSettings } from '@/store/selectors';
import { useAreaNameFromUrl, useCollectionNameFromUrl } from '@/routing/hooks';
import { useTenantSettings } from '@/auth/hooks/use-tenant-settings';
import * as ActionCreators from '@/store/actions/creators';
export const findArea = ({ areaName, areas, collection, }) => (areaName && (areas === null || areas === void 0 ? void 0 : areas.length) && (collection === null || collection === void 0 ? void 0 : collection.id)
    ? areas.find(({ name, collectionId, }) => name === areaName && collectionId === collection.id)
    : undefined);
export function useSetDefaultCollectionAndArea({ setSelectedCollection, setSelectedArea, setSeletedAreaName, selectedCollection, }) {
    const areas = useAreasSelector();
    const collections = useCollectionsSelector();
    const userSettings = useUserSettings();
    const tenantSettings = useTenantSettings();
    const urlCollectionName = useCollectionNameFromUrl();
    const urlAreaName = useAreaNameFromUrl();
    const [defaultArea, setDefaultArea] = useState();
    const [defaultCollection, setDefaultCollection] = useState();
    const dispatch = useDispatch();
    const [defaultLoaded, setDefaultLoaded] = useState(false);
    useEffect(() => {
        if (defaultLoaded)
            return;
        const { defaultCollection: userDefaultCollection, defaultArea: userDefaultArea } = userSettings;
        const { defaultCollection: tenantDefaultCollection, defaultArea: tenantDefaultArea } = tenantSettings;
        const collectionName = urlCollectionName || userDefaultCollection || tenantDefaultCollection;
        const areaName = urlAreaName || userDefaultArea || tenantDefaultArea;
        setDefaultArea(coalesce([userDefaultArea, tenantDefaultArea]));
        setDefaultCollection(coalesce([userDefaultCollection, tenantDefaultCollection]));
        if (!collectionName && !areaName)
            return;
        let collectionFound;
        if (collectionName) {
            collectionFound = collections.find((collection) => collectionName === collection.collection);
            setSelectedCollection(collectionFound);
        }
        if (areaName && collectionFound) {
            const foundArea = findArea({ areaName, areas, collection: collectionFound });
            setSeletedAreaName(foundArea === null || foundArea === void 0 ? void 0 : foundArea.name);
            setSelectedArea(foundArea);
        }
        setDefaultLoaded(true);
        if (collectionName && areaName) {
            dispatch(ActionCreators.setCollectionName(collectionName));
            dispatch(ActionCreators.switchArea(areaName));
        }
    }, [
        areas,
        collections,
        defaultLoaded,
        userSettings,
        tenantSettings,
        urlAreaName,
        urlCollectionName,
        setSeletedAreaName,
        setSelectedArea,
        setSelectedCollection,
        setDefaultLoaded,
        setDefaultArea,
        setDefaultCollection,
        dispatch,
    ]);
    return {
        defaultLoaded,
        defaultCollection: (userSettings === null || userSettings === void 0 ? void 0 : userSettings.defaultCollection) || defaultCollection,
        defaultArea: selectedCollection === ((userSettings === null || userSettings === void 0 ? void 0 : userSettings.defaultCollection) || defaultCollection) ? ((userSettings === null || userSettings === void 0 ? void 0 : userSettings.defaultArea) || defaultArea) : '',
    };
}
function coalesce(values) {
    return values.find((val) => !!val);
}
export function hasArea(areas, area) {
    if (!area || !areas.length)
        return false;
    return areas === null || areas === void 0 ? void 0 : areas.some((a) => a.id === (area === null || area === void 0 ? void 0 : area.id));
}

import React from 'react';
import { InputAdornment } from '@mui/material';
import { baseColors, Icon } from '@groupby/ui-components';
import { Button } from '@/components/button';
import { useLocalization } from '@/localization';
import { CustomSearchQueryInput } from './search-bar.styles';
import { StyledQuerySearchRow } from '@/search-for-retail/evaluate/view/search-bar/search-bar.styles';
export const SearchBar = ({ search, setSearch, onSubmit }) => {
    const { formatMessage: t } = useLocalization();
    const handleInputChange = (e) => setSearch(e.currentTarget.value);
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            const input = event.target;
            setSearch(input.value);
            onSubmit();
        }
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit();
    };
    return (<StyledQuerySearchRow onSubmit={handleSubmit}>
      <CustomSearchQueryInput variant="outlined" value={search} onChange={handleInputChange} onKeyDown={handleKeyDown} label={t({ key: 'RELEASE_NOTES_SEARCH' })} InputProps={{
        startAdornment: <InputAdornment position="start">
              <Icon variant="search" color={baseColors.primaryGreen400} className="search-icon"/>
            </InputAdornment>,
    }}/>
      <Button rounded title={t({ key: 'SEARCH' })} variant="contained" color="primary" type="submit"/>
    </StyledQuerySearchRow>);
};

import { baseColors } from '@groupby/ui-components';
import styled from 'styled-components';
import { Button } from '@/components/button';
export const StyledContextSelectorsWrapper = styled.div `
  display: flex;
  align-items: center;

  > div {
    margin-left: 16px;
  }
`;
export const StyledButton = styled(Button) `
  margin-left: ${({ theme }) => theme.spacing(2)};
`;
export const StyledDefaultButton = styled(Button) `
  margin-left: ${({ theme }) => theme.spacing(2)};
  background-color: ${baseColors.background050};
  color: ${baseColors.primaryGreen300};
`;

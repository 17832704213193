import { __rest } from "tslib";
import React from 'react';
import { Formik } from 'formik';
import { FormDisablingProvider } from '@/search-for-retail/generic/form/form-disabling/form-disabling.provider';
export const Form = (_a) => {
    var { initialValues, onSubmit, validationSchema, component, children } = _a, props = __rest(_a, ["initialValues", "onSubmit", "validationSchema", "component", "children"]);
    return (<FormDisablingProvider>
    <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema} component={component} {...props}>
      {children}
    </Formik>
  </FormDisablingProvider>);
};
export default Form;

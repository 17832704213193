export var AdvancedFilterBy;
(function (AdvancedFilterBy) {
    AdvancedFilterBy["TAG"] = "tag";
    AdvancedFilterBy["NAME"] = "name";
    AdvancedFilterBy["DATE_CREATED"] = "date_created";
    AdvancedFilterBy["DATE_MODIFIED"] = "date_modified";
})(AdvancedFilterBy || (AdvancedFilterBy = {}));
export var AdvancedFilterOption;
(function (AdvancedFilterOption) {
    AdvancedFilterOption["STARTS_WITH"] = "starts_with";
    AdvancedFilterOption["ENDS_WITH"] = "ends_with";
    AdvancedFilterOption["CONTAINS"] = "contains";
    AdvancedFilterOption["BEFORE"] = "before";
    AdvancedFilterOption["AFTER"] = "after";
    AdvancedFilterOption["BETWEEN"] = "between";
})(AdvancedFilterOption || (AdvancedFilterOption = {}));

import styled from 'styled-components';
import { baseColors } from '@groupby/ui-components';
import { TableRow } from '@mui/material';
export const StyledTableRow = styled(TableRow) `
  cursor: default;
  &&.row--highlighted {
    background-color: ${baseColors.primaryAccentGreen800};
  }


  &.table-row {
    overflow: clip;
    background-color: ${baseColors.white};
    height: 64px;

    &:hover:not(.disabled) {
      transform: scale(1);
      box-shadow: 0px 0.8px 1.5px rgba(0, 0, 0, 0.05), 0px 6px 12px rgba(0, 0, 0, 0.08);
    }

    ${({ $showMoreOptions }) => $showMoreOptions && `{
      & > td:last-child > div {
        display: flex;
        justify-content: flex-end;
        & > button {
          margin-right: 20px;
        }
      }
    }`}

    &.expandable td {
      border-bottom: none;
    }

    .toggle-expand {
      transition: transform 0.5s;
      margin-right: 8px;
    }
    .more-button-icon {
      transform: rotate(90deg);
    }
    .more-button:disabled,
    .checkbox--disabled {
      opacity: 0.5;
    }
  }
`;
export const StyledExpandableTableRowSection = styled(TableRow) `
  &:not(.expandable-section--expanded) td {
    padding-top: 0;
    padding-bottom: 0;
    transition: padding 0.5s;
  }
`;

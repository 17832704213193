import React, { useState } from 'react';
import { baseColors, Icon, StyledIconWrapper } from '@groupby/ui-components';
import { Divider } from '@mui/material';
import { useTheme } from 'styled-components';
import { LanguageSelectionMenu } from './language-selection.component';
import { getCodeToLanguage, Localization, LocalizedMessage, useLocalization, } from '@/localization';
import { StyledMenu, StyledMenuItem, StyledSelectedLanguageText } from './menu.styles';
import { useGoToUrl } from '@/routing/hooks/utils';
import { ModelType, Routing } from '@/constants';
import { useActiveAreaNameSelector, useCollectionName } from '@/store/selectors';
import { modelManagerRoute } from '@/routing';
import { AuditLogIcon, GlobeIcon } from '@/components/icons';
import { useNavigation } from '@/navigation/navigation.provider';
import { useAuthLogout } from '@/auth/hooks/authentication';
import { NavigationType } from '@/navigation/types';
import BookIcon from '@/components/icons/svg/search-for-retail/book-icon';
import { FeatureFlag, useFeatureFlagsProvider } from '@/search-for-retail/feature-flags';
export const UserOptionsMenu = ({ anchorEl, handleClose, }) => {
    const theme = useTheme();
    const goToUrl = useGoToUrl();
    const activeArea = useActiveAreaNameSelector();
    const activeCollectionName = useCollectionName();
    const { setVisibleRouteSection, setNavigationType } = useNavigation();
    const { getLocale, setLocale, formatMessage: t } = useLocalization();
    const selectedLanguage = getLocale();
    const codeToLanugage = getCodeToLanguage();
    const { featureFlagsService } = useFeatureFlagsProvider();
    const [languageMenuAnchorEl, setLanguageMenuAnchorEl] = useState(null);
    const handleLanguageSelectionOpen = (e) => setLanguageMenuAnchorEl(e.currentTarget);
    const handleCloseLanguageSelection = () => setLanguageMenuAnchorEl(null);
    const { logout } = useAuthLogout();
    return (<React.Fragment>
      <LanguageSelectionMenu languageMenuAnchorEl={languageMenuAnchorEl} handleClose={handleCloseLanguageSelection} selectedLanguage={selectedLanguage} selectLanguage={setLocale}/>
      <StyledMenu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose} $type="user">
        <StyledMenuItem button color={baseColors.white} iconHoverColor={baseColors.primaryGreen000} onClick={handleLanguageSelectionOpen} iconStart={<StyledIconWrapper>
              <GlobeIcon className="icon-left"/>
            </StyledIconWrapper>}>
          <LocalizedMessage messageKey="LANGUAGE_SELECTION" values={{
        language: codeToLanugage[selectedLanguage]
            ? codeToLanugage[selectedLanguage]
            : codeToLanugage[Localization.DEFAULT_LANGUAGE_CODE],
        lang: (chunks) => (<StyledSelectedLanguageText>{chunks}</StyledSelectedLanguageText>),
        label: (chunks) => (<div>{chunks}</div>),
    }}/>
        </StyledMenuItem>

        <Divider variant="middle"/>
        <StyledMenuItem button copy={t({ key: 'AUDIT_LOG' })} color={baseColors.primaryBlueMed500} onClick={() => {
        goToUrl(modelManagerRoute(ModelType.AUDIT_LOG, activeCollectionName, activeArea));
        setVisibleRouteSection();
        setNavigationType(NavigationType.PRIMARY);
    }} iconStart={<StyledIconWrapper>
              <AuditLogIcon className="icon-left"/>
            </StyledIconWrapper>}/>
        {featureFlagsService.isFeatureEnabled(FeatureFlag.EnableReleaseNotes)
        && (<StyledMenuItem button copy={t({ key: 'RELEASE_NOTES' })} color={baseColors.primaryBlueMed500} onClick={() => {
            goToUrl(Routing.RELEASE_NOTES_PATH);
            setVisibleRouteSection();
            setNavigationType(NavigationType.PRIMARY);
        }} iconStart={<StyledIconWrapper>
                <BookIcon className="icon-left"/>
              </StyledIconWrapper>}/>)}
        <Divider variant="middle"/>
        <StyledMenuItem button className="sign-out" copy={t({ key: 'NAVIGATION_LOG_OUT' })} color={theme.palette.error.dark} onClick={logout} iconStart={<Icon variant="rightArrowSquareBracket" additionalClasses="icon-left"/>}/>
      </StyledMenu>
    </React.Fragment>);
};
export default UserOptionsMenu;

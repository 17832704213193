import { __rest } from "tslib";
import cuid from 'cuid';
import decodeJWT from 'jwt-decode';
import moize from 'moize';
import moment from 'moment';
import * as qs from 'qs';
import { connect as reduxConnect } from 'react-redux';
import { getDisplayName } from 'recompose';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import StringNaturalCompare from 'string-natural-compare';
import { capitalize } from 'voca';
import { DATE_FORMAT_HOUR, Role, SearchForRetailRole, WRAPPED_COMPONENT_KEY, } from '@/constants';
import { extractCustomerName } from '@/config/utils';
export { cuid, decodeJWT, StringNaturalCompare, };
export const ARRAY_ACCESS_KEY_PATTERN = /\[(\d+)\]/g;
export const ARRAY_ACCESS_PATTERN = /(\[\d+\])/g;
export const exposeWrapped = (hoc) => (Component) => {
    const wrapped = hoc(Component);
    wrapped[WRAPPED_COMPONENT_KEY] = Component;
    return wrapped;
};
export function bindActions(actionCreators, component) {
    return (dispatch) => bindActionCreators(Object.keys(actionCreators).reduce((acc, key) => {
        acc[key] = (...args) => {
            const action = actionCreators[key](...args);
            return Object.assign(Object.assign({}, action), { meta: Object.assign(Object.assign({}, action.meta), { source: getDisplayName(component) }) });
        };
        return acc;
    }, {}), dispatch);
}
export function wrapMerger(merger) {
    return (stateProps, dispatchProps, props) => (Object.assign(Object.assign(Object.assign(Object.assign({}, stateProps), dispatchProps), props), merger(stateProps, dispatchProps, props)));
}
export function connect(selectors, actionCreators, mergeProps, _a = {
    merge: true,
    injectDispatch: false,
}) {
    var { merge, injectDispatch } = _a, opts = __rest(_a, ["merge", "injectDispatch"]);
    return ((component) => reduxConnect(selectors ? (createStructuredSelector(selectors)) : null, actionCreators ? bindActions(actionCreators, component) : injectDispatch ? null : {}, (mergeProps && merge) ? wrapMerger(mergeProps) : null, opts)(component));
}
export function parseSearch(search) {
    return qs.parse((search || '?').substr(1));
}
export function formatDate(datetime) {
    return moment(datetime)
        .hours(0)
        .minutes(0)
        .seconds(0)
        .milliseconds(0)
        .format('MMM DD YYYY');
}
export function convertFieldArrayAccessSyntax(path) {
    return path.replace(ARRAY_ACCESS_KEY_PATTERN, '.$1');
}
export const dot = {
    get(target, path, defaultValue) {
        try {
            const normalizedPath = convertFieldArrayAccessSyntax(path);
            const dotIndex = normalizedPath.indexOf('.');
            if (dotIndex === -1) {
                return normalizedPath in target ? target[normalizedPath] : defaultValue;
            }
            const key = normalizedPath.substr(0, dotIndex);
            return key in target ? dot.get(target[key], normalizedPath.substr(dotIndex + 1), defaultValue) : defaultValue;
        }
        catch (_a) {
            return defaultValue;
        }
    },
};
export function getDuration(start, end) {
    return moment.duration(moment(end, DATE_FORMAT_HOUR).diff(moment(start, DATE_FORMAT_HOUR))).asHours();
}
export const preventDefault = moize((handler) => (e) => {
    e.preventDefault();
    if (handler) {
        handler();
    }
});
export function normalize(models) {
    return {
        byId: models.reduce((byId, model) => (Object.assign(Object.assign({}, byId), { [model.id]: model })), {}),
        allIds: models.map(({ id }) => id),
    };
}
export const denormalize = moize((models) => {
    if (!models) {
        return [];
    }
    return models.allIds.map((id) => models.byId[id]);
});
export function convertS4RRoleToMerchandisingRoles(role) {
    var _a;
    const mapping = {
        [SearchForRetailRole.SuperAdmin]: [
            Role.ADMIN,
            Role.MERCHANDISING,
            Role.QUERY_REWRITE,
            Role.ENGINE_CONFIGURATION,
        ],
        [SearchForRetailRole.Admin]: [
            Role.ADMIN,
            Role.MERCHANDISING,
            Role.QUERY_REWRITE,
            Role.ENGINE_CONFIGURATION,
        ],
        [SearchForRetailRole.Merchandiser]: [
            Role.MERCHANDISING,
            Role.QUERY_REWRITE,
            Role.ENGINE_CONFIGURATION,
        ],
    };
    return (_a = mapping[role]) !== null && _a !== void 0 ? _a : [];
}
export function filterOutLastTwoChar(value) {
    return value ? value.slice(0, -2) : null;
}
export function extractLastTwoChar(value) {
    return value ? value.slice(-2) : null;
}
export function convertToISO(datetime) {
    return `${filterOutLastTwoChar(datetime)}T${extractLastTwoChar(datetime)}`;
}
export function getBasePath(activePath) {
    return activePath.split('/')[1];
}
export function formatTitles(value) {
    return capitalize(value.replace(/([A-Z])/g, ' $1'));
}
export function formatQueryParam(params) {
    const builtQueryParam = Object.entries(params)
        .filter((param) => param[1] && param[1].length)
        .map((param, index) => `${index === 0 ? '' : '&'}${param[0]}=${Array.isArray(param[1]) ? param[1].join(',') : param[1]}`);
    return encodeURI(`${builtQueryParam.join('')}`);
}
export function formatDateS4R(datetime, format = 'MMM DD, YYYY') {
    return moment(datetime).format(format);
}
export const isBase64 = (str) => {
    try {
        return btoa(atob(str)) === str;
    }
    catch (err) {
        return false;
    }
};
export const isLowerEnvironment = (hostname = window.location.hostname) => {
    var _a;
    const LOWER_SUFFIX = '-lo';
    // check third level domain
    return ((_a = hostname === null || hostname === void 0 ? void 0 : hostname.split('.')[1]) === null || _a === void 0 ? void 0 : _a.slice(-3)) === LOWER_SUFFIX;
};
export const isLocalhost = (hostname = window.location.hostname) => hostname === 'localhost';
export const isGbiQA = (hostname = window.location.hostname) => {
    const GBIQA_PREFIX = 'gbiqa';
    return extractCustomerName(hostname) === GBIQA_PREFIX;
};
export const isDemos = (hostname = window.location.hostname) => {
    const DEMOS_PREFIX = 'demos';
    return extractCustomerName(hostname) === DEMOS_PREFIX;
};
export const isCustomerEnv = (hostname = window.location.hostname) => (!isGbiQA(hostname)
    && !isDemos(hostname)
    && !isLocalhost(hostname)
    && !isLowerEnvironment(hostname));
// export const updateFeatureFlagsFromAPIResponse = (apiResponse: Features) => {
//   Object.values(FeatureFlag).forEach((flag) => {
//     const flagFromAPIResponse = apiResponse[flag];
//     const flagFromSessionStorage = window.sessionStorage.getItem(`gbi:${flag}`);
//     // If the flag was not set by a query parameter and there's a value in the API response, then update the session storage
//     if (flagFromSessionStorage === null && flagFromAPIResponse !== undefined) {
//       try {
//         window.sessionStorage.setItem(`gbi:${flag}`, String(flagFromAPIResponse));
//       } catch (error) {
//         // if and when this code is uncommented, import { `logError` } from '@/utils/logger'
//         logError(`Failed to set flag ${flag} in session storage: `, error);
//       }
//     }
//   })
// };

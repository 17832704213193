import { __rest } from "tslib";
import { DEFAULT_ROWS_PER_PAGE, EvaluationType } from '../evaluate.constants';
import { CLEAR_SEARCH_RESPONSE, RESET_NAVIGATIONS, RESET_REQUEST_OPTIONS, RESET_STATE, SET_EVALUATION_TYPE, SET_IMAGE_SETTINGS, SET_IS_ANALYTICS_ENABLED, SET_IS_LOADING, SET_OPEN_NAVIGATIONS, SET_REQUEST_OPTIONS, SET_SEARCH_RESPONSE, SET_SELECTED_REFINEMENTS, } from './actions';
const defaultAnalyticsSettings = {
    Rule: false,
    Evaluate: true,
};
const savedSettings = JSON.parse(localStorage.getItem('isAnalyticsEnabled') || '{}');
const isAnalyticsEnabled = Object.assign(Object.assign({}, defaultAnalyticsSettings), savedSettings);
export const initialValues = {
    isLoading: true,
    requestOptions: {
        page: 1,
        pageSize: DEFAULT_ROWS_PER_PAGE,
        refinements: [],
    },
    selectedRefinements: [],
    openNavigations: {},
    imageSettings: {},
    isAnalyticsEnabled,
    evaluationPageType: EvaluationType.Query,
};
export const refAppReducer = (
// eslint-disable-next-line default-param-last
state = initialValues, action) => {
    switch (action.type) {
        case SET_REQUEST_OPTIONS:
            return setRequestOptionsReducer(state, action);
        case RESET_REQUEST_OPTIONS:
            return resetRequestOptionsReducer(state);
        case SET_SEARCH_RESPONSE:
            return setSearchResponseReducer(state, action);
        case CLEAR_SEARCH_RESPONSE:
            return clearSearchResponseReducer(state);
        case SET_IS_LOADING:
            return setIsLoadingReducer(state, action);
        case SET_SELECTED_REFINEMENTS:
            return setSelectedRefinementsReducer(state, action);
        case SET_OPEN_NAVIGATIONS:
            return setOpenNavigationsReducer(state, action);
        case SET_IMAGE_SETTINGS:
            return setImageSettingsReducer(state, action);
        case SET_IS_ANALYTICS_ENABLED:
            // eslint-disable-next-line no-case-declarations
            const setIsAnalyticsEnabledAction = action;
            return Object.assign(Object.assign({}, state), { isAnalyticsEnabled: setIsAnalyticsEnabledAction.payload.isAnalyticsEnabled });
        case SET_EVALUATION_TYPE:
            return setEvaluationTypeReducer(state, action);
        case RESET_STATE:
            return resetStateReducer();
        case RESET_NAVIGATIONS: {
            let newState = state;
            newState = clearSearchResponseReducer(newState);
            newState = resetRequestOptionsReducer(newState);
            newState = setSelectedRefinementsReducer(newState, {
                type: SET_SELECTED_REFINEMENTS,
                payload: { selectedRefinements: [] },
            });
            newState = setOpenNavigationsReducer(newState, {
                type: SET_OPEN_NAVIGATIONS,
                payload: { openNavigations: {} },
            });
            return newState;
        }
        default:
            return state;
    }
};
export const setRequestOptionsReducer = (state, _a) => {
    var _b = _a.payload, { page = 1 } = _b, requestOptions = __rest(_b, ["page"]);
    return (Object.assign(Object.assign({}, state), { requestOptions: Object.assign(Object.assign(Object.assign({}, state.requestOptions), { page }), requestOptions) }));
};
export const resetRequestOptionsReducer = (state) => (Object.assign(Object.assign({}, state), { requestOptions: Object.assign({}, initialValues.requestOptions) }));
export const setSearchResponseReducer = (state, { payload: searchResponse }) => (Object.assign(Object.assign({}, state), { searchResponse }));
export const clearSearchResponseReducer = (state) => {
    const { searchResponse } = state, newState = __rest(state, ["searchResponse"]);
    return newState;
};
export const setIsLoadingReducer = (state, { payload: { isLoading } }) => (Object.assign(Object.assign({}, state), { isLoading }));
export const setSelectedRefinementsReducer = (state, { payload: { selectedRefinements } }) => (Object.assign(Object.assign({}, state), { selectedRefinements }));
export const setOpenNavigationsReducer = (state, { payload: { openNavigations } }) => (Object.assign(Object.assign({}, state), { openNavigations }));
export const setImageSettingsReducer = (state, { payload: imageMetaDataSettings }) => (Object.assign(Object.assign({}, state), { imageSettings: Object.assign(Object.assign({}, state.imageSettings), imageMetaDataSettings) }));
export const setIsAnalyticsEnabledReducer = (state, { payload }) => {
    const { modelType, isAnalyticsEnabled } = payload;
    return Object.assign(Object.assign({}, state), { isAnalyticsEnabled: Object.assign(Object.assign({}, state.isAnalyticsEnabled), { [modelType]: isAnalyticsEnabled }) });
};
export const setEvaluationTypeReducer = (state, { payload: evaluationType }) => (Object.assign(Object.assign({}, state), { evaluationPageType: evaluationType }));
export const resetStateReducer = () => initialValues;

// a list of Permissions that are defined as the type
const MERCHANDISING = 'MERCHANDISING';
const AREA = 'AREA';
export const getUserRoles = (permissions) => {
    const userRoles = [];
    permissions.forEach((permission) => {
        if (permission.startsWith(`${MERCHANDISING}.`)) {
            const userRole = permission.split(`${MERCHANDISING}.`)[1];
            if (!userRole.startsWith(`${AREA}.`)) {
                userRoles.push(userRole);
            }
        }
    });
    if (userRoles.length > 1)
        return '';
    return userRoles[0] || ''; // ensure function never returns undefined
};
export const extractAreaPermissions = (permissions) => {
    const areaPermissions = [];
    permissions.forEach((permission) => {
        const areaPermission = extractAreaPermission(permission);
        if (areaPermission) {
            areaPermissions.push(areaPermission);
        }
    });
    return areaPermissions;
};
export const extractAreaPermission = (permission) => {
    if (permission === null || permission === void 0 ? void 0 : permission.startsWith(`${MERCHANDISING}.${AREA}`)) {
        const areaRegExp = /:([0-9]+)\|/;
        const roleNameRegExp = /AREA\.([A-Z]+):/;
        const areaMatch = permission.match(areaRegExp);
        const roleNameMatch = permission.match(roleNameRegExp);
        if (areaMatch && roleNameMatch) {
            const areaId = parseInt(areaMatch[1], 10);
            const roleName = roleNameMatch[1];
            return { areaId, role: { name: roleName } };
        }
    }
    return null;
};

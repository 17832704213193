import { useDispatch } from 'react-redux';
import * as ActionCreators from '@/store/actions/creators';
import { INACTIVE_SESSION_ERROR, LOGIN_ERROR_KEY } from './constants';
import { useGoToLoginWithNext } from '@/routing/hooks/utils';
import { logError } from '@/utils/logger';
export const useExpireToken = () => {
    const dispatch = useDispatch();
    const goToLoginWithNext = useGoToLoginWithNext();
    return (pathname, search) => {
        try {
            dispatch(ActionCreators.showError(LOGIN_ERROR_KEY, { key: INACTIVE_SESSION_ERROR }));
            dispatch(ActionCreators.clearAuthentication());
            dispatch(ActionCreators.setCollectionName(null));
            goToLoginWithNext(pathname, search);
        }
        catch (error) {
            const errorMessage = 'UNKNOWN_ERROR';
            logError(errorMessage, error);
            dispatch(ActionCreators.addError({ key: errorMessage }));
        }
    };
};

import React, { useRef } from 'react';
import { useDrop } from 'react-dnd';
import { StyledTableRow } from '../table-rows/table-rows.styles';
export const LastDropArea = ({ handleMove, rowCount, children }) => {
    const ref = useRef(null);
    const [{ canDrop, isOver }, drop] = useDrop({
        accept: 'TABLE_DRAGGABLE_ROW',
        drop: (item) => handleMove(item.index, rowCount),
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
            droppingItem: monitor.getItem(),
        }),
    });
    drop(ref);
    return (<React.Fragment>
      <StyledTableRow ref={ref} $isDropping={canDrop && isOver}>
        {children}
      </StyledTableRow>
    </React.Fragment>);
};

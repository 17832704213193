import React from 'react';
import { StyledContainer, StyledPrimaryCircle, StyledStepWrapper } from './no-data-fallback.styles';
export const NoDataFallback = ({ icon, title, instructions, className, actions, }) => (<StyledContainer className={className}>
    {icon && <div className="icon">{icon}</div>}
    <div className="text-block">
      <span className="text-block-title">{title}</span>
      {instructions && (<div className="text-block-instructions">
          {instructions.map((message, index) => (<StyledStepWrapper key={index}>
              <StyledPrimaryCircle>{index + 1}</StyledPrimaryCircle>
              {message}
            </StyledStepWrapper>))}
        </div>)}
      {actions}
    </div>
  </StyledContainer>);

import React, { useEffect, useState } from 'react';
import { ButtonV2, DatePicker } from '@groupby/ui-components';
import Popover from '@mui/material/Popover';
import { endOfDay, startOfDay } from 'date-fns';
import { useLocalization } from '@/localization';
import { StyledDateFilterActionContainer, StyledDateFilterContainer } from './header-date-filter.styles';
export const HeaderDateFilter = ({ open, anchorEl, onClose, startDate, endDate, updateFilter, }) => {
    const { formatMessage: t, getLocale } = useLocalization();
    const [start, setStartDate] = useState(startDate);
    const [end, setEndDate] = useState(endDate);
    const selectedLanguage = getLocale();
    const onChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    };
    const onCancel = (e) => {
        setStartDate(startDate);
        setEndDate(endDate);
        onClose(e);
    };
    const onSave = (e) => {
        updateFilter({
            startAt: Number(startOfDay(start)),
            endAt: Number(endOfDay(end !== null && end !== void 0 ? end : start)),
        });
        onClose(e);
    };
    useEffect(() => {
        setStartDate(startDate);
        setEndDate(endDate);
    }, [
        startDate,
        endDate,
    ]);
    return (<Popover open={open} anchorEl={anchorEl} onClose={onClose} anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
    }} transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
    }}>
      <StyledDateFilterContainer>
        <DatePicker selected={start} onChange={onChange} startDate={start} endDate={end} selectsRange inline locale={selectedLanguage}/>
        <StyledDateFilterActionContainer>
          <ButtonV2 onClick={onCancel}>{t({ key: 'CANCEL' })}</ButtonV2>
          <ButtonV2 onClick={onSave}>{t({ key: 'OK' })}</ButtonV2>
        </StyledDateFilterActionContainer>
      </StyledDateFilterContainer>
    </Popover>);
};

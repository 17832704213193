export var DashboardType;
(function (DashboardType) {
    DashboardType["SEARCH_PERFORMANCE"] = "searchPerformance";
    DashboardType["API_USAGE"] = "apiUsage";
    DashboardType["API_RESPONSE_TIME"] = "apiResponseTime";
    DashboardType["RECORDS"] = "records";
    DashboardType["SEARCH_TERM"] = "searchTerm";
    DashboardType["SEARCH_TERM_ANALYSIS"] = "searchTermAnalysis";
    DashboardType["NAVIGATION_ENGAGEMENTS"] = "navigationEngagements";
    DashboardType["NULL_SEARCH_ANALYTICS"] = "nullSearchAnalytics";
    DashboardType["RULE_ANALYTICS"] = "ruleAnalytics";
    DashboardType["BIASING_PROFILE"] = "biasingProfile";
})(DashboardType || (DashboardType = {}));

import React from 'react';
import { SearchGraphic } from '@groupby/ui-components';
import { StyledNoResultsContainer } from './no-results.styles';
import { useLocalization } from '@/localization';
export const NoResults = ({ messageKey, className }) => {
    const { formatMessage: t } = useLocalization();
    return (<StyledNoResultsContainer className={className}>
      <div><SearchGraphic /></div>
      <div>{t({ key: messageKey || 'NO_DATA_AVAILABLE' })}</div>
    </StyledNoResultsContainer>);
};
export default NoResults;

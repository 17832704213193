import classNames from 'classnames';
/**
 * Merge two objects of classes into a new object. Any conflicting keys are
 * joined with spaces to ensure all provided classes are present.

 * @param classes1 The first object of classes.
 * @param classes2 The second object of classes.
 * @returns Returns a new object containing all keys and values from both arguments.
 */
export const mergeClasses = (...classesList) => {
    const mergedClasses = {};
    classesList.forEach((classes) => {
        if (classes === null || classes === undefined)
            return;
        Object.keys(classes).forEach((key) => {
            mergedClasses[key] = classNames(mergedClasses[key], classes[key]);
        });
    });
    return mergedClasses;
};

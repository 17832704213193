import { __rest } from "tslib";
import React from 'react';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import classNames from 'classnames';
import { StyledListItem } from './navigation-list-item.styles';
export const NavigationListItem = (_a) => {
    var { text, icon, active, hasSubNav, disabled, key } = _a, props = __rest(_a, ["text", "icon", "active", "hasSubNav", "disabled", "key"]);
    return (<StyledListItem key={key} className={classNames('list-item', { active })} disabled={disabled} {...props}>
    {icon && <ListItemIcon>{icon}</ListItemIcon>}
    <ListItemText primary={text}/>
  </StyledListItem>);
};

import { __rest } from "tslib";
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
export const TeamIllustration = (_a) => {
    var { fill = 'currentColor' } = _a, props = __rest(_a, ["fill"]);
    return (<SvgIcon width="73" height="81" viewBox="0 0 73 81" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M27.76 28.71C35.492 28.71 41.76 22.4419 41.76 14.71C41.76 6.97797 35.492 0.709961 27.76 0.709961C20.028 0.709961 13.76 6.97797 13.76 14.71C13.76 22.4419 20.028 28.71 27.76 28.71Z" fill="#E2E6EC"/>
    <path d="M12.85 62.8896C19.4774 62.8896 24.85 57.5171 24.85 50.8896C24.85 44.2622 19.4774 38.8896 12.85 38.8896C6.22256 38.8896 0.849976 44.2622 0.849976 50.8896C0.849976 57.5171 6.22256 62.8896 12.85 62.8896Z" fill="#E2E6EC"/>
    <path d="M52.85 80.46C63.8957 80.46 72.85 71.5057 72.85 60.46C72.85 49.4143 63.8957 40.46 52.85 40.46C41.8043 40.46 32.85 49.4143 32.85 60.46C32.85 71.5057 41.8043 80.46 52.85 80.46Z" fill="#E2E6EC"/>
    <path d="M61.85 65.46H5.84998V72.46H61.85V65.46Z" fill="#C3CEFF"/>
    <path d="M5.84998 65.46H57.85V68.46C57.85 69.01 57.4 69.46 56.85 69.46H5.84998V65.46Z" fill="white"/>
    <path d="M61.85 73.21H5.84998C5.43998 73.21 5.09998 72.87 5.09998 72.46V65.46C5.09998 65.05 5.43998 64.71 5.84998 64.71H61.85C62.26 64.71 62.6 65.05 62.6 65.46V72.46C62.6 72.87 62.26 73.21 61.85 73.21ZM6.59998 71.71H61.1V66.21H6.59998V71.71Z" fill="#081E42"/>
    <path d="M65.85 65.46H1.84998L9.84998 56.46H57.85L65.85 65.46Z" fill="#C3CEFF"/>
    <path d="M57.74 62.46H4.52002L9.84998 56.46H53.52L58.11 61.63C58.4 61.95 58.17 62.46 57.74 62.46Z" fill="white"/>
    <path d="M65.85 66.21H1.84995C1.54995 66.21 1.29002 66.04 1.17002 65.77C1.05002 65.5 1.10001 65.18 1.29001 64.96L9.29001 55.96C9.43001 55.8 9.63995 55.71 9.84995 55.71H57.8499C58.0599 55.71 58.27 55.8 58.41 55.96L66.41 64.96C66.61 65.18 66.65 65.5 66.53 65.77C66.41 66.04 66.14 66.21 65.85 66.21ZM3.51999 64.71H64.18L57.51 57.21H10.1899L3.51999 64.71Z" fill="#081E42"/>
    <path d="M32.5599 48.8903L29 47.9502H20.72L17.16 48.8903C15.81 49.2403 14.86 50.4702 14.86 51.8702V56.4602H34.86V51.8702C34.86 50.4702 33.9199 49.2503 32.5599 48.8903Z" fill="white"/>
    <path d="M33.27 49.1897C31.76 51.4397 29.24 53.2997 26.09 54.1897C21.74 55.4297 17.42 54.4797 14.85 52.0597V56.4497H34.85V51.8597C34.85 50.7297 34.22 49.7197 33.27 49.1797V49.1897Z" fill="#C3CEFF"/>
    <path d="M34.85 57.21H14.85C14.44 57.21 14.1 56.87 14.1 56.46V51.87C14.1 50.13 15.27 48.6099 16.96 48.1699L20.52 47.23C20.58 47.21 20.65 47.21 20.71 47.21H28.99C29.05 47.21 29.1201 47.21 29.1801 47.23L32.74 48.1699C34.42 48.6099 35.59 50.13 35.59 51.87V56.46C35.59 56.87 35.25 57.21 34.84 57.21H34.85ZM15.6 55.71H34.1V51.87C34.1 50.81 33.39 49.89 32.36 49.62L28.89 48.71H20.8L17.33 49.62C16.31 49.89 15.59 50.81 15.59 51.87V55.71H15.6Z" fill="#081E42"/>
    <path d="M24.85 52.8502C24.85 52.8502 28.96 49.6302 28.99 47.9602C28.12 47.5102 27.46 46.7002 27.28 45.7202C27.25 45.5802 27.23 44.6703 27.22 44.5303H22.48C22.46 44.6903 22.44 45.6302 22.4 45.7902C22.21 46.7502 21.57 47.5402 20.71 47.9602C20.75 49.6302 24.85 52.8502 24.85 52.8502Z" fill="white"/>
    <path d="M27.8199 50.0604C28.4699 49.3104 28.98 48.5404 28.99 47.9504C28.12 47.5004 27.46 46.6904 27.28 45.7104C27.25 45.5704 27.23 44.6605 27.22 44.5205H24.97C25 44.9405 25.0299 46.1705 25.0699 46.3805C25.3699 47.9805 26.42 49.3004 27.83 50.0504L27.8199 50.0604Z" fill="#C3CEFF"/>
    <path d="M24.85 53.6002C24.69 53.6002 24.52 53.5502 24.39 53.4402C23.36 52.6302 20.01 49.8502 19.96 47.9702C19.96 47.6802 20.12 47.4103 20.38 47.2803C21.06 46.9503 21.53 46.3503 21.67 45.6403C21.68 45.5403 21.7 45.2302 21.71 44.9602C21.72 44.7202 21.73 44.5102 21.74 44.4402C21.78 44.0602 22.1 43.7803 22.48 43.7803H27.22C27.6 43.7803 27.92 44.0702 27.97 44.4502C27.97 44.5102 27.99 44.7202 28 44.9602C28 45.2102 28.02 45.5102 28.03 45.6102C28.16 46.3102 28.65 46.9502 29.34 47.3002C29.59 47.4302 29.75 47.6902 29.75 47.9802C29.72 49.8602 26.3499 52.6502 25.3199 53.4502C25.1799 53.5602 25.02 53.6102 24.86 53.6102L24.85 53.6002ZM21.5599 48.3202C21.9499 49.1902 23.39 50.6803 24.85 51.8903C26.31 50.6803 27.76 49.1902 28.14 48.3202C27.31 47.7302 26.72 46.8402 26.54 45.8602C26.53 45.7902 26.51 45.5403 26.5 45.2803H23.1899C23.1699 45.5703 23.16 45.8502 23.14 45.9402C22.95 46.9202 22.3899 47.7602 21.5699 48.3302L21.5599 48.3202Z" fill="#081E42"/>
    <path d="M29.66 40.46C29.66 37.27 27.51 35.46 24.85 35.46C22.19 35.46 20.04 37.27 20.04 40.46C20.04 43.65 22.19 46.23 24.85 46.23C27.51 46.23 29.66 43.65 29.66 40.46Z" fill="white"/>
    <path d="M26.01 35.5801C26.38 36.2901 26.59 37.1501 26.59 38.1501C26.59 41.3401 24.44 43.92 21.78 43.92C21.5 43.92 21.23 43.8901 20.96 43.8301C21.83 45.2801 23.25 46.2301 24.86 46.2301C27.52 46.2301 29.67 43.6501 29.67 40.4601C29.67 37.7501 28.11 36.0401 26.01 35.5801Z" fill="#C3CEFF"/>
    <path d="M24.85 46.98C21.78 46.98 19.29 44.06 19.29 40.46C19.29 37.07 21.58 34.71 24.85 34.71C28.12 34.71 30.41 37.07 30.41 40.46C30.41 44.05 27.92 46.98 24.85 46.98ZM24.85 36.21C22.38 36.21 20.79 37.88 20.79 40.46C20.79 43.23 22.61 45.48 24.85 45.48C27.09 45.48 28.91 43.23 28.91 40.46C28.91 37.88 27.32 36.21 24.85 36.21Z" fill="#081E42"/>
    <path d="M50.55 46.8903L46.99 45.9502H38.71L35.15 46.8903C33.8 47.2403 32.85 48.4702 32.85 49.8702V56.4602H52.85V49.8702C52.85 48.4702 51.91 47.2503 50.55 46.8903Z" fill="white"/>
    <path d="M52.85 49.8702C52.85 48.4702 51.91 47.2503 50.55 46.8903L50.12 46.7803C49.96 49.7103 48.07 54.5702 45.11 56.4602H52.84V49.8702H52.85Z" fill="#C3CEFF"/>
    <path d="M52.85 57.21H32.85C32.44 57.21 32.1 56.87 32.1 56.46V49.87C32.1 48.13 33.27 46.6099 34.96 46.1699L38.52 45.23C38.58 45.21 38.65 45.21 38.71 45.21H46.99C47.05 45.21 47.1201 45.21 47.1801 45.23L50.74 46.1699C52.42 46.6099 53.59 48.13 53.59 49.87V56.46C53.59 56.87 53.25 57.21 52.84 57.21H52.85ZM33.6 55.71H52.1V49.87C52.1 48.81 51.39 47.89 50.36 47.62L46.89 46.71H38.8L35.33 47.62C34.31 47.89 33.59 48.81 33.59 49.87V55.71H33.6Z" fill="#081E42"/>
    <path d="M42.85 48.8502C42.85 48.8502 46.96 47.6302 46.99 45.9602C46.12 45.5102 45.46 44.7002 45.28 43.7202C45.25 43.5802 45.23 42.6703 45.22 42.5303H40.48C40.46 42.6903 40.44 43.6302 40.4 43.7902C40.21 44.7502 39.57 45.5402 38.71 45.9602C38.75 47.6302 42.85 48.8502 42.85 48.8502Z" fill="white"/>
    <path d="M46.99 45.9602C46.12 45.5102 45.46 44.7002 45.28 43.7202C45.25 43.5802 45.23 42.6703 45.22 42.5303L42.95 42.9402C42.97 43.1402 43 44.4602 43.04 44.6702C43.29 46.0202 44.17 47.1502 45.34 47.8102C46.21 47.3202 46.98 46.6802 46.99 45.9602Z" fill="#C3CEFF"/>
    <path d="M42.85 49.6002C42.78 49.6002 42.71 49.5902 42.64 49.5702C41.87 49.3402 38.01 48.0802 37.96 45.9702C37.96 45.6802 38.12 45.4103 38.38 45.2803C39.06 44.9503 39.53 44.3503 39.67 43.6403C39.68 43.5403 39.7 43.2302 39.71 42.9602C39.72 42.7202 39.73 42.5102 39.74 42.4402C39.78 42.0602 40.1 41.7803 40.48 41.7803H45.22C45.6 41.7803 45.92 42.0702 45.97 42.4502C45.97 42.5102 45.99 42.7202 46 42.9602C46 43.2102 46.02 43.5102 46.03 43.6102C46.16 44.3102 46.65 44.9502 47.34 45.3002C47.59 45.4302 47.75 45.6902 47.75 45.9802C47.72 48.1002 43.8499 49.3502 43.0699 49.5802C42.9999 49.6002 42.93 49.6102 42.86 49.6102L42.85 49.6002ZM39.61 46.2902C40.07 46.8902 41.5 47.6302 42.85 48.0602C44.21 47.6202 45.65 46.8803 46.1 46.2803C45.29 45.6903 44.72 44.8102 44.54 43.8502C44.53 43.7802 44.51 43.5303 44.5 43.2703H41.1899C41.1699 43.5603 41.16 43.8402 41.14 43.9302C40.95 44.8902 40.41 45.7202 39.61 46.2902Z" fill="#081E42"/>
    <path d="M47.66 38.46C47.66 35.27 45.51 33.46 42.85 33.46C40.19 33.46 38.04 35.27 38.04 38.46C38.04 41.65 40.19 44.23 42.85 44.23C45.51 44.23 47.66 41.65 47.66 38.46Z" fill="white"/>
    <path d="M44 33.5801C44.37 34.2901 44.58 35.1501 44.58 36.1501C44.58 39.3401 42.43 41.92 39.77 41.92C39.49 41.92 39.22 41.8901 38.95 41.8301C39.82 43.2801 41.24 44.2301 42.85 44.2301C45.51 44.2301 47.66 41.6501 47.66 38.4601C47.66 35.7501 46.1 34.0401 44 33.5801Z" fill="#C3CEFF"/>
    <path d="M42.85 44.98C39.79 44.98 37.29 42.06 37.29 38.46C37.29 35.07 39.58 32.71 42.85 32.71C46.12 32.71 48.41 35.07 48.41 38.46C48.41 42.05 45.92 44.98 42.85 44.98ZM42.85 34.21C40.38 34.21 38.79 35.88 38.79 38.46C38.79 41.23 40.61 43.48 42.85 43.48C45.09 43.48 46.91 41.23 46.91 38.46C46.91 35.88 45.32 34.21 42.85 34.21Z" fill="#081E42"/>
    <path d="M42.85 57.2103C42.44 57.2103 42.1 56.8703 42.1 56.4603V48.8604C42.1 48.4504 42.44 48.1104 42.85 48.1104C43.26 48.1104 43.6 48.4504 43.6 48.8604V56.4603C43.6 56.8703 43.26 57.2103 42.85 57.2103Z" fill="#081E42"/>
    <path d="M7.84998 14.46V28.46C7.84998 29.56 8.74998 30.46 9.84998 30.46H13.85L12.85 36.46L21.85 30.46H33.85C34.95 30.46 35.85 29.56 35.85 28.46V14.46C35.85 13.36 34.95 12.46 33.85 12.46H9.84998C8.74998 12.46 7.84998 13.36 7.84998 14.46Z" fill="#C3CEFF"/>
    <path d="M7.84998 14.46V28.46C7.84998 29.56 8.74998 30.46 9.84998 30.46H13.85L13.54 32.33L20.85 27.46H26.85C27.95 27.46 28.85 26.56 28.85 25.46V12.46H9.84998C8.74998 12.46 7.84998 13.36 7.84998 14.46Z" fill="white"/>
    <path d="M12.85 37.2002C12.71 37.2002 12.5699 37.1602 12.4399 37.0802C12.1899 36.9202 12.06 36.6202 12.11 36.3302L12.96 31.2002H9.83997C8.31997 31.2002 7.08997 29.9702 7.08997 28.4502V14.4502C7.08997 12.9302 8.31997 11.7002 9.83997 11.7002H33.84C35.36 11.7002 36.59 12.9302 36.59 14.4502V28.4502C36.59 29.9702 35.36 31.2002 33.84 31.2002H22.0699L13.26 37.0702C13.13 37.1502 12.99 37.2002 12.84 37.2002H12.85ZM9.84998 13.2002C9.15998 13.2002 8.59998 13.7602 8.59998 14.4502V28.4502C8.59998 29.1402 9.15998 29.7002 9.84998 29.7002H13.85C14.07 29.7002 14.28 29.8002 14.42 29.9702C14.56 30.1402 14.62 30.3602 14.59 30.5802L13.87 34.8702L21.4301 29.8302C21.5501 29.7502 21.7 29.7002 21.85 29.7002H33.85C34.54 29.7002 35.1 29.1402 35.1 28.4502V14.4502C35.1 13.7602 34.54 13.2002 33.85 13.2002H9.84998Z" fill="#081E42"/>
    <path d="M14.23 20.21H12.85C12.44 20.21 12.1 19.87 12.1 19.46C12.1 19.05 12.44 18.71 12.85 18.71H14.23C14.64 18.71 14.98 19.05 14.98 19.46C14.98 19.87 14.64 20.21 14.23 20.21Z" fill="#081E42"/>
    <path d="M18.85 20.21H17.47C17.06 20.21 16.72 19.87 16.72 19.46C16.72 19.05 17.06 18.71 17.47 18.71H18.85C19.26 18.71 19.6 19.05 19.6 19.46C19.6 19.87 19.26 20.21 18.85 20.21Z" fill="#081E42"/>
    <path d="M15.85 23.2101C15.44 23.2101 15.1 22.8701 15.1 22.4601V21.0801C15.1 20.6701 15.44 20.3301 15.85 20.3301C16.26 20.3301 16.6 20.6701 16.6 21.0801V22.4601C16.6 22.8701 16.26 23.2101 15.85 23.2101Z" fill="#081E42"/>
    <path d="M15.85 18.59C15.44 18.59 15.1 18.25 15.1 17.84V16.46C15.1 16.05 15.44 15.71 15.85 15.71C16.26 15.71 16.6 16.05 16.6 16.46V17.84C16.6 18.25 16.26 18.59 15.85 18.59Z" fill="#081E42"/>
    <path d="M24.23 25.21H22.85C22.44 25.21 22.1 24.87 22.1 24.46C22.1 24.05 22.44 23.71 22.85 23.71H24.23C24.64 23.71 24.98 24.05 24.98 24.46C24.98 24.87 24.64 25.21 24.23 25.21Z" fill="#081E42"/>
    <path d="M28.85 25.21H27.47C27.06 25.21 26.72 24.87 26.72 24.46C26.72 24.05 27.06 23.71 27.47 23.71H28.85C29.26 23.71 29.6 24.05 29.6 24.46C29.6 24.87 29.26 25.21 28.85 25.21Z" fill="#081E42"/>
    <path d="M25.85 28.2101C25.44 28.2101 25.1 27.8701 25.1 27.4601V26.0801C25.1 25.6701 25.44 25.3301 25.85 25.3301C26.26 25.3301 26.6 25.6701 26.6 26.0801V27.4601C26.6 27.8701 26.26 28.2101 25.85 28.2101Z" fill="#081E42"/>
    <path d="M25.85 23.59C25.44 23.59 25.1 23.25 25.1 22.84V21.46C25.1 21.05 25.44 20.71 25.85 20.71C26.26 20.71 26.6 21.05 26.6 21.46V22.84C26.6 23.25 26.26 23.59 25.85 23.59Z" fill="#081E42"/>
    <path d="M31.85 10.4502V24.4502C31.85 25.5502 32.75 26.4502 33.85 26.4502H45.85L54.85 32.4502L53.85 26.4502H57.85C58.95 26.4502 59.85 25.5502 59.85 24.4502V10.4502C59.85 9.3502 58.95 8.4502 57.85 8.4502H33.85C32.75 8.4502 31.85 9.3502 31.85 10.4502Z" fill="#C3CEFF"/>
    <path d="M31.85 10.4502V23.4502H54.85C55.95 23.4502 56.85 22.5502 56.85 21.4502V8.4502H33.85C32.75 8.4502 31.85 9.3502 31.85 10.4502Z" fill="white"/>
    <path d="M54.85 33.2002C54.7 33.2002 54.5601 33.1602 54.4301 33.0702L45.62 27.2002H33.85C32.33 27.2002 31.1 25.9702 31.1 24.4502V10.4502C31.1 8.9302 32.33 7.7002 33.85 7.7002H57.85C59.37 7.7002 60.6 8.9302 60.6 10.4502V24.4502C60.6 25.9702 59.37 27.2002 57.85 27.2002H54.73L55.58 32.3302C55.63 32.6202 55.5 32.9202 55.25 33.0802C55.13 33.1602 54.98 33.2002 54.84 33.2002H54.85ZM33.85 9.2002C33.16 9.2002 32.6 9.7602 32.6 10.4502V24.4502C32.6 25.1402 33.16 25.7002 33.85 25.7002H45.85C46 25.7002 46.14 25.7402 46.27 25.8302L53.83 30.8702L53.11 26.5802C53.07 26.3602 53.13 26.1402 53.28 25.9702C53.42 25.8002 53.63 25.7002 53.85 25.7002H57.85C58.54 25.7002 59.1 25.1402 59.1 24.4502V10.4502C59.1 9.7602 58.54 9.2002 57.85 9.2002H33.85Z" fill="#081E42"/>
    <path d="M50.24 16.2002H48.86C48.45 16.2002 48.11 15.8602 48.11 15.4502C48.11 15.0402 48.45 14.7002 48.86 14.7002H50.24C50.65 14.7002 50.99 15.0402 50.99 15.4502C50.99 15.8602 50.65 16.2002 50.24 16.2002Z" fill="#081E42"/>
    <path d="M54.85 16.2002H53.47C53.06 16.2002 52.72 15.8602 52.72 15.4502C52.72 15.0402 53.06 14.7002 53.47 14.7002H54.85C55.26 14.7002 55.6 15.0402 55.6 15.4502C55.6 15.8602 55.26 16.2002 54.85 16.2002Z" fill="#081E42"/>
    <path d="M51.85 19.2003C51.44 19.2003 51.1 18.8603 51.1 18.4503V17.0703C51.1 16.6603 51.44 16.3203 51.85 16.3203C52.26 16.3203 52.6 16.6603 52.6 17.0703V18.4503C52.6 18.8603 52.26 19.2003 51.85 19.2003Z" fill="#081E42"/>
    <path d="M51.85 14.59C51.44 14.59 51.1 14.25 51.1 13.84V12.46C51.1 12.05 51.44 11.71 51.85 11.71C52.26 11.71 52.6 12.05 52.6 12.46V13.84C52.6 14.25 52.26 14.59 51.85 14.59Z" fill="#081E42"/>
    <path d="M39.24 19.21H37.86C37.45 19.21 37.11 18.87 37.11 18.46C37.11 18.05 37.45 17.71 37.86 17.71H39.24C39.65 17.71 39.99 18.05 39.99 18.46C39.99 18.87 39.65 19.21 39.24 19.21Z" fill="#081E42"/>
    <path d="M43.85 19.21H42.47C42.06 19.21 41.72 18.87 41.72 18.46C41.72 18.05 42.06 17.71 42.47 17.71H43.85C44.26 17.71 44.6 18.05 44.6 18.46C44.6 18.87 44.26 19.21 43.85 19.21Z" fill="#081E42"/>
    <path d="M40.85 22.2101C40.44 22.2101 40.1 21.8701 40.1 21.4601V20.0801C40.1 19.6701 40.44 19.3301 40.85 19.3301C41.26 19.3301 41.6 19.6701 41.6 20.0801V21.4601C41.6 21.8701 41.26 22.2101 40.85 22.2101Z" fill="#081E42"/>
    <path d="M40.85 17.59C40.44 17.59 40.1 17.25 40.1 16.84V15.46C40.1 15.05 40.44 14.71 40.85 14.71C41.26 14.71 41.6 15.05 41.6 15.46V16.84C41.6 17.25 41.26 17.59 40.85 17.59Z" fill="#081E42"/>
  </SvgIcon>);
};
export default TeamIllustration;

import { Auth0ClientIdMap } from './constants';
import { isLowerEnvironment, isLocalhost } from '@/utils';
export const constructAuth0Domain = (region) => {
    const LOWER_ENV_SUBDOMAIN = 'groupbycloud-dev';
    const PRODUCTION_SUBDOMAIN = 'groupbycloud';
    return isLowerEnvironment() || isLocalhost()
        ? `${LOWER_ENV_SUBDOMAIN}.${region}.auth0.com`
        : `${PRODUCTION_SUBDOMAIN}.${region}.auth0.com`;
};
export const getTenantClientIdByRegion = (region) => Auth0ClientIdMap[region];
export var UrlParams;
(function (UrlParams) {
    UrlParams["CUSTOMER_ID"] = "customerId";
    UrlParams["EMAIL"] = "email";
    UrlParams["CONNECTION"] = "connection";
})(UrlParams || (UrlParams = {}));
// Construct `audience` dynamically with TARGET_CUSTOMER. Localhost is hard coded to 'gbiqa'
// export const getAuth0Audience = () => `https://cc.${TARGET_CUSTOMER}.groupbycloud.com`;
export const getAuth0Audience = (tenant) => `https://cc.${tenant}.groupbycloud.com`;
export const getLoginQueryParams = (queryString = window.location.search) => {
    const urlParams = new URLSearchParams(queryString);
    const customerId = urlParams.get(UrlParams.CUSTOMER_ID);
    const email = urlParams.get(UrlParams.EMAIL);
    const connection = urlParams.get(UrlParams.CONNECTION);
    return {
        customerId,
        email,
        connection,
    };
};

import React from 'react';
import { StyledDrawer, StyledNavigationDrawerList, StyledNavigationDrawerSection } from './navigation-drawer.styles';
import { useNavigation } from '@/navigation/navigation.provider';
import { PrimaryDrawerRouteList } from './partials/primary';
import { FeatureFlag, useIsFeatureFlagEnabled } from '@/search-for-retail/feature-flags';
export const NavigationDrawer = ({ onItemClick }) => {
    const { isDrawerOpen } = useNavigation();
    const enableNewBanner = useIsFeatureFlagEnabled(FeatureFlag.enableNewBanner);
    return (<StyledNavigationDrawerSection id="drawer--navigation" $enableNewBanner={enableNewBanner}>
      <StyledDrawer id="drawer__desktop" className={isDrawerOpen ? 'expanded' : 'collapsed'} variant="permanent" transitionDuration={500}>
        <StyledNavigationDrawerList>
          <PrimaryDrawerRouteList onItemClick={onItemClick}/>
        </StyledNavigationDrawerList>
      </StyledDrawer>
    </StyledNavigationDrawerSection>);
};

import styled from 'styled-components';
import { baseColors } from '@groupby/ui-components';
export const StyledDrawerSectionWrapper = styled.div `
  background: ${baseColors.white10};
  height: ${({ theme }) => `calc(100% - ${theme.appHeader.height})`};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
`;
StyledDrawerSectionWrapper.displayName = 'StyledDrawerSectionWrapper';
export const StyledDrawerContent = styled.div `
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  overflow-y: auto;
`;
export const StyledDrawerCategoryLabel = styled.div `
  cursor: default;
  padding: 1.625rem 1rem 0.5rem;
  color ${baseColors.primaryBlueMed400};
  font-size: 0.75rem;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1.2rem;
`;

import { __rest } from "tslib";
import React from 'react';
import ListItemText from '@mui/material/ListItemText';
import classNames from 'classnames';
import { StyledDrawerItem, StyledNavigationDrawerSublinkItem, StyledVerticalRule } from './drawer-sublink-item.styles';
export const NavigationDrawerSublinkItem = (_a) => {
    var { text, active, badge, level, disabled } = _a, props = __rest(_a, ["text", "active", "badge", "level", "disabled"]);
    return (<StyledDrawerItem>
    <StyledNavigationDrawerSublinkItem dense disabled={disabled} className={classNames('list-item', { active, 'with-badge': !!badge })} $level={level} {...props}>
      {[...Array(level)].map((_, index) => (index > 0 && <StyledVerticalRule className="level-border" $level={index} key={index}/>))}
      <ListItemText primary={text}/>
      {badge}
    </StyledNavigationDrawerSublinkItem>
  </StyledDrawerItem>);
};
export default NavigationDrawerSublinkItem;

import { __rest } from "tslib";
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
export const InQueueIcon = (_a) => {
    var { width = 24, height = 24 } = _a, props = __rest(_a, ["width", "height"]);
    return (<SvgIcon width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M18 22L6 22C4.34315 22 3 20.6569 3 19V5C3 3.34314 4.34315 2 6 2H12H15.0417C15.9147 2 16.7444 2.38028 17.3143 3.04161L18.5969 4.53001L20.245 6.38947C20.7314 6.93822 21 7.64611 21 8.37939L21 12V19C21 20.6569 19.6569 22 18 22ZM17.0909 5.84616L16 4.58022V7H18.1136L17.0909 5.84616ZM14 8V4L12 4L6 4C5.44772 4 5 4.44772 5 5L5 19C5 19.5523 5.44772 20 6 20L18 20C18.5523 20 19 19.5523 19 19L19 12V9H15C14.4477 9 14 8.55229 14 8ZM11.5 10C11.5 9.44772 11.0523 9 10.5 9C9.94772 9 9.5 9.44772 9.5 10V14C9.5 14.3344 9.6671 14.6466 9.9453 14.8321L12.9453 16.8321C13.4048 17.1384 14.0257 17.0142 14.3321 16.5547C14.6384 16.0952 14.5142 15.4743 14.0547 15.1679L11.5 13.4648V10Z" fill="currentColor"/>
  </SvgIcon>);
};
export default InQueueIcon;

import { useEffect } from 'react';
import { always } from 'ramda';
import { highlightTarget } from './use-table-highlighting.hook';
export const useRowHighlighting = ({ targetRef, className, scrollToHighlightedItem, shouldRowBeHighlighted = always(false), value, compareValue, resetFunction, }) => {
    useEffect(() => {
        const shouldBeHighlighted = shouldRowBeHighlighted === null || shouldRowBeHighlighted === void 0 ? void 0 : shouldRowBeHighlighted(value, compareValue);
        if (!shouldBeHighlighted)
            return;
        const target = targetRef.current;
        if (target) {
            const { highlightingTimeoutId, shadowTimeoutId } = highlightTarget({
                target,
                className,
                resetFunction,
                scrollToHighlightedItem,
            });
            return () => {
                clearTimeout(highlightingTimeoutId);
                clearTimeout(shadowTimeoutId);
            };
        }
    }, [className, scrollToHighlightedItem, shouldRowBeHighlighted, targetRef, value, compareValue, resetFunction]);
};
export default useRowHighlighting;

import { ModelType } from '@/constants';
export const AUDIT_EVENT_TYPE_TO_MODEL_TYPE = {
    AREA: ModelType.AREA,
    BIASING_PROFILE: ModelType.BIASING_PROFILE,
    NAVIGATION: ModelType.NAVIGATION,
    PROJECT_CONFIGURATION: ModelType.PROJECT_CONFIGURATION,
    REDIRECT: ModelType.REDIRECT,
    RULE: ModelType.RULE,
    TAG: ModelType.TAG,
    TEMPLATE: ModelType.TEMPLATE,
    TENANT: ModelType.TENANT,
    ZONE: ModelType.ZONE,
    FILTER_SET: ModelType.FILTER_SETS,
    PRODUCT_RECOMMENDATIONS: ModelType.PRODUCT_RECOMMENDATIONS,
    PLACEMENT: ModelType.PLACEMENT,
};
export const MODEL_TYPE_TO_AUDIT_EVENT_TYPE = Object.keys(AUDIT_EVENT_TYPE_TO_MODEL_TYPE).reduce((agg, auditEventType) => {
    agg[AUDIT_EVENT_TYPE_TO_MODEL_TYPE[auditEventType]] = auditEventType;
    return agg;
}, {});
export const AUDIT_EVENT_ACTION = {
    CREATE: 'CREATE',
    UPDATE: 'UPDATE',
    DELETE: 'DELETE',
    PROMOTE: 'PROMOTE',
    SINGLE_PROMOTE: 'SINGLE_PROMOTE',
    CHANGE_PRIORITY: 'CHANGE_PRIORITY',
};

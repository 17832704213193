import React, { useEffect, useMemo, useState } from 'react';
import { debounce } from 'debounce';
import { append, assoc, assocPath, isEmpty, remove, update, } from 'ramda';
import { baseColors, DatePicker, Icon } from '@groupby/ui-components';
import { Grid, InputAdornment } from '@mui/material';
import { useLocalization } from '@/localization';
import { AdvancedFilterBy, AdvancedFilterOption, } from '../table-filter.models';
import { ItemWrapper, StyledAdvancedFilter, StyledAdvancedFilters, StyledInput, } from './advanced-filters.styles';
const DEFAULT_ADVANCED_FILTER = {
    filterBy: AdvancedFilterBy.TAG,
    filterOption: AdvancedFilterOption.STARTS_WITH,
    filterValue: '',
};
const FILTERS_BY_STRING = [AdvancedFilterBy.TAG, AdvancedFilterBy.NAME];
export const AdvancedFilters = ({ updateFilter, resetFilter, onAdvancedFiltersIsEmpty = () => { }, hasAndOrToggle = false, className, }) => {
    const [advancedFilters, setAdvancedFilters] = useState([DEFAULT_ADVANCED_FILTER]);
    const { formatMessage: t, getLocale } = useLocalization();
    const selectedLanguage = getLocale();
    const debouncedUpdateFilter = useMemo(() => debounce(updateFilter, 250), [updateFilter]);
    const addAdvancedFilter = () => setAdvancedFilters(append(DEFAULT_ADVANCED_FILTER));
    const removeAdvancedFilter = (index) => setAdvancedFilters(remove(index, 1));
    const setAdvancedFilterByIndex = (index, value) => setAdvancedFilters(update(index, value));
    const handleChangeFilterBy = (index) => (newValue) => (FILTERS_BY_STRING.includes(newValue)
        ? setAdvancedFilterByIndex(index, {
            filterBy: newValue,
            filterOption: AdvancedFilterOption.STARTS_WITH,
            filterValue: null,
        })
        : setAdvancedFilterByIndex(index, {
            filterBy: newValue,
            filterOption: AdvancedFilterOption.BEFORE,
            filterValue: [],
        }));
    const handleChangeFilterOption = (index, prevAdvancedFilter) => (newValue) => (FILTERS_BY_STRING.includes(prevAdvancedFilter.filterBy)
        ? setAdvancedFilterByIndex(index, assoc('filterOption', newValue, prevAdvancedFilter))
        : setAdvancedFilterByIndex(index, Object.assign(Object.assign({}, prevAdvancedFilter), { filterOption: newValue, filterValue: [] })));
    useEffect(() => {
        if (isEmpty(advancedFilters)) {
            onAdvancedFiltersIsEmpty();
        }
        debouncedUpdateFilter({ advancedFilters });
    }, [advancedFilters, debouncedUpdateFilter, onAdvancedFiltersIsEmpty]);
    useEffect(() => () => resetFilter(['advancedFilters']), [resetFilter]);
    return (<StyledAdvancedFilters className={className}>
      {advancedFilters.map((advancedFilter, index) => {
        var _a, _b;
        const isFilterByString = FILTERS_BY_STRING.includes(advancedFilter.filterBy);
        const isAndOrShown = (advancedFilters === null || advancedFilters === void 0 ? void 0 : advancedFilters.length) > 1 && index !== ((_a = advancedFilters === null || advancedFilters === void 0 ? void 0 : advancedFilters.length) !== null && _a !== void 0 ? _a : 0) - 1;
        return isFilterByString ? (<StyledAdvancedFilter key={index} hasAndOrToggle={hasAndOrToggle} isAndOrShown={isAndOrShown} isAddButtonShown={index === advancedFilters.length - 1} selectedFilterBy={advancedFilter.filterBy} onChangeFilterBy={handleChangeFilterBy(index)} filterOptionOptions={[
            { label: t({ key: 'STARTS_WITH' }), value: AdvancedFilterOption.STARTS_WITH },
            { label: t({ key: 'ENDS_WITH' }), value: AdvancedFilterOption.ENDS_WITH },
            { label: t({ key: 'CONTAINS' }), value: AdvancedFilterOption.CONTAINS },
        ]} selectedFilterOption={advancedFilter.filterOption} onChangeFilterOption={handleChangeFilterOption(index, advancedFilter)} filterInput={<Grid item xs={4}>
                <ItemWrapper>
                  <StyledInput value={(_b = advancedFilter.filterValue) !== null && _b !== void 0 ? _b : ''} placeholder={t({ key: 'TEXT' })} onChange={({ target }) => setAdvancedFilterByIndex(index, assoc('filterValue', target.value, advancedFilter))} endAdornment={<InputAdornment position="end">
                        <Icon variant="cancel" color={baseColors.primaryBlueMed500} onClick={() => setAdvancedFilterByIndex(index, assoc('filterValue', '', advancedFilter))} className="delete-icon"/>
                      </InputAdornment>}/>
                </ItemWrapper>
              </Grid>} addAdvancedFilter={addAdvancedFilter} removeAdvancedFilter={() => removeAdvancedFilter(index)}/>) : (<StyledAdvancedFilter key={index} hasAndOrToggle={hasAndOrToggle} isAndOrShown={isAndOrShown} isAddButtonShown={index === advancedFilters.length - 1} selectedFilterBy={advancedFilter.filterBy} onChangeFilterBy={handleChangeFilterBy(index)} filterOptionOptions={[
            { label: t({ key: 'BEFORE' }), value: AdvancedFilterOption.BEFORE },
            { label: t({ key: 'AFTER' }), value: AdvancedFilterOption.AFTER },
            { label: t({ key: 'BETWEEN' }), value: AdvancedFilterOption.BETWEEN },
        ]} selectedFilterOption={advancedFilter.filterOption} onChangeFilterOption={handleChangeFilterOption(index, advancedFilter)} filterInput={<Grid item xs="auto">
                <ItemWrapper>
                  <DatePicker inputSize="small" startDateLabelText={t({
            key: advancedFilter.filterOption === AdvancedFilterOption.BETWEEN ? 'START_DATE' : 'DATE',
        })} selectsStart onChange={(newValue) => setAdvancedFilterByIndex(index, assocPath(['filterValue', 0], (newValue === null || newValue === void 0 ? void 0 : newValue.getTime()) || '', advancedFilter))} selected={advancedFilter.filterValue[0]} startDate={advancedFilter.filterValue[0]} endDate={advancedFilter.filterValue[1]} locale={selectedLanguage}/>

                  {advancedFilter.filterOption === AdvancedFilterOption.BETWEEN && (<DatePicker inputSize="small" endDateLabelText={t({ key: 'END_DATE' })} selectsEnd onChange={(newValue) => setAdvancedFilterByIndex(index, assocPath(['filterValue', 1], (newValue === null || newValue === void 0 ? void 0 : newValue.getTime()) || '', advancedFilter))} selected={advancedFilter.filterValue[1]} startDate={advancedFilter.filterValue[0]} endDate={advancedFilter.filterValue[1]} minDate={advancedFilter.filterValue[0]} locale={selectedLanguage}/>)}
                </ItemWrapper>
              </Grid>} addAdvancedFilter={addAdvancedFilter} removeAdvancedFilter={() => removeAdvancedFilter(index)}/>);
    })}
    </StyledAdvancedFilters>);
};
export default AdvancedFilters;

import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { collectionSelector } from './collection';
import { activeUserSelector } from './auth';
export function sessionSelector({ session }) {
    return session || {};
}
export const activeCollectionSelector = createSelector(sessionSelector, ({ collectionName }) => collectionName);
export const activeAreaSelector = createSelector(sessionSelector, ({ areaName }) => areaName);
export const activeAreaNameSelector = activeAreaSelector;
export const useActiveAreaNameSelector = () => useSelector(activeAreaNameSelector);
export const activeModelTypeSelector = createSelector(sessionSelector, ({ modelType }) => modelType);
export const keysSelector = createSelector(sessionSelector, ({ keys }) => keys);
export const inactiveSessionSelector = createSelector(sessionSelector, ({ isInactive }) => isInactive);
export const isLoadingSelector = createSelector(sessionSelector, ({ isLoading }) => isLoading);
export const defaultTenantLanguageSelector = createSelector(sessionSelector, ({ tenantSettings }) => tenantSettings === null || tenantSettings === void 0 ? void 0 : tenantSettings.preferredLanguage);
export const useDefaultTenantLanguageSelector = () => useSelector(defaultTenantLanguageSelector);
export const useIsLoadingAnalyticsDashboards = () => useSelector(createSelector(sessionSelector, ({ isLoadingAnalyticsDashboards }) => isLoadingAnalyticsDashboards));
export const useActiveCollection = () => useSelector(activeCollectionSelector);
export const useActiveArea = () => useSelector(activeAreaSelector);
export const useFindAreasForCollectionSelector = createSelector(collectionSelector, activeUserSelector, (collections, activeUser) => (collectionName, areas) => {
    const foundCollection = collections.find((collection) => (collection === null || collection === void 0 ? void 0 : collection.collection) === collectionName);
    if (!(areas === null || areas === void 0 ? void 0 : areas.length) || !foundCollection || !activeUser) {
        return [];
    }
    return areas.filter(({ id, collectionId }) => activeUser.areaPermissions
        .some(({ areaId }) => areaId === id) && collectionId === (foundCollection === null || foundCollection === void 0 ? void 0 : foundCollection.id))
        .sort((lhs, rhs) => lhs.name.localeCompare(rhs.name));
});
export const useFindAreasForCollection = () => useSelector(useFindAreasForCollectionSelector);
export const useActiveModelType = () => useSelector(activeModelTypeSelector);
export const useCollectionName = () => useSelector(createSelector(sessionSelector, ({ collectionName }) => collectionName));
export const auth0RegionSelector = createSelector(sessionSelector, ({ tenantSettings }) => tenantSettings === null || tenantSettings === void 0 ? void 0 : tenantSettings.auth0Region);
export const useAuth0RegionSelector = () => useSelector(auth0RegionSelector);
export const IsComparisonModeEnabledSelector = createSelector(sessionSelector, ({ isAnalyticsComparisonModeEnabled }) => isAnalyticsComparisonModeEnabled);
export const useIsAnalyticsComparisonModeEnabledSelector = () => useSelector(IsComparisonModeEnabledSelector);
export const mostRecentlyUpdatedModel = createSelector(sessionSelector, ({ mostRecentlyUpdatedModelId }) => mostRecentlyUpdatedModelId);
export const useMostRecentlyUpdatedModel = () => useSelector(mostRecentlyUpdatedModel);
export const mostRecentlyUpdatedModelPage = createSelector(sessionSelector, ({ mostRecentlyUpdatedModelPage }) => mostRecentlyUpdatedModelPage === null || mostRecentlyUpdatedModelPage === void 0 ? void 0 : mostRecentlyUpdatedModelPage.page);
export const useMostRecentlyUpdatedModelPage = () => useSelector(mostRecentlyUpdatedModelPage);

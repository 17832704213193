import * as React from 'react';
import { StyledHeaderBar, StyledLeftHeaderWrapper, StyledToolbar } from './header-bar.styles';
import { useNavigation } from '@/navigation/navigation.provider';
import { UserOptionsDisplay } from '@/search-for-retail/controls/user-display';
import { NavigationDrawerLogo } from '@/navigation/drawer/partials';
import LogoIcon from '@/components/icons/svg/logo';
import { useGoHome } from '@/routing/hooks/utils';
import { ContextSelectorsContainer } from '@/search-for-retail/generic/page-context-selection';
import { hasEnrichAccessOnly } from '@/config/utils';
import { NavigationType } from '@/navigation/types';
export const HeaderBar = () => {
    const { isDrawerOpen, setNavigationType } = useNavigation();
    const goHome = useGoHome();
    return (<StyledHeaderBar enableColorOnDark color="secondary" position="static">
      <StyledToolbar disableGutters>
        <StyledLeftHeaderWrapper>
          <NavigationDrawerLogo open={isDrawerOpen} text="Command Center" icon={<LogoIcon />} linkDisabled={hasEnrichAccessOnly} onLogoClick={() => {
        goHome();
        setNavigationType(NavigationType.PRIMARY);
    }}/>
          <ContextSelectorsContainer />
        </StyledLeftHeaderWrapper>
        <UserOptionsDisplay />
      </StyledToolbar>
    </StyledHeaderBar>);
};
export default HeaderBar;

import { FeatureFlagPrefix } from '../feature-flags.constants';
import { ApiFeatureFlagsService } from './api-feature-flags-service';
import { LaunchDarklyFeatureFlagService } from './launch-darkly-feature-flags-service';
import { QueryParamsFeatureFlagsService } from './query-params-feature-flags-service';
export var FeatureFlag;
(function (FeatureFlag) {
    FeatureFlag["Next"] = "enableNext";
})(FeatureFlag || (FeatureFlag = {}));
export var ProviderType;
(function (ProviderType) {
    ProviderType["FeaturesApi"] = "featuresApi";
    ProviderType["LaunchDarkly"] = "launchDarkly";
    ProviderType["QueryParams"] = "queryParams";
})(ProviderType || (ProviderType = {}));
export const providerTypeToServiceMap = {
    [ProviderType.QueryParams]: QueryParamsFeatureFlagsService,
    [ProviderType.FeaturesApi]: ApiFeatureFlagsService,
    [ProviderType.LaunchDarkly]: LaunchDarklyFeatureFlagService,
};
export var EventType;
(function (EventType) {
    EventType["ContextUpdated"] = "contextUpdated";
    EventType["FeatureFlagsSet"] = "featureFlagsSet";
})(EventType || (EventType = {}));
export const ONE_HOUR = 60 * 60 * 1000;
export const LAST_FETCH_TIME_KEY = `${FeatureFlagPrefix.FeaturesApi}lastFeaturesFetch`;
export const LD_CLIENT_SIDE_ID_KEY = `${FeatureFlagPrefix.FeaturesApi}clientSideId`;
export const SINGLE_TENANT_NAME_KEY = `${FeatureFlagPrefix.FeaturesApi}singleTenantName`;

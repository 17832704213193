import { __rest } from "tslib";
import React from 'react';
import { MessageBanner } from '@groupby/ui-components';
import { isEmpty, isNil } from 'ramda';
import { useAdhocMessages } from './adhoc-message.provider';
import { StyledAdhocMessageBar } from './adhoc-message.styles';
import { useLocalization } from '@/localization';
export const AdhocMessageBar = (_a) => {
    var { placement } = _a, props = __rest(_a, ["placement"]);
    const localization = useLocalization();
    const { adhocMessages, dismissAdhocMessage } = useAdhocMessages();
    const hasMessages = !isNil(adhocMessages) && !isEmpty(adhocMessages);
    return (<React.Fragment>
      {hasMessages && (<StyledAdhocMessageBar {...props} className={`${placement}-placement`}>
          {adhocMessages.map((adhocMessage) => (<MessageBanner key={adhocMessage.id} body={adhocMessage.body} title={adhocMessage.title} closeBtnText={localization.formatMessage({ key: 'GOT_IT' })} handleClose={() => dismissAdhocMessage(adhocMessage)}/>))}
        </StyledAdhocMessageBar>)}
    </React.Fragment>);
};
export default AdhocMessageBar;

import React, { useState } from 'react';
import { ConfirmationAlert } from './confirmation-alert.component';
export const ConfirmationAlertContext = React.createContext({ openDialog: () => { } });
export const ConfirmationAlertProvider = ({ children }) => {
    const [dialogConfig, setDialogConfig] = useState(null);
    const { open, message, infoList, hideCancel, confirmActionProps, title, } = dialogConfig !== null && dialogConfig !== void 0 ? dialogConfig : {};
    const openDialog = (config) => setDialogConfig(Object.assign({ open: true }, config));
    const closeDialog = () => setDialogConfig(null);
    const onConfirm = () => {
        closeDialog();
        dialogConfig.actionCallback(true);
    };
    const onDismiss = () => {
        closeDialog();
        dialogConfig.actionCallback(false);
    };
    return (<ConfirmationAlertContext.Provider value={{ openDialog }}>
      {children}
      {dialogConfig && open && (<ConfirmationAlert open title={title} message={message} infoList={infoList} hideCancel={hideCancel} onConfirm={onConfirm} onDismiss={onDismiss} confirmActionProps={confirmActionProps}/>)}
    </ConfirmationAlertContext.Provider>);
};
export default ConfirmationAlertProvider;

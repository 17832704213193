import ListItem from '@mui/material/ListItem';
import { baseColors } from '@groupby/ui-components';
import styled from 'styled-components';
export const StyledListItem = styled(ListItem) `
  cursor: ${({ $clickable }) => ($clickable ? 'pointer' : 'default')};
  width: ${({ theme }) => theme.s4rAppNavigation.expandedWidth};
  justify-content: center;
  overflow-x: hidden;
  white-space: nowrap;
  border: 3px solid transparent;
  color: ${({ theme }) => theme.palette.common.white};
  height: 3.75rem;
  transition: ${({ theme }) => theme.s4rAppNavigation.transitionSettings};

  &:hover {
    border-color: ${baseColors.primaryGreen200};
  }
  &.collapsed {
    width: ${({ theme }) => theme.s4rAppNavigation.collapsedWidth};
  }

  .MuiListItemIcon-root {
    min-width: 0;
    color: ${({ theme }) => theme.palette.common.white};
    margin-right: 10px;
    transition: margin-right 0.3s ease;
  }

  .MuiTypography-root {
    text-transform: uppercase;
    font-size: 1rem;
    line-height: 1.5rem;
    flex-shrink: 0;
    margin-right: auto;
    transition: opacity 0.3s ease;
  }

  &.collapsed {
    .MuiTypography-root {
      opacity: 0;
    }
    .MuiListItemIcon-root {
      margin-right: 0;
    }
  }

  svg {
    width: 1.4em;
    height: 1.4em;
  }
`;

import styled from 'styled-components';
import { baseColors, ButtonV2, TextField } from '@groupby/ui-components';
import Grid from '@mui/material/Grid';
export const TableFilterBarGridContainer = styled(Grid) `
  padding: 0 1rem;
`;
export const StyledDropDownLabel = styled.div `
  display: flex;
  align-items: center;
`;
export const StyledDropDownValueCount = styled.div `
  width: 1.25rem;
  height: 1.25rem;
  min-width: 1.25rem;
  min-height: 1.25rem;

  margin-left: 0.25rem;

  color: white;

  border-radius: 50px;
  background: ${baseColors.blue300};

  display: flex;
  align-items: center;
  justify-content: center;

  font-weight: 600;
  font-size: 0.75rem;
`;
export const StyledSearchField = styled(TextField) `
  && {
    .MuiInputBase-root {
      min-height: 2.25rem;
    }

    input {
      font-size: 0.875rem;
      height: 2.25rem;
      padding: unset;
    }

    svg {
      width: 1rem;
      height: 1rem;
    }
  }
`;
export const StyledQuickFilterButton = styled(ButtonV2) `
  && {
    color: ${baseColors.background600};
    svg {
      ${({ $open }) => $open && 'transform: rotate(180deg);'};

      path {
        fill: ${baseColors.primaryBlueMed400};
      }
    }

    &:hover {
      color: ${baseColors.primaryBlueDark500};
      background: ${baseColors.background000};

      svg {
        path {
          fill: ${baseColors.primaryBlueMed400};
        }
      }
    }
  }
`;

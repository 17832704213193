import React from 'react';
import Alert from '@/components/alert';
import { useLocalization } from '@/localization';
export const ErrorAlert = ({ open, message, onConfirm, onDismiss, }) => {
    const { formatMessage: t } = useLocalization();
    return (<Alert open={open} title={t({ key: 'RULE_RESTORE_ERROR_TITLE' })} primaryButton={{ onClick: onConfirm }} showCancel={false} onCancel={onDismiss}>
      <p>{message}</p>
    </Alert>);
};
export default ErrorAlert;

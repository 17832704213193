export var RecsModelDataState;
(function (RecsModelDataState) {
    RecsModelDataState["Unspecified"] = "DATA_STATE_UNSPECIFIED";
    RecsModelDataState["Ok"] = "DATA_OK";
    RecsModelDataState["Error"] = "DATA_ERROR";
})(RecsModelDataState || (RecsModelDataState = {}));
export var RecsModelServingState;
(function (RecsModelServingState) {
    RecsModelServingState["Unspecified"] = "SERVING_STATE_UNSPECIFIED";
    RecsModelServingState["Inactive"] = "INACTIVE";
    RecsModelServingState["Active"] = "ACTIVE";
    RecsModelServingState["Tuned"] = "TUNED";
})(RecsModelServingState || (RecsModelServingState = {}));

import { __awaiter } from "tslib";
import { InternalFeatureFlagsService } from '../internal-feature-flag-service';
var FlagValue;
(function (FlagValue) {
    FlagValue["Disabled"] = "0";
    FlagValue["Enabled"] = "1";
})(FlagValue || (FlagValue = {}));
export class QueryParamsFeatureFlagsService extends InternalFeatureFlagsService {
    constructor() {
        super(...arguments);
        this.getQueryParamFlagValue = (flagValue) => {
            if (flagValue === FlagValue.Enabled) {
                return true;
            }
            if (flagValue === FlagValue.Disabled) {
                return false;
            }
            return null;
        };
        this.saveQueryFlags = () => {
            const queryParams = new URLSearchParams(window.location.search);
            const queryParamFlags = {};
            queryParams.forEach((value, key) => {
                if (this.isFeatureFlagKey(key)) {
                    const flagValue = this.getQueryParamFlagValue(value);
                    if (flagValue !== null) {
                        queryParamFlags[key] = flagValue;
                    }
                }
            });
            super.saveFeatureFlags(queryParamFlags);
        };
    }
    setFeatureFlags() {
        return __awaiter(this, void 0, void 0, function* () {
            if (window.location.search) {
                this.saveQueryFlags();
            }
            this.updateCacheWithNewFeatureFlags(Array.from(this.featureFlags));
            return Promise.resolve();
        });
    }
}

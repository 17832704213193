import { __rest } from "tslib";
import React from 'react';
import { baseColors } from '@groupby/ui-components';
import SvgIcon from '@mui/material/SvgIcon';
export const AuditLogIcon = (_a) => {
    var { width = 20, height = 20, color = baseColors.primaryBlueMed400 } = _a, props = __rest(_a, ["width", "height", "color"]);
    return (<SvgIcon width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M20.9471 14.9894L21.6572 15.2309L20.9471 14.9894ZM20.6687 15.8084L19.9586 15.567L20.6687 15.8084ZM11.6712 15.584H20.8358V14.084H11.6712V15.584ZM14.4514 19.084H4.5V20.584H14.4514V19.084ZM4.5 20.584H7.91119V19.084H4.5V20.584ZM20.2371 14.748L19.9586 15.567L21.3788 16.0498L21.6572 15.2309L20.2371 14.748ZM7.91119 20.584C10.9575 20.584 13.1537 17.6636 12.3082 14.7369L10.8671 15.1533C11.4355 17.1207 9.95911 19.084 7.91119 19.084V20.584ZM14.4514 20.584C15.6354 20.584 16.8065 20.3371 17.8898 19.8592L17.2844 18.4868C16.3918 18.8806 15.4269 19.084 14.4514 19.084V20.584ZM20.8358 15.584C20.4026 15.584 20.0976 15.1582 20.2371 14.748L21.6572 15.2309C21.8486 14.6681 21.4302 14.084 20.8358 14.084V15.584ZM19.9586 15.567C19.5151 16.8716 18.5451 17.9306 17.2844 18.4868L17.8898 19.8592C19.5346 19.1336 20.8001 17.7519 21.3788 16.0498L19.9586 15.567ZM11.6712 14.084C11.1141 14.084 10.7125 14.6181 10.8671 15.1533L12.3082 14.7369C12.4307 15.1609 12.1125 15.584 11.6712 15.584V14.084Z" fill={color}/>
    <path d="M11.5877 14.9444L10.8671 15.1526L10.8671 15.1526L11.5877 14.9444ZM17 14.8333V15.5833H17.75V14.8333H17ZM1.75 5C1.75 3.20508 3.20508 1.75 5 1.75V0.25C2.37665 0.25 0.25 2.37665 0.25 5H1.75ZM1.75 15.8333V5H0.25V15.8333H1.75ZM5 19.0833C3.20507 19.0833 1.75 17.6283 1.75 15.8333H0.25C0.25 18.4567 2.37665 20.5833 5 20.5833V19.0833ZM7.91119 19.0833H5V20.5833H7.91119V19.0833ZM7.91119 20.5833C10.9575 20.5833 13.1537 17.6629 12.3082 14.7363L10.8671 15.1526C11.4355 17.1201 9.95911 19.0833 7.91119 19.0833V20.5833ZM12.3082 14.7363C12.4307 15.1603 12.1125 15.5833 11.6712 15.5833V14.0833C11.1141 14.0833 10.7125 14.6174 10.8671 15.1526L12.3082 14.7363ZM11.6712 15.5833H17V14.0833H11.6712V15.5833ZM16.25 5V14.8333H17.75V5H16.25ZM13 1.75C14.7949 1.75 16.25 3.20507 16.25 5H17.75C17.75 2.37665 15.6234 0.25 13 0.25V1.75ZM5 1.75H13V0.25H5V1.75Z" fill={color}/>
    <path d="M5 6H13" stroke={color} strokeWidth="1.5" strokeLinecap="round"/>
    <path d="M5 9H13" stroke={color} strokeWidth="1.5" strokeLinecap="round"/>
    <path d="M5 12H13" stroke={color} strokeWidth="1.5" strokeLinecap="round"/>
  </SvgIcon>);
};
export default AuditLogIcon;

import styled from 'styled-components';
import { baseColors, Typography } from '@groupby/ui-components';
export const InsightContainer = styled.div `
  display: flex;
  flex-direction: column;
  padding: 1rem;
  border-radius: 4px;
  border: 1px solid ${baseColors.gray200};

  p {
    display: inline;
  }

  .insight-details {
    margin-bottom: 0.5rem;
  }
`;
export const InsightTitleTypography = styled(Typography) `
  display: block;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;

  margin-bottom: 0.625rem;
`;
export const InsightDetails = styled.span `
  font-size: 0.75rem;
  font-weight: 400;
  color: ${baseColors.primaryBlueMed600};

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  .icon-wrapper-class {
    width: 1rem;
    height: 1rem;
    transform: rotate(${({ $isDeltaNegative }) => ($isDeltaNegative ? '90deg' : '-90deg')});

    svg {
      width: inherit;
      height: inherit;
    }
  }

  .insight-delta {
    font-weight: 700;
    margin-right: 0.5rem;
  }
`;
export const InsightSuggestion = styled.div `
    background-color: #F1EDFF;
    color: ${baseColors.primaryBlueAccent300};

    font-size: 0.75rem;
    font-weight: 400;
    padding: 0.25rem 0.5rem;
    border-radius: 4px;

    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 3;
    text-overflow: ellipsis;

    .insight-suggestion-drop-cap {
      font-weight: 700;
    }
`;

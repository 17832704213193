import * as ActionUtilsModule from './utils';
export function createAction(type, payload, meta) {
    return { type, payload, meta };
}
export function createErrorAction(type, payload, meta) {
    return Object.assign(Object.assign({}, ActionUtilsModule.createAction(type, payload, meta)), { error: true });
}
export function createThunkAction(type, payload, meta) {
    return ActionUtilsModule.createAction(type, payload, Object.assign({ thunk: true }, meta));
}

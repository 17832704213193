import * as Actions from '../types/auth';
import { createAction } from '../utils';
export const clearAuthentication = () => createAction(Actions.CLEAR_AUTHENTICATION, undefined, { persistImmediately: true });
export const setIsLoggingIn = (value) => createAction(Actions.SET_LOGGING_IN, { value });
export const acknowledgeAuthentication = (token, user, refreshToken) => createAction(Actions.ACKNOWLEDGE_AUTHENTICATION, {
    token,
    refreshToken,
    user,
});
export const storeUserPreferredLanguage = (preferredLanguage) => createAction(Actions.STORE_USER_PREFERRED_LANGUAGE, preferredLanguage);

import styled from 'styled-components';
import { baseColors, Icon } from '@groupby/ui-components';
export const StyledDrawerContentSection = styled.section `
  height: ${({ theme }) => `calc(100% - ${theme.appHeader.height})`};
  width: 100%;
  transition: margin .5s, width .5s;
  box-sizing: border-box;
  overflow-y: auto;
  position: relative;
  top: ${({ theme }) => theme.appHeader.height};
  z-index: ${({ theme }) => theme.zIndex.navigationDrawer};

  /* when displaying analytics, drawer section contains only the fixed app header */
  &.displayAnalytics {
    transition: ${({ theme }) => theme.s4rAppNavigation.transitionSettings};

    position: fixed;
    width: 0;
    height: 0;
    left: ${({ theme }) => theme.s4rAppNavigation.collapsedWidth};

    &.isDrawerOpen {
      left: ${({ theme }) => theme.s4rAppNavigation.expandedWidth};
    }
  };
`;
StyledDrawerContentSection.displayName = 'StyledDrawerContentSection';
export const StyledDoubleChevronIcon = styled(Icon) `
  ${({ $openedDrawer }) => $openedDrawer && 'transform: rotate(180deg)'};
`;
export const StyledChevronButtonWrapper = styled.div `
  display: flex;
  align-items: center;
`;
StyledChevronButtonWrapper.displayName = 'StyledChevronButtonWrapper';
export const StyledCopyRight = styled.div `
  cursor: default;
  color: ${baseColors.primaryBlueMed400};
  font-size: 0.8rem;
  line-height: 1.067rem;
  font-weight: 400;
  padding: ${({ theme }) => theme.spacing(2)};
`;
StyledCopyRight.displayName = 'StyledCopyRight';

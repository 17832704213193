import { __rest } from "tslib";
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { baseColors } from '@groupby/ui-components';
export const BookIcon = (_a) => {
    var { height = 24, width = 24, color = baseColors.primaryBlueMed400 } = _a, props = __rest(_a, ["height", "width", "color"]);
    return (<SvgIcon width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path style={{ fill: 'none' }} d="M12 4.11745V20.9906M12 4.11745L13.1001 3.70461C15.6036 2.76513 18.3964 2.76513 20.8999 3.70461C21.5643 3.95395 22 4.55769 22 5.22907V19.0797C22 20.0016 21.0075 20.632 20.0951 20.2896C18.1082 19.544 15.8918 19.544 13.9049 20.2896L12.0137 20.9993C12.0071 21.0018 12 20.9972 12 20.9906M12 4.11745L10.8999 3.70461C8.39638 2.76513 5.60362 2.76513 3.10014 3.70461C2.43569 3.95395 2 4.55769 2 5.22907V19.0797C2 20.0016 2.99247 20.632 3.90485 20.2896C5.89175 19.544 8.10825 19.544 10.0951 20.2896L11.9863 20.9993C11.9929 21.0018 12 20.9972 12 20.9906" stroke={color} strokeWidth="1.5"/>
  </SvgIcon>);
};
export default BookIcon;

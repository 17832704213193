import { Typography } from '@groupby/ui-components';
import styled from 'styled-components';
import MaintenanceIcon from '@/components/icons/svg/search-for-retail/maintenace-icon';
export const StyledBox = styled.div `
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  z-index: 2000;
`;
export const StyledTypography = styled(Typography) `
  color: black;
  margin: ${({ theme }) => theme.spacing(3.75, 0, 5, 0)};
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 2rem;
  text-align: center;
`;
export const StyledMaintenanceIcon = styled(MaintenanceIcon) `
  width: 7.625rem;
  height: 7.75rem;
`;

import { __awaiter } from "tslib";
import { s4rAuthenticated } from '@/client/utils';
import { SearchForRetail } from '@/client/api';
import { logError } from '@/utils/logger';
const { RELEASE_NOTES_PATH } = SearchForRetail;
function fetchReleaseNotesData(token, url) {
    return __awaiter(this, void 0, void 0, function* () {
        const { result } = yield s4rAuthenticated(token, { method: 'GET', url });
        return result.map((item) => ({
            releaseName: item.releaseName,
            releaseDate: item.releaseDate,
            fileName: item.fileName,
        }));
    });
}
export const getReleaseNotes = (token) => __awaiter(void 0, void 0, void 0, function* () { return fetchReleaseNotesData(token, `${SearchForRetail.API_URL}/${RELEASE_NOTES_PATH}`); });
export const fetchReleaseNoteImage = (token, fileName) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const response = yield fetch(`${SearchForRetail.API_URL}/${RELEASE_NOTES_PATH}/download?name=${encodeURIComponent(fileName)}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        });
        if (!response.ok) {
            throw new Error(`API call failed with status: ${response.status}`);
        }
        return response;
    }
    catch (error) {
        if (error instanceof Error) {
            logError('Error in fetchReleaseNoteImage:', error.message);
        }
        else {
            logError('Error in fetchReleaseNoteImage:', error);
        }
        throw error;
    }
});
export const fetchReleaseNoteMarkdown = (token, fileName) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const response = yield fetch(`${SearchForRetail.API_URL}/${RELEASE_NOTES_PATH}/download?name=${encodeURIComponent(fileName)}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        });
        if (!response.ok) {
            throw new Error(`API call failed with status: ${response.status}`);
        }
        const text = yield response.text();
        return text;
    }
    catch (error) {
        if (error instanceof Error) {
            logError('Error in fetchReleaseNoteMarkdown:', error.message);
        }
        else {
            logError('Error in fetchReleaseNoteMarkdown:', error);
        }
        throw error;
    }
});

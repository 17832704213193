import React from 'react';
import { Table as MuiTable, TableCell, TableRow } from '@mui/material';
import { TableHead } from './table-head';
import { StyledTableBody, StyledTableContainer, StyledTableLoader } from './table.styles';
import Loading from '@/components/loading';
import { NoResults } from '@/search-for-retail/generic/no-results';
export const Table = React.forwardRef(({ variant = 'dark', columns, rowCount, selectedRowCount, handleSelectAllClick, handleExpandAllClick, expandable, children, hasDraggableRows = false, hasSelect, className, hasMoreOptions, isLoading, customTableHeadComponent: CustomHeadComponent, guestUser = false, showNoResults, sortParam, onSort, noResultsMessageKey, }, ref) => (<StyledTableContainer ref={ref} className={hasDraggableRows && 'draggable'}>
    <MuiTable className={className} aria-labelledby="tableTitle" aria-label="enhanced table">
      {CustomHeadComponent ? (<CustomHeadComponent />) : (<TableHead variant={variant} selectedRowCount={selectedRowCount} onSelectAllClick={handleSelectAllClick} onExpandAllClick={handleExpandAllClick} rowCount={rowCount} columns={columns} expandable={expandable} hasSelect={hasSelect} hasMoreOptions={hasMoreOptions} guestUser={guestUser} sortParam={sortParam} onSort={onSort}/>)}
      <StyledTableBody $loading={isLoading}>
        {isLoading && (<StyledTableLoader>
            <Loading isLoading/>
          </StyledTableLoader>)}
        {showNoResults ? (<TableRow>
            <TableCell colSpan={columns.length + 2}>
              <NoResults messageKey={noResultsMessageKey}/>
            </TableCell>
          </TableRow>) : children}
      </StyledTableBody>
    </MuiTable>
  </StyledTableContainer>));
Table.displayName = 'Table';
export default Table;

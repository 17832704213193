import styled from 'styled-components';
export const StyledAdvancedFilter = styled.div `
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  box-sizing: border-box;
  margin-top: .7rem;

  .and-or-toggle {
    height: 42px;
  }
`;
export const StyledItemWrapper = styled.div `
  margin-right: 1.3rem;
  font-size: 1.1rem;
`;
export const StyledAnd = styled(StyledItemWrapper) `
  cursor: default;
  text-transform: uppercase;
  font-style: italic;
  color: ${({ theme }) => theme.palette.primary.main};
`;

import { __awaiter } from "tslib";
import { useEffect, useMemo, useState } from 'react';
import { format, sub } from 'date-fns';
import { useDispatch } from 'react-redux';
import { useAreaIdSelector } from '@/store/selectors';
import { useAuthToken } from '@/auth/hooks/authentication';
import { useAbortRequest } from '@/hooks/abort-request';
import { getActionableInsightData } from './client/actionable-insight.client';
import { logError } from '@/utils/logger';
import * as ActionCreators from '@/store/actions/creators';
import { FeatureFlag, useFeatureFlagsProvider } from '@/search-for-retail/feature-flags';
const MODULE_NAME = 'actionableInsights';
export const useActionableInsightData = (props) => {
    const token = useAuthToken();
    const areaId = useAreaIdSelector();
    const dispatch = useDispatch();
    const { signal } = useAbortRequest();
    const { featureFlagsService } = useFeatureFlagsProvider();
    const enableDashboardModules = featureFlagsService.isFeatureEnabled(FeatureFlag.enableDashboardModules);
    const isModuleEnabled = useMemo(() => {
        const dashboardModules = enableDashboardModules;
        const isHomePageEnabled = dashboardModules.homepage.includes(MODULE_NAME) && props.showOnHomePage;
        const isSearchPerformanceEnabled = dashboardModules.searchPerformance.includes(MODULE_NAME) && props.showOnSearchPerformancePage;
        return isHomePageEnabled || isSearchPerformanceEnabled;
    }, [enableDashboardModules, props.showOnHomePage, props.showOnSearchPerformancePage]);
    const [data, setData] = useState([]);
    useEffect(() => {
        if (!isModuleEnabled)
            return;
        const getData = () => __awaiter(void 0, void 0, void 0, function* () {
            try {
                if (data.length > 0) {
                    setData([]);
                }
                const response = yield getActionableInsightData(token, areaId, { signal }, { serverDate: format(sub(new Date(), { days: 1 }), 'yyyy/MM/dd') });
                setData((response === null || response === void 0 ? void 0 : response.actionableInsights) || []);
            }
            catch (error) {
                const errorMessage = 'UNKNOWN_ERROR';
                logError(errorMessage, error);
                dispatch(ActionCreators.addError({ key: errorMessage }));
            }
        });
        void getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [areaId, dispatch, isModuleEnabled, signal, token]);
    return data.filter((insight) => {
        if (props.showOnHomePage) {
            return insight.showOnHomePage;
        }
        if (props.showOnSearchPerformancePage) {
            return insight.showOnSearchPerformancePage;
        }
        return false;
    });
};

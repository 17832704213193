import React, { useState } from 'react';
import { Autocomplete, baseColors } from '@groupby/ui-components';
export const AttributeAutocompleteControlComponent = ({ name, disabled, clearAfterSelect, helperText, label, error, options, value, setValue, variant, onBlur, handleChange, }) => {
    const [inputValue, setInputValue] = useState('');
    const onInputChange = (_e, newValue) => {
        setInputValue(newValue);
    };
    const onChange = (_e, option) => {
        const { value } = option || {};
        setInputValue(clearAfterSelect ? '' : value);
        if (name && setValue) {
            setValue(value);
        }
        if (handleChange) {
            handleChange(value);
        }
    };
    return (<Autocomplete openOnFocus autoHighlight selectOnFocus clearOnBlur fullWidth background={baseColors.white} id="navigation-autocomplete" name={name} value={value} inputValue={inputValue || value} variant={variant} error={error} onBlur={onBlur} onChange={onChange} onInputChange={onInputChange} label={label} helperText={helperText} disabled={disabled} options={options} disableClearable={false} getOptionDisabled={(option) => !option.inUse}/>);
};
export default AttributeAutocompleteControlComponent;

import { useEffect, useMemo, useState } from 'react';
import xor from 'lodash/xor';
import { useTablePersistedData } from './use-table-persisted-data.hook';
export const useTableColumnsOrder = (columns, { persisted, onHiddenChanged, }) => {
    const [order, setOrder] = useState(columns.map(({ id }) => id));
    const [hidden, setHidden] = useState(columns.filter(({ hidden }) => hidden).map(({ id }) => id));
    const { persistedData, setPersistedData } = useTablePersistedData();
    useEffect(() => {
        if (persisted && (persistedData.order || persistedData.hidden)) {
            const persistedOrder = persistedData.order || order;
            const persistedHidden = persistedData.hidden || hidden;
            const orderDiff = xor(persistedOrder, order);
            setOrder([...persistedOrder, ...orderDiff]);
            setHidden(persistedHidden);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const handleOrderChange = (newOrder, newHidden) => {
        if (hidden.join('') !== newHidden.join(''))
            onHiddenChanged();
        setOrder(newOrder);
        setHidden(newHidden);
        setPersistedData({ order: newOrder, hidden: newHidden });
    };
    const orderedColumns = useMemo(() => (order.map((id) => {
        const column = columns.find((column) => column.id === id);
        return (Object.assign(Object.assign({}, column), { hidden: hidden.includes(column.id) }));
    })), [order, hidden, columns]);
    return ({
        orderedColumns,
        actions: {
            setOrder: handleOrderChange,
        },
    });
};

import { debounce } from 'debounce';
import { mergeDeepRight } from 'ramda';
import * as Redux from 'redux';
import { middleware as thunkMiddleware } from 'redux-saga-thunk';
import * as ActionCreators from './actions/creators';
import { isModelAction } from './actions/merchandising';
import * as Actions from './actions/types';
import * as Middleware from './middleware';
import * as Selectors from './selectors';
import { SKIP_AUTHENTICATION } from '@/config/global';
import { DEFAULT_CACHE_TTL, SEARCH_QUERY_UPDATE_DELAY } from '@/constants';
import { isGlobalModelType } from '@/search-for-retail/models';
import * as LocalStorage from '@/stores/local-storage';
import * as SessionStorage from '@/stores/session-storage';
import { DEFAULT_PERSIST_INTERVAL, STORAGE_KEY } from '@/stores/stores.constants';
import { logError } from '@/utils/logger';
export const REQUIRED_AREA_MODEL_ACTIONS = [Actions.STORE_ONE_MODEL, Actions.STORE_ALL_MODELS];
/* eslint-disable no-console */
export function modelActionValidationMiddleware() {
    return (next) => (action) => {
        if (isModelAction(action)) {
            if (!action.meta) {
                logError('model action should define a "meta" property', action);
                console.dir(action);
                return;
            }
            if (!action.meta.modelType) {
                logError('model action should define a "meta.modelType" property', action);
                console.dir(action);
                return;
            }
            const isGlobal = isGlobalModelType(action.meta.modelType);
            if (!isGlobal && REQUIRED_AREA_MODEL_ACTIONS.includes(action.type) && !action.meta.area) {
                logError('model action should define a "meta.area" property', action);
                console.dir(action);
                return;
            }
        }
        return next(action);
    };
}
/* eslint-enable no-console */
export const debouncedSearch = debounce((store, loadAction) => store.dispatch(loadAction()), SEARCH_QUERY_UPDATE_DELAY);
export function purgeExpiredCacheMiddleware(firstPurgeTime = 0) {
    let nextPurgeTime = firstPurgeTime;
    return (store) => (next) => (action) => {
        const now = Date.now();
        if (now >= nextPurgeTime) {
            nextPurgeTime = Date.now() + DEFAULT_CACHE_TTL;
            store.dispatch(ActionCreators.purgeExpiredCacheEntries(now));
        }
        return next(action);
    };
}
export const debouncedPersistToLocalStorage = debounce((state, key) => LocalStorage.persist(state, key), DEFAULT_PERSIST_INTERVAL);
export const debouncedPersistToSessionStorage = debounce((state, key) => SessionStorage.persist(state, key), DEFAULT_PERSIST_INTERVAL);
export const persistedMiddleware = (key = STORAGE_KEY) => (store) => (next) => (action) => {
    var _a;
    const newState = next(action);
    if ((_a = action.meta) === null || _a === void 0 ? void 0 : _a.persistImmediately) {
        debouncedPersistToLocalStorage.clear();
        LocalStorage.persist(store.getState(), key);
    }
    else {
        debouncedPersistToLocalStorage(store.getState(), key);
    }
    const isAuthenticated = Selectors.isAuthenticatedSelector(store.getState());
    if (isAuthenticated && action.type !== Actions.LOGOUT) {
        debouncedPersistToSessionStorage(store.getState(), key);
    }
    return newState;
};
export const enhancedReducer = (reducer) => (state, action) => {
    if (action.type === Actions.PERSIST_INIT) {
        const newState = mergeDeepRight(state, action.payload || {});
        return newState;
    }
    return reducer(state, action);
};
export const persistedStoreEnhancer = (key = STORAGE_KEY) => (createStore) => (reducer, ...args) => {
    const store = createStore(enhancedReducer(reducer), ...args);
    const persistedStateFromLocalStorage = LocalStorage.ls.getItem(key);
    const persistedStateFromSessionStorage = SessionStorage.ss.getItem(key);
    store.dispatch(ActionCreators.persistInit(mergeDeepRight(persistedStateFromLocalStorage, persistedStateFromSessionStorage !== null && persistedStateFromSessionStorage !== void 0 ? persistedStateFromSessionStorage : {})));
    return store;
};
export default function createMiddleware(sagaMiddleware) {
    const composeEnhancers = (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ maxAge: 300 }))
        || Redux.compose;
    return composeEnhancers(...(SKIP_AUTHENTICATION ? [] : [persistedStoreEnhancer()]), Redux.applyMiddleware(Middleware.purgeExpiredCacheMiddleware(), modelActionValidationMiddleware, thunkMiddleware, sagaMiddleware, ...(SKIP_AUTHENTICATION ? [] : [persistedMiddleware()])));
}

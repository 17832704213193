export var NavigationSortOrder;
(function (NavigationSortOrder) {
    NavigationSortOrder["CountAscending"] = "COUNT-ASCENDING";
    NavigationSortOrder["CountDescending"] = "COUNT-DESCENDING";
    NavigationSortOrder["ValueAscending"] = "VALUE-ASCENDING";
    NavigationSortOrder["ValueDescending"] = "VALUE-DESCENDING";
})(NavigationSortOrder || (NavigationSortOrder = {}));
export var NavigationType;
(function (NavigationType) {
    NavigationType["Value"] = "VALUE";
    NavigationType["Range"] = "RANGE";
})(NavigationType || (NavigationType = {}));

import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useLocation } from 'react-router-dom';
import { EnrichContext } from '@groupby/enrich-console';
import { compose } from 'ramda';
import TagManager from 'react-gtm-module';
import jsSHA from 'jssha';
import Notifications from '@/components/notifications';
import { ConfirmationAlertProvider } from '@/confirmation-alert';
import { ErrorAlertProvider } from '@/error-alert';
import { AdhocMessageProvider } from '@/adhoc-message/adhoc-message.provider';
import AppFrame from '@/components/frame';
import FrameContent from '@/components/frame/content';
import { LocaleProvider, Localization, LocalizationProvider, useLocale, } from '@/localization';
import { isDirtyAuthenticatedSelector } from '@/store/selectors';
import { addError, addSuccess } from '@/store/actions/creators';
import { ENRICH_API_PREFIX, IS_LOWER_ENV, IS_NON_PUBLIC_ENV } from '@/config/global';
import { Routing } from '@/constants';
import { ls } from '@/stores/local-storage';
import { AuthContext } from '@/auth/auth.context';
import { useValidateToken } from '@/auth/hooks/use-validate-token';
import { useAuthToken, useUserEmail } from '@/auth/hooks/authentication';
import { FeatureFlag, useFeatureFlagsProvider } from '@/search-for-retail/feature-flags';
import { FeatureFlagsUpdateContext } from '@/search-for-retail/feature-flags/feature-flags-context.layout';
import { logError } from '@/utils/logger';
import { SimpleLoading } from '@/components/loading';
export const AppLayout = () => {
    const { featureFlagsService } = useFeatureFlagsProvider();
    const auth0Enabled = featureFlagsService.isFeatureEnabled(FeatureFlag.Auth0);
    const dispatch = useDispatch();
    const { locale } = useLocale();
    const { isAppLoading } = useContext(AuthContext);
    const isDirtyAuthenticated = useSelector(isDirtyAuthenticatedSelector);
    const { pathname, search } = useLocation();
    const validateToken = useValidateToken();
    const userEmail = useUserEmail();
    useEffect(() => {
        if (auth0Enabled)
            return;
        if (isDirtyAuthenticated)
            void validateToken(pathname, search);
    }, [auth0Enabled, isDirtyAuthenticated, pathname, search, validateToken]);
    useEffect(() => {
        if (userEmail) {
            // Hash the email address to avoid sending PII to Google Analytics
            // eslint-disable-next-line new-cap
            const shaObj = new jsSHA('SHA-256', 'TEXT');
            shaObj.update(userEmail);
            const emailHashed = shaObj.getHash('HEX');
            // check if user is external by comparing user email domain to 'groupbyinc.com'
            const isExternal = !userEmail.endsWith('@groupbyinc.com');
            TagManager.dataLayer({
                dataLayer: {
                    'event': 'userAuthenticated',
                    'user_id': emailHashed,
                    'external_user': isExternal,
                },
            });
        }
    }, [userEmail]);
    const ENRICH_PROVIDER_VALUE = {
        useAuthToken,
        logError,
        notification: {
            addError: compose(dispatch, addError),
            addSuccess: compose(dispatch, addSuccess),
        },
        localization: {
            selectedLanguage: locale,
            defaultLanguage: Localization.DEFAULT_LANGUAGE_CODE,
        },
        config: {
            isNonPublicEnv: IS_NON_PUBLIC_ENV,
            defaultEnrichApiPrefix: ENRICH_API_PREFIX,
            enrichPath: Routing.ENRICH_PATH,
            isLowerEnv: IS_LOWER_ENV,
        },
        localStorage: ls,
        featureFlagsService,
    };
    if (isAppLoading) {
        return <SimpleLoading />;
    }
    return (<FeatureFlagsUpdateContext>
      <LocaleProvider>
        <EnrichContext.Provider value={ENRICH_PROVIDER_VALUE}>
          <LocalizationProvider>
            <AdhocMessageProvider>
              <ConfirmationAlertProvider>
                <ErrorAlertProvider>
                  <AppFrame>
                    <Notifications />
                    <FrameContent>
                      <Outlet />
                    </FrameContent>
                  </AppFrame>
                </ErrorAlertProvider>
              </ConfirmationAlertProvider>
            </AdhocMessageProvider>
          </LocalizationProvider>
        </EnrichContext.Provider>
      </LocaleProvider>
    </FeatureFlagsUpdateContext>);
};

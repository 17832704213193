import { __rest } from "tslib";
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
export const ReloadIcon = (_a) => {
    var { height = 22, width = 22 } = _a, props = __rest(_a, ["height", "width"]);
    return (<SvgIcon width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M13.0815 5.20004C12.5651 4.30564 11.8115 3.57145 10.9039 3.07865C9.99626 2.58586 8.97006 2.35365 7.93871 2.4077C6.90736 2.46175 5.91105 2.79996 5.05992 3.38493C4.20879 3.9699 3.536 4.77884 3.11594 5.72232M14.3695 3.46079L13.8589 5.67248C13.772 6.04917 13.3961 6.28404 13.0194 6.19708L10.8077 5.68649M3.38203 10.8C3.89841 11.6944 4.65207 12.4286 5.55968 12.9214C6.46729 13.4142 7.49349 13.6464 8.52484 13.5923C9.55619 13.5383 10.5525 13.2001 11.4036 12.6151C12.2548 12.0302 12.9275 11.2212 13.3476 10.2777M2.09404 12.5393L2.60463 10.3276C2.69159 9.95088 3.06746 9.71601 3.44415 9.80297L5.65583 10.3136" stroke="#919BAB" strokeWidth="1.05" strokeLinecap="round"/>
  </SvgIcon>);
};
export default ReloadIcon;

import { __awaiter } from "tslib";
import { isNil, update } from 'ramda';
import { get } from 'lodash';
import { EnrichSection } from '@groupby/enrich-console';
import { isAdminModelType, isGlobalModelType } from './models';
import { Routing, SearchForRetail } from '@/constants';
import { hasEnrichAccessOnly } from '@/config/utils';
import { EvaluationType } from './evaluate/evaluate.constants';
/**
 * Assumes pageNumber is 1-indexed, ie. the first page is page 1.
 * Returns the minimum and maximum index given a page number and
 * the number of rows per page.
 */
export const getItemIndicesFromPagination = (pageNumber, rowsPerPage) => ([(pageNumber - 1) * rowsPerPage, pageNumber * rowsPerPage]);
/**
 * Returns a new name for a duplicated model.
 * If a model has no duplicates, it will return the model name with '_COPY' appended to it.
 * If the model has duplicates, it will append a copy version number to '_COPY',
 * incrementing the greatest copy version number by 1.
 */
export const generateModelDuplicateName = (models, nameProperty, originalName) => {
    const regex = new RegExp(`^${originalName}(?:_COPY(\\d*))?$`);
    const matches = models.filter((model) => regex.test(String(model[nameProperty])));
    if (matches.length <= 1)
        return `${originalName}_COPY`;
    const versionNumber = matches.reduce((latestVersion, { [nameProperty]: modelName }) => {
        const [, extractedVersion] = String(modelName).match(regex);
        const duplicateModelVersion = extractedVersion === undefined
            ? 0
            : Number(extractedVersion || '1');
        return Math.max(latestVersion, duplicateModelVersion);
    }, 0);
    return `${originalName}_COPY${versionNumber + 1}`;
};
export const moveTableRow = ({ item, fromIndex, toIndex, reorderAction, }) => {
    let correctedToIndex = toIndex;
    if (fromIndex < toIndex)
        correctedToIndex++;
    if (fromIndex === correctedToIndex)
        return;
    reorderAction(fromIndex, correctedToIndex, item);
};
export const dragTableRow = (allRows, filteredRows, reorderAction) => (fromIndex, toIndex) => {
    const fromModel = filteredRows[fromIndex];
    const toModel = filteredRows[fromIndex < toIndex ? toIndex - 1 : toIndex];
    const correctedFromIndex = allRows.findIndex(({ id }) => id === fromModel.id);
    const correctedToIndex = allRows.findIndex(({ id }) => id === toModel.id);
    moveTableRow({
        item: allRows[correctedFromIndex],
        fromIndex: correctedFromIndex,
        toIndex: correctedToIndex,
        reorderAction,
    });
};
export const moveTableRowAboveBelow = (allRows, filteredRows, reorderAction) => (fromIndex, toIndex) => {
    const fromModel = filteredRows[fromIndex];
    const correctedFromIndex = allRows.findIndex(({ id }) => id === fromModel.id);
    moveTableRow({
        item: allRows[correctedFromIndex],
        fromIndex: correctedFromIndex,
        toIndex,
        reorderAction,
    });
};
export const doesStringMatchNumPatterns = (value, patterns, minRequiredMatches = patterns.length) => {
    const totalMatch = patterns.reduce((acc, pattern) => {
        let sum = acc;
        sum += (value === null || value === void 0 ? void 0 : value.match(pattern)) ? 1 : 0;
        return sum;
    }, 0);
    return totalMatch >= minRequiredMatches;
};
export const getEmailLocalPart = (email = '') => email.split('@')[0];
export const getMessageKeyFromModelType = (modelType) => SearchForRetail.MODEL_TYPE_DISPLAY_NAMES_LOCALIZED_MESSAGE_KEY[modelType];
export const updateItemInModelList = (item) => (oldData) => {
    const index = oldData.findIndex(({ id }) => id === item.id);
    if (index === -1) {
        return oldData;
    }
    const newData = update(index, item, oldData);
    return newData;
};
export const updateItemsInModelList = (itemsToUpdate, updatedItems) => (items) => {
    let newData = items.slice();
    const indexMap = itemsToUpdate.reduce((acc, { key, listIndex }) => (Object.assign(Object.assign({}, acc), { [key]: listIndex })), {});
    updatedItems.forEach((updatedItem) => {
        const listIndex = indexMap[updatedItem.key];
        if (!isNil(listIndex) && updatedItem.key === items[listIndex].key) {
            newData = update(listIndex, updatedItem, newData);
        }
    });
    return newData;
};
export function calculatePriority(sourceIndex, targetIndex, models) {
    const priorities = models.map((m) => m.priority);
    // due to the way the UI creates the droppable row, the newIndex calculation differs when dragging from above vs. below
    let newIndex = targetIndex;
    if (sourceIndex < targetIndex) {
        newIndex = targetIndex - 1;
    }
    return priorities[newIndex];
}
export const disableBulkCopy = (modelList) => !!modelList.find((model) => !model.copyable);
export const updateSelectedRowsWithCopyable = (selectedRows, clickedModel, copyable) => {
    const indexOfItemInSelected = selectedRows.findIndex((model) => model.id === clickedModel.id);
    if (indexOfItemInSelected >= 0) {
        selectedRows.splice(indexOfItemInSelected, 1, Object.assign(Object.assign({}, clickedModel), { copyable }));
    }
    return selectedRows;
};
export const getNavigationName = (navigation) => {
    let name = '';
    if (navigation === null || navigation === void 0 ? void 0 : navigation.field) {
        name += navigation.field;
    }
    if (navigation === null || navigation === void 0 ? void 0 : navigation.name) {
        name += name ? ` (${navigation.name})` : navigation.name;
    }
    return name;
};
export const getRulePinnedRefinementsNamedLabel = (item) => {
    let name = '';
    if (item === null || item === void 0 ? void 0 : item.navigation) {
        name += item.navigation;
    }
    if (item === null || item === void 0 ? void 0 : item.displayName) {
        name += name ? ` (${item.displayName})` : item.displayName;
    }
    return name;
};
export const updatePinnedRefinementsPriorities = (items = []) => items.map(({ value }, index) => ({
    value,
    priority: index + 1,
}));
export const copyTextToClipboard = (text) => __awaiter(void 0, void 0, void 0, function* () {
    yield navigator.clipboard.writeText(text);
});
export const generateImageUrl = (allMeta, imageData, evaluationPageType) => {
    const { imagePrefix = '', imageSuffix = '', imageAttributePath = '', imageAttributePathRecommendations = '', imagePrefixRecommendations = '', imageSuffixRecommendations = '', } = imageData !== null && imageData !== void 0 ? imageData : {};
    const isRecommendations = evaluationPageType === EvaluationType.Recommendations;
    const prefix = isRecommendations ? imagePrefixRecommendations : imagePrefix;
    const suffix = isRecommendations ? imageSuffixRecommendations : imageSuffix;
    const attributePath = isRecommendations ? imageAttributePathRecommendations : imageAttributePath;
    const attributeValue = get(allMeta, attributePath, '');
    const imagePathValue = typeof attributeValue === 'string' ? attributeValue : '';
    return `${prefix}${imagePathValue}${suffix}`;
};
export const generateCollectionAreaUrl = (collection, modelType, area) => {
    let url = modelType && !isAdminModelType(modelType) && collection ? `/${collection}` : '';
    url += !isGlobalModelType(modelType) && collection && area ? `/${area}` : '';
    return url;
};
export var SearchForRetailUtilities;
(function (SearchForRetailUtilities) {
    SearchForRetailUtilities.navigateTo = () => {
        if (hasEnrichAccessOnly) {
            return `${Routing.ENRICH_PATH}/${EnrichSection.ProductEditor}/viewer`;
        }
        return Routing.MERCHANDISING_PATH;
    };
})(SearchForRetailUtilities || (SearchForRetailUtilities = {}));
export const createFormNavigationState = (input) => ({ action: input.action });

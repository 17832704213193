import styled from 'styled-components';
import DialogActions from '@mui/material/DialogActions';
import { Button } from '@/components/button';
export const StyledDialogActions = styled(DialogActions) `
  && {
    flex-direction: row-reverse;
    justify-content: space-between;
    padding: 14px 16px 14px 8px;
    background-color: #F8F8FA;
  }
`;
StyledDialogActions.displayName = 'StyledDialogActions';
export const StyledConfirmationButton = styled(Button) `
  && {
    background-color: #A90B0B;
    color: #FFF;

    &:hover {
      background-color: #C5231F;
    }
  }
`;

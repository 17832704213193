import { useCallback, useState } from 'react';
export const useMoreMenu = () => {
    const [moreMenuAnchorEl, setMoreMenuAnchorEl] = useState(null);
    const [currentIndexMenuOpen, setCurrentIndexMenuOpen] = useState(null);
    const openMoreMenu = useCallback((index) => (e) => {
        setMoreMenuAnchorEl(e.currentTarget);
        setCurrentIndexMenuOpen(index);
    }, [setCurrentIndexMenuOpen, setMoreMenuAnchorEl]);
    const closeMoreMenu = useCallback(() => {
        setMoreMenuAnchorEl(null);
        setCurrentIndexMenuOpen(null);
    }, [setCurrentIndexMenuOpen, setMoreMenuAnchorEl]);
    return [
        {
            moreMenuAnchorEl,
            currentIndexMenuOpen,
        },
        {
            openMoreMenu,
            closeMoreMenu,
        },
        {
            setMoreMenuAnchorEl,
            setCurrentIndexMenuOpen,
        },
    ];
};
export default useMoreMenu;

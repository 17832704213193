let _documents;
export function initSearchIndex(documents) {
    _documents = documents;
}
export const searchPhrase = (phrase) => {
    var _a, _b;
    function getFieldSearchResult(field, document) {
        const startIndex = document[field].search(phrase);
        if (startIndex < 0) {
            return;
        }
        return {
            field, phrase, startIndex, phraseLength, document,
        };
    }
    const phraseLength = phrase.length;
    const result = { items: [] };
    let searchResultItem;
    for (const doc of _documents) {
        searchResultItem = (_b = (_a = getFieldSearchResult('content', doc)) !== null && _a !== void 0 ? _a : getFieldSearchResult('releaseName', doc)) !== null && _b !== void 0 ? _b : getFieldSearchResult('releaseDate', doc);
        if (searchResultItem) {
            result.items.push(searchResultItem);
        }
    }
    return result;
};

import React, { useState } from 'react';
import { ErrorAlert } from './error-alert.component';
export const ErrorAlertContext = React.createContext({ openDialog: () => { } });
export const ErrorAlertProvider = ({ children }) => {
    const [dialogConfig, setDialogConfig] = useState(null);
    const openDialog = (config) => setDialogConfig(Object.assign({ open: true }, config));
    const closeDialog = () => setDialogConfig(null);
    const handleConfirm = () => {
        closeDialog();
        dialogConfig.actionCallback(true);
    };
    const handleDismiss = () => {
        closeDialog();
        dialogConfig.actionCallback(false);
    };
    return (<ErrorAlertContext.Provider value={{ openDialog }}>
      {children}

      {dialogConfig && dialogConfig.open && (<ErrorAlert open message={dialogConfig.message} onConfirm={handleConfirm} onDismiss={handleDismiss}/>)}
    </ErrorAlertContext.Provider>);
};
export default ErrorAlertProvider;

import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import * as AuthSelectors from './auth';
export function authSelector({ auth }) {
    return auth;
}
export const useAuthSelector = () => useSelector(authSelector);
export const isLoggingInSelector = createSelector(authSelector, ({ isLoggingIn }) => isLoggingIn);
export const isAuthenticatedSelector = createSelector(authSelector, ({ isAuthenticated }) => isAuthenticated);
export const authTokenSelector = createSelector(authSelector, ({ token }) => token);
export const authRefreshTokenSelector = createSelector(authSelector, ({ refreshToken }) => refreshToken);
export const activeUserSelector = createSelector(authSelector, ({ user }) => user);
export const emailSelector = createSelector(activeUserSelector, ({ email }) => email);
export const isDirtyAuthenticatedSelector = createSelector(AuthSelectors.isAuthenticatedSelector, AuthSelectors.authTokenSelector, (isAuthenticated, token) => token && !isAuthenticated);
export const externalAuthenticationProvidersSelector = createSelector(authSelector, ({ externalProviders }) => externalProviders);
export const userIdSelector = createSelector(activeUserSelector, (user) => user === null || user === void 0 ? void 0 : user.id);
export const userSettingsSelector = createSelector(activeUserSelector, (user) => user === null || user === void 0 ? void 0 : user.settings);
export const preferredLanguageSelector = createSelector(userSettingsSelector, (settings) => settings === null || settings === void 0 ? void 0 : settings.preferredLanguage);
export const analyticDashboardsSettingsSelector = createSelector(userSettingsSelector, (settings) => (settings === null || settings === void 0 ? void 0 : settings.analyticDashboardsSettings) || '{}');
export const useIsLoggingInSelector = () => useSelector(isLoggingInSelector);
export const useIsAuthenticatedSelector = () => useSelector(isAuthenticatedSelector);
export const usePreferredLanguageSelector = () => useSelector(preferredLanguageSelector);
export const useAuthTokenSelector = () => useSelector(authTokenSelector);
export const useAnalyticsDashboardsSettingsSelector = () => useSelector(analyticDashboardsSettingsSelector);
export const useDirtyAuthenticatedSelector = () => useSelector(isDirtyAuthenticatedSelector);
export const useActiveUserSelector = () => useSelector(activeUserSelector);
export const useUserSettings = () => useSelector(userSettingsSelector);
export const useAllUserAreaPermissions = () => createSelector(activeUserSelector, (activeUser) => [...activeUser.areaPermissions]);

import * as Actions from '@/store/actions/types';
import * as CacheReducers from './cache';
export const DEFAULT = {};
export default (state = DEFAULT, action) => {
    switch (action.type) {
        case Actions.ADD_CACHE_ENTRY:
            return CacheReducers.addCacheEntry(state, action);
        case Actions.CLEAR_CACHE:
            return CacheReducers.clearCache();
        case Actions.PURGE_EXPIRED_CACHE_ENTRIES:
            return CacheReducers.purgeExpiredEntries(state, action);
        default:
            return state;
    }
};
export function addCacheEntry(state, { payload: { key, data, expires } }) {
    return Object.assign(Object.assign({}, state), { [key]: { data, expires } });
}
export function clearCache() {
    return DEFAULT;
}
export function purgeExpiredEntries(state, { payload: time }) {
    return Object.entries(state)
        .filter(([_, { expires }]) => time < expires)
        .reduce((newCache, [key, value]) => Object.assign(newCache, { [key]: value }), {});
}

import { __rest } from "tslib";
import React from 'react';
import { TimePicker as BaseTimePicker } from '@groupby/ui-components';
import { useLocalization } from '@/localization';
export const TimePicker = (_a) => {
    var { selectedDateTime, setSelectedTime } = _a, props = __rest(_a, ["selectedDateTime", "setSelectedTime"]);
    const { formatMessage: t, getLocale } = useLocalization();
    const selectedLanguage = getLocale();
    const handleDateChange = (time) => setSelectedTime(time);
    return (<BaseTimePicker id="start-time" label={t({ key: 'RULE_CREATE_DATE_RANGE_START_TIME_LABEL' })} handleDateChange={handleDateChange} selectedTime={selectedDateTime} cancelLabel={t({ key: 'CANCEL' })} okLabel={t({ key: 'OK' })} locale={selectedLanguage} {...props}/>);
};
export default TimePicker;

import React from 'react';
import 'brace';
import 'brace/mode/html';
import 'brace/theme/tomorrow';
import './polyfills';
import './styles.css';
import { createBrowserRouter, Navigate, RouterProvider, useRouteError, } from 'react-router-dom';
import { Enrich } from '@/pages/enrich/enrich';
import { AppLayout, LoggedInLayout } from '@/app/layouts';
import { SearchForRetailLayout } from '@/search-for-retail/search-for-retail.layout';
import { SearchForRetailRoutes as S4RRoutes } from '@/search-for-retail/search-for-retail.routes';
import { SearchForRetailUtilities as S4RUtils } from '@/search-for-retail/utilities';
import loadable from '@/components/loadable';
import { LoggedInGuard, LoggedOutGuard } from '@/routing';
import { FeatureFlag, useFeatureFlagsProvider } from '@/search-for-retail/feature-flags';
import { ReleaseNotes } from '@/pages/release-notes';
import FullScreenErrorOverlay from '@/error-boundary/error-screen.component.';
import { AuthProvider } from '@/auth';
import { GbLoading } from '@/components/gb-loading';
import { GlobalStyle } from './app.styles';
const ChunkLoadErrorFallback = () => {
    const error = useRouteError();
    const isChunkLoadError = error instanceof Error && error.name === 'ChunkLoadError';
    if (!isChunkLoadError) {
        return null;
    }
    return (<FullScreenErrorOverlay />);
};
const Login = () => {
    const Component = loadable(() => import('@/pages/login' /* webpackChunkName: "s4r-login" */));
    return <Component />;
};
const GuardedLogin = () => (<LoggedOutGuard authNavigateTo={S4RUtils.navigateTo()}>
    <Login />
  </LoggedOutGuard>);
const GuardedLoggedInLayout = () => (<LoggedInGuard>
    <LoggedInLayout />
  </LoggedInGuard>);
const createRouter = (featureFlagsService) => createBrowserRouter([
    {
        id: 'root',
        element: <AppLayout />,
        errorElement: <ChunkLoadErrorFallback />,
        children: [
            {
                path: '/',
                element: <GuardedLoggedInLayout />,
                errorElement: <ChunkLoadErrorFallback />,
                children: [
                    {
                        index: true,
                        element: <Navigate to={S4RUtils.navigateTo()}/>,
                        errorElement: <ChunkLoadErrorFallback />,
                    },
                    {
                        path: 'merchandising',
                        element: <SearchForRetailLayout />,
                        children: S4RRoutes.getRoutes(featureFlagsService),
                        errorElement: <ChunkLoadErrorFallback />,
                    },
                    {
                        path: 'enrich/*',
                        element: <Enrich />,
                        errorElement: <ChunkLoadErrorFallback />,
                    },
                    {
                        path: 'release-notes/:search?',
                        element: <ReleaseNotes />,
                        errorElement: <ChunkLoadErrorFallback />,
                    },
                    {
                        path: '*',
                        element: <Navigate to={S4RUtils.navigateTo()}/>,
                        errorElement: <ChunkLoadErrorFallback />,
                    },
                ],
            },
            {
                path: '/login',
                element: <GuardedLogin />,
                errorElement: <ChunkLoadErrorFallback />,
            },
        ],
    },
]);
export const App = () => {
    const { featureFlagsService } = useFeatureFlagsProvider();
    const enableFontSize = featureFlagsService
        .isFeatureEnabled(FeatureFlag.enableFontSize);
    if (enableFontSize.enabled) {
        document.querySelector('html').classList.add('smaller-font-size');
    }
    return (<AuthProvider>
      {enableFontSize.enabled && (<GlobalStyle fontSize={enableFontSize.fontSize}/>)}
      <GbLoading>
        <RouterProvider router={createRouter(featureFlagsService)}/>
      </GbLoading>
    </AuthProvider>);
};

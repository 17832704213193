import React from 'react';
import { withStyles } from '@/decorators';
export const styles = ({ palette }) => ({
    root: {
        position: 'relative',
        height: '100vh',
        width: '100vw',
        background: palette.background.default,
    },
});
const Frame = withStyles(styles)(function Frame({ classes, children }) {
    return <section className={classes.root}>{children}</section>;
});
export default Frame;

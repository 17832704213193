import { __rest } from "tslib";
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
export const AverageOrderValueIcon = (_a) => {
    var { width = 78, height = 76 } = _a, props = __rest(_a, ["width", "height"]);
    return (<SvgIcon xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 78 76" fill="none" {...props}>
    <path d="M21.3124 28.3745C29.0608 28.3745 35.3422 22.0912 35.3422 14.3403C35.3422 6.58949 29.0608 0.306183 21.3124 0.306183C13.564 0.306183 7.28271 6.58949 7.28271 14.3403C7.28271 22.0912 13.564 28.3745 21.3124 28.3745Z" fill="#E8EBEC"/>
    <path d="M65.0053 58.5481C71.6469 58.5481 77.0309 53.1624 77.0309 46.5188C77.0309 39.8752 71.6469 34.4895 65.0053 34.4895C58.3638 34.4895 52.9797 39.8752 52.9797 46.5188C52.9797 53.1624 58.3638 58.5481 65.0053 58.5481Z" fill="#E8EBEC"/>
    <path d="M21.0118 75.5895C32.081 75.5895 41.0543 66.6133 41.0543 55.5407C41.0543 44.468 32.081 35.4919 21.0118 35.4919C9.94259 35.4919 0.969238 44.468 0.969238 55.5407C0.969238 66.6133 9.94259 75.5895 21.0118 75.5895Z" fill="#E8EBEC"/>
    <path d="M68.713 54.0369C68.4124 55.6408 67.3101 57.0443 65.8069 57.6457L53.2803 62.4574C50.775 63.4599 47.969 62.0565 47.1673 59.4501C46.6662 57.5455 47.6683 55.5406 49.5724 54.9391L52.0777 54.1372C59.0926 52.6335 63.2013 50.0272 65.8069 50.0272C68.212 49.9269 69.2141 52.0321 68.713 54.0369Z" fill="white"/>
    <path d="M51.5767 63.56C50.775 63.56 49.9733 63.3595 49.1716 62.9586C47.8688 62.2569 46.8667 61.0539 46.4659 59.6505C45.8646 57.3449 47.0671 54.939 49.372 54.2373L51.8773 53.4354C55.5852 52.6334 58.3911 51.5307 60.696 50.7288C62.8004 49.9268 64.5041 49.3254 65.907 49.3254C67.1096 49.3254 68.2119 49.7264 68.8132 50.6286C69.5147 51.5308 69.8154 52.9342 69.5147 54.2373C69.2141 56.142 67.9113 57.7459 66.1075 58.4476L53.5809 63.2593C52.8794 63.4598 52.178 63.56 51.5767 63.56ZM65.907 50.7288C65.8068 50.7288 65.8068 50.7288 65.907 50.7288C64.8047 50.7288 63.2013 51.3303 61.197 52.1322C58.8922 53.0344 55.986 54.1371 52.2782 54.9391L49.8731 55.741C48.3699 56.2422 47.468 57.8461 47.9691 59.3498C48.2697 60.4525 48.9712 61.2544 49.9733 61.7556C50.9754 62.2569 52.0777 62.2569 53.0799 61.9561L65.6064 57.1444C66.9092 56.6432 67.8111 55.4403 68.0115 54.1371C68.0115 54.1371 68.0115 54.1371 68.0115 54.0368C68.212 53.1346 68.1117 52.2325 67.6107 51.631C67.2098 51.0295 66.6085 50.7288 65.907 50.7288Z" fill="#21365F"/>
    <path d="M71.4186 53.9367C69.5145 52.3328 64.103 57.2448 50.1735 59.8511C51.9773 59.1494 52.6788 58.147 52.779 57.1445C53.0797 52.5333 49.0712 52.7338 49.0712 52.7338C28.5276 52.9343 44.1608 48.1226 16.2014 47.9221V65.966C16.2014 65.966 46.9667 76.3914 70.3163 58.4477C70.3163 58.548 74.0241 56.0419 71.4186 53.9367Z" fill="white"/>
    <path d="M50.1738 59.9513C51.9777 59.6506 53.6813 59.2496 55.1845 58.8486C53.9819 58.8486 52.9798 58.8486 52.0779 58.7484C51.6771 59.1493 51.0758 59.5503 50.1738 59.9513Z" fill="#BDC3D8"/>
    <path d="M71.4189 53.9366C69.7153 52.5332 65.5063 56.0417 55.1844 58.7483C53.6812 59.1492 51.9776 59.5502 50.1738 59.8509C51.0757 59.5502 51.677 59.1492 52.0778 58.648C48.6706 58.4475 46.8667 58.1468 45.6642 57.6456C36.645 54.1371 27.0246 59.2495 24.7197 67.9707C33.338 69.3741 47.1674 70.1761 60.3955 64.3619C63.8027 62.8583 67.1098 60.9536 70.3166 58.5478C70.3166 58.5478 74.0244 56.0417 71.4189 53.9366Z" fill="#BDC3D8"/>
    <path d="M37.0457 69.7751C25.1204 69.7751 16.2015 66.7678 16.0011 66.7678C15.7004 66.6676 15.5 66.3669 15.5 66.0661V48.0222C15.5 47.8217 15.6002 47.6212 15.7004 47.521C15.8007 47.4207 16.1013 47.3205 16.2015 47.3205C31.3336 47.4207 33.9392 48.9244 36.244 50.2276C38.1481 51.3302 39.7515 52.2325 49.0713 52.1322C49.0713 52.1322 50.9753 52.032 52.2781 53.4354C53.18 54.3376 53.5809 55.741 53.4806 57.3449C53.4806 57.7459 53.3804 58.1469 53.0798 58.6481C58.7919 57.3449 62.8004 55.6407 65.6064 54.5381C68.8132 53.2349 70.617 52.4329 71.9197 53.5356C72.9219 54.3376 73.1223 55.2398 73.1223 55.8412C73.0221 57.6456 71.0178 59.1493 70.7172 59.2495C59.8942 67.5698 47.3676 69.7751 37.0457 69.7751ZM17.0032 65.4647C21.1119 66.6676 48.7706 74.1859 69.9155 57.9464C70.4166 57.6456 71.6191 56.6432 71.6191 55.6408C71.6191 55.5405 71.6191 55.0393 71.0179 54.5381C70.4166 54.0368 68.713 54.8388 66.3079 55.8412C63.0008 57.2447 57.89 59.2495 50.4743 60.7532C50.0734 60.8534 49.6725 60.5527 49.5723 60.252C49.4721 59.851 49.6725 59.45 50.0734 59.3498C51.3762 58.8486 52.0776 58.1468 52.1778 57.2446C52.278 56.0417 51.9774 55.0393 51.3761 54.4378C50.5744 53.5356 49.2717 53.6359 49.2717 53.6359C39.4509 53.7361 37.7472 52.7337 35.6427 51.5307C33.5383 50.3278 31.2334 48.9244 17.1034 48.8242V65.4647H17.0032Z" fill="#21365F"/>
    <path d="M43.259 61.5551C40.7537 61.5551 37.8475 61.3547 34.5405 60.8534C34.1396 60.7532 33.839 60.4525 33.9392 60.0515C34.0394 59.6505 34.3401 59.3498 34.7409 59.45C43.3592 60.5527 47.7685 59.9512 50.0734 59.2495C50.4743 59.1493 50.8752 59.3498 50.9754 59.7508C51.0756 60.1517 50.8752 60.5527 50.4743 60.653C48.5703 61.2544 46.1652 61.5551 43.259 61.5551Z" fill="#21365F"/>
    <path d="M16.2015 48.0224H8.18457V66.0663H16.2015V48.0224Z" fill="white"/>
    <path d="M16.2015 66.0663H12.193V56.0419C12.193 53.8365 13.9968 52.0321 16.2015 52.0321V66.0663Z" fill="#BDC3D8"/>
    <path d="M16.2016 66.768H8.18451C7.78365 66.768 7.38281 66.4673 7.38281 65.966V47.9221C7.38281 47.5212 7.68344 47.1202 8.18451 47.1202H16.2016C16.6025 47.1202 17.0033 47.4209 17.0033 47.9221V65.966C17.0033 66.4673 16.6025 66.768 16.2016 66.768ZM8.98621 65.2643H15.5001V48.7241H8.98621V65.2643Z" fill="#21365F"/>
    <path d="M12.193 63.0589C12.7464 63.0589 13.1951 62.6101 13.1951 62.0565C13.1951 61.5029 12.7464 61.0541 12.193 61.0541C11.6396 61.0541 11.1909 61.5029 11.1909 62.0565C11.1909 62.6101 11.6396 63.0589 12.193 63.0589Z" fill="#21365F"/>
    <path d="M46.2653 49.0248C58.4414 49.0248 68.3121 39.1511 68.3121 26.9712C68.3121 14.7912 58.4414 4.91748 46.2653 4.91748C34.0892 4.91748 24.2185 14.7912 24.2185 26.9712C24.2185 39.1511 34.0892 49.0248 46.2653 49.0248Z" fill="white"/>
    <path d="M68.3121 26.9712C68.3121 22.9614 67.2098 19.1521 65.3058 15.9443C62.099 14.0397 58.2909 12.937 54.2824 12.937C42.0564 12.937 32.2356 22.7609 32.2356 34.9907C32.2356 39.0005 33.3379 42.8097 35.242 46.0175C38.4488 47.9222 42.2569 49.0249 46.2654 49.0249C58.4913 49.0249 68.3121 39.1007 68.3121 26.9712Z" fill="#BDC3D8"/>
    <path d="M46.2653 49.7265C33.7387 49.7265 23.4167 39.5017 23.4167 26.8709C23.4167 14.3404 33.6384 4.01529 46.2653 4.01529C58.8921 4.01529 69.1138 14.2402 69.1138 26.8709C69.1138 39.5017 58.8921 49.7265 46.2653 49.7265ZM46.2653 5.61919C34.5404 5.61919 24.9199 15.1424 24.9199 26.9712C24.9199 38.6997 34.4401 48.3231 46.2653 48.3231C58.0904 48.3231 67.6106 38.7999 67.6106 26.9712C67.6106 15.2426 57.9902 5.61919 46.2653 5.61919Z" fill="#21365F"/>
    <path d="M71.9199 22.9614C71.5191 22.9614 71.2184 22.6607 71.2184 22.36C70.3165 17.6485 68.1118 13.2378 64.8048 9.72923C64.5042 9.4285 64.5042 8.92728 64.8048 8.62655C65.1055 8.32582 65.6065 8.32582 65.9071 8.62655C69.4146 12.3356 71.7195 17.047 72.7216 22.0592C72.8218 22.4602 72.5212 22.8612 72.1204 22.9614C72.0201 22.9614 71.9199 22.9614 71.9199 22.9614Z" fill="#21365F"/>
    <path d="M62.2994 7.12272C62.099 7.12272 61.9988 7.02247 61.7983 6.92223C61.0968 6.421 60.3953 5.91978 59.6938 5.41856C59.2929 5.21807 59.1928 4.71685 59.4934 4.41612C59.6939 4.11538 60.1949 3.9149 60.4956 4.21563C61.2973 4.71685 61.9987 5.21807 62.7002 5.7193C63.0009 6.02003 63.1011 6.42101 62.8005 6.82198C62.8005 7.02247 62.4998 7.12272 62.2994 7.12272Z" fill="#21365F"/>
    <path d="M46.2655 43.0102C55.1209 43.0102 62.2996 35.8293 62.2996 26.9711C62.2996 18.113 55.1209 10.9321 46.2655 10.9321C37.4101 10.9321 30.2314 18.113 30.2314 26.9711C30.2314 35.8293 37.4101 43.0102 46.2655 43.0102Z" fill="white"/>
    <path d="M62.2993 26.9711C62.2993 24.6654 61.7982 22.3598 60.8963 20.355C58.892 19.4528 56.6874 18.9515 54.2823 18.9515C45.4635 18.9515 38.2482 26.1691 38.2482 34.9906C38.2482 37.2962 38.7492 39.6018 39.6511 41.6067C41.6554 42.5089 43.8601 43.0101 46.2652 43.0101C55.1842 43.0101 62.2993 35.7925 62.2993 26.9711Z" fill="#BDC3D8"/>
    <path d="M46.2654 43.7119C37.0458 43.7119 29.4297 36.1936 29.4297 26.8709C29.4297 17.6484 36.9456 10.0299 46.2654 10.0299C55.5851 10.0299 63.1011 17.5482 63.1011 26.8709C63.1011 36.1936 55.5851 43.7119 46.2654 43.7119ZM46.2654 11.6338C37.8475 11.6338 30.9329 18.4504 30.9329 26.9711C30.9329 35.3916 37.7473 42.3084 46.2654 42.3084C54.7834 42.3084 61.5979 35.4919 61.5979 26.9711C61.5979 18.5506 54.6832 11.6338 46.2654 11.6338Z" fill="#21365F"/>
    <path d="M57.0885 32.9858C56.9883 32.9858 56.8881 32.9858 56.7879 32.8856C56.3871 32.6851 56.2869 32.2841 56.4873 31.8831C57.289 30.3795 57.5896 28.6753 57.5896 26.9712C57.5896 25.267 57.1888 23.5629 56.4873 22.0592C56.2869 21.6582 56.4873 21.2573 56.7879 21.0568C57.1887 20.8563 57.5896 21.0568 57.79 21.3575C58.6919 23.0617 59.0927 24.9663 59.0927 26.9712C59.0927 28.976 58.6919 30.7804 57.79 32.5848C57.6898 32.7853 57.3892 32.9858 57.0885 32.9858Z" fill="#21365F"/>
    <path d="M35.4425 32.9857C35.1418 32.9857 34.9414 32.7853 34.741 32.5848C33.8391 30.8806 33.4382 28.976 33.4382 26.9711C33.4382 24.9662 33.8391 23.1618 34.741 21.3574C34.9414 20.9564 35.3422 20.8562 35.7431 21.0567C36.1439 21.2572 36.2441 21.6582 36.0437 22.0591C35.242 23.5628 34.9414 25.2669 34.9414 26.9711C34.9414 28.6752 35.3422 30.3794 36.0437 31.8831C36.2441 32.284 36.0437 32.685 35.7431 32.8855C35.7431 32.9857 35.5427 32.9857 35.4425 32.9857Z" fill="#21365F"/>
    <path d="M27.225 27.7729C27.6678 27.7729 28.0267 27.4139 28.0267 26.971C28.0267 26.5281 27.6678 26.169 27.225 26.169C26.7823 26.169 26.4233 26.5281 26.4233 26.971C26.4233 27.4139 26.7823 27.7729 27.225 27.7729Z" fill="#21365F"/>
    <path d="M65.3058 27.7729C65.7486 27.7729 66.1075 27.4139 66.1075 26.971C66.1075 26.5281 65.7486 26.169 65.3058 26.169C64.8631 26.169 64.5042 26.5281 64.5042 26.971C64.5042 27.4139 64.8631 27.7729 65.3058 27.7729Z" fill="#21365F"/>
    <path d="M46.2654 46.8193C46.7081 46.8193 47.067 46.4603 47.067 46.0174C47.067 45.5745 46.7081 45.2154 46.2654 45.2154C45.8226 45.2154 45.4637 45.5745 45.4637 46.0174C45.4637 46.4603 45.8226 46.8193 46.2654 46.8193Z" fill="#21365F"/>
    <path d="M46.2654 8.72659C46.7081 8.72659 47.067 8.36754 47.067 7.92463C47.067 7.48172 46.7081 7.12268 46.2654 7.12268C45.8226 7.12268 45.4637 7.48172 45.4637 7.92463C45.4637 8.36754 45.8226 8.72659 46.2654 8.72659Z" fill="#21365F"/>
    <path d="M59.7941 41.2058C60.2369 41.2058 60.5958 40.8467 60.5958 40.4038C60.5958 39.9609 60.2369 39.6019 59.7941 39.6019C59.3514 39.6019 58.9924 39.9609 58.9924 40.4038C58.9924 40.8467 59.3514 41.2058 59.7941 41.2058Z" fill="#21365F"/>
    <path d="M32.8367 14.1398C33.2241 14.1398 33.5382 13.8256 33.5382 13.4381C33.5382 13.0505 33.2241 12.7364 32.8367 12.7364C32.4493 12.7364 32.1353 13.0505 32.1353 13.4381C32.1353 13.8256 32.4493 14.1398 32.8367 14.1398Z" fill="#21365F"/>
    <path d="M59.7941 14.24C60.2369 14.24 60.5958 13.8809 60.5958 13.438C60.5958 12.9951 60.2369 12.6361 59.7941 12.6361C59.3514 12.6361 58.9924 12.9951 58.9924 13.438C58.9924 13.8809 59.3514 14.24 59.7941 14.24Z" fill="#21365F"/>
    <path d="M32.8367 41.1054C33.2241 41.1054 33.5382 40.7912 33.5382 40.4037C33.5382 40.0161 33.2241 39.7019 32.8367 39.7019C32.4493 39.7019 32.1353 40.0161 32.1353 40.4037C32.1353 40.7912 32.4493 41.1054 32.8367 41.1054Z" fill="#21365F"/>
    <path d="M63.9028 35.0909C64.3455 35.0909 64.7045 34.7318 64.7045 34.2889C64.7045 33.846 64.3455 33.487 63.9028 33.487C63.46 33.487 63.1011 33.846 63.1011 34.2889C63.1011 34.7318 63.46 35.0909 63.9028 35.0909Z" fill="#21365F"/>
    <path d="M28.7282 20.4552C29.171 20.4552 29.53 20.0962 29.53 19.6533C29.53 19.2104 29.171 18.8513 28.7282 18.8513C28.2855 18.8513 27.9265 19.2104 27.9265 19.6533C27.9265 20.0962 28.2855 20.4552 28.7282 20.4552Z" fill="#21365F"/>
    <path d="M53.5808 10.0298C53.9682 10.0298 54.2823 9.71562 54.2823 9.32808C54.2823 8.94053 53.9682 8.62637 53.5808 8.62637C53.1934 8.62637 52.8794 8.94053 52.8794 9.32808C52.8794 9.71562 53.1934 10.0298 53.5808 10.0298Z" fill="#21365F"/>
    <path d="M38.95 45.3156C39.3928 45.3156 39.7517 44.9566 39.7517 44.5137C39.7517 44.0708 39.3928 43.7117 38.95 43.7117C38.5072 43.7117 38.1482 44.0708 38.1482 44.5137C38.1482 44.9566 38.5072 45.3156 38.95 45.3156Z" fill="#21365F"/>
    <path d="M63.9028 20.4552C64.3455 20.4552 64.7045 20.0962 64.7045 19.6533C64.7045 19.2104 64.3455 18.8513 63.9028 18.8513C63.46 18.8513 63.1011 19.2104 63.1011 19.6533C63.1011 20.0962 63.46 20.4552 63.9028 20.4552Z" fill="#21365F"/>
    <path d="M28.7282 35.0909C29.171 35.0909 29.53 34.7318 29.53 34.2889C29.53 33.846 29.171 33.487 28.7282 33.487C28.2855 33.487 27.9265 33.846 27.9265 34.2889C27.9265 34.7318 28.2855 35.0909 28.7282 35.0909Z" fill="#21365F"/>
    <path d="M38.9497 10.0298C39.3371 10.0298 39.6512 9.71562 39.6512 9.32808C39.6512 8.94053 39.3371 8.62637 38.9497 8.62637C38.5623 8.62637 38.2482 8.94053 38.2482 9.32808C38.2482 9.71562 38.5623 10.0298 38.9497 10.0298Z" fill="#21365F"/>
    <path d="M53.5808 45.3156C54.0236 45.3156 54.3825 44.9566 54.3825 44.5137C54.3825 44.0708 54.0236 43.7117 53.5808 43.7117C53.1381 43.7117 52.7792 44.0708 52.7792 44.5137C52.7792 44.9566 53.1381 45.3156 53.5808 45.3156Z" fill="#21365F"/>
    <path d="M46.2654 37.1961C43.4594 37.1961 40.9541 35.7927 39.952 33.6875C39.7516 33.2866 39.952 32.8856 40.3528 32.6851C40.7537 32.4846 41.1545 32.6851 41.3549 32.9858C42.1566 34.5897 44.0607 35.5922 46.2654 35.5922C49.1715 35.5922 51.4764 33.7878 51.4764 31.5824C51.4764 28.9761 49.5724 27.5727 46.1651 27.5727C41.9562 27.5727 39.4509 25.4675 39.4509 22.0593C39.4509 19.0519 42.4573 16.5458 46.1651 16.5458C48.9711 16.5458 51.4764 17.9493 52.4786 20.0544C52.679 20.4554 52.4785 20.8563 52.0777 21.0568C51.6768 21.2573 51.276 21.0568 51.0756 20.6558C50.2739 19.0519 48.3698 18.0495 46.1651 18.0495C43.259 18.0495 40.9541 19.8539 40.9541 22.0593C40.9541 24.6656 42.8581 26.069 46.1651 26.069C50.3741 26.069 52.9796 28.1741 52.9796 31.5824C53.0798 34.7902 49.9732 37.1961 46.2654 37.1961Z" fill="#21365F"/>
    <path d="M46.2654 40.2034C45.8646 40.2034 45.4637 39.9026 45.4637 39.4014V14.3404C45.4637 13.9394 45.7644 13.5385 46.2654 13.5385C46.7664 13.5385 47.067 13.8392 47.067 14.3404V39.4014C47.067 39.9026 46.6662 40.2034 46.2654 40.2034Z" fill="#21365F"/>
    <path d="M20.2101 42.0077C21.317 42.0077 22.2144 41.1101 22.2144 40.0029C22.2144 38.8956 21.317 37.998 20.2101 37.998C19.1032 37.998 18.2058 38.8956 18.2058 40.0029C18.2058 41.1101 19.1032 42.0077 20.2101 42.0077Z" fill="white"/>
    <path d="M20.21 42.7094C18.7068 42.7094 17.4041 41.5065 17.4041 39.9026C17.4041 38.3989 18.6066 37.0958 20.21 37.0958C21.8134 37.0958 23.016 38.2987 23.016 39.9026C23.016 41.5065 21.7132 42.7094 20.21 42.7094ZM20.21 38.6997C19.5085 38.6997 18.9073 39.3011 18.9073 40.0028C18.9073 40.7046 19.5085 41.306 20.21 41.306C20.9115 41.306 21.5128 40.7046 21.5128 40.0028C21.5128 39.3011 20.9115 38.6997 20.21 38.6997Z" fill="#21365F"/>
    <path d="M70.3166 46.0175C71.4235 46.0175 72.3209 45.1199 72.3209 44.0126C72.3209 42.9053 71.4235 42.0077 70.3166 42.0077C69.2096 42.0077 68.3123 42.9053 68.3123 44.0126C68.3123 45.1199 69.2096 46.0175 70.3166 46.0175Z" fill="white"/>
    <path d="M70.3164 46.7192C68.8132 46.7192 67.5105 45.5163 67.5105 43.9124C67.5105 42.4087 68.713 41.1055 70.3164 41.1055C71.9198 41.1055 73.1224 42.3085 73.1224 43.9124C73.1224 45.5163 71.8196 46.7192 70.3164 46.7192ZM70.3164 42.7094C69.6149 42.7094 69.0137 43.3109 69.0137 44.0126C69.0137 44.7143 69.6149 45.3158 70.3164 45.3158C71.0179 45.3158 71.6192 44.7143 71.6192 44.0126C71.6192 43.3109 71.0179 42.7094 70.3164 42.7094Z" fill="#21365F"/>
    <path d="M10.1888 33.9882C11.2957 33.9882 12.193 33.0906 12.193 31.9833C12.193 30.876 11.2957 29.9784 10.1888 29.9784C9.08189 29.9784 8.18457 30.876 8.18457 31.9833C8.18457 33.0906 9.08189 33.9882 10.1888 33.9882Z" fill="white"/>
    <path d="M10.1888 34.69C8.68556 34.69 7.38281 33.487 7.38281 31.8831C7.38281 30.3795 8.58535 29.0763 10.1888 29.0763C11.7922 29.0763 12.9947 30.2792 12.9947 31.8831C12.9947 33.487 11.6919 34.69 10.1888 34.69ZM10.1888 30.6802C9.48726 30.6802 8.886 31.2817 8.886 31.9834C8.886 32.6851 9.48726 33.2865 10.1888 33.2865C10.8902 33.2865 11.4915 32.6851 11.4915 31.9834C11.4915 31.2817 10.8902 30.6802 10.1888 30.6802Z" fill="#21365F"/>
    <path d="M18.2057 22.9613C19.3126 22.9613 20.21 22.0637 20.21 20.9564C20.21 19.8492 19.3126 18.9515 18.2057 18.9515C17.0988 18.9515 16.2014 19.8492 16.2014 20.9564C16.2014 22.0637 17.0988 22.9613 18.2057 22.9613Z" fill="white"/>
    <path d="M18.2057 23.6631C16.7025 23.6631 15.3998 22.4602 15.3998 20.8563C15.3998 19.3526 16.6023 18.0494 18.2057 18.0494C19.8091 18.0494 21.0116 19.2524 21.0116 20.8563C21.0116 22.4602 19.7089 23.6631 18.2057 23.6631ZM18.2057 19.6533C17.5042 19.6533 16.903 20.2548 16.903 20.9565C16.903 21.6582 17.5042 22.2597 18.2057 22.2597C18.9072 22.2597 19.5084 21.6582 19.5084 20.9565C19.5084 20.2548 18.9072 19.6533 18.2057 19.6533Z" fill="#21365F"/>
    <path d="M13.1951 11.9346C14.302 11.9346 15.1993 11.0369 15.1993 9.92968C15.1993 8.82242 14.302 7.9248 13.1951 7.9248C12.0882 7.9248 11.1909 8.82242 11.1909 9.92968C11.1909 11.0369 12.0882 11.9346 13.1951 11.9346Z" fill="white"/>
    <path d="M13.1951 12.6363C11.6919 12.6363 10.3892 11.4334 10.3892 9.82947C10.3892 8.32581 11.5917 7.02264 13.1951 7.02264C14.7985 7.02264 16.0011 8.22557 16.0011 9.82947C16.0011 11.4334 14.6983 12.6363 13.1951 12.6363ZM13.1951 8.62655C12.4936 8.62655 11.8924 9.22801 11.8924 9.92971C11.8924 10.6314 12.4936 11.2329 13.1951 11.2329C13.8966 11.2329 14.4979 10.6314 14.4979 9.92971C14.4979 9.22801 13.8966 8.62655 13.1951 8.62655Z" fill="#21365F"/>
    <path d="M26.2227 8.92724C27.3297 8.92724 28.227 8.02962 28.227 6.92236C28.227 5.8151 27.3297 4.91748 26.2227 4.91748C25.1158 4.91748 24.2185 5.8151 24.2185 6.92236C24.2185 8.02962 25.1158 8.92724 26.2227 8.92724Z" fill="white"/>
    <path d="M26.2227 9.62895C24.7195 9.62895 23.4167 8.42602 23.4167 6.82212C23.4167 5.31846 24.6193 4.01529 26.2227 4.01529C27.8261 4.01529 29.0287 5.21821 29.0287 6.82212C29.0287 8.42602 27.7259 9.62895 26.2227 9.62895ZM26.2227 5.61919C25.5212 5.61919 24.92 6.22065 24.92 6.92236C24.92 7.62407 25.5212 8.22553 26.2227 8.22553C26.9242 8.22553 27.5255 7.62407 27.5255 6.92236C27.5255 6.22065 26.9242 5.61919 26.2227 5.61919Z" fill="#21365F"/>
  </SvgIcon>);
};
export default AverageOrderValueIcon;

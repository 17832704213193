class Cache {
    constructor() {
        this.cached = {};
    }
    has(key) {
        const result = this.cached[key];
        if (result) {
            if (result.expiry > Date.now()) {
                return true;
            }
            this.cached[key] = null;
        }
        return false;
    }
    get(key) {
        return this.cached[key].value;
    }
    set(key, value, expiry) {
        this.cached[key] = { value, expiry: Date.now() + expiry };
        return value;
    }
    static generateKey(url, { headers, body }) {
        return `${url}|${JSON.stringify(headers || {})}|${body || ''}`;
    }
}
export default Cache;

import React, { useState } from 'react';
import { TableRow, Tooltip } from '@mui/material';
import { Checkbox } from '@groupby/ui-components';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { StyledSortButton, StyledTableHead, StyledTableHeadCell, StyledTableHeader, } from './table-header.styled';
import { FilterButton } from './filter-button.component';
import { SortDirection } from '@/search-for-retail/models';
import { HeaderBulkActions } from './partials/header-bulk-actions';
export const TableHeader = ({ data, filter, columns, isSelectable, isMoreOption, updateFilter, isSelectedAll, isIndeterminate, handleSelectAll, sort, updateSort, count, selectedCount, bulkActions, tableHasScrollY, }) => {
    const [sortIconHovered, setSortIconHovered] = useState('');
    const onSelectAll = (e) => {
        e.stopPropagation();
        handleSelectAll();
    };
    return (<StyledTableHead>
      {selectedCount > 0 && (<HeaderBulkActions count={count} bulkActions={bulkActions} selectedCount={selectedCount} tableHasScrollY={tableHasScrollY}>
          <StyledTableHeadCell width="50px" sx={{ width: '50px', maxWidth: '50px' }} padding="checkbox"/>
          {isSelectable && (<StyledTableHeadCell width="50px" sx={{ width: '50px', maxWidth: '50px' }} padding="checkbox">
              <Checkbox checked={isSelectedAll} onClick={onSelectAll} indeterminate={isIndeterminate && !isSelectedAll}/>
            </StyledTableHeadCell>)}
        </HeaderBulkActions>)}
      <TableRow>
        <StyledTableHeadCell width="50px" sticky left={0} padding="checkbox"/>
        {isSelectable && (<StyledTableHeadCell width="50px" sticky left={50} padding="checkbox">
            <Checkbox checked={isSelectedAll} onClick={onSelectAll} indeterminate={isIndeterminate && !isSelectedAll}/>
          </StyledTableHeadCell>)}
        {columns.filter(({ hidden }) => !hidden).map((column) => (<StyledTableHeadCell key={column.label} align={column.align} width={column.width} minWidth={column.minWidth} maxWidth={column.maxWidth} onMouseEnter={() => {
        if (column.sortConfig) {
            setSortIconHovered(column.sortConfig.key);
        }
    }} onMouseLeave={() => {
        setSortIconHovered('');
    }}>
              <StyledTableHeader>
                <Tooltip title={column.tooltip} placement="top" arrow>
                  <div>{column.label}</div>
                </Tooltip>
                {column.filterConfig && (<FilterButton data={data} column={column} filter={filter} updateFilter={updateFilter}/>)}
                {column.sortConfig && sort && (sort.key === column.sortConfig.key || sortIconHovered === column.sortConfig.key) && (<StyledSortButton size="small" $desc={sort.direction === SortDirection.Descending} $isActive={sort.key === column.sortConfig.key} icon={<ArrowDownwardIcon />} onClick={() => updateSort(column.sortConfig.key)}/>)}
              </StyledTableHeader>
            </StyledTableHeadCell>))}
        {isMoreOption && <StyledTableHeadCell width="2rem"/>}
      </TableRow>
    </StyledTableHead>);
};

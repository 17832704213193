import React, { createContext, useContext } from 'react';
import { useIsEditingAllowed } from '@/store/selectors';
const FormDisablingContext = createContext({
    isDisabled: false,
});
export const FormDisablingProvider = ({ disabled = false, children, }) => {
    const isEditingAllowed = useIsEditingAllowed();
    return (<FormDisablingContext.Provider value={{ isDisabled: disabled || !isEditingAllowed }}>
      {children}
    </FormDisablingContext.Provider>);
};
export default FormDisablingProvider;
export const useFormDisabling = () => useContext(FormDisablingContext);

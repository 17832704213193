import { IconButton } from '@mui/material';
import styled from 'styled-components';
import { baseColors } from '@groupby/ui-components';
export const StyledInformationNotification = styled.div `
  cursor: default;
  border: ${({ $isNegativeNotification }) => `1px ${$isNegativeNotification ? baseColors.alertsRed15 : baseColors.primaryBlueAccent100} solid`};
  border-radius: 8px;
  display: flex;
  align-items: center;
  font-size: 14px;
  background-color: ${({ $isNegativeNotification }) => ($isNegativeNotification ? baseColors.alertsRed10 : baseColors.primaryBlueAccent050)};
  margin: ${({ theme, $openModal }) => ($openModal ? `${theme.spacing(1)} 0rem` : `${theme.spacing(1)} ${theme.spacing(4)}`)};

  .MuiSvgIcon-root {
    fill: ${baseColors.primaryBlueAccent500};
    padding: ${({ theme, $hasCancelIcon }) => ($hasCancelIcon ? theme.spacing(1.5) : theme.spacing(1))};
  }
`;
export const StyledText = styled.div `
  flex-basis: 100%;
  font-weight: 400;
  line-height: 26px;
  color: ${({ theme }) => theme.palette.accent.main};
`;
export const StyledIconButton = styled(IconButton) `
  svg {
    z-index: initial;
  }
`;

import React from 'react';
import { PageTitle } from '@groupby/ui-components';
import { InitialSelectionGraphic } from '@/components/icons';
import { useLocalization } from '@/localization';
import { StyledInitialSelectionPageContainer } from './initial-selection.styles';
import { PageContainer } from '@/search-for-retail/generic';
export const InitialSelectionPage = ({ titleMessageKey, selectionMessageKey, titleBadge }) => {
    const { formatMessage: t } = useLocalization();
    return (<PageContainer>
      <PageTitle title={t({ key: titleMessageKey })} titleBadge={titleBadge}/>
      <StyledInitialSelectionPageContainer>
        <InitialSelectionGraphic className="initial-selection-content-icon"/>
        <span className="initial-selection-content-text">{t({ key: selectionMessageKey })}</span>
      </StyledInitialSelectionPageContainer>
    </PageContainer>);
};
export default InitialSelectionPage;

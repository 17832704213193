export const REF_APP_SLICE_KEY = 'refApp';
export const ACTION_PREFIX = 'REF_APP:';
export const DEFAULT_ROWS_PER_PAGE = 20;
export const REF_APP_STATE_PATHS_TO_PERSIST_IN_SESSION_STORAGE = [
    `${REF_APP_SLICE_KEY}.requestOptions.visitorId`,
];
export const START_DEPTH_FOR_COLLAPSING = 1;
export var EvaluationType;
(function (EvaluationType) {
    EvaluationType["Query"] = "query";
    EvaluationType["Recommendations"] = "recommendations";
    EvaluationType["Browse"] = "browse";
})(EvaluationType || (EvaluationType = {}));
export const EVALUATE_PARSE_JSON_ERROR = 'EVALUATE_PARSE_JSON_ERROR';

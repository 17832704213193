// other types will be follow up tickets
export var ZONE_TYPE;
(function (ZONE_TYPE) {
    ZONE_TYPE["Content"] = "CONTENT";
    ZONE_TYPE["RichContent"] = "RICH_CONTENT";
    ZONE_TYPE["HTMLContent"] = "HTML";
    // Query = 'QUERY',
    // ExpertQuery = 'EXPERT_QUERY',
    // GeneratedContent = 'GENERATED_CONTENT',
})(ZONE_TYPE || (ZONE_TYPE = {}));

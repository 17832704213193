import createSagaMiddleware from 'redux-saga';
import { createStore as createReduxStore } from 'redux';
import createMiddleware from './middleware';
import createReducers from './reducers';
import sagas from './sagas';
export default function createStore() {
    const sagaMiddleware = createSagaMiddleware();
    const middleware = createMiddleware(sagaMiddleware);
    const store = createReduxStore(createReducers(), {}, middleware);
    if (module.hot) {
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        module.hot.accept('./reducers/', () => import('./reducers').then(({ default: hotCreateReducers }) => store.replaceReducer(hotCreateReducers())));
    }
    sagas.forEach((saga) => sagaMiddleware.run(saga));
    return store;
}

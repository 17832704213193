import qs from 'qs';
import { ModelType, Routing } from '@/constants';
import { generateCollectionAreaUrl } from '@/search-for-retail/utilities';
export function modelManagerRoute(modelType, collection, area) {
    const url = generateCollectionAreaUrl(collection, modelType, area);
    return `${Routing.MERCHANDISING_PATH}/${Routing.MODEL_PATHS[modelType]}${url}`;
}
export function createModelRoute(modelType, collection, area) {
    return `${modelManagerRoute(modelType, collection, area)}/_create`;
}
export function editModelRoute(modelType, collection, area, id, queryParams) {
    if (!id) {
        return '';
    }
    const query = `${qs.stringify(queryParams)}`;
    return `${modelManagerRoute(modelType, collection, area)}?id=${id}${query && `&${query}`}`;
}
export function linguisticControlManagerRoute(controlType, collection, area) {
    const url = generateCollectionAreaUrl(collection, ModelType.LINGUISTIC_CONTROLS, area);
    return `${Routing.MERCHANDISING_PATH}/${ModelType.LINGUISTIC_CONTROLS}${url}?type=${controlType}`;
}
export function createLinguisticControlRoute(controlType, collection, area) {
    return `${modelManagerRoute(ModelType.LINGUISTIC_CONTROLS, collection, area)}/_create?type=${controlType}`;
}
export function editLinguisticControlRoute(controlType, collection, area, id) {
    return `${modelManagerRoute(ModelType.LINGUISTIC_CONTROLS, collection, area)}?type=${controlType}&id=${id}`;
}

import { takeLatest } from 'redux-saga/effects';
import * as Actions from '@/store/actions/types';
export function scrollToTop() {
    const { scrollX, scrollY } = window;
    window.scrollTo(scrollX, scrollY);
    window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
    });
}
export default function* uiSaga() {
    yield takeLatest(Actions.SCROLL_TO_TOP, scrollToTop);
}

import React, { useState } from 'react';
import { Avatar } from '@groupby/ui-components';
import UserOptionsMenu from './menu';
import { StyledAvatarIconButton, StyledAvatarWrapper } from './user-display.styles';
export const UserOptionsDisplay = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const handleOpen = (e) => setAnchorEl(e.currentTarget);
    const handleClose = () => setAnchorEl(null);
    return (<StyledAvatarWrapper $open={Boolean(anchorEl)}>
      <StyledAvatarIconButton $open={Boolean(anchorEl)} onClick={handleOpen}>
        <Avatar />
      </StyledAvatarIconButton>

      <UserOptionsMenu anchorEl={anchorEl} handleClose={handleClose}/>
    </StyledAvatarWrapper>);
};
export default UserOptionsDisplay;

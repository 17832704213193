import { __awaiter } from "tslib";
import { EnrichFeatureFlag } from '@groupby/enrich-console';
import { IS_LOCAL_ENV, IS_LOWER_ENV, TARGET_CUSTOMER } from '@/config/global';
import { EventType, FeatureFlagsService } from './feature-flag-service';
import { ProviderType } from './feature-flag-service/feature-flag-service.constants';
import { FeatureFlag } from './feature-flags.constants';
const baseContext = {
    kind: 'multi',
    application: {
        key: 'command-center-ui',
        name: 'command-center-ui',
    },
    configuration: {
        key: TARGET_CUSTOMER,
        environment: TARGET_CUSTOMER,
        isUpper: !IS_LOWER_ENV && !IS_LOCAL_ENV,
    },
};
export const featureFlagsService = new FeatureFlagsService([ProviderType.QueryParams, ProviderType.LaunchDarkly, ProviderType.FeaturesApi]);
export const initializeFeatureFlagsService = () => __awaiter(void 0, void 0, void 0, function* () {
    yield featureFlagsService.initialize(baseContext);
    // register existing feature flags first
    const featureFlags = [...Object.values(FeatureFlag), ...Object.values(EnrichFeatureFlag)];
    featureFlagsService.registerFeatureFlags(featureFlags);
    const persistedFlagsOnLogout = [FeatureFlag.Auth0];
    featureFlagsService.persistFlags(persistedFlagsOnLogout);
    const featuresExcludedFromNext = [FeatureFlag.Auth0, FeatureFlag.EmojiLang];
    featureFlagsService.excludeFlagsFromNext(featuresExcludedFromNext);
    const checkIfAuth0Enabled = () => featureFlagsService.isFeatureEnabled(FeatureFlag.Auth0);
    const isAuth0Enabled = checkIfAuth0Enabled();
    featureFlagsService.subscribe(EventType.FeatureFlagsSet, () => {
        // if the value of the Auth0 flag has been changed, the page should be reloaded
        if (isAuth0Enabled !== checkIfAuth0Enabled()) {
            window.location.reload();
        }
    });
    // load all feature flags and save them to storage
    yield featureFlagsService.setFeatureFlags();
});

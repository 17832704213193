import { __rest } from "tslib";
export const translateCreateIdentityUserRequestData = (userData, formattedPermissions) => {
    const { areaPermissions } = userData, restUserData = __rest(userData, ["areaPermissions"]);
    return Object.assign(Object.assign({}, restUserData), { permissions: formattedPermissions });
};
export function formatPermissions(permissions, role, allAreas) {
    var _a;
    // TODO": handle Enrich case
    const formattedPermissions = [
        `MERCHANDISING.${role}`,
    ];
    for (const permission of permissions) {
        const area = allAreas.find((area) => area.id === permission.areaId);
        if (area) {
            const formattedPermission = `MERCHANDISING.AREA.${(_a = permission === null || permission === void 0 ? void 0 : permission.role) === null || _a === void 0 ? void 0 : _a.name}:${permission.areaId}|${area === null || area === void 0 ? void 0 : area.name}`;
            formattedPermissions.push(formattedPermission);
        }
    }
    return formattedPermissions;
}

import { __awaiter } from "tslib";
import React, { useCallback, useEffect, useRef, useState, } from 'react';
import { Auth0Provider } from '@auth0/auth0-react';
import { cuid, getTenantName, setTenantName } from '@/utils';
import { redirectUri } from './constants';
import { getLoginQueryParams } from './utilities';
import { getOrganizationWebApp } from '@/search-for-retail/user/user.client';
import { TARGET_CUSTOMER } from '@/config/global';
import { Auth0LoginLoaderProvider } from './auth0-login-loader-provider.component';
import { logError } from '@/utils/logger';
import { SimpleLoading } from '@/components/loading';
export const Auth0ProviderWithNavigate = ({ children }) => {
    const [loginDetails, setLoginDetails] = useState({
        loginId: '',
        tenant: '',
        email: '',
    });
    const [organizationWebApp, setOrganizationWebApp] = useState();
    const [isLoggingIn, setIsLoggingIn] = useState(false);
    const [webAppCallFailed, setWebAppCallFailed] = useState(false);
    const { customerId, email, connection } = getLoginQueryParams();
    const prevWebApp = useRef(new Map());
    const refreshWebApp = useCallback((tenant, signal) => __awaiter(void 0, void 0, void 0, function* () {
        let organizationWebAppResponse = prevWebApp.current.get(tenant);
        if (!organizationWebAppResponse) {
            organizationWebAppResponse = yield getOrganizationWebApp(tenant, { signal });
            prevWebApp.current.set(tenant, organizationWebAppResponse);
            setOrganizationWebApp(organizationWebAppResponse);
            // Persist the tenant name across the navigation to Auth0 so that
            // Auth0Provider can be reinitialized with the correct tenant-specific
            // data when we come back.
            setTenantName(tenant);
        }
    }), []);
    const handleLogin = useCallback((tenant, email, signal) => __awaiter(void 0, void 0, void 0, function* () {
        setIsLoggingIn(true);
        try {
            yield refreshWebApp(tenant, signal);
            setLoginDetails({
                loginId: cuid(),
                tenant,
                email,
            });
            // Setting isLoggingIn to false on failures will cause the login screen to show while logging in
            // Setting to false on success is unnecessary because in memory state will be wiped uponm Auth0 redirect
        }
        catch (error) {
            setWebAppCallFailed(true);
            setIsLoggingIn(false);
        }
    }), [refreshWebApp]);
    // `customerId`, `email` and `connection` are query parameters that signal for an automatic login.
    // Having just `customerId` is sufficient to initiate a login.
    useEffect(() => {
        const abortController = new AbortController();
        if (customerId) {
            void handleLogin(customerId, email !== null && email !== void 0 ? email : '', abortController.signal);
        }
        return () => {
            abortController.abort();
        };
    }, [customerId, email, handleLogin]);
    useEffect(() => {
        const abortController = new AbortController();
        const fetchOrganizationWebApp = () => __awaiter(void 0, void 0, void 0, function* () {
            var _a;
            try {
                const derivedCustomerId = (_a = customerId !== null && customerId !== void 0 ? customerId : getTenantName()) !== null && _a !== void 0 ? _a : TARGET_CUSTOMER;
                yield refreshWebApp(derivedCustomerId, abortController.signal);
            }
            catch (error) {
                if (!(error instanceof DOMException && error.name === 'AbortError')) {
                    logError('Organization webApp failed', error);
                }
            }
        });
        void fetchOrganizationWebApp();
        return () => {
            abortController.abort();
        };
    }, [customerId, refreshWebApp]);
    const handleRedirectCallback = (appState) => {
        const { tenant } = appState;
        setTenantName(tenant);
        window.location.replace((appState === null || appState === void 0 ? void 0 : appState.returnTo) || window.location.pathname);
    };
    const clientId = (organizationWebApp === null || organizationWebApp === void 0 ? void 0 : organizationWebApp.clientId) || null;
    const domain = (organizationWebApp === null || organizationWebApp === void 0 ? void 0 : organizationWebApp.domain) || null;
    if (!(domain && clientId && redirectUri)) {
        return <SimpleLoading />;
    }
    const key = [
        loginDetails.loginId,
        loginDetails.tenant,
        loginDetails.email,
        organizationWebApp.region,
        organizationWebApp.clientId,
        organizationWebApp.domain,
    ].toString();
    const authorizationParams = Object.assign({ redirect_uri: redirectUri, useRefreshTokens: true, scope: 'offline_access openid profile email', ignoreCache: true, detailedResponse: true }, (connection ? { connection } : {}));
    return (<Auth0Provider 
    // Auth0Provider does not recognize changes to props,
    // so we need to force the component to remount when
    // new webApp data is available.
    key={key} domain={domain} clientId={clientId} authorizationParams={authorizationParams} onRedirectCallback={handleRedirectCallback}>
      <Auth0LoginLoaderProvider onLogin={handleLogin} loginId={loginDetails.loginId} tenant={loginDetails.tenant} email={loginDetails.email} webAppCallFailed={webAppCallFailed}>
        {isLoggingIn ? <SimpleLoading /> : children}
      </Auth0LoginLoaderProvider>
    </Auth0Provider>);
};
export default Auth0ProviderWithNavigate;

import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { getModelPathFromPathname, getModelTypeFromModelPath } from '@/routing';
import * as ActionCreators from '@/store/actions/creators';
import { useActiveModelType } from '@/store/selectors';
import { useLocationChanged } from '@/routing/hooks';
import { logError } from '@/utils/logger';
export const useHandleModelTypeOnLocationChanged = () => {
    const dispatch = useDispatch();
    const activeModelType = useActiveModelType();
    const handleModelType = useCallback((location) => {
        try {
            const modelPath = getModelPathFromPathname(location.pathname);
            const modelType = getModelTypeFromModelPath(modelPath);
            if (modelType) {
                if (modelType !== activeModelType) {
                    dispatch(ActionCreators.switchModelType(modelType));
                }
            }
            else {
                dispatch(ActionCreators.switchModelType(null));
            }
        }
        catch (error) {
            const errorMessage = 'UNKNOWN_ERROR';
            logError(errorMessage, error);
            dispatch(ActionCreators.addError({ key: errorMessage }));
        }
    }, [activeModelType, dispatch]);
    useLocationChanged(handleModelType);
};

import { Routing } from '@/constants';
import { getModelManagerPathMatcher } from './get-model-manager-path-matcher';
export const getCollectionNameFromPath = (pathname) => {
    let collection;
    const match = getModelManagerPathMatcher()(pathname);
    if (match && (match === null || match === void 0 ? void 0 : match.collection) && match.collection !== Routing.CREATE_MODEL_SEGMENT) {
        collection = match.collection;
    }
    return collection;
};

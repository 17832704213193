import React from 'react';
import IdleTimer from 'react-idle-timer';
import { SESSION_EXPIRATION_TIMEOUT, SESSION_INACTIVE_TIMEOUT } from '@/constants';
import * as ActionCreators from '@/store/actions/creators';
import { connect } from '@/utils';
import IdleAlert from './idle-alert';
export const INACTIVITY_CHECK_DEBOUNCE = 250;
export const actionCreators = {
    setSessionInactive: ActionCreators.setInactive,
};
const InactivityMonitor = connect(null, actionCreators)(function InactivityMonitor({ setSessionInactive }) {
    return (<React.Fragment>
      <IdleTimer debounce={INACTIVITY_CHECK_DEBOUNCE} timeout={SESSION_EXPIRATION_TIMEOUT - SESSION_INACTIVE_TIMEOUT} onIdle={setSessionInactive}/>
      <IdleAlert />
    </React.Fragment>);
});
export default InactivityMonitor;

import { useEffect, useRef } from 'react';
export const useAbortRequest = () => {
    const controller = useRef(new AbortController());
    useEffect(() => () => {
        controller.current.abort();
    }, []);
    return {
        signal: controller.current.signal,
    };
};

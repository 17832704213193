import { REF_APP_STATE_PATHS_TO_PERSIST_IN_SESSION_STORAGE } from '@/search-for-retail/evaluate';
import persistSubsetSelector from '@/store/selectors/persist';
export const STATE_PATHS_TO_PERSIST = [
    'session.isAnalyticsComparisonModeEnabled',
    ...REF_APP_STATE_PATHS_TO_PERSIST_IN_SESSION_STORAGE,
];
export const ss = {
    getItem: (key) => {
        try {
            const data = sessionStorage.getItem(key);
            return JSON.parse(data);
        }
        catch (e) {
            void e;
        }
    },
    setItem: (key, value) => {
        try {
            const serialized = JSON.stringify(value);
            sessionStorage.setItem(key, serialized);
        }
        catch (e) {
            void e;
        }
    },
    clear(key) {
        try {
            if (key) {
                sessionStorage.removeItem(key);
            }
            else {
                sessionStorage.clear();
            }
        }
        catch (e) {
            void e;
        }
    },
};
export function persist(state, key) {
    const value = persistSubsetSelector(state, STATE_PATHS_TO_PERSIST);
    ss.setItem(key, value);
}

import React from 'react';
import { StyledTableDraggableRow } from './custom-row.styles';
import { TableRow } from '@/search-for-retail/generic/table';
export const TableCustomRow = React.forwardRef(({ id, index, showMoreOptions, isDraggable, isExpanded, children, hasSelect, handleMoreOptionsOpen, handleExpandChange, handleMove, onSelectionChange, collapsedContent, checked, isAnalyticView, columnCount, rowCount, guestUser, disableDrag, }, ref) => {
    if (!guestUser && isDraggable) {
        return (<StyledTableDraggableRow handleMoreOptionsOpen={handleMoreOptionsOpen} handleMove={handleMove} handleExpandChange={handleExpandChange} checked={checked} onSelectionChange={onSelectionChange} collapsedContent={collapsedContent} index={index} isExpanded={isExpanded} isAnalyticView={isAnalyticView} columnCount={columnCount} rowCount={rowCount} hasSelect={hasSelect} disabled={disableDrag} ref={ref}>
        {children}
      </StyledTableDraggableRow>);
    }
    return (<TableRow id={id} handleMoreOptionsOpen={handleMoreOptionsOpen} checked={checked} onSelectionChange={onSelectionChange} isExpanded={isExpanded} showMoreOptions={showMoreOptions} columnCount={columnCount} hasSelect={hasSelect} guestUser={guestUser} ref={ref}>
      {children}
    </TableRow>);
});
TableCustomRow.displayName = 'TableCustomRow';
export default TableCustomRow;

import styled from 'styled-components';
export const StyledTitle = styled.div `
  display: flex;
  flex-direction: column;

  .tab-title-label {
    white-space: nowrap;
  }

  .tab-title-count {
    font-size: 12px;
  }
`;
export default StyledTitle;

import { __rest } from "tslib";
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Fade from '@mui/material/Fade';
import Snackbar from '@mui/material/Snackbar';
import { clearNotifications, closeNotification, removeNotification } from '@/store/actions/creators';
import { useLocationChanged } from '@/hooks/use-location-changed';
import Content from './content';
import { Container } from './notifications.style';
export const TIMEOUT = 3000;
export const actionCreators = { closeNotification, removeNotification };
const Notifications = () => {
    const dispatch = useDispatch();
    const notifications = useSelector(({ notifications }) => notifications);
    const handleClose = (id) => (_, reason) => {
        if (reason === 'timeout') {
            dispatch(closeNotification(id));
        }
    };
    const handleRemove = (id) => () => dispatch(removeNotification(id));
    const handleClickClose = (id) => () => dispatch(closeNotification(id));
    useLocationChanged((prevLocation, location) => {
        if (prevLocation.pathname.replace('/_create', '') !== location.pathname) {
            dispatch(clearNotifications());
        }
    });
    return (<Container id="notifications">
      {[...notifications].slice(0, 3).map((_a) => {
        var { id, visible, timeout = TIMEOUT } = _a, notificationProps = __rest(_a, ["id", "visible", "timeout"]);
        return (<Snackbar key={id} open={visible} autoHideDuration={timeout} TransitionComponent={Fade} transitionDuration={500} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} onClose={handleClose(id)} classes={{ root: 'notification-item' }} message={<Content {...notificationProps} onClick={handleClickClose(id)}/>} ContentProps={{
            classes: { root: `notification-content notification-content-${notificationProps.type}`, action: 'notification-action' },
        }} TransitionProps={{
            onExited: handleRemove(id),
        }}/>);
    })}
    </Container>);
};
export default Notifications;

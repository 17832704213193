import { __rest } from "tslib";
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
export const ErrorIcon = (_a) => {
    var { width = 24, height = 24 } = _a, props = __rest(_a, ["width", "height"]);
    return (<SvgIcon width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12ZM4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12ZM11.9999 5.75C11.3178 5.75 10.7726 6.31742 10.7998 6.999L11.0249 12.6257C11.0458 13.149 11.4762 13.5625 11.9999 13.5625C12.5236 13.5625 12.9539 13.1491 12.9748 12.6258L13.1999 6.999C13.2272 6.31742 12.682 5.75 11.9999 5.75ZM10.5625 16.5312C10.5625 15.7546 11.1921 15.125 11.9688 15.125C12.7454 15.125 13.375 15.7546 13.375 16.5312C13.375 17.3079 12.7454 17.9375 11.9688 17.9375C11.1921 17.9375 10.5625 17.3079 10.5625 16.5312Z" fill="currentColor"/>
  </SvgIcon>);
};
export default ErrorIcon;

import React from 'react';
import { Icon, Link, PageTitle, Typography, } from '@groupby/ui-components';
import { Button } from '@/components/button';
import { StyledEmptyPageContainer } from './enrich-empty-page.styles';
import { GROUPBY_SEARCH_EXPERTS } from '@/routing/constants';
import { TeamIllustration } from '@/components/icons';
import { useLocalization } from '@/localization';
import { NoDataFallback } from '@/search-for-retail/common/no-data-fallback';
export const EnrichEmptyPage = () => {
    const { formatMessage: t } = useLocalization();
    return (<StyledEmptyPageContainer>
      <PageTitle title={t({ key: 'EMPTY_PAGE_TITLE' })} description={t({ key: 'EMPTY_PAGE_DESCRIPTION' })}/>
      <NoDataFallback className="empty-box-container" icon={<TeamIllustration />} title={(<React.Fragment>
            <Typography>{t({ key: 'EMPTY_PAGE_BOX_TITLE' })}</Typography>
            <Typography>{t({ key: 'EMPTY_PAGE_BOX_DESCRIPTION' })}</Typography>
          </React.Fragment>)} actions={(<Link target="_blank" rel="noopener noreferrer" href={GROUPBY_SEARCH_EXPERTS}>
            <Button rounded variant="contained" title={t({ key: 'EMPTY_PAGE_BOX_ACTION' })} endIcon={<Icon variant="arrowCircleDown"/>}/>
          </Link>)}/>
    </StyledEmptyPageContainer>);
};
export default EnrichEmptyPage;

import React, { createContext, useCallback, useContext, useEffect, useState, } from 'react';
import { EventType } from './feature-flag-service';
export const FeatureFlagsContext = createContext({
    featureFlagsService: null,
});
export const FeatureFlagsProvider = ({ children, featureFlagsService }) => {
    const [_, setFlagUpdate] = useState(0); // State to trigger re-render
    const handleFlagUpdate = useCallback(() => {
        setFlagUpdate((prev) => prev + 1);
    }, []);
    useEffect(() => {
        featureFlagsService.subscribe(EventType.ContextUpdated, handleFlagUpdate);
        return () => featureFlagsService.unsubscribe(EventType.ContextUpdated, handleFlagUpdate);
    }, [featureFlagsService, handleFlagUpdate]);
    return (<FeatureFlagsContext.Provider value={{ featureFlagsService }}>
      {children}
    </FeatureFlagsContext.Provider>);
};
export default FeatureFlagsProvider;
export const useFeatureFlagsProvider = () => useContext(FeatureFlagsContext);
export const useIsFeatureFlagEnabled = (featureFlag) => {
    const { featureFlagsService } = useFeatureFlagsProvider();
    return featureFlagsService.isFeatureEnabled(featureFlag);
};

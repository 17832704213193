import styled from 'styled-components';
import { breakpoints } from '@/theme';
export const Container = styled.div `
  position: fixed;
  z-index: ${({ theme }) => theme.zIndex.snackbar};
  bottom: ${({ theme }) => theme.spacing(1.5)};
  right: ${({ theme }) => theme.spacing(1.5)};

  @media (max-width: ${breakpoints.sm}px) {
    bottom: ${({ theme }) => theme.footer.heightMobile};
  }

  .notification-item {
    position: static;
    left: auto;
    margin-top: ${({ theme }) => theme.spacing(1)};

    @media (max-width: ${breakpoints.sm}px) {
      z-index: ${({ theme }) => theme.zIndex.temporaryDrawer};
    }
  }

  .notification-content {
    cursor: pointer;
    width: ${({ theme }) => theme.spacing(56)};
    min-height: ${({ theme }) => theme.spacing(6)};
    text-align: end;
    flex-grow: 0;
    min-width: 0;
    border-radius: 8px;
    box-shadow: 0 4px 14px 0 #081E4214;
  }

  .notification-content-success {
    background: ${({ theme }) => theme.palette.notification.background.success};
    border: ${({ theme }) => `1px solid ${theme.palette.notification.border.success}`};
  }

  .notification-content-error {
    background: ${({ theme }) => theme.palette.notification.background.error};
    border:${({ theme }) => `1px solid ${theme.palette.notification.border.error}`};
  }

  .notification-action {
    margin: ${({ theme }) => theme.spacing(0, 2)};
    padding: 0;
  }

  .notification-close-icon {
    fill: ${({ theme }) => theme.palette.grey[500]};

    &:hover {
      cursor: pointer;
    }
  }
`;

import { __awaiter } from "tslib";
import { JSON_HEADERS, SearchForRetail } from '@/client/api';
import { buildCustomerHeaders, s4rAuthenticated } from '@/client/utils';
import { formatPermissions, translateCreateIdentityUserRequestData } from './utils';
import fetch from '@/client/fetch';
const USER_PATH = `${SearchForRetail.API_URL}/user`;
export const createUser = (token, data) => __awaiter(void 0, void 0, void 0, function* () {
    const { result } = yield s4rAuthenticated(token, {
        url: USER_PATH,
        method: 'POST',
        body: data,
    });
    return result;
});
export const getUser = (token, userId) => __awaiter(void 0, void 0, void 0, function* () {
    const { result } = yield s4rAuthenticated(token, {
        method: 'GET',
        url: `${USER_PATH}/${userId}`,
    });
    return result === null || result === void 0 ? void 0 : result[0];
});
export const updateUser = (token, userId, data) => __awaiter(void 0, void 0, void 0, function* () {
    const { result } = yield s4rAuthenticated(token, {
        method: 'PUT',
        body: data,
        url: `${USER_PATH}/${userId}`,
    });
    return result;
});
export const getUserList = (token, options) => __awaiter(void 0, void 0, void 0, function* () {
    const { result } = yield s4rAuthenticated(token, Object.assign({ url: USER_PATH, method: 'GET' }, options));
    return result;
});
export const deleteUser = (token, id) => __awaiter(void 0, void 0, void 0, function* () {
    yield s4rAuthenticated(token, { url: `${USER_PATH}/${id}`, method: 'DELETE', skipCache: true });
});
// Auth0
const IDENTITY = 'identity';
const USERS_PATH = `${SearchForRetail.API_URL}/user`;
const IDENTITY_USER_PATH = `${SearchForRetail.API_URL}/${IDENTITY}/users`;
const IDENTITY_ORGANIZATION_PATH = `${SearchForRetail.API_URL}/${IDENTITY}/organization`;
// GET user profile is equivalent to 'active user'
export const getUserProfile = (token, options) => __awaiter(void 0, void 0, void 0, function* () {
    const url = `${IDENTITY_USER_PATH}/profile`;
    const { result } = yield s4rAuthenticated(token, Object.assign({ url, method: 'GET' }, options));
    return result;
});
// GET identity/users list route
export const getIdentityUserList = (token, options) => __awaiter(void 0, void 0, void 0, function* () {
    const { result } = yield s4rAuthenticated(token, Object.assign({ url: IDENTITY_USER_PATH, method: 'GET' }, options));
    return result;
});
// GET Identity User by ID
// Returns more data about user including 'connections' for the form
export const getIdentityUserById = (token, id, options) => __awaiter(void 0, void 0, void 0, function* () {
    const { result } = yield s4rAuthenticated(token, Object.assign({ url: `${IDENTITY_USER_PATH}/${id}`, method: 'GET' }, options));
    return result;
});
export const createIdentityUser = (token, allAreas, formData) => __awaiter(void 0, void 0, void 0, function* () {
    const formatedPermissions = formatPermissions(formData.areaPermissions, formData.role, allAreas);
    const { result } = yield s4rAuthenticated(token, {
        url: IDENTITY_USER_PATH,
        method: 'POST',
        body: translateCreateIdentityUserRequestData(formData, formatedPermissions),
    });
    return result;
});
// EDIT identity/user{id}
export const updateIdentityUser = (token, userId, allAreas, formData) => __awaiter(void 0, void 0, void 0, function* () {
    const formatedPermissions = formatPermissions(formData.areaPermissions, formData.role, allAreas);
    const { result } = yield s4rAuthenticated(token, {
        method: 'PUT',
        body: translateCreateIdentityUserRequestData(formData, formatedPermissions),
        url: `${IDENTITY_USER_PATH}/${userId}`,
    });
    return result;
});
// DELETE identity/users{id}
export const deleteIdentityUser = (token, id) => __awaiter(void 0, void 0, void 0, function* () {
    yield s4rAuthenticated(token, { url: `${IDENTITY_USER_PATH}/${id}`, method: 'DELETE', skipCache: true });
});
// GET identity/user settings by id
// Used to get settings object for user with passed id. Only users with 'ADMIN' role may call this endpoint
export const getIdentityUserSettingsById = (token, id, options) => __awaiter(void 0, void 0, void 0, function* () {
    const { result } = yield s4rAuthenticated(token, Object.assign({ url: `${IDENTITY_USER_PATH}/${id}/settings`, method: 'GET' }, options));
    return result;
});
// PUT /user/settings update user settings
// Used to update settings for the currently authenticated user.
export const updateCurrentUserSettings = (token, settingsUpdate) => __awaiter(void 0, void 0, void 0, function* () {
    const { result } = yield s4rAuthenticated(token, {
        url: `${USERS_PATH}/settings`,
        method: 'PUT',
        body: settingsUpdate,
    });
    return result;
});
// POST to sync identity user profile
// Syncs the current user's profile with Auth0.
// Call this endpoint to sync the user's legacy permissions with Auth0 when they first login with a social account, eg. Google
export const syncAuth0UserProfile = (token, options) => __awaiter(void 0, void 0, void 0, function* () {
    const { result } = yield s4rAuthenticated(token, Object.assign({ url: `${IDENTITY_USER_PATH}/syncAuthUserProfile`, method: 'POST' }, options));
    return result;
});
// GET Connections for Organization Connections dropdown
// customerId is tenantName from User Profile/Identity USers
export const getOranizationConnectionsList = (token, customerId, options) => __awaiter(void 0, void 0, void 0, function* () {
    const { result } = yield s4rAuthenticated(token, Object.assign({ url: `${IDENTITY_ORGANIZATION_PATH}/${customerId}/connections`, method: 'GET' }, options));
    return result;
});
// GET Organization Web App by `customerId`
// `customerId` is CC `tenantName` ex: global config `TARGET_CUSTOMER`
export const getOrganizationWebApp = (customerId, options) => __awaiter(void 0, void 0, void 0, function* () {
    const ORGANIZATION_WEB_APP_PATH = `${IDENTITY_ORGANIZATION_PATH}/${customerId}/webApp`;
    const { result } = yield fetch(ORGANIZATION_WEB_APP_PATH, Object.assign({ method: 'GET', headers: Object.assign(Object.assign({}, buildCustomerHeaders()), JSON_HEADERS), expiry: false, skipCache: false }, options));
    return result;
});

import styled from 'styled-components';
export const StyledSortIconsContainer = styled.div `
  display: flex;
  flex-direction: column;

  &&.MuiTableSortLabel-icon {
    opacity: 1;
    margin: ${({ theme }) => theme.spacing(1)}
  }

  &.MuiTableSortLabel-iconDirectionAsc {
    transform: unset;
  }

  .sort-icon-asc,
  .sort-icon-desc {
    width: 0.5em;
    height: 0.5em;

    svg {
      width: 1em;
      height: 1em;

      path {
        fill: currentColor
      }
    }
  }
`;

import { __awaiter } from "tslib";
import { useCallback, useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch } from 'react-redux';
import { clearTenantName, getTenantName } from '@/utils';
import { useActiveUserSelector, useAuthTokenSelector, useIsAuthenticatedSelector } from '@/store/selectors';
import { FeatureFlag, featureFlagsService, useFeatureFlagsProvider } from '@/search-for-retail/feature-flags';
import { getAuth0Audience } from '@/auth/utilities';
import * as ActionCreators from '@/store/actions/creators';
import { resetRefAppState } from '@/search-for-retail/evaluate';
import { ss } from '@/stores/session-storage';
import { STORAGE_KEY } from '@/stores/stores.constants';
import { logError } from '@/utils/logger';
// Auth token
const useToken = () => {
    const [token, setToken] = useState('');
    const { getAccessTokenSilently } = useAuth0();
    const tenantName = getTenantName();
    useEffect(() => {
        if (!tenantName)
            return;
        const getAuth0AccessToken = () => __awaiter(void 0, void 0, void 0, function* () {
            // `tenantName` is stored on login form submit handler
            // tenant is persisted through auth0Provider `handleRedirectCallback` after returning to application
            // audience is constructed from `tenant` in login form
            const audience = getAuth0Audience(tenantName);
            try {
                const accessToken = yield getAccessTokenSilently({ authorizationParams: { audience } });
                setToken(accessToken);
            }
            catch (e) {
                // do nothing
            }
        });
        void getAuth0AccessToken();
    }, [getAccessTokenSilently, tenantName]);
    return token;
};
export const useAuth0Token = () => {
    const { getAccessTokenSilently } = useAuth0();
    const tenantName = getTenantName();
    const getToken = useCallback((cacheMode = 'on') => __awaiter(void 0, void 0, void 0, function* () {
        if (!tenantName) {
            return null;
        }
        // `tenantName` is stored on login form submit handler
        // tenant is persisted through auth0Provider `handleRedirectCallback` after returning to application
        // audience is constructed from `tenant` in login form
        const audience = getAuth0Audience(tenantName);
        try {
            return yield getAccessTokenSilently({ cacheMode, authorizationParams: { audience } });
        }
        catch (e) {
            return null;
        }
    }), [getAccessTokenSilently, tenantName]);
    return {
        getToken,
    };
};
const useReduxToken = () => {
    const ccApiToken = useAuthTokenSelector();
    return ccApiToken;
};
export const checkIsAuth0Enabled = () => featureFlagsService.isFeatureEnabled(FeatureFlag.Auth0);
export const useAuthToken = checkIsAuth0Enabled() ? useToken : useReduxToken;
export const useGetToken = () => {
    const legacyToken = useReduxToken();
    const { getToken: getAuth0Token } = useAuth0Token();
    return useCallback(() => __awaiter(void 0, void 0, void 0, function* () { return (checkIsAuth0Enabled() ? getAuth0Token() : legacyToken); }), [getAuth0Token, legacyToken]);
};
// Active user
const useReduxActiveUser = () => {
    const activeUser = useActiveUserSelector();
    return activeUser;
};
export const useActiveUser = useReduxActiveUser;
export const useLegacyAuth = () => {
    const isAuthenticated = useIsAuthenticatedSelector();
    return { isAuthenticated, isLoading: false };
};
// Logout
export const useAuthLogout = () => {
    const { logout: auth0Logout } = useAuth0();
    const { featureFlagsService } = useFeatureFlagsProvider();
    const dispatch = useDispatch();
    const logout = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        try {
            dispatch(ActionCreators.logout());
            dispatch(ActionCreators.clearAuthentication());
            resetRefAppState();
            // clear local storage
            clearTenantName();
            // clear session storage
            featureFlagsService.clear();
            ss.clear(STORAGE_KEY);
            if (checkIsAuth0Enabled()) {
                yield featureFlagsService.reset();
            }
        }
        catch (error) {
            const errorMessage = 'UNKNOWN_ERROR';
            logError(errorMessage, error);
            dispatch(ActionCreators.addError({ key: errorMessage }));
        }
        if (featureFlagsService.isFeatureEnabled(FeatureFlag.Auth0)) {
            void auth0Logout({
                logoutParams: {
                    returnTo: `${window.location.origin}`,
                },
                /* eslint-disable-next-line  @typescript-eslint/require-await */
                openUrl(url) {
                    return __awaiter(this, void 0, void 0, function* () {
                        // const parsedUrl = new URL(url);
                        // The `openUrl` function instructs Auth0 how to handle redirects. This is particularly important because
                        // we have a custom routing library and navigations to same-origin URLs need to go through the routing library
                        // to keep the internal routing state consistent. `url` will contain the URL to redirect to in order to
                        // complete the logout process.
                        //
                        // Our `redirect` function doesn't work with cross-origin URLs. In these cases, we use the browser native
                        // `assign` function. This distinction ensures that our routing state remains consistent for same-origin URLs
                        // while still allowing for cross-origin redirects when necessary.
                        // if (parsedUrl.origin === window.location.origin) {
                        //   redirect(url);
                        // } else {
                        window.location.assign(url);
                        // }
                    });
                },
            });
        }
    }), [auth0Logout, dispatch, featureFlagsService]);
    return { logout };
};
// Authenticated user email
const useUserEmailFromObject = (userObject) => {
    const [userEmail, setUserEmail] = useState('');
    useEffect(() => {
        if (userObject === null || userObject === void 0 ? void 0 : userObject.email) {
            setUserEmail(userObject.email);
        }
    }, [userObject]);
    return userEmail;
};
const useAuth0UserEmail = () => {
    const { user: auth0User } = useAuth0();
    return useUserEmailFromObject(auth0User);
};
const useReduxUserEmail = () => {
    const user = useActiveUser();
    return useUserEmailFromObject(user);
};
export const useUserEmail = checkIsAuth0Enabled() ? useAuth0UserEmail : useReduxUserEmail;

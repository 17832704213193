import styled from 'styled-components';
import AppBar from '@mui/material/AppBar';
import { IconButton, Toolbar } from '@mui/material';
export const StyledHeaderBar = styled(AppBar) `
  &.MuiAppBar-root {
    position: fixed;
    width: 100vw;
    box-shadow: none;
  }
`;
export const StyledIconButton = styled(IconButton) `
  &.MuiIconButton-root {
    padding: 0;
  }
`;
export const StyledToolbar = styled(Toolbar) `
 &.MuiToolbar-regular {
   min-height: ${({ theme }) => theme.appHeader.height};
   justify-content: space-between;
 }
`;
export const StyledLeftHeaderWrapper = styled.div `
  display: flex;
`;

import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import createPathMatcher from 'path-match';
import * as ActionCreators from '@/store/actions/creators';
import { Routing } from '@/constants';
import { useLocationChanged } from '@/routing/hooks';
const modelManagerPathMatcher = createPathMatcher({ end: false })(Routing.MODEL_MANAGER_PATH);
export const useUpdateAreaOnLocationChanged = () => {
    const dispatch = useDispatch();
    const updateArea = useCallback((location) => {
        let collection;
        let area;
        const match = modelManagerPathMatcher(location.pathname);
        if (match && (match === null || match === void 0 ? void 0 : match.collection) && match.collection !== Routing.CREATE_MODEL_SEGMENT) {
            collection = match.collection;
        }
        if (match && (match === null || match === void 0 ? void 0 : match.area) && match.area !== Routing.CREATE_MODEL_SEGMENT) {
            area = match.area;
        }
        if (collection) {
            dispatch(ActionCreators.setCollectionName(collection));
            if (area) {
                dispatch(ActionCreators.switchArea(area));
            }
        }
    }, [dispatch]);
    useLocationChanged(updateArea);
};

var State;
(function (State) {
    let Notification;
    (function (Notification) {
        let Type;
        (function (Type) {
            Type["ERROR"] = "error";
            Type["SUCCESS"] = "success";
        })(Type = Notification.Type || (Notification.Type = {}));
    })(Notification = State.Notification || (State.Notification = {}));
})(State || (State = {}));
export default State;

import React, { useCallback, useEffect, useState } from 'react';
import Drawer from '@mui/material/Drawer';
import { ButtonV2, SimpleDragDropList, TextField } from '@groupby/ui-components';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { GearIcon } from '@/components/icons';
import { useLocalization } from '@/localization';
import { StyledCustomizeViewActionContainer, StyledCustomizeViewButton, StyledCustomizeViewContainer, StyledCustomizeViewHeader, StyledCustomizeViewTitle, StyledDropZone, } from './table-customize-view.styles';
import { CustomizeViewListItem } from './customize-view-list-item.component';
export const TableCustomizeView = ({ columns, setOrder }) => {
    const { formatMessage: t } = useLocalization();
    const [isOpen, setOpen] = useState(false);
    const [localOrder, setLocalOrder] = useState([]);
    const [localHidden, setLocalHidden] = useState([]);
    const [input, setInput] = useState('');
    const setInitial = useCallback(() => {
        setLocalOrder(columns.map(({ id }) => id));
        setLocalHidden(columns.filter(({ hidden }) => Boolean(hidden)).map(({ id }) => id));
    }, [columns]);
    useEffect(() => {
        setInitial();
    }, [setInitial]);
    const handleOrderChange = (newOrder) => {
        if (newOrder.length === localOrder.length) {
            setLocalOrder(newOrder.map(({ id }) => id));
        }
    };
    const handleHiddenChange = (item) => {
        if (localHidden.includes(item.id)) {
            setLocalHidden((prev) => prev.filter((id) => id !== item.id));
        }
        else {
            setLocalHidden((prev) => [...prev, item.id]);
        }
    };
    useEffect(() => {
        setInput('');
    }, [isOpen]);
    return (<React.Fragment>
      <StyledCustomizeViewButton onClick={() => setOpen(true)} endIcon={<GearIcon />}>
        {t({ key: 'CUSTOMIZE_VIEW' })}
      </StyledCustomizeViewButton>
      <Drawer open={isOpen} anchor="right" onClose={() => {
        setOpen(false);
        setInitial();
    }}>
        <StyledCustomizeViewContainer>
          <StyledCustomizeViewTitle>
            {t({ key: 'CUSTOMIZE_VIEW' })}
          </StyledCustomizeViewTitle>

          <StyledCustomizeViewHeader>
            <div>{t({ key: 'CONFIGURE_FIELDS' })}</div>
            <ButtonV2 size="small" onClick={() => {
        if (localHidden.length > 0) {
            setLocalHidden([]);
        }
        else {
            setLocalHidden(columns.filter((item) => !item.static).map(({ id }) => id));
        }
    }}>
              {t({ key: localHidden.length > 0 ? 'SELECT_ALL' : 'DESELECT_ALL' })}
            </ButtonV2>
          </StyledCustomizeViewHeader>

          <TextField fullWidth placeholder={t({ key: 'SEARCH' })} variant="outlined" value={input} onChange={(e) => setInput(e.target.value)} InputProps={{
        startAdornment: (<InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>),
        endAdornment: input.length > 0 && (<InputAdornment position="end">
                  <IconButton onClick={() => setInput('')}>
                    <CloseIcon />
                  </IconButton>
                </InputAdornment>),
    }}/>

          <SimpleDragDropList uniqueId="rule_table_customize_view" data={localOrder
        .map((id) => columns.find((column) => column.id === id))
        .filter(Boolean)
        .filter(({ label }) => label.toLowerCase().includes(input.toLowerCase()))} onOrderChange={handleOrderChange} onDrag={() => null} renderDropzone={() => <StyledDropZone />} getCanDrag={(item) => input.length === 0 && !item.static} renderItem={(item) => (<CustomizeViewListItem item={item} isDraggable={input.length === 0 && !item.static} onDrag={() => null} checked={item.static || !localHidden.includes(item.id)} onCheck={handleHiddenChange}/>)}/>
        </StyledCustomizeViewContainer>
        <StyledCustomizeViewActionContainer>
          <ButtonV2 onClick={() => {
        setOpen(false);
        setInitial();
    }}>
            {t({ key: 'CANCEL' })}
          </ButtonV2>
          <ButtonV2 variant='contained' onClick={() => {
        setOpen(false);
        setOrder(localOrder, localHidden);
    }}>
            {t({ key: 'APPLY' })}
          </ButtonV2>
        </StyledCustomizeViewActionContainer>
      </Drawer>
    </React.Fragment>);
};

import { useCallback, useMemo } from 'react';
import { useActiveAreaNameSelector, useCollectionName } from '@/store/selectors';
const PERSISTED_STORAGE = 'gbi:rule_table_v2_persisted_storage';
export const useTablePersistedData = () => {
    const activeCollection = useCollectionName();
    const activeArea = useActiveAreaNameSelector();
    const storageName = useMemo(() => (`${PERSISTED_STORAGE}-${activeCollection}-${activeArea}`), [activeCollection, activeArea]);
    const getPersistedData = useCallback(() => JSON.parse(localStorage.getItem(storageName) || '{}'), [storageName]);
    const persistedData = useMemo(() => getPersistedData(), [getPersistedData]);
    const setPersistedData = useCallback((newData) => {
        localStorage.setItem(storageName, JSON.stringify(Object.assign(Object.assign({}, getPersistedData()), newData)));
    }, [storageName, getPersistedData]);
    return {
        persistedData,
        setPersistedData,
    };
};

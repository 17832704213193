import { __awaiter } from "tslib";
import { SearchForRetail } from '@/client/api';
import { s4rAuthenticated } from '@/client/utils';
import { parseAreaList } from './area.parsers';
const AREA_PATH = `${SearchForRetail.API_URL}/${SearchForRetail.AREA_PATH}`;
export const getAreaList = (token, options) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield s4rAuthenticated(token, Object.assign({ url: AREA_PATH, method: 'GET' }, options));
    return parseAreaList(response.result);
});
export const getNotCopyableConfigs = (token, fromArea) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield s4rAuthenticated(token, {
        method: 'GET',
        url: `${AREA_PATH}/${fromArea}/config?copyable=false`,
        skipCache: true,
    });
    return response.result;
});
export const copyAllConfigurationsFromAreaToArea = (token, targetArea, fromArea) => __awaiter(void 0, void 0, void 0, function* () {
    yield s4rAuthenticated(token, {
        method: 'POST',
        skipCache: false,
        url: `${AREA_PATH}/${fromArea}/${SearchForRetail.PROMOTE_PATH}/${targetArea}`,
    });
});
export const duplicate = (token, fromArea, params) => __awaiter(void 0, void 0, void 0, function* () {
    const result = yield s4rAuthenticated(token, {
        method: 'POST',
        skipCache: false,
        url: `${AREA_PATH}/${fromArea}`,
        body: {
            id: fromArea,
            newName: params.name,
        },
    });
    return result.result;
});
export const createArea = (token, data) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield s4rAuthenticated(token, {
        url: AREA_PATH,
        method: 'POST',
        body: data,
    });
    return response.result;
});
export const updateArea = (token, areaId, data) => __awaiter(void 0, void 0, void 0, function* () {
    const { result } = yield s4rAuthenticated(token, {
        url: `${AREA_PATH}/${areaId}`,
        method: 'PUT',
        body: data,
    });
    return result;
});
export const deleteArea = (token, id) => __awaiter(void 0, void 0, void 0, function* () {
    yield s4rAuthenticated(token, { url: `${AREA_PATH}/${id}`, method: 'DELETE', skipCache: true });
});
export const getAreaById = (token, areaId) => __awaiter(void 0, void 0, void 0, function* () {
    const { result } = yield s4rAuthenticated(token, {
        url: `${AREA_PATH}/${areaId}`,
        method: 'GET',
    });
    return result;
});

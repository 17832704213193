import React, { forwardRef } from 'react';
import { MenuList } from '@groupby/ui-components';
import { isNil } from 'ramda';
import { DeleteModelMenuItem, Divider, DuplicateModelMenuItem, EditModelMenuItem, MoveAboveBelowModelMenuItem, PromoteModelMenuItem, ViewAuditLogsMenuItem, ViewModelMenuItem, } from '@/search-for-retail/generic/table/row/menu-items';
import { useIsEditingAllowed } from '@/store/selectors';
export const TableRowMenu = forwardRef(({ deleteDisabled, open, onClose, anchorEl, onEditClick, onViewClick, onDeleteClick, onDuplicateClick, onViewAuditLogsClick, onCopyToAreaClick, onMoveAboveBelowClick, }, ref) => {
    // fix logic on menus to set open relative to currentIndexMenuOpen being a number (not null)
    const isEditingAllowed = useIsEditingAllowed();
    const menuItems = [
        onViewClick
            ? <ViewModelMenuItem key="view-item" onClick={onViewClick}/>
            : <EditModelMenuItem key="edit-item" onClick={onEditClick}/>,
    ];
    if (isEditingAllowed) {
        menuItems.push(...[
            ...(onDuplicateClick ? [
                <DuplicateModelMenuItem key="duplicate-item" onClick={onDuplicateClick}/>,
                <Divider key="duplicate-item-divider"/>,
            ] : []),
            ...(onCopyToAreaClick ? [
                <PromoteModelMenuItem key="promote-item" onClick={onCopyToAreaClick}/>,
            ] : []),
            ...(onMoveAboveBelowClick ? [
                <MoveAboveBelowModelMenuItem key="move-above-below-item" onClick={onMoveAboveBelowClick}/>,
            ] : []),
            ...((onCopyToAreaClick || onMoveAboveBelowClick) ? [
                <Divider key="view-delete-divider"/>,
            ] : []),
            ...(!isNil(onViewAuditLogsClick)
                ? [<ViewAuditLogsMenuItem key="view-audit-log-item" onClick={onViewAuditLogsClick}/>]
                : []),
            <Divider key="view-audit-log-divider"/>,
            <DeleteModelMenuItem key="delete-item" onClick={onDeleteClick} disabled={deleteDisabled}/>,
        ]);
    }
    else if (!isNil(onViewAuditLogsClick)) {
        menuItems.push(...[
            <Divider key="view-audit-log-divider"/>,
            <ViewAuditLogsMenuItem key="view-audit-log-item" onClick={onViewAuditLogsClick}/>,
        ]);
    }
    return (<MenuList ref={ref} open={open} onClose={onClose} anchorEl={anchorEl} getContentAnchorEl={null} classes={{ paper: 'additional-filters' }} anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
    }} transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
    }}>
      {menuItems}
    </MenuList>);
});
TableRowMenu.displayName = 'TableRowMenu';
export default TableRowMenu;

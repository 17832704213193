import styled from 'styled-components';
import { baseColors } from '@groupby/ui-components';
import { TableContainer } from '@mui/material';
export const StyledTable = styled.div `
  padding-top: 1rem;
  border-radius: 4px;
  background-color: ${baseColors.white};

  && {
    table {
      font-size: 0.875rem;
      font-weight: 400;

      border-collapse: separate;
      border-spacing: 0;
    }

    table th {
      border-top: 1px solid ${baseColors.background100};
      padding: 0;
    }

    table td {
      height: 3.5rem;
      max-height: 3.5rem;
      box-sizing: border-box;
      padding: 0;
    }
  }
`;
export const StyledTablePaginationContainer = styled.div `
  position: sticky;
  left: 0;
  bottom: -1px;
  z-index: 2;
  background: ${baseColors.white};
`;
export const StyledTableContainer = styled(TableContainer) ``;

import React from 'react';
import { Routing } from '@/constants';
import { usePathname } from '@/routing/hooks/utils';
import AuthGuard from '../auth-guard';
export const LoggedInGuard = ({ children }) => {
    const activePath = usePathname();
    const path = `${Routing.LOGIN_PATH}${activePath && activePath !== '/' ? `?next=${activePath}` : ''}`;
    return <AuthGuard pathType="protected" fallbackPath={path}>{children}</AuthGuard>;
};
export default LoggedInGuard;

import { __rest } from "tslib";
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
export const InfoTooltipIcon = (_a) => {
    var { width = 16, height = 16 } = _a, props = __rest(_a, ["width", "height"]);
    return (<SvgIcon width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect x="1.33313" y="1.33374" width="13.3333" height="13.3333" rx="6.66667" stroke="#919BAB" strokeWidth="1.5"/>
    <path d="M8.33313 11.3335L8.33313 7.3335" stroke="#919BAB" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M6.9998 7.3335L8.33313 7.3335" stroke="#919BAB" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M8.33313 5.33341L8.33313 4.66675" stroke="#919BAB" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  </SvgIcon>);
};
export default InfoTooltipIcon;

import { Routing } from '@/constants';
import { getModelManagerPathMatcher } from './get-model-manager-path-matcher';
export const getAreaNameFromPath = (pathname) => {
    let area;
    const match = getModelManagerPathMatcher()(pathname);
    if (match && (match === null || match === void 0 ? void 0 : match.area) && match.area !== Routing.CREATE_MODEL_SEGMENT) {
        area = match.area;
    }
    return area;
};

import styled from 'styled-components';
import { baseColors } from '@groupby/ui-components';
export const StyledContainer = styled.div `
  cursor: default;
  margin: 0 ${({ theme }) => theme.spacing(3)} ${({ theme }) => theme.spacing(3)} 0;
  align-self: flex-end;
  font-size: 14px;
  font-weight: 500;

  .counter-title {
    margin-right: 10px;
    text-transform: uppercase;
    color: ${baseColors.primaryBlueAccent700};
  }

  .counter-info {
    color: ${baseColors.primaryBlueAccent500};
  }
`;

import { Box } from '@mui/material';
import styled from 'styled-components';
export const StyledAdhocMessageBar = styled(Box) `
  &.table-placement {
    margin-top: 1rem;
  }

  &.analytics-dashboard-placement {
    padding: 1rem 3.75rem 0 3.75rem;
  }

  &.login-placement {
    position: absolute;
    top: 20px;
    left: 300px;
    right: 10px;
  }
`;
export default StyledAdhocMessageBar;

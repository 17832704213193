import { __rest } from "tslib";
import React from 'react';
import { exposeWrapped } from '@/utils';
import LabeledInline from './inline';
import LabeledSection from './section';
export const labeled = exposeWrapped((Component) => (function LabeledControl(_a) {
    var { label, tooltip, size, labelDecorator, alignCenter, wrapperClassName } = _a, props = __rest(_a, ["label", "tooltip", "size", "labelDecorator", "alignCenter", "wrapperClassName"]);
    return (<LabeledInline for={props.id} label={label} tooltip={tooltip} size={size} labelDecorator={labelDecorator} wrapperClassName={wrapperClassName} alignCenter={alignCenter}>
          <Component {...props}/>
        </LabeledInline>);
}));
export const labeledSection = exposeWrapped((Component) => (function LabeledControl(_a) {
    var { label, size, tooltip, labelDecorator, labelClassName, wrapperClassName } = _a, props = __rest(_a, ["label", "size", "tooltip", "labelDecorator", "labelClassName", "wrapperClassName"]);
    return (<LabeledSection label={label} tooltip={tooltip} size={size} labelDecorator={labelDecorator} wrapperClassName={wrapperClassName} labelClassName={labelClassName}>
          <Component {...props}/>
        </LabeledSection>);
}));

import React from 'react';
import { useCollectionName } from '@/store/selectors';
import { InitialSelectionPage } from '@/search-for-retail/generic/page-context-selection';
export const CollectionPageWrapper = ({ titleMessageKey, children, titleBadge, }) => {
    const activeCollection = useCollectionName();
    return (<React.Fragment>
      {!activeCollection
        ? <InitialSelectionPage titleMessageKey={titleMessageKey} selectionMessageKey='SELECT_COLLECTION_PAGE_TEXT' titleBadge={titleBadge}/>
        : children}
    </React.Fragment>);
};
export default CollectionPageWrapper;

export const TAB_NAMES = {
    FROM_WEBSITE_APP: 'PIPELINE_HEALTH_TOOLTIP_WEBSITE',
    TO_GOOGLE: 'PIPELINE_HEALTH_TOOLTIP_GOOGLE',
};
export const TAB_TOOLTIPS = {
    [TAB_NAMES.FROM_WEBSITE_APP]: 'PIPELINE_HEALTH_TOOLTIP_WEBSITE_TEXT',
    [TAB_NAMES.TO_GOOGLE]: 'PIPELINE_HEALTH_TOOLTIP_GOOGLE_TEXT',
};
export const PIPELINE_HEALTH_TITLE_WEBSITE = 'Pipeline Health - From Website/App';
export const PIPELINE_HEALTH_TITLE_GOOGLE = 'Pipeline Health - To Google';

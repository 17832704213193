import styled from 'styled-components';
import { baseColors, ButtonV2, Checkbox } from '@groupby/ui-components';
export const StyledCustomizeViewContainer = styled.div `
  cursor: default;
  width: 25rem;
  height: 95%;
  padding: 0.5rem;
  overflow: auto;
`;
export const StyledCustomizeViewActionContainer = styled.div `
  width: 25rem;
  height: 5%;
  padding: 0.5rem;
  background-color: ${baseColors.background000};

  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const StyledCustomizeViewTitle = styled.div `
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1.5rem;
  color: ${baseColors.primaryBlueDark500}
`;
export const StyledCustomizeViewHeader = styled.div `
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5rem;
  color: ${baseColors.primaryBlueDark500}
`;
export const StyledCustomizeViewButton = styled(ButtonV2) `
  && {
    color: ${baseColors.primaryBlueDark500};


    svg {
      path {
        fill: ${baseColors.primaryBlueMed600};
      }
      circle {
        stroke: ${baseColors.primaryBlueMed600}
      }
    }

    &:hover {
      svg {
        circle {
          stroke: ${baseColors.primaryGreen400}
        }
      }
    }
  }
`;
export const StyledListItem = styled.div `
  width: 100%;
  height: 3rem;

  display: flex;
  align-items: center;

  font-size: 0.875rem;
  font-weight: 400;
  color: ${baseColors.primaryBlueDark500};
`;
export const StyledListItemCheckbox = styled(Checkbox) `
  &.Mui-disabled.Mui-checked {
    color: ${baseColors.primaryBlueMed200};
  }
`;
export const StyledDropZone = styled.div `
  width: 100%;
  height: 3rem;
  background: ${baseColors.primaryBlueAccent050};
`;

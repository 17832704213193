import React, { useMemo } from 'react';
import cn from 'classnames';
import { baseColors } from '@groupby/ui-components';
import { TabTitle } from './partials/tab-title.component';
import { StyledTab, StyledTabRight, StyledTabs } from './tabs.styles';
import { WarningIcon } from '@/components/icons';
export const TabToolBar = ({ id, tabData, activeTab = tabData[0].status, actions, ariaLabel, onTabChange, }) => {
    const onChange = (_e, newTab) => onTabChange === null || onTabChange === void 0 ? void 0 : onTabChange(newTab);
    const activeTabHasErrors = useMemo(() => tabData.find(({ status, hasErrors }) => status === activeTab && hasErrors), [tabData, activeTab]);
    const tabList = useMemo(() => tabData.map(({ status, message, count, isDisabled, hasErrors, }) => (<StyledTab wrapped className={cn({ 'tab--has-errors': hasErrors })} value={status} key={status} disabled={isDisabled} icon={hasErrors && <WarningIcon />} iconPosition="end" label={<TabTitle label={message} count={count}/>} id={`${id}-tab-${status}`} aria-controls={`${id}-tab-panel-${status}`}/>)), [id, tabData]);
    return (<StyledTabs value={activeTab} indicatorColor="primary" textColor="primary" onChange={onChange} aria-label={ariaLabel} TabIndicatorProps={activeTabHasErrors && {
        style: {
            backgroundColor: baseColors.darkRed,
        },
    }}>
      {tabList}
      {actions && (<StyledTabRight>
          {actions}
        </StyledTabRight>)}
    </StyledTabs>);
};
export default TabToolBar;

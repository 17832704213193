import { __rest } from "tslib";
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
export const UnLockIcon = (_a) => {
    var { width = 24, height = 24, fill = 'currentColor', stroke = 'currentColor' } = _a, props = __rest(_a, ["width", "height", "fill", "stroke"]);
    return (<SvgIcon width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M8 10V7C8 4.79086 9.79086 3 12 3C13.8638 3 15.4299 4.27477 15.874 6" stroke={stroke} strokeWidth="1.5" strokeLinecap="round"/>
    <path d="M5 10H19V19C19 20.1046 18.1046 21 17 21H7C5.89543 21 5 20.1046 5 19V10Z" stroke={stroke} strokeWidth="1.5" strokeLinejoin="round"/>
    <rect x="15.5" y="13.5" width="0.01" height="0.01" stroke={stroke} strokeWidth="3" strokeLinejoin="round"/>
  </SvgIcon>);
};
export default UnLockIcon;

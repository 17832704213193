import { __rest } from "tslib";
import React from 'react';
import { baseColors } from '@groupby/ui-components';
import SvgIcon from '@mui/material/SvgIcon';
export const GlobeIcon = (_a) => {
    var { width = 20, height = 20, color = baseColors.primaryBlueMed400 } = _a, props = __rest(_a, ["width", "height", "color"]);
    return (<SvgIcon width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M19 10.75C19 12.6585 18.4376 14.432 17.4739 15.905C15.858 18.3749 13.1136 20 10 20C6.88636 20 4.14202 18.3749 2.52609 15.905C1.56237 14.432 1 12.6585 1 10.75C1 5.64137 5.02944 1.5 10 1.5C14.9706 1.5 19 5.64137 19 10.75Z" stroke={color} strokeWidth="1.5"/>
    <path d="M9.37597 1.5C4.87598 6.125 4.87327 14.8 9.37597 20" stroke={color} strokeWidth="1.5" strokeLinejoin="round"/>
    <path d="M10.624 1.5C15.124 6.125 15.1267 14.8 10.624 20" stroke={color} strokeWidth="1.5" strokeLinejoin="round"/>
    <line x1="19" y1="8.41663" x2="1" y2="8.41663" stroke={color} strokeWidth="1.5"/>
    <line x1="19" y1="13.5559" x2="1" y2="13.5559" stroke={color} strokeWidth="1.5"/>
  </SvgIcon>);
};
export default GlobeIcon;

import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
export const SearchJourneyLoadingIcon = (props) => (<SvgIcon viewBox="0 0 1282 581" preserveAspectRatio="none" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g opacity="0.8">
      <path d="M423.526 36.8125H204.214V45.8703H423.526V36.8125Z" fill="#F8F8FA"/>
      <path d="M423.526 136.448C350.422 136.448 277.318 91.1592 204.214 91.1592V181.737C277.318 181.737 350.422 227.026 423.526 227.026V136.448Z" fill="#F8F8FA"/>
      <path d="M423.526 227.026H204.214V272.315H423.526V227.026Z" fill="#F8F8FA"/>
      <path d="M423.498 534C350.23 534 276.961 324.968 203.693 324.968V318C276.961 318 350.23 527.032 423.498 527.032V534Z" fill="#F8F8FA"/>
      <path d="M423.526 344.777C350.422 344.777 277.318 371.951 204.214 371.951V417.24C277.318 417.24 350.422 390.066 423.526 390.066V344.777Z" fill="#F8F8FA"/>
      <path d="M423.526 390.066C350.422 390.066 277.318 489.702 204.214 489.702V580.28C277.318 580.28 350.422 489.702 423.526 489.702V390.066Z" fill="#F8F8FA"/>
      <path d="M647.225 36.8125H427.913V63.9859H647.225V36.8125Z" fill="#F8F8FA"/>
      <path d="M647.225 63.9863C574.121 63.9863 501.017 136.449 427.913 136.449V299.489C501.017 299.489 574.121 109.275 647.225 109.275V63.9863Z" fill="#F8F8FA"/>
      <path d="M647.225 163.622C574.121 163.622 501.017 354.288 427.913 354.288V444.866C501.017 444.866 574.121 254.882 647.225 254.882V163.622Z" fill="#F8F8FA"/>
      <path d="M647.225 109.275C574.121 109.275 501.017 345.684 427.913 345.684V354.741C501.017 354.741 574.121 118.333 647.225 118.333V109.275Z" fill="#F8F8FA"/>
      <path d="M647.225 299.488C574.121 299.488 501.017 444.413 427.913 444.413V489.702C501.017 489.702 574.121 344.777 647.225 344.777V299.488Z" fill="#F8F8FA"/>
      <path d="M646.754 416.059C573.87 416.059 500.985 55 428.101 55V89.9412C500.985 89.9412 573.87 451 646.754 451V416.059Z" fill="#F8F8FA"/>
      <path d="M870.924 36.8125H651.612V87.7625C724.716 87.7625 797.82 82.1014 870.924 82.1014V36.8125Z" fill="#F8F8FA"/>
      <path d="M870.924 82.1016C797.82 82.1016 724.716 299.488 651.612 299.488V390.066C724.716 390.066 797.82 172.679 870.924 172.679V82.1016Z" fill="#F8F8FA"/>
      <path d="M870.924 217.968C797.82 217.968 724.716 87.7627 651.612 87.7627V118.333C724.716 118.333 797.82 245.142 870.924 245.142V217.968Z" fill="#F8F8FA"/>
      <path d="M871.162 305.59C797.894 305.59 724.626 88 651.357 88V139.086C724.626 139.086 797.894 351 871.162 351V305.59Z" fill="#F8F8FA"/>
      <path d="M870.924 245.142C797.82 245.142 724.716 163.622 651.612 163.622V254.882C724.716 254.882 797.82 254.2 870.924 254.2V245.142Z" fill="#F8F8FA"/>
      <path d="M1094.62 36.8125H875.311V172.679C948.415 172.679 1021.52 100.217 1094.62 100.217V36.8125Z" fill="#F8F8FA"/>
      <path d="M1094.62 100.218C1021.52 100.218 948.415 217.969 875.311 217.969V254.2C948.415 254.2 1021.52 220.901 1094.62 220.901V100.218Z" fill="#F8F8FA"/>
      <path d="M423.526 272.315C350.422 272.315 277.318 417.24 204.214 417.24V444.413C277.318 444.413 350.422 299.489 423.526 299.489V272.315Z" fill="#F8F8FA"/>
      <rect x="199.829" y="36.3027" width="4.3829" height="9.05778" fill="#F8F8FA"/>
      <rect x="199.829" y="90.6495" width="4.3829" height="90.5778" fill="#F8F8FA"/>
      <rect x="199.829" y="226.517" width="4.3829" height="45.2889" fill="#F8F8FA"/>
      <rect x="199.829" y="317.094" width="4.3829" height="9.05778" fill="#F8F8FA"/>
      <rect x="199.829" y="371.44" width="4.3829" height="72.4623" fill="#F8F8FA"/>
      <rect x="199.829" y="489.192" width="4.3829" height="90.5778" fill="#F8F8FA"/>
      <rect x="424.055" y="36.3027" width="10.4891" height="55.038" fill="#F8F8FA"/>
      <rect x="424.055" y="135.371" width="10.4891" height="163.542" fill="#F8F8FA"/>
      <rect x="424.055" y="344.516" width="10.4891" height="144.671" fill="#F8F8FA"/>
      <rect x="647.226" y="36.3027" width="10.4891" height="81.5201" fill="#F8F8FA"/>
      <rect x="646.97" y="163.677" width="10.4891" height="91.2059" fill="#F8F8FA"/>
      <rect x="646.97" y="298.913" width="10.4891" height="91.2059" fill="#F8F8FA"/>
      <rect x="870.925" y="36.3027" width="10.4891" height="135.867" fill="#F8F8FA"/>
      <rect x="870.925" y="217.458" width="10.4891" height="36.2311" fill="#F8F8FA"/>
      <rect x="1094.28" y="36.4004" width="6.81974" height="185" fill="#F8F8FA"/>
    </g>
    <rect y="37" width="153.057" height="14" fill="#F8F8FA"/>
    <rect x="1128.94" y="37" width="153.057" height="14" fill="#F8F8FA"/>
    <rect y="85" width="153.057" height="14" fill="#F8F8FA"/>
    <rect y="133" width="153.057" height="14" fill="#F8F8FA"/>
    <rect y="181" width="153.057" height="14" fill="#F8F8FA"/>
    <rect y="229" width="153.057" height="14" fill="#F8F8FA"/>
    <rect y="277" width="153.057" height="14" fill="#F8F8FA"/>
    <rect y="325" width="153.057" height="14" fill="#F8F8FA"/>
    <rect y="373" width="153.057" height="14" fill="#F8F8FA"/>
    <rect y="421" width="153.057" height="14" fill="#F8F8FA"/>
    <rect y="469" width="153.057" height="14" fill="#F8F8FA"/>
    <rect y="517" width="153.057" height="14" fill="#F8F8FA"/>
    <rect y="565" width="153.057" height="14" fill="#F8F8FA"/>
    <rect x="229.011" width="173.772" height="16" fill="#F8F8FA"/>
    <rect x="451.117" width="173.772" height="16" fill="#F8F8FA"/>
    <rect x="684.73" width="173.772" height="16" fill="#F8F8FA"/>
    <rect x="906.837" width="173.772" height="16" fill="#F8F8FA"/>
  </SvgIcon>);

import React, { forwardRef, useEffect, useState } from 'react';
import { ButtonV2, Checkbox, MenuItem } from '@groupby/ui-components';
import { IconButton, Tooltip } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { useLocalization } from '@/localization';
import { StyledFilterMenuActions, StyledFilterMenuSearch, StyledFilterMenuSearchContainer, StyledMenuList, StyledMenuSearch, } from './header-filter-menu.styles';
const FilterMenuItem = ({ value, valueList, label, handleSelect, }) => {
    const [isTooltipOpen, setIsTooltipOpen] = useState(false);
    const onMouseEnter = (event) => {
        const el = event.target;
        const span = el.querySelector('span.children');
        setIsTooltipOpen((el === null || el === void 0 ? void 0 : el.offsetWidth) < (el === null || el === void 0 ? void 0 : el.scrollWidth) || (span === null || span === void 0 ? void 0 : span.offsetWidth) < (span === null || span === void 0 ? void 0 : span.scrollWidth));
    };
    return (<Tooltip arrow open={isTooltipOpen} title={label} onClose={() => setIsTooltipOpen(false)}>
      <MenuItem onClick={() => handleSelect(value, !valueList.includes(value))} onMouseEnter={onMouseEnter}>
        <Checkbox checked={valueList.includes(value)}/>
        {label}
      </MenuItem>
    </Tooltip>);
};
const HeaderFilterMenu = forwardRef(({ open, searchable, onClose, anchorEl, menuItems, updateFilter, value, groupActions = true, }, ref) => {
    const { formatMessage: t } = useLocalization();
    const [inputSearch, setInputSearch] = useState('');
    const handleSelect = (newValue, checked) => {
        updateFilter(checked
            ? [...value, newValue]
            : value.filter((item) => item !== newValue));
    };
    const handleSelectNone = () => updateFilter([]);
    const handleSelectAll = () => updateFilter(menuItems === null || menuItems === void 0 ? void 0 : menuItems.map(({ value }) => value));
    useEffect(() => {
        setInputSearch('');
    }, [open]);
    return (<StyledMenuList ref={ref} open={open} onClose={onClose} anchorEl={anchorEl} variant="menu" autoFocus={false} autoFocusItem={false} anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
    }} transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
    }} PaperProps={{
        style: {
            maxHeight: '19rem',
            width: '13rem',
        },
    }}>

        {(searchable || groupActions) && (<StyledFilterMenuSearch>
            {searchable && (<StyledFilterMenuSearchContainer onKeyDown={(event) => event.stopPropagation()}>
                <StyledMenuSearch fullWidth variant="outlined" value={inputSearch} placeholder={t({ key: 'SEARCH' })} onChange={(e) => {
        e.preventDefault();
        e.stopPropagation();
        setInputSearch(e.target.value);
    }} InputProps={{
        startAdornment: (<InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>),
        endAdornment: inputSearch.length > 0 && (<InputAdornment position="end">
                        <IconButton onClick={() => setInputSearch('')}>
                          <CloseIcon />
                        </IconButton>
                      </InputAdornment>),
    }}/>
              </StyledFilterMenuSearchContainer>)}
            {groupActions && (<StyledFilterMenuActions>
                <ButtonV2 size="small" onClick={handleSelectAll} disabled={(value === null || value === void 0 ? void 0 : value.length) === (menuItems === null || menuItems === void 0 ? void 0 : menuItems.length)}>
                  {t({ key: 'SELECT_ALL' })}
                </ButtonV2>
                <ButtonV2 size="small" onClick={handleSelectNone} disabled={(value === null || value === void 0 ? void 0 : value.length) === 0}>
                  {t({ key: 'CLEAR' })}
                </ButtonV2>
              </StyledFilterMenuActions>)}
          </StyledFilterMenuSearch>)}
        {menuItems
        .filter(({ label }) => String(label).toLowerCase().includes(inputSearch.toLowerCase()))
        .map((item) => (<FilterMenuItem key={item.value} label={item.label} value={item.value} valueList={value} handleSelect={handleSelect}/>))}
      </StyledMenuList>);
});
HeaderFilterMenu.displayName = 'HeaderFilterMenu';
export default HeaderFilterMenu;

import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
export const DashboardEmptyIcon = (props) => (<SvgIcon width="81" height="80" viewBox="0 0 81 80" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M27.7734 79.38C35.5054 79.38 41.7734 73.112 41.7734 65.38C41.7734 57.648 35.5054 51.38 27.7734 51.38C20.0415 51.38 13.7734 57.648 13.7734 65.38C13.7734 73.112 20.0415 79.38 27.7734 79.38Z" fill="#E2E6EC"/>
    <path d="M68.0234 44.9401C74.6509 44.9401 80.0234 39.5675 80.0234 32.9401C80.0234 26.3126 74.6509 20.9401 68.0234 20.9401C61.396 20.9401 56.0234 26.3126 56.0234 32.9401C56.0234 39.5675 61.396 44.9401 68.0234 44.9401Z" fill="#E2E6EC"/>
    <path d="M20.5234 40C31.5691 40 40.5234 31.0457 40.5234 20C40.5234 8.9543 31.5691 0 20.5234 0C9.47774 0 0.523438 8.9543 0.523438 20C0.523438 31.0457 9.47774 40 20.5234 40Z" fill="#E2E6EC"/>
    <path d="M39.7734 70.13C57.4465 70.13 71.7734 55.8031 71.7734 38.13C71.7734 20.4569 57.4465 6.13 39.7734 6.13C22.1003 6.13 7.77344 20.4569 7.77344 38.13C7.77344 55.8031 22.1003 70.13 39.7734 70.13Z" fill="white"/>
    <path d="M59.7234 13.13C64.0934 18.6 66.7234 25.5301 66.7234 33.0801C66.7234 50.7501 52.3934 65.0801 34.7234 65.0801C27.1734 65.0801 20.2534 62.4501 14.7734 58.0801C20.6334 65.4201 29.6434 70.13 39.7734 70.13C57.4434 70.13 71.7734 55.8 71.7734 38.13C71.7734 28.01 67.0634 19 59.7234 13.13Z" fill="#C3CEFF"/>
    <path d="M39.7734 70.88C21.7134 70.88 7.02344 56.19 7.02344 38.13C7.02344 20.07 21.7134 5.38 39.7734 5.38C57.8334 5.38 72.5234 20.07 72.5234 38.13C72.5234 56.19 57.8334 70.88 39.7734 70.88ZM39.7734 6.88C22.5434 6.88 8.52344 20.9 8.52344 38.13C8.52344 55.36 22.5434 69.38 39.7734 69.38C57.0034 69.38 71.0234 55.36 71.0234 38.13C71.0234 20.9 57.0034 6.88 39.7734 6.88Z" fill="#081E42"/>
    <path d="M24.7739 15.2301C24.5239 15.2301 24.2837 15.11 24.1437 14.88C23.9237 14.53 24.024 14.0701 24.374 13.8401C25.324 13.2401 26.3139 12.6801 27.3339 12.1901C27.7039 12.0101 28.1539 12.17 28.3339 12.54C28.5139 12.91 28.3538 13.36 27.9838 13.54C27.0238 14 26.0738 14.5301 25.1838 15.1001C25.0638 15.1801 24.9239 15.2201 24.7839 15.2201L24.7739 15.2301Z" fill="#081E42"/>
    <path d="M12.2139 33.9401C12.2139 33.9401 12.124 33.9401 12.084 33.9301C11.674 33.8601 11.4039 33.4701 11.4739 33.0601C12.5939 26.7801 15.8441 20.9601 20.6341 16.6801C20.9441 16.4001 21.4139 16.43 21.6939 16.74C21.9739 17.05 21.9441 17.5201 21.6341 17.8001C17.0941 21.8501 14.0139 27.3601 12.9539 33.3201C12.8939 33.6801 12.5739 33.9401 12.2139 33.9401Z" fill="#081E42"/>
    <path d="M39.7734 38.88C39.7134 38.88 39.6435 38.88 39.5835 38.86C39.2535 38.77 39.0234 38.48 39.0234 38.13V6.13C39.0234 5.72 39.3634 5.38 39.7734 5.38C40.1834 5.38 40.5234 5.72 40.5234 6.13V35.28L54.8635 9.90002C55.0735 9.54002 55.5235 9.41 55.8835 9.62C56.2435 9.81999 56.3733 10.28 56.1633 10.64L40.4233 38.5C40.2833 38.74 40.0334 38.88 39.7734 38.88Z" fill="#081E42"/>
    <path d="M21.8833 65.4101C21.7433 65.4101 21.5932 65.3701 21.4632 65.2801C21.1232 65.0501 21.0332 64.58 21.2632 64.24L39.1531 37.7101C39.2431 37.5701 39.3832 37.4701 39.5332 37.4201L69.9031 27.3001C70.3031 27.1701 70.7233 27.3801 70.8533 27.7701C70.9833 28.1601 70.7733 28.5901 70.3833 28.7201L40.2632 38.76L22.5132 65.0801C22.3632 65.2901 22.1331 65.4101 21.8931 65.4101H21.8833Z" fill="#081E42"/>
    <path d="M66.4733 56.52C66.3333 56.52 66.1834 56.48 66.0634 56.4L39.3634 38.76C39.0134 38.53 38.9232 38.07 39.1532 37.72C39.3832 37.37 39.8433 37.28 40.1933 37.51L66.8932 55.15C67.2432 55.38 67.3334 55.84 67.1034 56.19C66.9634 56.41 66.7233 56.53 66.4733 56.53V56.52Z" fill="#081E42"/>
    <path d="M39.7734 60.13C51.9237 60.13 61.7734 50.2803 61.7734 38.13C61.7734 25.9797 51.9237 16.13 39.7734 16.13C27.6232 16.13 17.7734 25.9797 17.7734 38.13C17.7734 50.2803 27.6232 60.13 39.7734 60.13Z" fill="white"/>
    <path d="M57.0537 24.51C57.5237 26.3 57.7837 28.18 57.7837 30.12C57.7837 42.27 47.9337 52.12 35.7837 52.12C28.7737 52.12 22.5337 48.84 18.5137 43.74C20.9937 53.17 29.5737 60.13 39.7837 60.13C51.9337 60.13 61.7837 50.28 61.7837 38.13C61.7837 32.99 60.0137 28.26 57.0537 24.51Z" fill="#C3CEFF"/>
    <path d="M39.7734 60.88C27.2334 60.88 17.0234 50.67 17.0234 38.13C17.0234 25.59 27.2334 15.38 39.7734 15.38C52.3134 15.38 62.5234 25.59 62.5234 38.13C62.5234 50.67 52.3134 60.88 39.7734 60.88ZM39.7734 16.88C28.0534 16.88 18.5234 26.41 18.5234 38.13C18.5234 49.85 28.0534 59.38 39.7734 59.38C51.4934 59.38 61.0234 49.85 61.0234 38.13C61.0234 26.41 51.4934 16.88 39.7734 16.88Z" fill="#081E42"/>
    <path d="M36.7734 25.13V59.92C37.7534 60.05 38.7534 60.13 39.7734 60.13C40.7934 60.13 41.7934 60.05 42.7734 59.92V25.13H36.7734Z" fill="white"/>
    <path d="M39.7734 25.13V56.03C39.7734 56.54 39.3935 56.98 38.8835 57.03C38.1835 57.1 37.4834 57.14 36.7734 57.14V59.9301C37.7534 60.0601 38.7534 60.14 39.7734 60.14C40.7934 60.14 41.7934 60.0601 42.7734 59.9301V25.14H39.7734V25.13Z" fill="#C3CEFF"/>
    <path d="M39.7734 60.88C38.7634 60.88 37.7233 60.81 36.6733 60.66C36.3033 60.61 36.0234 60.29 36.0234 59.92V25.13C36.0234 24.72 36.3634 24.38 36.7734 24.38H42.7734C43.1834 24.38 43.5234 24.72 43.5234 25.13V59.92C43.5234 60.3 43.2435 60.61 42.8735 60.66C41.8235 60.8 40.7834 60.88 39.7734 60.88ZM37.5234 59.26C39.0534 59.42 40.4934 59.42 42.0234 59.26V25.88H37.5234V59.26Z" fill="#081E42"/>
    <path d="M42.7734 34.13V59.92C44.8834 59.63 46.8934 59.0401 48.7734 58.2001V34.13H42.7734Z" fill="white"/>
    <path d="M45.7734 34.13V53.8501C45.7734 54.6901 45.2434 55.44 44.4534 55.74C43.9034 55.94 43.3434 56.13 42.7734 56.29V59.92C44.8834 59.63 46.8934 59.0401 48.7734 58.2001V34.13H45.7734Z" fill="#C3CEFF"/>
    <path d="M42.7734 60.67C42.5934 60.67 42.4134 60.6 42.2834 60.49C42.1234 60.35 42.0234 60.14 42.0234 59.92V34.13C42.0234 33.72 42.3634 33.38 42.7734 33.38H48.7734C49.1834 33.38 49.5234 33.72 49.5234 34.13V58.2001C49.5234 58.4901 49.3535 58.76 49.0835 58.88C47.1135 59.77 45.0235 60.36 42.8735 60.66C42.8435 60.66 42.8034 60.66 42.7734 60.66V60.67ZM43.5234 34.88V59.04C45.0734 58.76 46.5734 58.3201 48.0234 57.7101V34.88H43.5234Z" fill="#081E42"/>
    <path d="M48.7734 30.13V58.2001C50.9934 57.2001 53.0134 55.8501 54.7734 54.2101V30.13H48.7734Z" fill="white"/>
    <path d="M51.7734 30.13V51.3101C51.7734 51.8901 51.5335 52.4401 51.0935 52.8201C50.3635 53.4501 49.5834 54.0301 48.7734 54.5601V58.2101C50.9934 57.2101 53.0134 55.8601 54.7734 54.2201V30.14H51.7734V30.13Z" fill="#C3CEFF"/>
    <path d="M48.7734 58.9501C48.6334 58.9501 48.4935 58.9101 48.3635 58.8301C48.1535 58.6901 48.0234 58.4501 48.0234 58.2001V30.13C48.0234 29.72 48.3634 29.38 48.7734 29.38H54.7734C55.1834 29.38 55.5234 29.72 55.5234 30.13V54.2101C55.5234 54.4201 55.4334 54.62 55.2834 54.76C53.4534 56.47 51.3635 57.86 49.0835 58.88C48.9835 58.92 48.8834 58.9501 48.7734 58.9501ZM49.5234 30.88V57.01C51.1534 56.17 52.6634 55.12 54.0234 53.89V30.89H49.5234V30.88Z" fill="#081E42"/>
    <path d="M30.7734 37.13V58.2001C32.6534 59.0401 34.6634 59.63 36.7734 59.92V37.13H30.7734Z" fill="white"/>
    <path d="M33.7734 37.13V54.74C33.7734 55.37 33.1935 55.8401 32.5635 55.7201C31.9535 55.6001 31.3634 55.46 30.7734 55.29V58.2001C32.6534 59.0401 34.6634 59.63 36.7734 59.92V37.13H33.7734Z" fill="#C3CEFF"/>
    <path d="M36.7734 60.67C36.7734 60.67 36.7033 60.67 36.6733 60.67C34.5233 60.38 32.4434 59.78 30.4634 58.89C30.1934 58.77 30.0234 58.5001 30.0234 58.2101V37.14C30.0234 36.73 30.3634 36.39 30.7734 36.39H36.7734C37.1834 36.39 37.5234 36.73 37.5234 37.14V59.9301C37.5234 60.1501 37.4334 60.35 37.2634 60.5C37.1234 60.62 36.9534 60.6801 36.7734 60.6801V60.67ZM31.5234 57.7101C32.9734 58.3201 34.4734 58.77 36.0234 59.04V37.88H31.5234V57.7101Z" fill="#081E42"/>
    <path d="M24.7734 41.13V54.2101C26.5334 55.8501 28.5534 57.2001 30.7734 58.2001V41.13H24.7734Z" fill="white"/>
    <path d="M27.7734 41.13V51.4C27.7734 51.77 27.3835 52.02 27.0535 51.86C26.2635 51.47 25.5034 51.03 24.7734 50.55V54.2101C26.5334 55.8501 28.5534 57.2001 30.7734 58.2001V41.13H27.7734Z" fill="#C3CEFF"/>
    <path d="M30.7734 58.9501C30.6734 58.9501 30.5634 58.93 30.4634 58.88C28.1834 57.85 26.0934 56.47 24.2634 54.76C24.1134 54.62 24.0234 54.4201 24.0234 54.2101V41.13C24.0234 40.72 24.3634 40.38 24.7734 40.38H30.7734C31.1834 40.38 31.5234 40.72 31.5234 41.13V58.2001C31.5234 58.4501 31.3933 58.6901 31.1833 58.8301C31.0633 58.9101 30.9234 58.9501 30.7734 58.9501ZM25.5234 53.88C26.8834 55.11 28.3934 56.16 30.0234 57V41.87H25.5234V53.87V53.88Z" fill="#081E42"/>
    <path d="M12.7734 13.13C13.878 13.13 14.7734 12.2346 14.7734 11.13C14.7734 10.0254 13.878 9.13 12.7734 9.13C11.6689 9.13 10.7734 10.0254 10.7734 11.13C10.7734 12.2346 11.6689 13.13 12.7734 13.13Z" fill="white"/>
    <path d="M12.7734 13.88C11.2534 13.88 10.0234 12.65 10.0234 11.13C10.0234 9.61 11.2534 8.38 12.7734 8.38C14.2934 8.38 15.5234 9.61 15.5234 11.13C15.5234 12.65 14.2934 13.88 12.7734 13.88ZM12.7734 9.88C12.0834 9.88 11.5234 10.44 11.5234 11.13C11.5234 11.82 12.0834 12.38 12.7734 12.38C13.4634 12.38 14.0234 11.82 14.0234 11.13C14.0234 10.44 13.4634 9.88 12.7734 9.88Z" fill="#081E42"/>
    <path d="M63.7734 10.13C64.878 10.13 65.7734 9.23457 65.7734 8.13C65.7734 7.02544 64.878 6.13 63.7734 6.13C62.6689 6.13 61.7734 7.02544 61.7734 8.13C61.7734 9.23457 62.6689 10.13 63.7734 10.13Z" fill="white"/>
    <path d="M63.7734 10.88C62.2534 10.88 61.0234 9.65 61.0234 8.13C61.0234 6.61 62.2534 5.38 63.7734 5.38C65.2934 5.38 66.5234 6.61 66.5234 8.13C66.5234 9.65 65.2934 10.88 63.7734 10.88ZM63.7734 6.88C63.0834 6.88 62.5234 7.44 62.5234 8.13C62.5234 8.82 63.0834 9.38 63.7734 9.38C64.4634 9.38 65.0234 8.82 65.0234 8.13C65.0234 7.44 64.4634 6.88 63.7734 6.88Z" fill="#081E42"/>
    <path d="M69.7734 17.13C70.878 17.13 71.7734 16.2346 71.7734 15.13C71.7734 14.0254 70.878 13.13 69.7734 13.13C68.6689 13.13 67.7734 14.0254 67.7734 15.13C67.7734 16.2346 68.6689 17.13 69.7734 17.13Z" fill="white"/>
    <path d="M69.7734 17.88C68.2534 17.88 67.0234 16.65 67.0234 15.13C67.0234 13.61 68.2534 12.38 69.7734 12.38C71.2934 12.38 72.5234 13.61 72.5234 15.13C72.5234 16.65 71.2934 17.88 69.7734 17.88ZM69.7734 13.88C69.0834 13.88 68.5234 14.44 68.5234 15.13C68.5234 15.82 69.0834 16.38 69.7734 16.38C70.4634 16.38 71.0234 15.82 71.0234 15.13C71.0234 14.44 70.4634 13.88 69.7734 13.88Z" fill="#081E42"/>
    <path d="M9.77344 64.13C10.878 64.13 11.7734 63.2346 11.7734 62.13C11.7734 61.0254 10.878 60.13 9.77344 60.13C8.66887 60.13 7.77344 61.0254 7.77344 62.13C7.77344 63.2346 8.66887 64.13 9.77344 64.13Z" fill="white"/>
    <path d="M9.77344 64.88C8.25344 64.88 7.02344 63.65 7.02344 62.13C7.02344 60.61 8.25344 59.38 9.77344 59.38C11.2934 59.38 12.5234 60.61 12.5234 62.13C12.5234 63.65 11.2934 64.88 9.77344 64.88ZM9.77344 60.88C9.08344 60.88 8.52344 61.44 8.52344 62.13C8.52344 62.82 9.08344 63.38 9.77344 63.38C10.4634 63.38 11.0234 62.82 11.0234 62.13C11.0234 61.44 10.4634 60.88 9.77344 60.88Z" fill="#081E42"/>
    <path d="M17.7734 70.13C18.878 70.13 19.7734 69.2346 19.7734 68.13C19.7734 67.0254 18.878 66.13 17.7734 66.13C16.6689 66.13 15.7734 67.0254 15.7734 68.13C15.7734 69.2346 16.6689 70.13 17.7734 70.13Z" fill="white"/>
    <path d="M17.7734 70.88C16.2534 70.88 15.0234 69.65 15.0234 68.13C15.0234 66.61 16.2534 65.38 17.7734 65.38C19.2934 65.38 20.5234 66.61 20.5234 68.13C20.5234 69.65 19.2934 70.88 17.7734 70.88ZM17.7734 66.88C17.0834 66.88 16.5234 67.44 16.5234 68.13C16.5234 68.82 17.0834 69.38 17.7734 69.38C18.4634 69.38 19.0234 68.82 19.0234 68.13C19.0234 67.44 18.4634 66.88 17.7734 66.88Z" fill="#081E42"/>
    <path d="M68.7734 65.13C69.878 65.13 70.7734 64.2346 70.7734 63.13C70.7734 62.0254 69.878 61.13 68.7734 61.13C67.6689 61.13 66.7734 62.0254 66.7734 63.13C66.7734 64.2346 67.6689 65.13 68.7734 65.13Z" fill="white"/>
    <path d="M68.7734 65.88C67.2534 65.88 66.0234 64.65 66.0234 63.13C66.0234 61.61 67.2534 60.38 68.7734 60.38C70.2934 60.38 71.5234 61.61 71.5234 63.13C71.5234 64.65 70.2934 65.88 68.7734 65.88ZM68.7734 61.88C68.0834 61.88 67.5234 62.44 67.5234 63.13C67.5234 63.82 68.0834 64.38 68.7734 64.38C69.4634 64.38 70.0234 63.82 70.0234 63.13C70.0234 62.44 69.4634 61.88 68.7734 61.88Z" fill="#081E42"/>
  </SvgIcon>);

import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setMostRecentlyUpdatedModel } from '@/store/actions/creators';
import { useMostRecentlyUpdatedModel } from '@/store/selectors';
export const DELAY = 200;
export const FADE_DELAY = 2000;
export const highlightTarget = ({ target, className, resetFunction, scrollToHighlightedItem, delay = DELAY, fadeDelay = FADE_DELAY, }) => {
    if (scrollToHighlightedItem)
        target.scrollIntoView();
    target.classList.add(className);
    let shadowTimeoutId;
    const highlightingTimeoutId = setTimeout(() => {
        target.classList.remove(className);
        target.style.transition = `background-color ${fadeDelay}ms`;
        resetFunction();
        shadowTimeoutId = setTimeout(() => { target.style.transition = ''; }, fadeDelay);
    }, delay);
    return ({
        shadowTimeoutId,
        highlightingTimeoutId,
    });
};
export const useTableHighlighting = ({ tableRef, className = 'row--highlighted', resetFunction, scrollToHighlightedItem = true, }) => {
    const dispatch = useDispatch();
    const mostRecentlyUpdatedModel = useMostRecentlyUpdatedModel();
    const commonReset = () => dispatch(setMostRecentlyUpdatedModel(null, null));
    const reset = resetFunction || commonReset;
    useEffect(() => {
        var _a;
        const target = (_a = tableRef === null || tableRef === void 0 ? void 0 : tableRef.current) === null || _a === void 0 ? void 0 : _a.querySelector(`#row_${mostRecentlyUpdatedModel}`);
        if (target) {
            const { highlightingTimeoutId, shadowTimeoutId } = highlightTarget({
                target,
                className,
                resetFunction: reset,
                scrollToHighlightedItem,
            });
            return () => {
                clearTimeout(shadowTimeoutId);
                clearTimeout(highlightingTimeoutId);
            };
        }
    }, [mostRecentlyUpdatedModel, reset, className, scrollToHighlightedItem, tableRef, resetFunction]);
};
export default useTableHighlighting;

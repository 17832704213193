import * as Sentry from '@sentry/react';
export function logError(message, error) {
    console.error(message, error);
    if (error) {
        Sentry.captureException(error, { tags: { message } });
    }
    else {
        Sentry.captureMessage(message);
    }
}

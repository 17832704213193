import React, { useCallback, useState } from 'react';
import { Grid } from '@mui/material';
import { LocalizedMessage, useLocalization } from '@/localization';
import { FilterButton } from './partials/filter-button.component';
import { AdvancedFilters } from './partials/advanced-filters.component';
import { AdvancedFiltersButton } from './partials/advanced-filters-button.component';
import { StyledSearchBlock } from './table-filter.styles';
export const TableFilter = ({ filter = {}, numberOfResults, modelType, updateFilter, resetFilter, quickFilterButtons, advancedFiltersButton, hasAndOrToggle = false, }) => {
    var _a;
    const { formatMessage: t } = useLocalization();
    const [isAdvancedFiltersShown, setIsAdvancedFiltersShown] = useState(false);
    const onAdvancedFiltersClick = useCallback(() => setIsAdvancedFiltersShown((prev) => !prev), []);
    const onInputChange = (e) => updateFilter({ search: e.currentTarget.value });
    const onInputClear = () => resetFilter(['search']);
    const onRecentlyUpdatedFilterClick = (e) => updateFilter({ recentlyUpdated: e.target.checked });
    const onOnlyMyRulesFilterClick = (e) => updateFilter({ onlyMyStuff: e.target.checked });
    const filterButtons = quickFilterButtons ? [
        <FilterButton key="onlyMyRules" name="onlyMyRules" checked={filter.onlyMyStuff} onChange={onOnlyMyRulesFilterClick} label={<LocalizedMessage messageKey="ONLY_MY_STUFF" values={{ modelType: modelType.toLowerCase() }}/>} size="small"/>,
        <FilterButton key="recentlyUpdated" name="recentlyUpdated" checked={filter.recentlyUpdated} onChange={onRecentlyUpdatedFilterClick} label={t({ key: 'RECENTLY_UPDATED' })} size="small"/>,
    ] : undefined;
    return (<Grid container alignItems="center">
      <Grid container>
        <Grid item xs>
          <StyledSearchBlock placeholder={t({ key: 'SEARCH' })} searchInputValue={(_a = filter.search) !== null && _a !== void 0 ? _a : ''} numberOfResultsLabel={<LocalizedMessage messageKey="SEARCH_INPUT_RESULTS_LABEL" values={{ numberOfResults, modelType }}/>} handleInputChange={onInputChange} handleInputClear={onInputClear} quickFilterButtons={filterButtons}/>
        </Grid>
        {advancedFiltersButton && (<AdvancedFiltersButton onClick={onAdvancedFiltersClick} hidden={isAdvancedFiltersShown}/>)}
      </Grid>

      {isAdvancedFiltersShown && (<AdvancedFilters updateFilter={updateFilter} resetFilter={resetFilter} onAdvancedFiltersIsEmpty={onAdvancedFiltersClick} hasAndOrToggle={hasAndOrToggle}/>)}
    </Grid>);
};
export default TableFilter;

import styled from 'styled-components';
import { StyledSearchRowContainer } from '../common/search-row.styles';
export const StyledQuerySearchRow = styled(StyledSearchRowContainer) `
  align-items: center;
  & .MuiGrid-container {
    display: block;
    flex: 0 1 500px;
  }

  & .MuiGrid-item {
    max-width: 100%;
  }

  & .search-input-container {
    border-radius: 8px 8px 0 0;
  }
`;
export const StyledCustomizeViewButtonWrapper = styled.div `
flex-grow: 1;
justify-content: flex-end;
display: flex;

button {
  margin-left: 10px;
  height: 36px;
  
  .view-customize-icon {
      transform: rotate(180deg);
  }
}`;

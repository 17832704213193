import { baseColors, Icon, shadows } from '@groupby/ui-components';
import { List, ListItemButton, ListItemText, Menu, MenuItem, OutlinedInput, } from '@mui/material';
import styled from 'styled-components';
import { TitleBadge } from '../title-badge/title-badge.component';
const MENU_ITEM_HEIGHT = '48px';
export const StyledContextSelector = styled.div `
  display: flex;
  background: ${({ $disabled }) => ($disabled ? baseColors.primaryBlueMed200 : baseColors.background050)};
  width: 300px;
  height: 40px;
  border-radius: 4px;
`;
export const StyledList = styled(List) `
  flex: 1;
  overflow: hidden;

  > .MuiListItemButton-root {
    justify-content: space-between;
    &:hover {
      background: none
    }
  }

  > .MuiListItemButton-root.Mui-disabled {
    opacity: 1;
  }
// https://github.com/mui/material-ui/issues/32658
`;
export const StyledListItem = styled(ListItemButton) `
  height: 100%;
  display: flex;
  gap: 5px;
  padding: 0px;

  & .MuiListItemText-secondary,
  & path {
    transition: all .2s ease-in-out;
  }

  &:hover .MuiListItemText-secondary {
    color: ${baseColors.primaryGreen200};
  }

  &:hover path {
    fill: ${baseColors.primaryGreen200};
  }

  & .icon-wrapper-class {
    margin: 0px;
  }
`;
export const StyledListItemText = styled(ListItemText) `
  display: flex;
  && {
    flex-grow: 0;
  }

  & .MuiListItemText-primary {
    color: ${({ theme, $disabled }) => ($disabled ? baseColors.disabled : theme.palette.common.white)};
    font-size: 14px;
    height: 40px;
    text-transform: uppercase;
    background: ${baseColors.background700};
    padding: 0px 16px;
    border-radius: 4px 0px 0px 4px;
    display: flex;
    align-items: center;
  }

  & .MuiListItemText-secondary {
    color: ${({ theme }) => theme.palette.text.secondary};
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    align-items: center;
    padding: 0px 8px;
  }
`;
export const StyledMenu = styled(Menu) `
  .MuiPaper-root {
    box-sizing: border-box;
    min-width: 300px;
    padding: 8px;
    border-radius: 8px;
    color: ${({ theme }) => theme.palette.accent.main};
    margin-top: 12px;
    text-align: center;
    max-height: 300px;
    z-index: 1400;
  }

  & .MuiList-root {
    display: flex;
  }
`;
export const StyledMenuContentWrapper = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;
export const StyledSearchInput = styled(OutlinedInput) `
  && {
    height: ${MENU_ITEM_HEIGHT};
    min-width: 260px;
    flex-basis: fill;
    box-shadow: ${shadows.dropdownSwitcherSearchInput};
    border-radius: 8px;
    margin: 0 0 12px 0;
    color: ${({ theme }) => theme.palette.text.secondary};
    background: ${baseColors.white}
  }
`;
export const StyledMenuItem = styled(MenuItem) `
  && {
    display: flex;
    border-left: 4px solid transparent;
    padding-left: 12px;
    transition: all .2s ease-in-out;
    height: ${MENU_ITEM_HEIGHT};
    justify-content: space-between;

    &.menu-item--selected,
    &:hover {
      background: ${baseColors.primaryGreen001};
    }
  }
`;
export const StyledTitleBadge = styled(TitleBadge) `
  border: 1px solid ${baseColors.primaryBlue200};
  background: ${baseColors.primaryBlue100};
  color: ${baseColors.primaryBlue500};
`;
export const StyledArrowIcon = styled(Icon) `
  padding: 0px 16px;
  ${({ $menuOpen }) => $menuOpen && 'transform: rotate(180deg)'};
`;

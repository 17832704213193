export var UploadType;
(function (UploadType) {
    UploadType["BASELINE"] = "BASELINE";
    UploadType["PARTIAL"] = "PARTIAL";
    UploadType["PARTIAL_PARTIAL"] = "PARTIAL_PARTIAL";
    UploadType["PARTIAL_CATALOG"] = "PARTIAL_CATALOG";
    UploadType["PARTIAL_RECORD"] = "PARTIAL_RECORD";
    UploadType["BASELINE_CLEAN"] = "BASELINE_CLEAN";
    UploadType["BASELINE_INVENTORIES"] = "BASELINE_INVENTORIES";
    UploadType["BASELINE_INVENTORIES_CLEAN"] = "BASELINE_INVENTORIES_CLEAN";
    UploadType["AUTOCOMPLETE_DENY_LIST"] = "AUTOCOMPLETE_DENY_LIST";
    UploadType["LOCAL_INVENTORIES"] = "LOCAL_INVENTORIES";
})(UploadType || (UploadType = {}));
export var IngestionStage;
(function (IngestionStage) {
    IngestionStage["COMPLETE"] = "COMPLETE";
    IngestionStage["IN_PROGRESS"] = "IN_PROGRESS";
    IngestionStage["FAILED"] = "FAILED";
    IngestionStage["IN_QUEUE"] = "QUEUED";
    IngestionStage["INITIATED"] = "INITIATED";
    IngestionStage["ABANDONED"] = "ABANDONED";
    IngestionStage["CANCELLED"] = "CANCELLED";
    IngestionStage["SENT_PROCESSING"] = "SENT_FOR_PROCESSING";
})(IngestionStage || (IngestionStage = {}));

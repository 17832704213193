import { __rest } from "tslib";
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
export const EditIcon = (_a) => {
    var { width = 16, height = 16, fill = 'currentColor', stroke = 'currentColor' } = _a, props = __rest(_a, ["width", "height", "fill", "stroke"]);
    return (<SvgIcon width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path id="Union" fillRule="evenodd" clipRule="evenodd" d="M8.20565 3.35926C8.81578 2.74821 9.80536 2.74784 10.4159 3.35843L11.741 4.68346C12.3464 5.28884 12.3526 6.26888 11.7551 6.88199L6.84537 11.9194C6.45116 12.3238 5.91049 12.5519 5.34593 12.5519L3.88248 12.5518C3.14471 12.5518 2.55552 11.9368 2.58655 11.1991L2.64937 9.70541C2.67144 9.18074 2.88951 8.68347 3.26041 8.31199L8.20565 3.35926ZM9.70938 4.06607C9.48932 3.846 9.13266 3.84614 8.91276 4.06637L8.19119 4.78903L10.3219 6.91974L11.0395 6.18348C11.2549 5.96251 11.2526 5.60929 11.0344 5.3911L9.70938 4.06607ZM3.96752 9.0191L7.48462 5.49668L9.62387 7.63592L6.12978 11.2209C5.92371 11.4323 5.64109 11.5515 5.34597 11.5515L3.88252 11.5514C3.7133 11.5514 3.57816 11.4104 3.58528 11.2412L3.64811 9.74749C3.65965 9.47322 3.77364 9.21328 3.96752 9.0191ZM11.8719 13.4992C12.1479 13.4992 12.3717 13.2753 12.3717 12.9991C12.3717 12.7228 12.1479 12.4989 11.8719 12.4989H8.77092C8.49488 12.4989 8.27111 12.7228 8.27111 12.9991C8.27111 13.2753 8.49488 13.4992 8.77092 13.4992H11.8719Z" fill="#047E8B"/>
  </SvgIcon>);
};
export default EditIcon;

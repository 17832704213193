import React from 'react';
import { Navigate } from 'react-router-dom';
import { once } from 'ramda';
import { createDrawerConfig } from '@/navigation/drawer';
import authorized from '@/routing/ui/authorized/authorized';
import { InitialSelectionPage } from '@/search-for-retail/generic/page-context-selection';
import { ModelType, Role, Routing } from '@/constants';
import { useActiveArea } from '@/store/selectors';
import { isGlobalModelType } from '@/search-for-retail/models';
import Managers from './search-for-retail.managers';
import { hasEnrichAccessOnly } from '@/config/utils';
import { SearchForRetailUtilities } from '@/search-for-retail/utilities';
export var SearchForRetailRoutes;
(function (SearchForRetailRoutes) {
    const getCategoryRoutes = (routes) => routes.reduce((routesArray, route) => {
        var _a;
        const subroutes = ((_a = route.subroutes) === null || _a === void 0 ? void 0 : _a.length) && !route.disabled ? getCategoryRoutes(route.subroutes) : [];
        return [...routesArray, ...(route.disabled ? [] : [route]), ...subroutes];
    }, []);
    const getDynamicRoutes = (featureFlagsService) => {
        const s4rDrawerSections = createDrawerConfig(featureFlagsService).sections.filter((section) => !(section === null || section === void 0 ? void 0 : section.plugin));
        const routesArray = s4rDrawerSections.reduce((acc, section) => {
            let sectionRoutes = [];
            if (!section.disabled) {
                section.categories.forEach((category) => {
                    const categoryRoutes = getCategoryRoutes(category.routes);
                    sectionRoutes = [...sectionRoutes, ...categoryRoutes];
                });
            }
            return [...acc, ...sectionRoutes];
        }, []);
        const DynamicElement = (props) => {
            const { route } = props;
            const activeAreaName = useActiveArea();
            const Component = authorized(route.roles)(Managers[route.name]);
            if (isGlobalModelType(route.name) || activeAreaName) {
                return <Component />;
            }
            return <InitialSelectionPage titleMessageKey={route.label} selectionMessageKey="SELECT_COLLECTION_AND_AREA_PAGE_TEXT"/>;
        };
        const result = routesArray.map((route) => {
            const subPath = route.path === 'analytics'
                ? ':collection?/:area?/:dashboardTitle?'
                : route.path === 'areas'
                    ? '_create?'
                    : ':collection?/:area?/_create?';
            return {
                id: route.name,
                path: route.path,
                children: [
                    {
                        path: subPath,
                        element: <DynamicElement route={route}/>,
                    },
                ],
            };
        });
        return result;
    };
    const HomeElement = () => {
        if (hasEnrichAccessOnly) {
            return <Navigate to={SearchForRetailUtilities.navigateTo()}/>;
        }
        const Component = Managers[ModelType.HOME_DASHBOARDS];
        return <Component />;
    };
    const AuditLogElement = () => {
        const Component = authorized([Role.MERCHANDISING])(Managers[ModelType.AUDIT_LOG]);
        return <Component />;
    };
    const DefaultElement = () => <p>Default Element</p>;
    const getAllRoutes = (featureFlagsService) => [
        {
            index: true,
            element: <HomeElement />,
        },
        ...getDynamicRoutes(featureFlagsService),
        {
            path: Routing.MODEL_PATHS[ModelType.AUDIT_LOG],
            children: [
                {
                    index: true,
                    element: <AuditLogElement />,
                },
                {
                    path: ':collectionName',
                    element: <AuditLogElement />,
                },
            ],
        },
        {
            path: '*',
            element: <DefaultElement />,
        },
    ];
    SearchForRetailRoutes.getRoutes = once(getAllRoutes);
})(SearchForRetailRoutes || (SearchForRetailRoutes = {}));

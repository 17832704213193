import { __awaiter } from "tslib";
import { isNil } from 'ramda';
import { CommonFeatureFlagsService } from '../common-feature-flags-service';
export class InternalFeatureFlagsService extends CommonFeatureFlagsService {
    constructor({ sessionStoragePrefix = '' }) {
        super();
        this.prefix = sessionStoragePrefix;
    }
    getFeatureFlagValueFromCache(flag) {
        var _a;
        return (_a = this.featureFlagCache[flag]) !== null && _a !== void 0 ? _a : null;
    }
    getFeatureFlagValueFromStorage(flag) {
        const featureFlag = window.sessionStorage.getItem(`${this.prefix}${flag}`);
        if (!isNil(featureFlag)) {
            const featureFlagValue = featureFlag === 'true';
            this.featureFlagCache[flag] = featureFlagValue;
            return featureFlagValue;
        }
        return null;
    }
    updateCacheWithNewFeatureFlags(flags) {
        const cache = this.generateFeatureFlagCache(flags);
        this.featureFlagCache = Object.assign(Object.assign({}, this.featureFlagCache), cache);
    }
    generateFeatureFlagCache(featureFlags) {
        return featureFlags.reduce((flagCache, flag) => {
            const featureFlagValue = this.getFeatureFlagValueFromStorage(flag);
            if (!isNil(featureFlagValue) || this.persistedFlags.has(flag)) {
                flagCache[flag] = featureFlagValue;
            }
            return flagCache;
        }, {});
    }
    clearFeatureFlagsFromSessionStorage() {
        this.featureFlags.forEach((flag) => {
            if (!this.persistedFlags.has(flag)) {
                window.sessionStorage.removeItem(`${this.prefix}${flag}`);
            }
        });
    }
    saveFeatureFlags(features) {
        Object.keys(features).forEach((feature) => {
            try {
                window.sessionStorage.setItem(`${this.prefix}${feature}`, String(features[feature]));
            }
            catch (error) {
                // options parameter was added in typescript@4.9
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                throw new Error(`Failed to save flag ${feature} in session storage: `, { cause: error });
            }
        });
    }
    getFeatureFlagValue(flag) {
        const cachedFeatureFlag = this.getFeatureFlagValueFromCache(flag);
        if (!isNil(cachedFeatureFlag)) {
            return cachedFeatureFlag;
        }
        return this.getFeatureFlagValueFromStorage(flag);
    }
    clearFeatureFlagsStorage() {
        super.resetFeatureFlagsStorage();
        this.clearFeatureFlagsFromSessionStorage();
    }
    resetFeatureFlagsStorage() {
        super.resetFeatureFlagsStorage();
        this.updateCacheWithNewFeatureFlags(Array.from(this.featureFlags));
    }
    updateFeatureFlagsContext() {
        this.resetFeatureFlagsStorage();
        return Promise.resolve();
    }
    setFeatureFlags() {
        return __awaiter(this, void 0, void 0, function* () {
            return Promise.resolve();
        });
    }
    setFeaturesResponse(_featuresResponse) { }
}

import styled from 'styled-components';
import Drawer from '@mui/material/Drawer';
import { baseColors } from '@groupby/ui-components';
export const StyledNavigationDrawerSection = styled.section `
  display: flex;
  position: relative;
  min-height: ${({ theme }) => `calc(100vh - ${theme.appHeader.height})`};
  margin-top: ${({ theme }) => theme.appHeader.height};
  ${({ $enableNewBanner }) => $enableNewBanner && `z-index: ${({ theme }) => theme.zIndex.navigationDrawer};`}
`;
StyledNavigationDrawerSection.displayName = 'StyledNavigationDrawerSection';
export const StyledDrawer = styled(Drawer) `
  transition: ${({ theme }) => theme.s4rAppNavigation.transitionSettings};
  box-sizing: border-box;

  .MuiPaper-root {
    position: relative;
    min-height: ${({ theme }) => `calc(100vh - ${theme.appHeader.height})`};
    overflow: hidden;
    background: ${baseColors.background900};
  }

  &.expanded {
    width: ${({ theme }) => theme.s4rAppNavigation.expandedWidth};
  }

  &.collapsed {
    width: ${({ theme }) => theme.s4rAppNavigation.collapsedWidth};
  }
`;
StyledDrawer.displayName = 'StyledDrawer';
export const StyledNavigationDrawerList = styled.nav `
  color: ${({ theme }) => theme.palette.common.white};
  position: relative;
  height: 100%;
  background: ${baseColors.background900};

  .list-item {
    transition: all .3s ease-in;
    border-left: 3px solid transparent;
    min-height: 48px;

    &:hover,
    &:focus {
      color: ${baseColors.primaryGreen200};
    }
  }

  .MuiListItemText-root {
    flex-shrink: 0;
    margin-left: 1px;
  }

  .hidden {
    display: none;
  }
`;
StyledNavigationDrawerList.displayName = 'StyledNavigationDrawerList';
export const StyledBetaWrapper = styled.div `
  display: flex;
  gap: 4px;

  .MuiChip-root {
    margin-top: -4px;
  }
`;

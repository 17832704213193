import { endOfYesterday, format, formatISO, getDate, getYear, isLeapYear, subDays, subYears, } from 'date-fns';
import { isNil } from 'ramda';
import { timeFrameToCompareFunction, timeFrameToDateGranularity, timeFrameToDateGranularityFunction, timeFrameToDays, timeFrameToPeriodFunction, } from './analytics.constants';
import { TimeFrame } from './analytics.models';
export const POSITIVE_PERIOD_OFFSET = 1;
export const NEGATIVE_PERIOD_OFFSET = -1;
export const getPreviousPeriodDate = (date, timeFrame) => {
    const offset = timeFrame === TimeFrame.Annual ? 1 : timeFrameToDays[timeFrame];
    const subPeriod = timeFrameToPeriodFunction[timeFrame];
    return subPeriod(date, offset);
};
export const getBoundaryDates = (timeFrame) => {
    const primaryFinishDate = endOfYesterday();
    const secondaryFinishDate = getPreviousPeriodDate(primaryFinishDate, timeFrame);
    const primaryStartDate = getStartDate(timeFrame, primaryFinishDate);
    const secondaryStartDate = getStartDate(timeFrame, secondaryFinishDate);
    return [
        [primaryStartDate, primaryFinishDate],
        [secondaryStartDate, secondaryFinishDate],
    ].map(([startDate, finishDate]) => [formatDateToISOString(startDate), formatDateToISOString(finishDate)]);
};
// dates are in the wrong order when timeFrame = ALL
export const sortDates = (a, b) => new Date(a).valueOf() - new Date(b).valueOf();
const formatDateToISOString = (date) => formatISO(date, { representation: 'date' });
const getStartDate = (timeFrame, finishDate) => {
    let startDate;
    if (timeFrame === TimeFrame.Annual) {
        startDate = subYears(finishDate, 1); // can be leap year (365/366)
    }
    else {
        startDate = subDays(finishDate, timeFrameToDays[timeFrame] - 1);
    }
    return startDate;
};
export const generatePeriodFromTimeFrame = (timeFrame, options) => {
    const { hasPeriodToCompare = true } = options !== null && options !== void 0 ? options : {};
    let finishDate = endOfYesterday();
    if (hasPeriodToCompare && timeFrame === TimeFrame.Annual) {
        // a year may be taken from the comparison period if it has more days because it is a leap year
        const finishDatePreviousYear = subYears(finishDate, 1);
        if (isLeapYear(finishDatePreviousYear)) {
            finishDate = finishDatePreviousYear;
        }
    }
    const startDate = getStartDate(timeFrame, finishDate);
    const getGranularityDates = timeFrameToDateGranularityFunction[timeFrame];
    return getGranularityDates({ start: startDate, end: finishDate }).map(formatDateToISOString);
};
export const getOffset = (finishDate, timeFrame) => {
    if (timeFrame === TimeFrame.Annual) {
        return getYear(new Date(finishDate)) < getYear(endOfYesterday()) ? [POSITIVE_PERIOD_OFFSET, 0] : [0, NEGATIVE_PERIOD_OFFSET];
    }
    return [0, NEGATIVE_PERIOD_OFFSET];
};
export const getPeriodDate = (periodTick, offset, currentFilter) => {
    if (currentFilter === TimeFrame.Annual) {
        const [year, month, day] = periodTick.split('-');
        return `${Number(year) + offset}-${month}-${day}`;
    }
    if (offset === 0) {
        return periodTick;
    }
    return format(getPreviousPeriodDate(new Date(periodTick), currentFilter), 'yyyy-MM-dd');
};
export const getXYValues = (dateMap, periodTick, offset, currentFilter) => {
    var _a;
    const date = getPeriodDate(periodTick, offset, currentFilter);
    if (dateMap.size === 0) {
        return [date, null];
    }
    if (dateMap.has(date)) {
        return [date, dateMap.get(date)];
    }
    if (timeFrameToDateGranularity[currentFilter] === 'DAY') {
        return [date, null];
    }
    const compare = timeFrameToCompareFunction[currentFilter];
    let closestPeriodTick = new Date(date);
    const isSameDay = getDate(new Date(periodTick)) === getDate(closestPeriodTick);
    if (!isSameDay) { // for leap years
        closestPeriodTick = subDays(closestPeriodTick, 1);
    }
    const closestDate = Array.from(dateMap.keys()).find((dynamicDate) => compare(new Date(dynamicDate), closestPeriodTick));
    const closestPeriodTickUTC = closestPeriodTick.toISOString().split('T')[0]; // treat the date as UTC so that it doesn't do double timezone conversion
    return [closestPeriodTickUTC, (_a = dateMap.get(closestDate)) !== null && _a !== void 0 ? _a : null];
};
export const isDataEmpty = (data) => !data.some((item) => !isNil(item));
export const isVariantDataEmpty = (variantData) => !variantData.some(({ data }) => !isDataEmpty(data));

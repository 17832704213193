import React from 'react';
import SuccessIcon from '@mui/icons-material/CheckCircle';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import classNames from 'classnames';
import { withStyles } from '@/decorators';
import { LocalizedMessage } from '@/localization';
import State from '@/store/state';
var Notification = State.Notification;
export const styles = ({ fontSize, spacing, palette }) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        maxWidth: spacing(62.5),
        '&$error': {
            color: palette.notification.error,
        },
        '&$success': {
            color: palette.notification.success,
        },
    },
    error: {},
    success: {},
    text: {
        marginLeft: spacing(1.5),
        color: palette.notification.font,
    },
    message: {
        margin: 0,
        fontWeight: 500,
        fontSize: fontSize.formHeading,
        textAlign: 'left',
    },
    details: {
        margin: 0,
        fontSize: fontSize.formControl,
    },
});
const NotificationContent = withStyles(styles)(function NotificationContent({ onClick, message, type, details, classes, }) {
    const formattedMessage = typeof message === 'string' ? message : <LocalizedMessage messageKey={message.key} values={message.values}/>;
    return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div onClick={onClick} className={classNames(classes.root, classes[type])}>
      {Notification.Type.ERROR === type ? <WarningRoundedIcon /> : <SuccessIcon />}
      <div className={classes.text}>
        <h3 className={classes.message}>{formattedMessage}</h3>
        {details && <p className={classes.details}>{details}</p>}
      </div>
    </div>);
});
export default NotificationContent;

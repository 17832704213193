import styled from 'styled-components';
export const StyledTag = styled.div `
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  color: ${({ $color }) => $color};
  border-radius: 100px;
  padding: 0.25rem 0.5rem;
  flex-grow: 0;
  display: inline-block;

  ${({ $borderColor }) => `
    border: 1px solid ${$borderColor};
  `};

  span {
    text-transform: capitalize;
  }
`;

import React, { useState } from 'react';
import { baseColors, Icon } from '@groupby/ui-components';
import { InputAdornment } from '@mui/material';
import cn from 'classnames';
import { StyledArrowIcon, StyledContextSelector, StyledList, StyledListItem, StyledListItemText, StyledMenu, StyledMenuContentWrapper, StyledMenuItem, StyledSearchInput, StyledTitleBadge, } from './context-selector.styles';
import { useLocalization } from '@/localization';
export const ContextSelector = ({ label, disabled = false, options = [], searchPlaceholderMessageKey = 'SEARCH', primaryCopy, selectedOption, switchOption, className = '', defaultDropdownText = '', noOptionsText = '', defaultOption, }) => {
    const [menuAnchorEl, setMenuAnchorEl] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const { formatMessage: t } = useLocalization();
    const handleListItemClick = (e) => {
        setMenuAnchorEl(e.currentTarget);
    };
    const handleMenuItemClick = (option) => {
        setMenuAnchorEl(null);
        if (switchOption) {
            switchOption(option);
        }
    };
    const handleSearchChange = (e) => {
        setSearchTerm(e.currentTarget.value);
    };
    const handleCloseMenu = () => {
        setMenuAnchorEl(null);
    };
    const filteredOptions = searchTerm === ''
        ? options
        : options === null || options === void 0 ? void 0 : options.filter((option) => option === null || option === void 0 ? void 0 : option.toLowerCase().includes(searchTerm.toLowerCase()));
    return (<React.Fragment>
      <StyledContextSelector className={className} $disabled={disabled}>
        <StyledList component="nav" aria-label={label}>
          <StyledListItem onClick={handleListItemClick} disabled={disabled}>
            <StyledListItemText $disabled={disabled} primary={primaryCopy} secondary={selectedOption || defaultDropdownText}/>
            <StyledArrowIcon height={15} width={15} variant="downArrow" $menuOpen={!!menuAnchorEl} color={baseColors.primaryGreen500}/>
          </StyledListItem>
        </StyledList>
      </StyledContextSelector>

      <StyledMenu id="option-menu" anchorEl={menuAnchorEl} keepMounted open={Boolean(menuAnchorEl)} onClose={handleCloseMenu} elevation={0} anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
    }} transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
    }}>
        <StyledMenuContentWrapper>
          {noOptionsText && options.length > 0 && (<StyledSearchInput onChange={handleSearchChange} value={searchTerm} placeholder={t({ key: searchPlaceholderMessageKey })} startAdornment={<InputAdornment position="start">
                  <Icon variant="search" color={baseColors.primaryBlueMed400} className="search-icon"/>
                </InputAdornment>}/>)}

          {filteredOptions === null || filteredOptions === void 0 ? void 0 : filteredOptions.map((option) => (<StyledMenuItem key={option} selected={option === selectedOption} onClick={() => handleMenuItemClick(option)} className={cn({ 'menu-item--selected': option === selectedOption })}>
              {option}
              {option === defaultOption ? <StyledTitleBadge label={t({ key: 'DEFAULT' })} size="small"/> : null}
            </StyledMenuItem>))}
          {noOptionsText && !filteredOptions.length && (<StyledMenuItem disabled>
              {noOptionsText}
            </StyledMenuItem>)}

        </StyledMenuContentWrapper>
      </StyledMenu>
    </React.Fragment>);
};
export default ContextSelector;

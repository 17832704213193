import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@/decorators';
import LabeledInline from './inline';
export const styles = ({ spacing }) => ({
    content: {
        marginLeft: spacing(3),
        marginTop: spacing(1),
    },
});
const LabeledSection = withStyles(styles)(function LabeledSection({ label, tooltip, size, labelDecorator, labelClassName, wrapperClassName, classes, children, }) {
    return (<React.Fragment>
      <LabeledInline label={label} tooltip={tooltip} size={size} wrapperClassName={wrapperClassName} labelDecorator={labelDecorator}/>
      <div className={classNames(classes.content, labelClassName)}>{children}</div>
    </React.Fragment>);
});
export default LabeledSection;

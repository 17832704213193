import { ModelType, Routing } from '@/constants';
export const getModelPathFromPathname = (pathname) => {
    const pathParts = pathname.split('/');
    // '/merchandising/rules/collection/area` -> ['', 'merchandising', 'rules', 'collection', 'area']
    // we need to get 'rules' out of here
    if (pathParts.length > 2) {
        return pathParts[2];
    }
    if (pathname === Routing.MERCHANDISING_PATH) {
        return Routing.MODEL_PATHS[ModelType.HOME_DASHBOARDS];
    }
    return null;
};

import { eachDayOfInterval, eachMonthOfInterval, eachWeekOfInterval, isSameDay, isSameISOWeek, isSameMonth, subDays, subYears, } from 'date-fns';
import { TimeFrame } from './analytics.models';
export const timeFrameToDays = {
    [TimeFrame.SevenDays]: 7,
    [TimeFrame.ThirtyDays]: 30,
    [TimeFrame.NinetyDays]: 90,
};
export const timeFrameToFilter = {
    [TimeFrame.SevenDays]: '7 days ago for 7 days',
    [TimeFrame.ThirtyDays]: '30 days ago for 30 days',
    [TimeFrame.NinetyDays]: '90 days ago for 90 days',
    [TimeFrame.Annual]: '365 days ago for 365 days',
    [TimeFrame.All]: 'before tomorrow',
};
export const timeFrameToPreviousFilter = {
    [TimeFrame.SevenDays]: `${7 + 7} days ago for 7 days`,
    [TimeFrame.ThirtyDays]: `${30 + 30} days ago for 30 days`,
    [TimeFrame.NinetyDays]: `${90 + 90} days ago for 90 days`,
    [TimeFrame.Annual]: `${365 + 365} days ago for 365 days`,
};
export const timeFrameToDateGranularity = {
    [TimeFrame.SevenDays]: 'DAY',
    [TimeFrame.ThirtyDays]: 'DAY',
    [TimeFrame.NinetyDays]: 'ISOWEEK',
    [TimeFrame.Annual]: 'MONTH',
    [TimeFrame.All]: 'MONTH',
};
export const timeFrameToDateGranularityFunction = {
    [TimeFrame.SevenDays]: eachDayOfInterval,
    [TimeFrame.ThirtyDays]: eachDayOfInterval,
    [TimeFrame.NinetyDays]: (interval) => eachWeekOfInterval(interval, { weekStartsOn: 1 }),
    [TimeFrame.Annual]: eachMonthOfInterval,
};
export const timeFrameToCompareFunction = {
    [TimeFrame.SevenDays]: isSameDay,
    [TimeFrame.ThirtyDays]: isSameDay,
    [TimeFrame.NinetyDays]: isSameISOWeek,
    [TimeFrame.Annual]: isSameMonth,
    [TimeFrame.All]: isSameMonth,
};
// remove it when isAnalyticsComparisonModeEnabled feature flag is deleted
export const timeFrameToPeriodFunctionV1 = {
    [TimeFrame.SevenDays]: subYears,
    [TimeFrame.ThirtyDays]: subYears,
    [TimeFrame.NinetyDays]: subYears,
    [TimeFrame.Annual]: subYears,
};
export const timeFrameToPeriodFunction = {
    [TimeFrame.SevenDays]: subDays,
    [TimeFrame.ThirtyDays]: subDays,
    [TimeFrame.NinetyDays]: subDays,
    [TimeFrame.Annual]: subYears,
};

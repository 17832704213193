import React, { forwardRef } from 'react';
import { StyledMenuList } from './table-row-menu.styles';
const TableRowMenu = forwardRef(({ open, row, onClose, anchorEl, menuItems, currentIndexMenuOpen, }, ref) => (<StyledMenuList ref={ref} open={open} onClose={onClose} anchorEl={anchorEl} getContentAnchorEl={null} classes={{ paper: 'additional-filters' }} anchorOrigin={{
    vertical: 'bottom',
    horizontal: 'center',
}} transformOrigin={{
    vertical: 'top',
    horizontal: 'center',
}}>
      {menuItems.map(({ key, component, onClick }) => {
    const Component = component;
    return (<Component key={key} data={row} onClick={() => {
        onClick === null || onClick === void 0 ? void 0 : onClick(currentIndexMenuOpen);
        onClose();
    }}/>);
})}
    </StyledMenuList>));
TableRowMenu.displayName = 'TableRowMenu';
export default TableRowMenu;

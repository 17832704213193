import React, { Fragment } from 'react';
import { DrawerLink } from '../link-items/drawer-link.component';
import { StyledDrawerCategoryLabel, StyledDrawerContent, StyledDrawerSectionWrapper } from './drawer-section.styles';
import { StyledCopyRight } from '@/navigation/drawer/drawer.styles';
import { NavigationDrawerSectionHeader } from './drawer-section-header.component';
import { useActiveUserSelector } from '@/store/selectors';
import { useLocalization } from '@/localization';
import { filterRoutesByUserAccess } from '../../../utils';
import { getSectionRoutes } from '@/navigation/drawer/lib';
const renderRoutes = (routes, onClick, level) => (<Fragment>
    {routes.map((route) => {
    var _a;
    const subroutes = ((_a = route.subroutes) === null || _a === void 0 ? void 0 : _a.length) ? renderRoutes(route.subroutes, onClick, level + 1) : null;
    return (<Fragment key={route.label.toString()}>
          <DrawerLink route={route} level={level} label={route.label} badgeLabel={route.badgeLabel} disabled={route.disabled} onClick={onClick}/>
          {subroutes}
        </Fragment>);
})}
  </Fragment>);
export const DrawerSectionComponent = ({ section, onClick, onClose }) => {
    const { formatMessage: t } = useLocalization();
    const activeUser = useActiveUserSelector();
    const routes = getSectionRoutes(section);
    if (!routes.length)
        return null;
    return (<StyledDrawerSectionWrapper>
      <NavigationDrawerSectionHeader onClose={onClose} text={t({ key: section.label })}/>
      <StyledDrawerContent>
        {section.categories
        .filter(({ routes }) => !!filterRoutesByUserAccess(routes, activeUser).length)
        .map(({ label, routes }) => (<div className="category" key={label}>
              <StyledDrawerCategoryLabel className="label">{t({ key: label })}</StyledDrawerCategoryLabel>
              {renderRoutes(routes, onClick, 1)}
            </div>))}
      </StyledDrawerContent>
      <StyledCopyRight>
        ©2018-{new Date().getFullYear()} GroupBy Inc.
      </StyledCopyRight>
    </StyledDrawerSectionWrapper>);
};
export default DrawerSectionComponent;

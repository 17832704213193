import React, { useCallback, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { Backdrop, Card } from '@mui/material';
import { has } from 'ramda';
import { NavigationDrawer } from '@/navigation/drawer/navigation-drawer.component';
import { StyledDrawerContentSection } from './drawer.styles';
import { useNavigation } from '@/navigation/navigation.provider';
import { HeaderBar } from '@/search-for-retail/header-bar';
import { useActiveAreaNameSelector, useCollectionName } from '@/store/selectors';
import { DrawerSectionComponent } from '@/navigation/drawer/partials';
import { NavigationType } from '@/navigation/types';
import { getDrawerRouteForPath, getSectionRoutes, getSectionsRoutes } from '@/navigation/drawer/lib';
import { useGoToUrl, usePathname } from '@/routing/hooks';
export const DrawerContainer = ({ children, displayAnalytics = false, }) => {
    const recentLink = useRef(null);
    const goToUrl = useGoToUrl();
    const { sections, isDrawerOpen, setDrawerOpen, navigationTypeOpen, setNavigationType, visibleRouteSection, setVisibleRouteSection, } = useNavigation();
    const activeCollection = useCollectionName();
    const activeArea = useActiveAreaNameSelector();
    const currentRoute = usePathname();
    const sectionsMapRef = useRef();
    const routesRef = useRef();
    const prevRoutesLengthRef = useRef();
    const updateDrawerState = useCallback((section, overrides) => {
        const sectionRoutes = section ? getSectionRoutes(section) : null;
        function updateWithOverrides() {
            if (has('isDrawerOpen')(overrides)) {
                setDrawerOpen(true, overrides.isDrawerOpen);
            }
            if (has('navigationType')(overrides)) {
                setNavigationType(overrides.navigationType);
            }
            if (sectionRoutes === null || sectionRoutes === void 0 ? void 0 : sectionRoutes.length) {
                setVisibleRouteSection(section);
            }
            else {
                setVisibleRouteSection();
            }
        }
        function update() {
            if (sectionRoutes === null || sectionRoutes === void 0 ? void 0 : sectionRoutes.length) {
                setVisibleRouteSection(section);
                setNavigationType(NavigationType.SECONDARY);
            }
            else {
                setVisibleRouteSection();
                setNavigationType(NavigationType.PRIMARY);
            }
        }
        if (overrides) {
            updateWithOverrides();
        }
        else {
            update();
        }
    }, [setDrawerOpen, setNavigationType, setVisibleRouteSection]);
    const getCurrentDrawerRoute = useCallback((routes, currentPath) => getDrawerRouteForPath(routes, currentPath, activeCollection, activeArea), [activeArea, activeCollection]);
    useEffect(() => {
        var _a, _b, _c;
        if (!(sections === null || sections === void 0 ? void 0 : sections.length))
            return;
        sectionsMapRef.current = new Map(sections.map((s) => [s.path, s]));
        routesRef.current = getSectionsRoutes(sections);
        if (prevRoutesLengthRef.current !== ((_a = routesRef.current) === null || _a === void 0 ? void 0 : _a.length)) {
            const currentDrawerRoute = getCurrentDrawerRoute(routesRef.current, currentRoute);
            if (currentDrawerRoute) {
                const section = (_b = sectionsMapRef.current) === null || _b === void 0 ? void 0 : _b.get(currentDrawerRoute.sectionPath);
                updateDrawerState(section, Object.assign(Object.assign({}, currentDrawerRoute.drawerStateOverrides), { navigationType: NavigationType.PRIMARY }));
            }
        }
        prevRoutesLengthRef.current = (_c = routesRef.current) === null || _c === void 0 ? void 0 : _c.length;
    }, [currentRoute, getCurrentDrawerRoute, sections, updateDrawerState]);
    const handlePrimaryDrawerSectionLinkClick = (section, sectionLink) => {
        var _a, _b;
        const recentLinkIsCurrent = currentRoute === recentLink.current;
        const sectionIsCurrent = (visibleRouteSection === null || visibleRouteSection === void 0 ? void 0 : visibleRouteSection.path) === section.path;
        if (recentLinkIsCurrent && sectionIsCurrent) {
            if (navigationTypeOpen === NavigationType.PRIMARY) {
                setNavigationType(NavigationType.SECONDARY);
            }
            return;
        }
        // Update the drawer state regardless of whether the link is current or not
        if ((_a = routesRef.current) === null || _a === void 0 ? void 0 : _a.length) {
            const currentDrawerRoute = getCurrentDrawerRoute(routesRef.current, sectionLink);
            if (currentDrawerRoute) {
                const section = (_b = sectionsMapRef.current) === null || _b === void 0 ? void 0 : _b.get(currentDrawerRoute.sectionPath);
                updateDrawerState(section, currentDrawerRoute.drawerStateOverrides);
            }
        }
        // Redirect to the link if it's the "enrich" link
        if (!sectionIsCurrent && sectionLink === 'enrich') {
            setNavigationType(NavigationType.PRIMARY);
            goToUrl(sectionLink);
        }
    };
    const handleSecondaryLinkClick = (isActiveClicked, link) => {
        setNavigationType(NavigationType.PRIMARY);
        if (!isActiveClicked) {
            recentLink.current = link;
            goToUrl(link);
        }
    };
    const handleSecondaryCloseClick = () => {
        setNavigationType(NavigationType.PRIMARY);
    };
    return (<React.Fragment>
      <HeaderBar />
      <NavigationDrawer onItemClick={handlePrimaryDrawerSectionLinkClick}/>
      <StyledDrawerContentSection className={classNames({ displayAnalytics, isDrawerOpen })} $area={activeArea}>
        {navigationTypeOpen === NavigationType.SECONDARY && (<React.Fragment>
            <Card elevation={3} square={true} sx={{
        position: 'fixed', height: '100%', width: '240px', zIndex: (theme) => theme.zIndex.navigationDrawer,
    }}>
              <DrawerSectionComponent section={visibleRouteSection} onClick={handleSecondaryLinkClick} onClose={handleSecondaryCloseClick}/>
            </Card>
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.navigationDrawerBackdrop }} open={true} invisible={true} onClick={handleSecondaryCloseClick}/>
          </React.Fragment>)}
        {children}
      </StyledDrawerContentSection>
    </React.Fragment>);
};
export default DrawerContainer;

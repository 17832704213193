import styled from 'styled-components';
export const StyledContainer = styled.div `
  display: flex;
  cursor: default;
  flex-direction: column;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(1)};
  margin: auto;

  font-weight: 400;
  color: ${({ theme }) => theme.palette.grey[600]};

  .text-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: ${({ theme }) => theme.spacing(1)};

    &-title {
      font-size: 1rem;
      line-height: 1.625rem;
    }

    &-instructions {
      display: flex;
      flex-direction: column;
      align-self: flex-start;
      gap: ${({ theme }) => theme.spacing(2)};
      font-size: 0.875rem;
    }
  }

  .icon svg {
    width: unset;
    height: unset;
  }
`;
export const StyledPrimaryCircle = styled.div `
  border-radius: 50%;
  width: ${({ theme }) => theme.spacing(2.25)};
  height: ${({ theme }) => theme.spacing(2.25)};
  background: ${({ theme }) => theme.palette.primary.main};
  color: ${({ theme }) => theme.palette.common.white};
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: ${({ theme }) => theme.spacing(1)};
`;
export const StyledStepWrapper = styled.div `
  display: flex;
  align-items: center;
`;

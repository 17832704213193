import React, { useCallback, useMemo } from 'react';
import { useLocalization } from '@/localization';
import { ContextSelector } from '@/search-for-retail/generic/page-context-selection';
export const AreaSelector = ({ areas, disabled, selectedAreaName, defaultArea, onSelectArea, }) => {
    const { formatMessage: t } = useLocalization();
    const areaOptions = useMemo(() => areas.map(({ name }) => name), [areas]);
    const handleSwitchArea = useCallback((areaName) => {
        const area = areas.find((value) => value.name === areaName);
        onSelectArea(area);
    }, [areas, onSelectArea]);
    return (<ContextSelector label={t({ key: 'AREA_SELECTION' })} selectedOption={selectedAreaName !== null && selectedAreaName !== void 0 ? selectedAreaName : ''} switchOption={handleSwitchArea} options={areaOptions} defaultOption={defaultArea} primaryCopy={t({ key: 'AREA' })} defaultDropdownText={t({ key: 'SELECT_AREA_DROPDOWN' })} noOptionsText={t({ key: 'NO_AREA_AVAILABLE' })} disabled={disabled}/>);
};
export default AreaSelector;

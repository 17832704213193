import React, { useMemo } from 'react';
import { usePathname } from '@/routing/hooks/utils';
import { useLocalization } from '@/localization';
import { useActiveAreaNameSelector, useCollectionName } from '@/store/selectors';
import { NavigationDrawerSublinkItem } from './drawer-sublink-item.component';
import { TitleBadge } from '@/search-for-retail/generic/title-badge/title-badge.component';
import { ModelType } from '@/constants';
import { getPath } from '@/navigation/drawer/lib';
export const DrawerLink = ({ route, label, badgeLabel, onClick, disabled, level, }) => {
    const { formatMessage: t } = useLocalization();
    const activeRoute = usePathname();
    const activeCollection = useCollectionName();
    const activeArea = useActiveAreaNameSelector();
    const path = getPath(route, activeCollection, activeArea);
    const arePathsEqual = activeRoute === path;
    // Temporary fix to address breaking change that impacted enrich.
    // For all dynamic routes (enrich included), 'activeArea' was required in order to set active to 'true'.
    // S4R-3714 should address the underlying issue.
    const active = route.name === ModelType.ANALYTICS ? activeArea && arePathsEqual : arePathsEqual;
    const textLabel = label !== null && label !== void 0 ? label : route.label;
    const badge = useMemo(() => (badgeLabel
        ? <TitleBadge label={t({ key: badgeLabel })} size="small"/>
        : undefined), [badgeLabel, t]);
    const handleClick = () => {
        const isActivePageClicked = arePathsEqual && !window.location.search;
        onClick(isActivePageClicked, path);
    };
    return (<NavigationDrawerSublinkItem active={active} text={t({ key: textLabel })} onClick={handleClick} badge={badge} disabled={disabled} level={level}/>);
};
export default DrawerLink;

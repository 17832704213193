import { useCallback, useMemo, useState } from 'react';
import { useTableFilter } from './use-table-filter.hook';
import { useTableSort } from './use-table-sort.hook';
import { useTableColumnsOrder } from './use-table-columns-order.hook';
import { tableRowsPerPageOptions } from '@/search-for-retail/constants';
import { getItemIndicesFromPagination } from '@/search-for-retail/utilities';
export const useTableConfig = (data, columns, options) => {
    const [selected, setSelected] = useState([]);
    const [perPage, setPerPage] = useState(tableRowsPerPageOptions[0]);
    const [page, setPage] = useState(1);
    const { persisted, initialSortingKey } = options !== null && options !== void 0 ? options : {};
    const { filteredRows, filter, actions: { updateFilter, resetFilter }, } = useTableFilter(data, columns, { persisted });
    const { sort, sortedRows, actions: { updateSort, resetSort }, } = useTableSort(filteredRows, columns, { initialSortingKey, persisted });
    const onHiddenChanged = () => {
        resetFilter();
        resetSort();
    };
    const { orderedColumns, actions: { setOrder }, } = useTableColumnsOrder(columns, { persisted, onHiddenChanged });
    const paginatedRows = useMemo(() => {
        const [min, max] = getItemIndicesFromPagination(page, perPage);
        return sortedRows.slice(min, max);
    }, [sortedRows, page, perPage]);
    const resetPageNumber = useCallback(() => setPage(1), []);
    const handleUpdateFilter = useCallback((newFilter) => {
        updateFilter(newFilter);
        resetPageNumber();
    }, [updateFilter, resetPageNumber]);
    const handleResetFilter = useCallback((filters) => {
        resetFilter(filters);
        resetPageNumber();
    }, [resetFilter, resetPageNumber]);
    const handleSetRowsPerPage = useCallback((newRowsPerPage) => {
        setPerPage(newRowsPerPage);
        resetPageNumber();
    }, [resetPageNumber]);
    return {
        pagination: {
            page,
            perPage,
            total: filteredRows.length,
        },
        selectedItems: selected !== null && selected !== void 0 ? selected : [],
        rows: paginatedRows,
        filter,
        sort,
        orderedColumns,
        actions: {
            setPage,
            updateSort,
            resetSort,
            setOrder,
            updateFilter: handleUpdateFilter,
            resetFilter: handleResetFilter,
            setPerPage: handleSetRowsPerPage,
            selectItems: setSelected,
        },
    };
};
export default useTableConfig;

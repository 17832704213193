import React, { useEffect, useState } from 'react';
import { useActiveArea, useActiveCollection, useAreasSelector, useFindAreasForCollection, } from '@/store/selectors';
import { InitialSelectionPage } from '@/search-for-retail/generic/page-context-selection';
export const AreaPageWrapper = ({ children, titleMessageKey }) => {
    const activeCollection = useActiveCollection();
    const activeArea = useActiveArea();
    const areas = useAreasSelector();
    const findAreasForCollection = useFindAreasForCollection();
    const [areaIsValid, setAreaIsValid] = useState();
    useEffect(() => {
        if (!activeArea)
            return;
        const areasForCollection = findAreasForCollection(activeCollection, areas);
        const activeCollectionContainsActiveArea = areasForCollection.some((area) => area.name === activeArea);
        setAreaIsValid(activeCollectionContainsActiveArea);
    }, [activeArea, activeCollection, areas, findAreasForCollection]);
    return (<React.Fragment>
      {!activeArea || !areaIsValid
        ? <InitialSelectionPage titleMessageKey={titleMessageKey} selectionMessageKey='SELECT_COLLECTION_AND_AREA_PAGE_TEXT'/>
        : children}
    </React.Fragment>);
};
export default AreaPageWrapper;

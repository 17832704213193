import { baseColors } from '@groupby/ui-components';
import { createSpacing } from '@mui/system';
const spacing = createSpacing((number) => `${0.5 * number}rem`);
// copied from MUI defaults
export const breakpoints = {
    xs: 0,
    sm: 600,
    md: 900,
    lg: 1200,
    xl: 1536,
};
export const ccThemeOptions = {
    palette: {
        background: {
            default: baseColors.background050,
            paper: baseColors.white,
            pageContextControl: baseColors.primaryGreen100,
        },
        accentColours: {
            50: '#f1f9e2',
            900: '#5cb004',
        },
        warning: {
            main: '#FFD800',
        },
        mainFont: '#666666',
        disabledFont: '#9e9e9e',
        notification: {
            error: '#A90B0B',
            success: '#4262FF',
            font: '#081E42',
            border: {
                success: '#CBD4FF',
                error: '#FFDBDD',
            },
            background: {
                success: '#E8ECFF',
                error: '#FBEBEC',
            },
        },
    },
    appHeader: {
        height: spacing(7.5),
        heightMobile: spacing(0),
        heightXSMobile: spacing(0),
    },
    frame: {
        background: baseColors.background900,
        color: baseColors.white,
    },
    pageHeader: {
        backgroundColor: baseColors.white,
        height: spacing(7.5),
        position: 'sticky',
        zIndex: 1100,
    },
    zIndex: {
        stickyHeader: 1090,
        pageHeader: 1100,
        drag: 1600,
        header: 1700,
        temporaryDrawer: 1800,
        drawerModal: 1900,
        tableCntr: 15,
        navigationDrawerBackdrop: 16,
        navigationDrawer: 18,
    },
    s4rAppNavigation: {
        expandedWidth: spacing(28),
        collapsedWidth: spacing(7),
        transitionSettings: '.3s ease',
    },
    pageContent: {
        contentPadding: '0 1.5rem 1.5rem 2rem',
        createContentPadding: '1rem 1rem 1rem 2rem',
        formHeaderHeight: spacing(6),
    },
    analyticsCards: {
        shadow: '0px 0.3px 0.5px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.09)',
    },
    footer: {
        height: spacing(6),
        heightMobile: spacing(8),
    },
    label: {
        color: '#898989',
    },
    fontSize: {
        itemHeader: '1.2em',
        formHeading: '1.1em',
        itemSubHeader: '1em',
        formControl: '0.9em',
        caption: '0.8em',
        pageHeaderContent: '0.75em',
    },
    table: {
        border: '#E0E0E0',
        contrastRowBackground: '#F6F8FC',
    },
    spacing,
    components: {
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    cursor: 'default',
                    backgroundColor: baseColors.primaryBlueMed600,
                    maxWidth: '158px',
                    textAlign: 'center',
                },
            },
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    cursor: 'default',
                },
            },
        },
        MuiFormControlLabel: {
            styleOverrides: {
                root: {
                    '& .MuiTypography-root': {
                        cursor: 'pointer',
                    },
                    '& .MuiTypography-root.Mui-disabled': {
                        cursor: 'default',
                    },
                },
            },
        },
        MuiFormControl: {
            styleOverrides: {
                root: {
                    '& .MuiInputLabel-root': {
                        cursor: 'text',
                    },
                    '& .MuiFormHelperText-root': {
                        cursor: 'default',
                        '& a': {
                            cursor: 'pointer',
                        },
                    },
                },
            },
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    cursor: 'default',
                },
            },
        },
        MuiButtonBase: {
            defaultProps: {
                disableRipple: true,
            },
            styleOverrides: {
                root: {
                    '& .MuiTypography-root': {
                        cursor: 'pointer',
                    },
                },
            },
        },
    },
    appBreakpoints: breakpoints,
};
export default ccThemeOptions;

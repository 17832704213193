import { __rest } from "tslib";
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
export const TopSearchesIcon = (_a) => {
    var { width = 79, height = 78 } = _a, props = __rest(_a, ["width", "height"]);
    return (<SvgIcon xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 79 78" fill="none" {...props}>
    <path d="M29.7 77.3978C37.4319 77.3978 43.7 71.1298 43.7 63.3978C43.7 55.6658 37.4319 49.3978 29.7 49.3978C21.968 49.3978 15.7 55.6658 15.7 63.3978C15.7 71.1298 21.968 77.3978 29.7 77.3978Z" fill="#E8EBEC"/>
    <path d="M12 36.8978C18.6274 36.8978 24 31.5252 24 24.8978C24 18.2704 18.6274 12.8978 12 12.8978C5.37258 12.8978 0 18.2704 0 24.8978C0 31.5252 5.37258 36.8978 12 36.8978Z" fill="#E8EBEC"/>
    <path d="M58.4 40.7978C69.4457 40.7978 78.4 31.8435 78.4 20.7978C78.4 9.75213 69.4457 0.797821 58.4 0.797821C47.3543 0.797821 38.4 9.75213 38.4 20.7978C38.4 31.8435 47.3543 40.7978 58.4 40.7978Z" fill="#E8EBEC"/>
    <path d="M54.9 6.19782H10.9C9.20002 6.19782 7.90002 7.49782 7.90002 9.19782V67.1978C7.90002 68.8978 9.20002 70.1978 10.9 70.1978H54.9C56.6 70.1978 57.9 68.8978 57.9 67.1978V9.19782C57.9 7.49782 56.5 6.19782 54.9 6.19782Z" fill="#BDC3D8"/>
    <path d="M51.5999 6.09782H10.7999C9.09993 6.09782 7.79993 7.39782 7.79993 9.09782V66.0978H48.5999C50.2999 66.0978 51.5999 64.7978 51.5999 63.0978V6.09782Z" fill="white"/>
    <path d="M54.9 70.8978H10.9C8.79998 70.8978 7.09998 69.1978 7.09998 67.0978V9.09782C7.09998 6.99782 8.79998 5.29782 10.9 5.29782H54.9C57 5.29782 58.7 6.99782 58.7 9.09782V67.0978C58.6 69.1978 56.9 70.8978 54.9 70.8978ZM10.9 6.89782C9.69998 6.89782 8.59999 7.89782 8.59999 9.19782V67.1978C8.59999 68.3978 9.59998 69.4978 10.9 69.4978H54.9C56.1 69.4978 57.2 68.4978 57.2 67.1978V9.19782C57.2 7.99782 56.2 6.89782 54.9 6.89782H10.9Z" fill="#21365F"/>
    <path d="M45.9 6.19782H19.9V10.1978C19.9 11.8978 21.2 13.1978 22.9 13.1978H42.9C44.6 13.1978 45.9 11.8978 45.9 10.1978V6.19782Z" fill="#BDC3D8"/>
    <path d="M42.9 6.19782H19.9V8.19782C19.9 9.29782 20.8 10.1978 21.9 10.1978H41.9C42.5 10.1978 42.9 9.79782 42.9 9.19782V6.19782Z" fill="white"/>
    <path d="M42.9 13.8978H22.9C20.8 13.8978 19.1 12.1978 19.1 10.0978V6.09782C19.1 5.69782 19.4 5.29782 19.9 5.29782H45.9C46.3 5.29782 46.7 5.59782 46.7 6.09782V10.0978C46.6 12.1978 44.9 13.8978 42.9 13.8978ZM20.6 6.89782V10.1978C20.6 11.3978 21.6 12.4978 22.9 12.4978H42.9C44.1 12.4978 45.2 11.4978 45.2 10.1978V6.89782H20.6Z" fill="#21365F"/>
    <path d="M15.9 25.0978H17.9C19 25.0978 19.9 24.1978 19.9 23.0978V21.0978C19.9 19.9978 19 19.0978 17.9 19.0978H15.9C14.8 19.0978 13.9 19.9978 13.9 21.0978V23.0978C13.9 24.1978 14.8 25.0978 15.9 25.0978Z" fill="#BDC3D8"/>
    <path d="M13.9 23.0978H16.9C17.5 23.0978 17.9 22.5978 17.9 22.0978V19.0978H15.9C14.8 19.0978 13.9 19.9978 13.9 21.0978V23.0978Z" fill="white"/>
    <path d="M15.9 25.8978C14.4 25.8978 13.2 24.6978 13.1 23.1978V21.1978C13.1 20.4978 13.4 19.7978 13.9 19.2978C14.4 18.7978 15.1 18.4978 15.8 18.4978H17.8C19.3 18.4978 20.5 19.6978 20.6 21.1978V23.1978C20.6 23.8978 20.3 24.5978 19.8 25.0978C19.3 25.5978 18.6 25.8978 17.9 25.8978H15.9ZM17.9 19.8978H15.9C15.6 19.8978 15.3 19.9978 15 20.2978C14.8 20.4978 14.6 20.8978 14.6 21.1978V23.1978C14.6 23.8978 15.2 24.3978 15.9 24.3978H17.9C18.2 24.3978 18.5 24.2978 18.8 23.9978C19 23.7978 19.2 23.3978 19.2 23.0978V21.0978C19.1 20.3978 18.5 19.8978 17.9 19.8978Z" fill="#21365F"/>
    <path d="M54.9 61.8978C45.1 61.8978 37.1 53.8978 37.1 44.0978C37.1 34.2978 45.1 26.2978 54.9 26.2978C64.7 26.2978 72.7 34.2978 72.7 44.0978C72.7 53.8978 64.6 61.8978 54.9 61.8978ZM54.9 27.8978C45.9 27.8978 38.6 35.1978 38.6 44.1978C38.6 53.1978 45.9 60.4978 54.9 60.4978C63.9 60.4978 71.2 53.1978 71.2 44.1978C71.2 35.1978 63.8 27.8978 54.9 27.8978Z" fill="#21365F"/>
    <path d="M54.9 61.0978C64.2889 61.0978 71.9 53.4867 71.9 44.0978C71.9 34.709 64.2889 27.0978 54.9 27.0978C45.5112 27.0978 37.9 34.709 37.9 44.0978C37.9 53.4867 45.5112 61.0978 54.9 61.0978Z" fill="white"/>
    <path d="M64.7 30.2978C66.7 33.0978 67.9 36.4978 67.9 40.0978C67.9 49.4978 60.3 57.0978 50.9 57.0978C47.2 57.0978 43.8 55.8978 41.1 53.8978C44.2 58.1978 49.2 61.0978 54.9 61.0978C64.3 61.0978 71.9 53.4978 71.9 44.0978C71.9 38.3978 69 33.3978 64.7 30.2978Z" fill="#BDC3D8"/>
    <path d="M54.9 61.8978C45.1 61.8978 37.1 53.8978 37.1 44.0978C37.1 34.2978 45.1 26.2978 54.9 26.2978C64.7 26.2978 72.7 34.2978 72.7 44.0978C72.7 53.8978 64.6 61.8978 54.9 61.8978ZM54.9 27.8978C45.9 27.8978 38.6 35.1978 38.6 44.1978C38.6 53.1978 45.9 60.4978 54.9 60.4978C63.9 60.4978 71.2 53.1978 71.2 44.1978C71.2 35.1978 63.8 27.8978 54.9 27.8978Z" fill="#21365F"/>
    <path d="M54.9 32.6978L58.3 39.4978L65.9 40.5978L60.4 45.9978L61.7 53.5978L54.9 49.9978L48.1 53.5978L49.4 45.9978L43.9 40.5978L51.5 39.4978L54.9 32.6978Z" fill="white"/>
    <path d="M60.4 45.9978L65.9 40.5978L61.9 39.9978L58 43.7978C57.4 44.3978 57.1 45.2978 57.2 46.0978L58.1 51.4978L61.7 53.3978L60.4 45.9978Z" fill="#BDC3D8"/>
    <path d="M48.1 54.2978C47.9 54.2978 47.8 54.2978 47.7 54.1978C47.5 53.9978 47.4 53.6978 47.4 53.4978L48.6 46.2978L43.4 41.1978C43.2 40.9978 43.1 40.6978 43.2 40.3978C43.3 40.0978 43.5 39.8978 43.8 39.8978L51 38.8978L54.2 32.3978C54.5 31.8978 55.3 31.8978 55.5 32.3978L58.7 38.8978L65.9 39.8978C66.2 39.8978 66.4 40.0978 66.5 40.3978C66.6 40.6978 66.5 40.9978 66.3 41.1978L61.1 46.2978L62.3 53.4978C62.3 53.7978 62.2 54.0978 62 54.1978C61.8 54.3978 61.5 54.3978 61.2 54.2978L54.7 50.8978L48.4 54.1978C48.3 54.2978 48.2 54.2978 48.1 54.2978ZM54.9 49.2978C55 49.2978 55.1 49.2978 55.2 49.3978L60.7 52.2978L59.7 46.1978C59.7 45.9978 59.7 45.6978 59.9 45.4978L64.3 41.1978L58.2 40.2978C58 40.2978 57.7 40.0978 57.6 39.8978L54.9 34.3978L52.2 39.8978C52.1 40.0978 51.9 40.2978 51.6 40.2978L45.5 41.1978L49.9 45.4978C50.1 45.6978 50.2 45.8978 50.1 46.1978L49.1 52.2978L54.6 49.3978C54.6 49.2978 54.7 49.2978 54.9 49.2978Z" fill="#21365F"/>
    <path d="M47.9 20.5978H40.9C40.5 20.5978 40.1 20.2978 40.1 19.7978C40.1 19.3978 40.4 18.9978 40.9 18.9978H47.9C48.3 18.9978 48.7 19.2978 48.7 19.7978C48.6 20.2978 48.3 20.5978 47.9 20.5978Z" fill="#21365F"/>
    <path d="M36.9 20.5978H23.9C23.5 20.5978 23.1 20.2978 23.1 19.7978C23.1 19.3978 23.4 18.9978 23.9 18.9978H36.9C37.3 18.9978 37.7 19.2978 37.7 19.7978C37.6 20.2978 37.3 20.5978 36.9 20.5978Z" fill="#21365F"/>
    <path d="M32.9 25.0978H23.9C23.5 25.0978 23.1 24.7978 23.1 24.2978C23.1 23.8978 23.4 23.4978 23.9 23.4978H32.9C33.3 23.4978 33.7 23.7978 33.7 24.2978C33.6 24.7978 33.3 25.0978 32.9 25.0978Z" fill="#21365F"/>
    <path d="M15.9 37.0978H17.9C19 37.0978 19.9 36.1978 19.9 35.0978V33.0978C19.9 31.9978 19 31.0978 17.9 31.0978H15.9C14.8 31.0978 13.9 31.9978 13.9 33.0978V35.0978C13.9 36.1978 14.8 37.0978 15.9 37.0978Z" fill="#BDC3D8"/>
    <path d="M13.9 35.0978H16.9C17.5 35.0978 17.9 34.5978 17.9 34.0978V31.0978H15.9C14.8 31.0978 13.9 31.9978 13.9 33.0978V35.0978Z" fill="white"/>
    <path d="M15.9 37.8978C14.4 37.8978 13.2 36.6978 13.1 35.1978V33.1978C13.1 32.4978 13.4 31.7978 13.9 31.2978C14.4 30.7978 15.1 30.4978 15.8 30.4978H17.8C19.3 30.4978 20.5 31.6978 20.6 33.1978V35.1978C20.6 35.8978 20.3 36.5978 19.8 37.0978C19.3 37.5978 18.6 37.8978 17.9 37.8978H15.9ZM17.9 31.8978H15.9C15.6 31.8978 15.3 31.9978 15 32.2978C14.8 32.4978 14.6 32.8978 14.6 33.1978V35.1978C14.6 35.8978 15.2 36.3978 15.9 36.3978H17.9C18.2 36.3978 18.5 36.2978 18.8 35.9978C19 35.7978 19.2 35.3978 19.2 35.0978V33.0978C19.1 32.3978 18.5 31.8978 17.9 31.8978Z" fill="#21365F"/>
    <path d="M36.9 32.5978H23.9C23.5 32.5978 23.1 32.2978 23.1 31.7978C23.1 31.2978 23.4 30.9978 23.9 30.9978H36.9C37.3 30.9978 37.7 31.2978 37.7 31.7978C37.7 32.2978 37.3 32.5978 36.9 32.5978Z" fill="#21365F"/>
    <path d="M30.9 37.0978H23.9C23.5 37.0978 23.1 36.7978 23.1 36.2978C23.1 35.7978 23.4 35.4978 23.9 35.4978H30.9C31.3 35.4978 31.7 35.7978 31.7 36.2978C31.7 36.7978 31.3 37.0978 30.9 37.0978Z" fill="#21365F"/>
    <path d="M15.9 49.0978H17.9C19 49.0978 19.9 48.1978 19.9 47.0978V45.0978C19.9 43.9978 19 43.0978 17.9 43.0978H15.9C14.8 43.0978 13.9 43.9978 13.9 45.0978V47.0978C13.9 48.1978 14.8 49.0978 15.9 49.0978Z" fill="#BDC3D8"/>
    <path d="M13.9 47.0978H16.9C17.5 47.0978 17.9 46.5978 17.9 46.0978V43.0978H15.9C14.8 43.0978 13.9 43.9978 13.9 45.0978V47.0978Z" fill="white"/>
    <path d="M15.9 49.8978C14.4 49.8978 13.2 48.6978 13.1 47.1978V45.1978C13.1 44.4978 13.4 43.7978 13.9 43.2978C14.4 42.7978 15.1 42.4978 15.8 42.4978H17.8C19.3 42.4978 20.5 43.6978 20.6 45.1978V47.1978C20.6 47.8978 20.3 48.5978 19.8 49.0978C19.3 49.5978 18.6 49.8978 17.9 49.8978H15.9ZM17.9 43.8978H15.9C15.6 43.8978 15.3 43.9978 15 44.2978C14.8 44.4978 14.6 44.8978 14.6 45.1978V47.1978C14.6 47.8978 15.2 48.3978 15.9 48.3978H17.9C18.2 48.3978 18.5 48.2978 18.8 47.9978C19 47.7978 19.2 47.3978 19.2 47.0978V45.0978C19.1 44.3978 18.5 43.8978 17.9 43.8978Z" fill="#21365F"/>
    <path d="M33.9 44.5978H23.9C23.5 44.5978 23.1 44.2978 23.1 43.7978C23.1 43.2978 23.4 42.9978 23.9 42.9978H33.9C34.3 42.9978 34.7 43.2978 34.7 43.7978C34.7 44.2978 34.3 44.5978 33.9 44.5978Z" fill="#21365F"/>
    <path d="M30.9 49.0978H23.9C23.5 49.0978 23.1 48.7978 23.1 48.2978C23.1 47.7978 23.4 47.4978 23.9 47.4978H30.9C31.3 47.4978 31.7 47.7978 31.7 48.2978C31.7 48.7978 31.3 49.0978 30.9 49.0978Z" fill="#21365F"/>
    <path d="M15.9 61.0978H17.9C19 61.0978 19.9 60.1978 19.9 59.0978V57.0978C19.9 55.9978 19 55.0978 17.9 55.0978H15.9C14.8 55.0978 13.9 55.9978 13.9 57.0978V59.0978C13.9 60.1978 14.8 61.0978 15.9 61.0978Z" fill="#BDC3D8"/>
    <path d="M13.9 59.0978H16.9C17.5 59.0978 17.9 58.5978 17.9 58.0978V55.0978H15.9C14.8 55.0978 13.9 55.9978 13.9 57.0978V59.0978Z" fill="white"/>
    <path d="M15.9 61.8978C14.4 61.8978 13.2 60.6978 13.1 59.1978V57.1978C13.1 56.4978 13.4 55.7978 13.9 55.2978C14.4 54.7978 15.1 54.4978 15.8 54.4978H17.8C19.3 54.4978 20.5 55.6978 20.6 57.1978V59.1978C20.6 59.8978 20.3 60.5978 19.8 61.0978C19.3 61.5978 18.6 61.8978 17.9 61.8978H15.9ZM17.9 55.8978H15.9C15.6 55.8978 15.3 55.9978 15 56.2978C14.8 56.4978 14.6 56.8978 14.6 57.1978V59.1978C14.6 59.8978 15.2 60.3978 15.9 60.3978H17.9C18.2 60.3978 18.5 60.2978 18.8 59.9978C19 59.7978 19.2 59.3978 19.2 59.0978V57.0978C19.1 56.3978 18.5 55.8978 17.9 55.8978Z" fill="#21365F"/>
    <path d="M36.9 56.5978H32.8C32.4 56.5978 32 56.2978 32 55.7978C32 55.3978 32.3 54.9978 32.8 54.9978H36.9C37.3 54.9978 37.7 55.2978 37.7 55.7978C37.6 56.2978 37.3 56.5978 36.9 56.5978Z" fill="#21365F"/>
    <path d="M28.7 56.5978H23.8C23.4 56.5978 23 56.2978 23 55.7978C23 55.3978 23.3 54.9978 23.8 54.9978H28.7C29.1 54.9978 29.5 55.2978 29.5 55.7978C29.5 56.2978 29.1 56.5978 28.7 56.5978Z" fill="#21365F"/>
    <path d="M33.9 61.0978H23.9C23.5 61.0978 23.1 60.7978 23.1 60.2978C23.1 59.8978 23.4 59.4978 23.9 59.4978H33.9C34.3 59.4978 34.7 59.7978 34.7 60.2978C34.6 60.7978 34.3 61.0978 33.9 61.0978Z" fill="#21365F"/>
    <path d="M67.2 12.8978H65.8C65.4 12.8978 65 12.5978 65 12.0978C65 11.5978 65.3 11.2978 65.8 11.2978H67.2C67.6 11.2978 68 11.5978 68 12.0978C68 12.5978 67.7 12.8978 67.2 12.8978Z" fill="#21365F"/>
    <path d="M71.9 12.8978H70.5C70.1 12.8978 69.7 12.5978 69.7 12.0978C69.7 11.5978 70 11.2978 70.5 11.2978H71.9C72.3 11.2978 72.7 11.5978 72.7 12.0978C72.7 12.5978 72.3 12.8978 71.9 12.8978Z" fill="#21365F"/>
    <path d="M68.9 15.8978C68.5 15.8978 68.1 15.5978 68.1 15.0978V13.6978C68.1 13.2978 68.4 12.8978 68.9 12.8978C69.4 12.8978 69.7 13.1978 69.7 13.6978V15.0978C69.6 15.4978 69.3 15.8978 68.9 15.8978Z" fill="#21365F"/>
    <path d="M68.9 11.1978C68.5 11.1978 68.1 10.8978 68.1 10.3978V8.99783C68.1 8.59783 68.4 8.19782 68.9 8.19782C69.4 8.19782 69.7 8.49783 69.7 8.99783V10.3978C69.6 10.8978 69.3 11.1978 68.9 11.1978Z" fill="#21365F"/>
    <path d="M63.2 23.8978H61.8C61.4 23.8978 61 23.5978 61 23.0978C61 22.5978 61.3 22.2978 61.8 22.2978H63.2C63.6 22.2978 64 22.5978 64 23.0978C64 23.5978 63.7 23.8978 63.2 23.8978Z" fill="#21365F"/>
    <path d="M67.9 23.8978H66.5C66.1 23.8978 65.7 23.5978 65.7 23.0978C65.7 22.5978 66 22.2978 66.5 22.2978H67.9C68.3 22.2978 68.7 22.5978 68.7 23.0978C68.7 23.5978 68.3 23.8978 67.9 23.8978Z" fill="#21365F"/>
    <path d="M64.9 26.8978C64.5 26.8978 64.1 26.5978 64.1 26.0978V24.6978C64.1 24.2978 64.4 23.8978 64.9 23.8978C65.4 23.8978 65.7 24.1978 65.7 24.6978V26.0978C65.6 26.4978 65.3 26.8978 64.9 26.8978Z" fill="#21365F"/>
    <path d="M64.9 22.1978C64.5 22.1978 64.1 21.8978 64.1 21.3978V19.9978C64.1 19.5978 64.4 19.1978 64.9 19.1978C65.4 19.1978 65.7 19.4978 65.7 19.9978V21.3978C65.6 21.8978 65.3 22.1978 64.9 22.1978Z" fill="#21365F"/>
    <path d="M66.2 64.8978H64.8C64.4 64.8978 64 64.5978 64 64.0978C64 63.5978 64.3 63.2978 64.8 63.2978H66.2C66.6 63.2978 67 63.5978 67 64.0978C67 64.5978 66.7 64.8978 66.2 64.8978Z" fill="#21365F"/>
    <path d="M70.9 64.8978H69.5C69.1 64.8978 68.7 64.5978 68.7 64.0978C68.7 63.5978 69 63.2978 69.5 63.2978H70.9C71.3 63.2978 71.7 63.5978 71.7 64.0978C71.7 64.5978 71.3 64.8978 70.9 64.8978Z" fill="#21365F"/>
    <path d="M67.9 67.8978C67.5 67.8978 67.1 67.5978 67.1 67.0978V65.6978C67.1 65.2978 67.4 64.8978 67.9 64.8978C68.4 64.8978 68.7 65.1978 68.7 65.6978V67.0978C68.6 67.4978 68.3 67.8978 67.9 67.8978Z" fill="#21365F"/>
    <path d="M67.9 63.2978C67.5 63.2978 67.1 62.9978 67.1 62.4978V61.0978C67.1 60.6978 67.4 60.2978 67.9 60.2978C68.4 60.2978 68.7 60.5978 68.7 61.0978V62.4978C68.6 62.8978 68.3 63.2978 67.9 63.2978Z" fill="#21365F"/>
  </SvgIcon>);
};
export default TopSearchesIcon;

import React from 'react';
import classNames from 'classnames';
import Disabling from '@/components/disabling';
import { withStyles } from '@/decorators';
export const styles = ({ spacing, palette }) => ({
    root: {
        fontWeight: 500,
        marginRight: spacing(1),
        '&$normal': {},
        '&$small': {
            fontSize: '0.7em',
            fontWeight: 'normal',
            color: '#898989',
        },
        '&$disabled': {
            color: palette.disabledFont,
        },
    },
    secondaryLabel: {
        marginLeft: spacing(1),
        fontWeight: 400,
    },
    disabled: {},
    normal: {},
    small: {},
});
const Label = withStyles(styles)(function Label({ for: forId, secondary, size = Size.NORMAL, classes, children, }) {
    return (<Disabling.Consumer>
      {(disabled) => (<label className={classNames(classes.root, classes[size], { [classes.disabled]: disabled })} htmlFor={forId}>
          {children}
          {secondary && (<span className={classes.secondaryLabel}>
              <em>({secondary})</em>
            </span>)}
        </label>)}
    </Disabling.Consumer>);
});
export default Label;
export var Size;
(function (Size) {
    Size.NORMAL = 'normal';
    Size.SMALL = 'small';
})(Size || (Size = {}));

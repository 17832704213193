import * as ReleaseNotesActions from '@/store/actions/types/release-notes.types';
const DEFAULT = { filterTerm: '', filteredItems: [], items: { byId: {}, allIds: [] } };
export default (state = DEFAULT, action) => {
    switch (action.type) {
        case ReleaseNotesActions.CACHE_RELEASE_NOTES: {
            const newById = action.payload.items.reduce((prev, cur) => (Object.assign(Object.assign({}, prev), { [cur.fileName]: cur })), {});
            return Object.assign(Object.assign({}, state), { items: {
                    byId: Object.assign(Object.assign({}, state.items.byId), newById),
                    allIds: [...new Set([...state.items.allIds, ...Object.keys(newById)])],
                } });
        }
        default:
            return state;
    }
};

import React from 'react';
import { baseColors, Checkbox, Icon } from '@groupby/ui-components';
import { Collapse, Box as MuiBox, TableCell } from '@mui/material';
import cn from 'classnames';
import { Button } from '@/components/button';
import { StyledExpandableTableRowSection, StyledTableRow } from './table-row.styles';
export const TableRow = React.forwardRef(({ id, checked = false, onSelectionChange, handleMoreOptionsOpen, children, expandedContent, expandedColumnOffset, columnCount, isExpanded = false, showMoreOptions = true, disabled, hasSelect = true, guestUser = false, readonly, }, ref) => {
    const isExpandable = !!expandedContent;
    const renderEmptyColumns = (offset = 1) => {
        if (!isExpandable) {
            return [];
        }
        const emptyColumns = [];
        for (let i = 0; i < offset; i++) {
            emptyColumns.push(<TableCell />);
        }
        return emptyColumns;
    };
    const emptyColumns = renderEmptyColumns(expandedColumnOffset);
    return (<React.Fragment>
      <StyledTableRow id={`row_${id}`} ref={ref} $showMoreOptions={showMoreOptions} className={cn('table-row', {
        disabled,
        expandable: isExpandable,
        expanded: isExpanded,
        readonly,
        checked,
    })}>
        {!guestUser && hasSelect && (<TableCell align="left">
            <Checkbox disabled={disabled} checked={checked} onClick={onSelectionChange} className={cn({ 'checkbox--disabled': disabled })}/>
          </TableCell>)}
        {children}
        {(!guestUser && showMoreOptions) && (<TableCell padding="none" align="right">
            <MuiBox display="flex">
              <Button disabled={disabled} onClick={handleMoreOptionsOpen} disableRipple disableTouchRipple className="more-button">
                <Icon color={baseColors.primaryBlueMed600} variant="more" showHover wrapperClasses="more-button-icon"/>
              </Button>
            </MuiBox>
          </TableCell>)}
      </StyledTableRow>

      {isExpandable && (<StyledExpandableTableRowSection className={cn({ 'expandable-section--expanded': isExpanded })}>
          {emptyColumns}
          <TableCell colSpan={columnCount - emptyColumns.length}>
            <Collapse unmountOnExit in={isExpanded} timeout="auto">
              {expandedContent}
            </Collapse>
          </TableCell>
        </StyledExpandableTableRowSection>)}
    </React.Fragment>);
});
export default TableRow;

import { __rest } from "tslib";
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
export const LoginPageGraphic = (_a) => {
    var props = __rest(_a, []);
    return (<SvgIcon viewBox="0 0 501 550" enableBackground="new 0 0 501 550" xmlSpace="preserve" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path fill="#B4DAE2" d="M77.5,388c42.8,0,77.5-34.7,77.5-77.5S120.3,233,77.5,233S0,267.7,0,310.5S34.7,388,77.5,388z"/>
    <path fill="#B4DAE2" d="M327,544c35.9,0,65-29.1,65-65s-29.1-65-65-65s-65,29.1-65,65S291.1,544,327,544z"/>
    <path fill="#B4DAE2" d="M390.6,350c60.8,0,110-49.2,110-110s-49.2-110-110-110s-110,49.2-110,110S329.8,350,390.6,350z"/>
    <g>
      <g>
        <path fill="#FFFFFF" d="M69.5,166.9h360c2.2,0,4,1.8,4,4v298c0,2.2-1.8,4-4,4h-360c-2.2,0-4-1.8-4-4v-298
      C65.5,168.7,67.3,166.9,69.5,166.9z"/>
        <path fillRule="evenodd" clipRule="evenodd" fill="#5A6981" d="M402.5,188.9c-1.1,0-2,0.9-2,2s0.9,2,2,2s2-0.9,2-2S403.6,188.9,402.5,188.9z M409.5,188.9c-1.1,0-2,0.9-2,2
      s0.9,2,2,2s2-0.9,2-2S410.6,188.9,409.5,188.9z M414.5,190.9c0-1.1,0.9-2,2-2s2,0.9,2,2s-0.9,2-2,2S414.5,192,414.5,190.9z"/>
        <defs>
          <filter id="Adobe_OpacityMaskFilter" filterUnits="userSpaceOnUse" x="64.8" y="314.9" width="371.1" height="183.1">
            <feColorMatrix type="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0"/>
          </filter>
        </defs>

        <mask maskUnits="userSpaceOnUse" x="64.8" y="314.9" width="371.1" height="183.1" id="mask0_52_10632_00000095301389394329834040000007018047213087526305_">
          <path fill="#FFFFFF" filter="url(#Adobe_OpacityMaskFilter)" d="M97.5,298.9h304c4.4,0,8,3.6,8,8v134c0,4.4-3.6,8-8,8h-304c-4.4,0-8-3.6-8-8v-134
        C89.5,302.5,93.1,298.9,97.5,298.9z"/>
        </mask>
        <g mask="url(#mask0_52_10632_00000095301389394329834040000007018047213087526305_)">

          <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="6.5" y1="442.0971" x2="6.5" y2="260" gradientTransform="matrix(1 0 0 -1 244 758)">
            <stop offset="0" stopColor="#BAE26E"/>
            <stop offset="1" stopColor="#FFFFFF" stopOpacity="1.000000e-02"/>
          </linearGradient>
          <path fillRule="evenodd" clipRule="evenodd" fill="url(#SVGID_1_)" d="M65.5,414.9c0,0,44.5-43.5,86.9-35.9c42.4,7.6,106.7,18.2,136.7-13.6c30-31.8,96.7-65,146.4-41.6V498h-370
        V414.9z"/>
          <g>
            <path fill="none" stroke="#699B0B" strokeWidth={2} d="M65.5,414.9c0,0,44.5-43.5,86.9-35.9c42.4,7.6,106.7,18.2,136.7-13.6c30-31.8,96.7-65,146.4-41.6"/>
          </g>
        </g>
        <path fill="#5A6981" d="M96.3,276.9h1.3v6.7c0,0.7-0.2,1.4-0.5,1.9c-0.3,0.5-0.8,0.9-1.3,1.1c-0.5,0.2-1.1,0.4-1.8,0.4
      c-0.7,0-1.3-0.1-1.8-0.4c-0.5-0.3-1-0.6-1.3-1.1c-0.3-0.5-0.5-1.1-0.5-1.9v-6.7h1.3v6.7c0,0.5,0.1,0.9,0.3,1.3
      c0.2,0.3,0.5,0.6,0.8,0.8c0.3,0.2,0.7,0.2,1.2,0.2c0.5,0,0.9-0.1,1.2-0.2c0.3-0.2,0.6-0.4,0.8-0.8c0.2-0.3,0.3-0.8,0.3-1.3V276.9z
        M100.8,280.9v8.8h-1.3v-10.2h1.2L100.8,280.9z M105.8,283.1v0.1c0,0.5-0.1,1-0.2,1.5c-0.1,0.5-0.3,0.9-0.6,1.2
      c-0.2,0.3-0.5,0.6-0.9,0.8c-0.4,0.2-0.8,0.3-1.2,0.3c-0.5,0-0.9-0.1-1.2-0.2s-0.7-0.4-0.9-0.7c-0.3-0.3-0.5-0.7-0.6-1.1
      c-0.1-0.4-0.2-0.9-0.3-1.4v-0.8c0.1-0.5,0.2-1,0.3-1.5c0.2-0.4,0.3-0.8,0.6-1.1c0.3-0.3,0.6-0.5,0.9-0.7c0.4-0.2,0.8-0.2,1.2-0.2
      c0.5,0,0.9,0.1,1.2,0.3c0.4,0.2,0.7,0.4,0.9,0.8c0.2,0.3,0.4,0.7,0.6,1.2C105.7,282,105.8,282.6,105.8,283.1z M104.5,283.3v-0.1
      c0-0.4,0-0.7-0.1-1c-0.1-0.3-0.2-0.6-0.4-0.9c-0.2-0.3-0.4-0.4-0.6-0.6c-0.3-0.1-0.5-0.2-0.9-0.2c-0.3,0-0.6,0.1-0.8,0.2
      c-0.2,0.1-0.4,0.3-0.6,0.4c-0.2,0.2-0.3,0.4-0.4,0.6c-0.1,0.2-0.2,0.5-0.2,0.7v1.8c0.1,0.3,0.2,0.6,0.4,0.9
      c0.2,0.3,0.4,0.5,0.7,0.7c0.3,0.2,0.6,0.3,1,0.3c0.3,0,0.6-0.1,0.9-0.2c0.3-0.1,0.5-0.3,0.6-0.6c0.2-0.3,0.3-0.5,0.4-0.9
      C104.5,284,104.5,283.6,104.5,283.3z M112.3,282.1l-1-0.3l0.5-5h5.1v1.2h-4l-0.3,2.7c0.2-0.1,0.4-0.2,0.7-0.3
      c0.3-0.1,0.6-0.1,1-0.1c0.5,0,0.9,0.1,1.2,0.2c0.4,0.2,0.7,0.4,0.9,0.7c0.3,0.3,0.5,0.6,0.6,1.1s0.2,0.9,0.2,1.4
      c0,0.5-0.1,0.9-0.2,1.3c-0.1,0.4-0.3,0.8-0.6,1.1c-0.3,0.3-0.6,0.5-1,0.7c-0.4,0.2-0.9,0.2-1.4,0.2c-0.4,0-0.8-0.1-1.1-0.2
      c-0.4-0.1-0.7-0.3-1-0.5c-0.3-0.2-0.5-0.5-0.7-0.9c-0.2-0.3-0.3-0.8-0.3-1.2h1.2c0.1,0.4,0.2,0.7,0.3,0.9s0.4,0.4,0.6,0.6
      c0.3,0.1,0.6,0.2,0.9,0.2c0.3,0,0.6-0.1,0.8-0.2c0.2-0.1,0.4-0.3,0.6-0.5c0.2-0.2,0.3-0.4,0.4-0.7c0.1-0.3,0.1-0.6,0.1-0.9
      c0-0.3,0-0.6-0.1-0.9c-0.1-0.3-0.2-0.5-0.4-0.7c-0.2-0.2-0.4-0.4-0.6-0.5c-0.2-0.1-0.5-0.2-0.8-0.2c-0.4,0-0.7,0.1-1,0.2
      C112.8,281.8,112.6,282,112.3,282.1z M118.5,279.4v-0.5c0-0.4,0.1-0.7,0.2-1c0.2-0.3,0.4-0.6,0.7-0.7c0.3-0.2,0.7-0.3,1.1-0.3
      c0.4,0,0.8,0.1,1.1,0.3c0.3,0.2,0.5,0.4,0.7,0.7c0.2,0.3,0.2,0.7,0.2,1v0.5c0,0.4-0.1,0.7-0.2,1c-0.2,0.3-0.4,0.6-0.7,0.7
      c-0.3,0.2-0.7,0.3-1.1,0.3c-0.4,0-0.8-0.1-1.1-0.3c-0.3-0.2-0.5-0.4-0.7-0.7C118.6,280.1,118.5,279.7,118.5,279.4z M119.5,278.8
      v0.5c0,0.2,0,0.4,0.1,0.6c0.1,0.2,0.2,0.3,0.4,0.5c0.2,0.1,0.4,0.2,0.6,0.2c0.2,0,0.4-0.1,0.6-0.2c0.2-0.1,0.3-0.3,0.4-0.5
      c0.1-0.2,0.1-0.4,0.1-0.6v-0.5c0-0.2,0-0.4-0.1-0.6c-0.1-0.2-0.2-0.3-0.4-0.5c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.4,0.1-0.6,0.2
      c-0.2,0.1-0.3,0.3-0.4,0.5C119.5,278.4,119.5,278.6,119.5,278.8z M123.4,285v-0.5c0-0.4,0.1-0.7,0.2-1c0.2-0.3,0.4-0.6,0.7-0.7
      c0.3-0.2,0.7-0.3,1.1-0.3c0.4,0,0.8,0.1,1.1,0.3c0.3,0.2,0.5,0.4,0.7,0.7c0.2,0.3,0.2,0.7,0.2,1v0.5c0,0.4-0.1,0.7-0.2,1
      c-0.2,0.3-0.4,0.6-0.7,0.7c-0.3,0.2-0.7,0.3-1.1,0.3c-0.4,0-0.8-0.1-1.1-0.3c-0.3-0.2-0.5-0.4-0.7-0.7
      C123.4,285.7,123.4,285.3,123.4,285z M124.3,284.4v0.5c0,0.2,0,0.4,0.1,0.6c0.1,0.2,0.2,0.3,0.4,0.5c0.2,0.1,0.4,0.2,0.6,0.2
      c0.2,0,0.4-0.1,0.6-0.2c0.2-0.1,0.3-0.3,0.4-0.5c0.1-0.2,0.1-0.4,0.1-0.6v-0.5c0-0.2,0-0.4-0.1-0.6c-0.1-0.2-0.2-0.3-0.4-0.5
      c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.1-0.3,0.3-0.4,0.5C124.3,284,124.3,284.2,124.3,284.4z M125.7,278.3
      l-4.9,7.8l-0.7-0.5l4.9-7.8L125.7,278.3z M135.6,279.5v1h-4v-1H135.6z M132.9,277.7h1.3v7.4c0,0.3,0,0.4,0.1,0.6
      c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0,0.3,0.1,0.4,0.1c0.1,0,0.2,0,0.3,0c0.1,0,0.2,0,0.3-0.1l0,1c-0.1,0-0.2,0.1-0.4,0.1
      c-0.2,0-0.4,0-0.6,0c-0.3,0-0.6-0.1-0.9-0.2c-0.3-0.1-0.5-0.3-0.6-0.6c-0.2-0.3-0.2-0.7-0.2-1.2V277.7z M138.3,276.4v10.5h-1.3
      v-10.5H138.3z M138,282.9l-0.5,0c0-0.5,0.1-1,0.2-1.4c0.1-0.4,0.4-0.8,0.6-1.1c0.3-0.3,0.6-0.6,0.9-0.7c0.4-0.2,0.8-0.3,1.2-0.3
      c0.4,0,0.7,0,1,0.1c0.3,0.1,0.5,0.3,0.7,0.5c0.2,0.2,0.4,0.5,0.5,0.8c0.1,0.3,0.2,0.8,0.2,1.2v4.8h-1.3V282c0-0.4-0.1-0.7-0.2-0.9
      c-0.1-0.2-0.3-0.4-0.5-0.5c-0.2-0.1-0.5-0.2-0.8-0.2c-0.3,0-0.6,0.1-0.9,0.2c-0.3,0.1-0.5,0.3-0.7,0.5c-0.2,0.2-0.3,0.5-0.4,0.8
      C138.1,282.3,138,282.6,138,282.9z M146.1,279.5v7.4h-1.3v-7.4H146.1z M144.8,277.5c0-0.2,0.1-0.4,0.2-0.5
      c0.1-0.1,0.3-0.2,0.6-0.2c0.2,0,0.4,0.1,0.6,0.2c0.1,0.1,0.2,0.3,0.2,0.5c0,0.2-0.1,0.4-0.2,0.5c-0.1,0.1-0.3,0.2-0.6,0.2
      c-0.2,0-0.4-0.1-0.6-0.2C144.8,277.9,144.8,277.7,144.8,277.5z M152.5,284.9c0-0.2,0-0.4-0.1-0.5c-0.1-0.2-0.2-0.3-0.5-0.4
      c-0.2-0.1-0.6-0.2-1.1-0.3c-0.4-0.1-0.8-0.2-1.1-0.3c-0.3-0.1-0.6-0.3-0.8-0.4c-0.2-0.2-0.4-0.4-0.5-0.6s-0.2-0.5-0.2-0.8
      c0-0.3,0.1-0.6,0.2-0.8c0.1-0.3,0.3-0.5,0.6-0.7c0.2-0.2,0.5-0.4,0.9-0.5c0.3-0.1,0.7-0.2,1.1-0.2c0.6,0,1.1,0.1,1.5,0.3
      c0.4,0.2,0.7,0.5,1,0.8c0.2,0.3,0.3,0.7,0.3,1.2h-1.3c0-0.2-0.1-0.4-0.2-0.6c-0.1-0.2-0.3-0.4-0.5-0.5c-0.2-0.1-0.5-0.2-0.8-0.2
      c-0.4,0-0.6,0.1-0.9,0.2c-0.2,0.1-0.4,0.2-0.5,0.4c-0.1,0.2-0.1,0.3-0.1,0.5c0,0.1,0,0.3,0.1,0.4c0.1,0.1,0.1,0.2,0.3,0.3
      c0.1,0.1,0.3,0.2,0.5,0.2c0.2,0.1,0.5,0.2,0.9,0.2c0.6,0.1,1.1,0.3,1.5,0.5c0.4,0.2,0.7,0.4,0.9,0.7s0.3,0.6,0.3,1
      c0,0.3-0.1,0.6-0.2,0.9c-0.1,0.3-0.3,0.5-0.6,0.7c-0.3,0.2-0.6,0.3-0.9,0.5c-0.3,0.1-0.7,0.2-1.2,0.2c-0.7,0-1.2-0.1-1.7-0.3
      c-0.5-0.2-0.8-0.5-1-0.9c-0.2-0.4-0.3-0.8-0.3-1.2h1.3c0,0.3,0.1,0.6,0.3,0.8c0.2,0.2,0.4,0.3,0.7,0.4c0.3,0.1,0.5,0.1,0.8,0.1
      c0.3,0,0.6,0,0.9-0.1c0.2-0.1,0.4-0.2,0.5-0.4C152.4,285.3,152.5,285.1,152.5,284.9z M160.8,285.6l1.9-6.1h0.8l-0.2,1.2l-1.9,6.2
      h-0.8L160.8,285.6z M159.5,279.5l1.6,6.2l0.1,1.2h-0.9l-2.1-7.4H159.5z M165.3,285.6l1.5-6.1h1.3l-2.1,7.4h-0.8L165.3,285.6z
        M163.7,279.5l1.9,6l0.2,1.4h-0.8l-2-6.2l-0.2-1.2H163.7z M172.5,287c-0.5,0-1-0.1-1.4-0.3c-0.4-0.2-0.8-0.4-1.1-0.7
      c-0.3-0.3-0.5-0.7-0.7-1.1c-0.2-0.4-0.2-0.9-0.2-1.4v-0.3c0-0.6,0.1-1.1,0.3-1.6c0.2-0.5,0.4-0.9,0.7-1.2c0.3-0.3,0.7-0.6,1-0.7
      c0.4-0.2,0.8-0.3,1.2-0.3c0.5,0,1,0.1,1.4,0.3c0.4,0.2,0.7,0.4,0.9,0.8c0.2,0.3,0.4,0.7,0.5,1.1c0.1,0.4,0.2,0.9,0.2,1.4v0.6h-5.5
      v-1h4.3v-0.1c0-0.3-0.1-0.6-0.2-1c-0.1-0.3-0.3-0.6-0.5-0.8c-0.3-0.2-0.6-0.3-1-0.3c-0.3,0-0.6,0.1-0.8,0.2
      c-0.2,0.1-0.4,0.3-0.6,0.5c-0.2,0.2-0.3,0.5-0.4,0.9c-0.1,0.3-0.1,0.7-0.1,1.2v0.3c0,0.3,0,0.7,0.1,1c0.1,0.3,0.2,0.6,0.4,0.8
      c0.2,0.2,0.4,0.4,0.7,0.5c0.3,0.1,0.6,0.2,0.9,0.2c0.4,0,0.8-0.1,1.1-0.3c0.3-0.2,0.6-0.4,0.8-0.7l0.8,0.6
      c-0.2,0.2-0.4,0.5-0.6,0.7c-0.2,0.2-0.5,0.4-0.9,0.5C173.4,287,173,287,172.5,287z M179.9,287c-0.5,0-1-0.1-1.4-0.3
      c-0.4-0.2-0.8-0.4-1.1-0.7c-0.3-0.3-0.5-0.7-0.7-1.1c-0.2-0.4-0.2-0.9-0.2-1.4v-0.3c0-0.6,0.1-1.1,0.3-1.6
      c0.2-0.5,0.4-0.9,0.7-1.2c0.3-0.3,0.7-0.6,1-0.7c0.4-0.2,0.8-0.3,1.2-0.3c0.5,0,1,0.1,1.4,0.3c0.4,0.2,0.7,0.4,1,0.8
      c0.2,0.3,0.4,0.7,0.5,1.1c0.1,0.4,0.2,0.9,0.2,1.4v0.6h-5.5v-1h4.3v-0.1c0-0.3-0.1-0.6-0.2-1c-0.1-0.3-0.3-0.6-0.5-0.8
      c-0.3-0.2-0.6-0.3-1-0.3c-0.3,0-0.6,0.1-0.8,0.2c-0.2,0.1-0.4,0.3-0.6,0.5c-0.2,0.2-0.3,0.5-0.4,0.9c-0.1,0.3-0.1,0.7-0.1,1.2v0.3
      c0,0.3,0,0.7,0.1,1c0.1,0.3,0.2,0.6,0.4,0.8c0.2,0.2,0.4,0.4,0.7,0.5c0.3,0.1,0.6,0.2,0.9,0.2c0.4,0,0.8-0.1,1.1-0.3
      c0.3-0.2,0.6-0.4,0.8-0.7l0.8,0.6c-0.2,0.2-0.4,0.5-0.6,0.7c-0.2,0.2-0.5,0.4-0.9,0.5C180.8,287,180.4,287,179.9,287z
        M185.5,276.4v10.5h-1.3v-10.5H185.5z M190,279.5l-3.2,3.5l-1.8,1.9l-0.1-1.3l1.3-1.5l2.3-2.4H190z M188.9,286.9l-2.6-3.5l0.7-1.1
      l3.5,4.7H188.9z"/>
        <path fill="#081E42" d="M96.7,243.9l-4.5-1.1l1.6-14.4h16v4.6H98.5l-0.7,6.3c0.4-0.2,1-0.5,1.7-0.7c0.8-0.3,1.6-0.4,2.6-0.4
      c1.4,0,2.6,0.2,3.7,0.6c1.1,0.4,2,1.1,2.7,1.9c0.8,0.8,1.3,1.8,1.7,3c0.4,1.2,0.6,2.5,0.6,4c0,1.3-0.2,2.5-0.6,3.6
      c-0.4,1.1-1,2.1-1.8,3c-0.8,0.9-1.8,1.6-3,2.1s-2.6,0.8-4.3,0.8c-1.2,0-2.4-0.2-3.6-0.5c-1.1-0.4-2.2-0.9-3.1-1.6
      c-0.9-0.7-1.6-1.6-2.2-2.6c-0.5-1-0.8-2.2-0.8-3.5h5.6c0.1,0.8,0.3,1.5,0.6,2.1c0.4,0.6,0.8,1,1.4,1.3c0.6,0.3,1.2,0.5,2,0.5
      c0.7,0,1.3-0.1,1.8-0.4c0.5-0.3,0.9-0.7,1.2-1.1c0.3-0.5,0.5-1.1,0.7-1.7c0.2-0.7,0.2-1.4,0.2-2.1c0-0.8-0.1-1.5-0.3-2.1
      c-0.2-0.6-0.5-1.2-0.8-1.6c-0.4-0.5-0.9-0.8-1.4-1.1c-0.6-0.2-1.2-0.4-2-0.4c-1,0-1.8,0.2-2.4,0.5
      C97.6,243.1,97.1,243.5,96.7,243.9z M120,252.1l0,3.8c0,1.4-0.4,2.9-1.1,4.4c-0.7,1.5-1.6,2.7-2.7,3.6l-2.9-1.5
      c0.3-0.6,0.6-1.2,1-1.8c0.3-0.6,0.6-1.3,0.8-2c0.2-0.7,0.3-1.5,0.3-2.4v-4H120z M143.7,252.5v4.4h-19.5v-3.8l9.2-9.9
      c0.9-1,1.7-1.9,2.2-2.7c0.5-0.8,0.9-1.5,1.2-2.1c0.2-0.6,0.4-1.2,0.4-1.8c0-0.9-0.1-1.6-0.4-2.2c-0.3-0.6-0.7-1.1-1.3-1.4
      c-0.5-0.3-1.2-0.5-2-0.5c-0.9,0-1.6,0.2-2.2,0.6c-0.6,0.4-1.1,1-1.4,1.7c-0.3,0.7-0.5,1.6-0.5,2.5h-5.6c0-1.7,0.4-3.2,1.2-4.6
      c0.8-1.4,1.9-2.5,3.4-3.4c1.5-0.8,3.2-1.3,5.2-1.3c2,0,3.7,0.3,5,1c1.4,0.6,2.4,1.6,3.1,2.8c0.7,1.2,1.1,2.6,1.1,4.3
      c0,0.9-0.1,1.9-0.4,2.8c-0.3,0.9-0.7,1.8-1.3,2.7c-0.5,0.9-1.2,1.8-2,2.7c-0.8,0.9-1.6,1.8-2.6,2.8l-4.9,5.5H143.7z M153,240.2h3
      c1,0,1.8-0.2,2.4-0.5c0.6-0.3,1.1-0.8,1.4-1.4c0.3-0.6,0.5-1.3,0.5-2.1c0-0.7-0.1-1.4-0.4-1.9c-0.3-0.6-0.7-1-1.3-1.3
      c-0.6-0.3-1.3-0.5-2.2-0.5c-0.7,0-1.3,0.1-1.9,0.4c-0.6,0.3-1.1,0.7-1.4,1.2c-0.4,0.5-0.5,1.1-0.5,1.8h-5.6c0-1.6,0.4-2.9,1.2-4.1
      c0.8-1.2,2-2.1,3.4-2.7c1.4-0.7,3-1,4.7-1c1.9,0,3.6,0.3,5.1,0.9c1.4,0.6,2.6,1.5,3.4,2.7c0.8,1.2,1.2,2.7,1.2,4.5
      c0,0.9-0.2,1.8-0.6,2.6c-0.4,0.8-1,1.6-1.8,2.3c-0.8,0.7-1.7,1.2-2.8,1.6c-1.1,0.4-2.4,0.6-3.7,0.6H153V240.2z M153,244.5v-2.9
      h3.9c1.5,0,2.9,0.2,4.1,0.5c1.2,0.4,2.2,0.9,2.9,1.5c0.8,0.7,1.4,1.4,1.8,2.3c0.4,0.9,0.6,1.9,0.6,2.9c0,1.3-0.3,2.5-0.8,3.6
      c-0.5,1-1.2,1.9-2.1,2.6c-0.9,0.7-2,1.3-3.2,1.6c-1.2,0.4-2.6,0.5-4,0.5c-1.2,0-2.4-0.2-3.5-0.5c-1.1-0.3-2.2-0.8-3.1-1.5
      c-0.9-0.7-1.7-1.5-2.2-2.5c-0.5-1-0.8-2.2-0.8-3.7h5.6c0,0.7,0.2,1.4,0.5,2c0.4,0.6,0.9,1,1.5,1.3c0.7,0.3,1.4,0.5,2.2,0.5
      c0.9,0,1.7-0.2,2.3-0.5c0.7-0.3,1.1-0.8,1.5-1.4c0.4-0.6,0.5-1.3,0.5-2.1c0-1-0.2-1.9-0.6-2.5c-0.4-0.6-0.9-1.1-1.6-1.4
      c-0.7-0.3-1.5-0.4-2.5-0.4H153z M190.1,246.3v4.4h-20.5l-0.3-3.4l11.9-18.8h4.5l-4.8,8.1l-5.9,9.7H190.1z M186.9,228.4v28.4h-5.6
      v-28.4H186.9z"/>
        <path fillRule="evenodd" clipRule="evenodd" fill="#00838F" d="M203,237.4c0,0.7,0.6,1.3,1.3,1.3h8.1L202.2,249c-0.4,0.4-0.4,1,0,1.4l0.5,0.5c0.4,0.4,1,0.4,1.4,0l10.3-10.3
      l0.3,7.8c0,0.7,0.7,1.3,1.4,1.3c0.7,0,1.3-0.7,1.3-1.4l-0.5-10.8c0-0.7-0.6-1.3-1.3-1.3h-11.3C203.6,236.1,203,236.7,203,237.4z"/>
        <path fill="#081E42" d="M96.4,210.6h1.7c-0.1,0.7-0.2,1.2-0.5,1.7c-0.3,0.5-0.7,0.9-1.3,1.2c-0.6,0.3-1.2,0.4-2,0.4
      c-0.6,0-1.2-0.1-1.7-0.3c-0.5-0.2-0.9-0.5-1.3-0.9c-0.4-0.4-0.6-0.9-0.8-1.5c-0.2-0.6-0.3-1.2-0.3-1.9v-0.8c0-0.7,0.1-1.4,0.3-1.9
      c0.2-0.6,0.5-1.1,0.8-1.5c0.4-0.4,0.8-0.7,1.3-1c0.5-0.2,1.1-0.3,1.7-0.3c0.8,0,1.5,0.1,2,0.4c0.5,0.3,1,0.7,1.3,1.2
      c0.3,0.5,0.5,1.1,0.6,1.8h-1.7c0-0.4-0.1-0.8-0.3-1.1c-0.2-0.3-0.4-0.5-0.7-0.7c-0.3-0.2-0.7-0.2-1.2-0.2c-0.4,0-0.7,0.1-1,0.2
      c-0.3,0.1-0.5,0.4-0.7,0.6c-0.2,0.3-0.3,0.6-0.4,1c-0.1,0.4-0.1,0.9-0.1,1.4v0.8c0,0.5,0,1,0.1,1.4c0.1,0.4,0.2,0.8,0.4,1
      c0.2,0.3,0.4,0.5,0.7,0.7c0.3,0.2,0.6,0.2,1,0.2c0.5,0,0.9-0.1,1.2-0.2c0.3-0.2,0.5-0.4,0.7-0.7C96.2,211.4,96.3,211.1,96.4,210.6
      z M99.4,210.3v-0.2c0-0.5,0.1-1,0.2-1.5c0.2-0.5,0.4-0.9,0.7-1.2c0.3-0.3,0.7-0.6,1.1-0.8c0.4-0.2,0.9-0.3,1.4-0.3
      c0.5,0,1,0.1,1.4,0.3c0.4,0.2,0.8,0.5,1.1,0.8c0.3,0.3,0.5,0.7,0.7,1.2c0.2,0.5,0.2,1,0.2,1.5v0.2c0,0.5-0.1,1-0.2,1.5
      c-0.2,0.5-0.4,0.9-0.7,1.2c-0.3,0.3-0.7,0.6-1.1,0.8c-0.4,0.2-0.9,0.3-1.4,0.3c-0.5,0-1-0.1-1.5-0.3c-0.4-0.2-0.8-0.5-1.1-0.8
      c-0.3-0.3-0.5-0.7-0.7-1.2C99.5,211.3,99.4,210.8,99.4,210.3z M101,210.1v0.2c0,0.3,0,0.6,0.1,0.9c0.1,0.3,0.2,0.6,0.3,0.8
      c0.1,0.2,0.3,0.4,0.6,0.5c0.2,0.1,0.5,0.2,0.8,0.2c0.3,0,0.6-0.1,0.8-0.2c0.2-0.1,0.4-0.3,0.6-0.5c0.1-0.2,0.3-0.5,0.3-0.8
      c0.1-0.3,0.1-0.6,0.1-0.9v-0.2c0-0.3,0-0.6-0.1-0.9c-0.1-0.3-0.2-0.6-0.3-0.8c-0.1-0.2-0.3-0.4-0.6-0.5c-0.2-0.1-0.5-0.2-0.8-0.2
      c-0.3,0-0.6,0.1-0.8,0.2c-0.2,0.1-0.4,0.3-0.6,0.5c-0.1,0.2-0.3,0.5-0.3,0.8C101.1,209.5,101,209.8,101,210.1z M109.5,208.1v5.8
      h-1.6v-7.4h1.6L109.5,208.1z M109.2,209.9l-0.5,0c0-0.5,0.1-1,0.2-1.4c0.1-0.4,0.3-0.8,0.6-1.1c0.3-0.3,0.6-0.6,0.9-0.7
      c0.4-0.2,0.8-0.3,1.2-0.3c0.4,0,0.7,0.1,1,0.2c0.3,0.1,0.5,0.3,0.7,0.5c0.2,0.2,0.4,0.5,0.5,0.9c0.1,0.3,0.2,0.8,0.2,1.3v4.8h-1.7
      v-4.8c0-0.4-0.1-0.6-0.2-0.8c-0.1-0.2-0.2-0.4-0.4-0.4c-0.2-0.1-0.4-0.1-0.7-0.1c-0.3,0-0.5,0.1-0.8,0.2s-0.4,0.3-0.6,0.5
      c-0.2,0.2-0.3,0.4-0.3,0.7C109.2,209.3,109.2,209.6,109.2,209.9z M118.2,212.6l1.8-6.1h1.7l-2.6,7.4h-1.1L118.2,212.6z
        M116.8,206.5l1.8,6.1l0.1,1.3h-1.1l-2.6-7.4H116.8z M126.1,214c-0.5,0-1-0.1-1.5-0.3c-0.4-0.2-0.8-0.4-1.1-0.8
      c-0.3-0.3-0.5-0.7-0.7-1.1c-0.2-0.4-0.2-0.9-0.2-1.4v-0.3c0-0.6,0.1-1.1,0.3-1.6c0.2-0.5,0.4-0.9,0.7-1.2c0.3-0.3,0.7-0.6,1.1-0.8
      c0.4-0.2,0.9-0.3,1.3-0.3c0.5,0,1,0.1,1.4,0.3s0.7,0.4,1,0.8c0.3,0.3,0.5,0.7,0.6,1.1c0.1,0.4,0.2,0.9,0.2,1.5v0.7h-5.7v-1.2h4.1
      v-0.1c0-0.3-0.1-0.6-0.2-0.8c-0.1-0.3-0.3-0.5-0.5-0.6c-0.2-0.2-0.5-0.2-0.9-0.2c-0.3,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.3-0.5,0.5
      c-0.1,0.2-0.3,0.5-0.3,0.8c-0.1,0.3-0.1,0.6-0.1,1v0.3c0,0.3,0,0.6,0.1,0.9c0.1,0.3,0.2,0.5,0.4,0.7c0.2,0.2,0.4,0.4,0.6,0.5
      c0.2,0.1,0.5,0.2,0.8,0.2c0.4,0,0.7-0.1,1.1-0.2c0.3-0.2,0.6-0.4,0.8-0.7l0.9,0.8c-0.2,0.2-0.4,0.5-0.6,0.7
      c-0.3,0.2-0.6,0.4-0.9,0.5C127,214,126.6,214,126.1,214z M132.2,207.9v6h-1.6v-7.4h1.6L132.2,207.9z M134.5,206.4l0,1.5
      c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2,0-0.3,0c-0.3,0-0.5,0-0.7,0.1c-0.2,0.1-0.4,0.2-0.5,0.3c-0.1,0.1-0.3,0.3-0.3,0.5
      c-0.1,0.2-0.1,0.4-0.1,0.7l-0.4,0c0-0.5,0-0.9,0.1-1.3c0.1-0.4,0.2-0.7,0.4-1c0.2-0.3,0.4-0.5,0.7-0.7c0.3-0.2,0.6-0.3,1-0.3
      c0.1,0,0.2,0,0.3,0C134.4,206.4,134.4,206.4,134.5,206.4z M139.9,211.9c0-0.2,0-0.3-0.1-0.4c-0.1-0.1-0.2-0.3-0.5-0.4
      c-0.2-0.1-0.6-0.2-1-0.3c-0.4-0.1-0.8-0.2-1.1-0.3c-0.3-0.1-0.6-0.3-0.8-0.4c-0.2-0.2-0.4-0.4-0.5-0.6s-0.2-0.5-0.2-0.8
      c0-0.3,0.1-0.6,0.2-0.9c0.1-0.3,0.3-0.5,0.6-0.7c0.2-0.2,0.5-0.4,0.9-0.5c0.4-0.1,0.8-0.2,1.2-0.2c0.6,0,1.2,0.1,1.6,0.3
      c0.4,0.2,0.8,0.5,1,0.8c0.2,0.4,0.4,0.7,0.4,1.2h-1.6c0-0.2-0.1-0.4-0.2-0.5c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.7-0.2
      c-0.3,0-0.5,0-0.7,0.1c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.1,0.3-0.1,0.5c0,0.1,0,0.2,0.1,0.3c0.1,0.1,0.1,0.2,0.2,0.3
      c0.1,0.1,0.3,0.2,0.5,0.2c0.2,0.1,0.5,0.1,0.8,0.2c0.6,0.1,1.1,0.3,1.5,0.5c0.4,0.2,0.7,0.4,0.9,0.7c0.2,0.3,0.3,0.7,0.3,1.1
      c0,0.3-0.1,0.6-0.2,0.9c-0.1,0.3-0.3,0.5-0.6,0.7c-0.3,0.2-0.6,0.3-1,0.5c-0.4,0.1-0.8,0.2-1.2,0.2c-0.7,0-1.2-0.1-1.7-0.4
      c-0.5-0.2-0.8-0.5-1.1-0.9c-0.2-0.4-0.4-0.8-0.4-1.2h1.6c0,0.3,0.1,0.5,0.3,0.7c0.2,0.2,0.3,0.3,0.6,0.4c0.2,0.1,0.5,0.1,0.7,0.1
      c0.3,0,0.5,0,0.7-0.1c0.2-0.1,0.4-0.2,0.5-0.3C139.8,212.2,139.9,212.1,139.9,211.9z M144.9,206.5v7.4h-1.7v-7.4H144.9z
        M143.1,204.5c0-0.3,0.1-0.5,0.2-0.6c0.2-0.2,0.4-0.3,0.7-0.3c0.3,0,0.5,0.1,0.7,0.3c0.2,0.2,0.3,0.4,0.3,0.6
      c0,0.2-0.1,0.5-0.3,0.6c-0.2,0.2-0.4,0.2-0.7,0.2c-0.3,0-0.5-0.1-0.7-0.2C143.2,205,143.1,204.8,143.1,204.5z M146.6,210.3v-0.2
      c0-0.5,0.1-1,0.2-1.5c0.2-0.5,0.4-0.9,0.7-1.2c0.3-0.3,0.7-0.6,1.1-0.8c0.4-0.2,0.9-0.3,1.4-0.3c0.5,0,1,0.1,1.4,0.3
      c0.4,0.2,0.8,0.5,1.1,0.8c0.3,0.3,0.5,0.7,0.7,1.2c0.2,0.5,0.2,1,0.2,1.5v0.2c0,0.5-0.1,1-0.2,1.5c-0.2,0.5-0.4,0.9-0.7,1.2
      c-0.3,0.3-0.7,0.6-1.1,0.8c-0.4,0.2-0.9,0.3-1.4,0.3c-0.5,0-1-0.1-1.5-0.3c-0.4-0.2-0.8-0.5-1.1-0.8c-0.3-0.3-0.5-0.7-0.7-1.2
      C146.7,211.3,146.6,210.8,146.6,210.3z M148.2,210.1v0.2c0,0.3,0,0.6,0.1,0.9c0.1,0.3,0.2,0.6,0.3,0.8c0.1,0.2,0.3,0.4,0.6,0.5
      c0.2,0.1,0.5,0.2,0.8,0.2c0.3,0,0.6-0.1,0.8-0.2c0.2-0.1,0.4-0.3,0.6-0.5c0.1-0.2,0.3-0.5,0.3-0.8c0.1-0.3,0.1-0.6,0.1-0.9v-0.2
      c0-0.3,0-0.6-0.1-0.9c-0.1-0.3-0.2-0.6-0.3-0.8c-0.1-0.2-0.3-0.4-0.6-0.5c-0.2-0.1-0.5-0.2-0.8-0.2c-0.3,0-0.6,0.1-0.8,0.2
      c-0.2,0.1-0.4,0.3-0.6,0.5c-0.1,0.2-0.3,0.5-0.3,0.8C148.3,209.5,148.2,209.8,148.2,210.1z M156.7,208.1v5.8H155v-7.4h1.6
      L156.7,208.1z M156.4,209.9l-0.5,0c0-0.5,0.1-1,0.2-1.4c0.1-0.4,0.3-0.8,0.6-1.1c0.3-0.3,0.6-0.6,0.9-0.7c0.4-0.2,0.8-0.3,1.2-0.3
      c0.4,0,0.7,0.1,1,0.2c0.3,0.1,0.5,0.3,0.7,0.5c0.2,0.2,0.4,0.5,0.5,0.9c0.1,0.3,0.2,0.8,0.2,1.3v4.8h-1.7v-4.8
      c0-0.4-0.1-0.6-0.2-0.8c-0.1-0.2-0.2-0.4-0.4-0.4c-0.2-0.1-0.4-0.1-0.7-0.1c-0.3,0-0.5,0.1-0.8,0.2s-0.4,0.3-0.6,0.5
      c-0.2,0.2-0.3,0.4-0.3,0.7C156.4,209.3,156.4,209.6,156.4,209.9z M167.2,211.9c0-0.2,0-0.3-0.1-0.4c-0.1-0.1-0.2-0.3-0.5-0.4
      c-0.2-0.1-0.6-0.2-1-0.3c-0.4-0.1-0.8-0.2-1.1-0.3c-0.3-0.1-0.6-0.3-0.8-0.4c-0.2-0.2-0.4-0.4-0.5-0.6c-0.1-0.2-0.2-0.5-0.2-0.8
      c0-0.3,0.1-0.6,0.2-0.9c0.1-0.3,0.3-0.5,0.6-0.7c0.2-0.2,0.5-0.4,0.9-0.5c0.4-0.1,0.8-0.2,1.2-0.2c0.6,0,1.2,0.1,1.6,0.3
      c0.4,0.2,0.8,0.5,1,0.8c0.2,0.4,0.4,0.7,0.4,1.2h-1.6c0-0.2-0.1-0.4-0.2-0.5c-0.1-0.2-0.2-0.3-0.4-0.4c-0.2-0.1-0.4-0.2-0.7-0.2
      c-0.3,0-0.5,0-0.7,0.1c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.1,0.3-0.1,0.5c0,0.1,0,0.2,0.1,0.3c0.1,0.1,0.1,0.2,0.2,0.3
      c0.1,0.1,0.3,0.2,0.5,0.2c0.2,0.1,0.5,0.1,0.8,0.2c0.6,0.1,1.1,0.3,1.5,0.5c0.4,0.2,0.7,0.4,0.9,0.7c0.2,0.3,0.3,0.7,0.3,1.1
      c0,0.3-0.1,0.6-0.2,0.9c-0.1,0.3-0.3,0.5-0.6,0.7c-0.3,0.2-0.6,0.3-0.9,0.5c-0.4,0.1-0.8,0.2-1.2,0.2c-0.7,0-1.2-0.1-1.7-0.4
      c-0.5-0.2-0.8-0.5-1.1-0.9c-0.2-0.4-0.4-0.8-0.4-1.2h1.6c0,0.3,0.1,0.5,0.3,0.7c0.2,0.2,0.3,0.3,0.6,0.4c0.2,0.1,0.5,0.1,0.7,0.1
      c0.3,0,0.5,0,0.7-0.1c0.2-0.1,0.4-0.2,0.5-0.3C167.1,212.2,167.2,212.1,167.2,211.9z"/>
        <g>
          <circle fill="#FFFFFF" cx="303" cy="352.4" r="8.5"/>
        </g>
        <circle fill="none" stroke="#4262FF" strokeWidth={2} cx="303" cy="352.4" r="5.5"/>
        <rect x="302.5" y="317.9" fill="#4262FF" width="1" height="24"/>
        <g>
          <path fill="#FFFFFF" d="M273.5,281.9h58c1.7,0,3,1.3,3,3v26c0,1.7-1.3,3-3,3h-58c-1.7,0-3-1.3-3-3v-26
        C270.5,283.2,271.8,281.9,273.5,281.9z"/>
          <path fill="none" stroke="#4262FF" d="M273.5,282.4h58c1.4,0,2.5,1.1,2.5,2.5v26c0,1.4-1.1,2.5-2.5,2.5h-58c-1.4,0-2.5-1.1-2.5-2.5v-26
        C271,283.5,272.1,282.4,273.5,282.4z"/>
        </g>
        <path fillRule="evenodd" clipRule="evenodd" fill="#4262FF" d="M270.5,284.9c0-1.7,1.3-3,3-3c4.6,0,13,0,13,0s-0.1,32.2,0,32c0.1-0.1-8.3-0.1-13,0c-1.7,0-3-1.3-3-3V284.9z
      "/>
        <path fill="none" stroke="#FFFFFF" d="M278.5,302.9v-8.3"/>
        <path fill="none" stroke="#FFFFFF" d="M275.7,296.7l2.2-2.2c0.3-0.3,0.9-0.3,1.3,0l2.2,2.2"/>
        <path fill="#919BAB" d="M304.5,301.8h-2.2v6.1h-1.2v-6.1h-2.2v-1h5.6V301.8z M311.6,307.9h-1.2v-3.2h-3.2v3.2h-1.2v-7.1h1.2v3h3.2
      v-3h1.2V307.9z M318.8,300.8v4.8c0,0.8-0.2,1.4-0.7,1.8c-0.5,0.4-1.1,0.7-1.9,0.7c-0.8,0-1.5-0.2-1.9-0.7c-0.5-0.4-0.7-1-0.7-1.8
      v-4.7h1.2v4.8c0,0.5,0.1,0.8,0.4,1.1c0.2,0.3,0.6,0.4,1.1,0.4c1,0,1.4-0.5,1.4-1.5v-4.7H318.8z"/>
        <path fill="#384D69" d="M295.8,291h0.9c0.4,0,0.8-0.1,1-0.3c0.3-0.2,0.4-0.5,0.4-1c0-0.4-0.1-0.7-0.3-0.9c-0.2-0.2-0.5-0.3-1-0.3
      c-0.4,0-0.7,0.1-0.9,0.3c-0.2,0.2-0.4,0.5-0.4,0.9h-1.4c0-0.4,0.1-0.8,0.3-1.2c0.2-0.4,0.6-0.6,1-0.8c0.4-0.2,0.9-0.3,1.4-0.3
      c0.9,0,1.5,0.2,2,0.6c0.5,0.4,0.7,1,0.7,1.8c0,0.4-0.1,0.7-0.4,1.1c-0.2,0.3-0.6,0.6-0.9,0.8c0.5,0.2,0.8,0.4,1.1,0.8
      c0.2,0.3,0.4,0.8,0.4,1.2c0,0.8-0.3,1.4-0.8,1.8c-0.5,0.5-1.2,0.7-2.1,0.7c-0.8,0-1.5-0.2-2-0.7c-0.5-0.4-0.8-1-0.8-1.7h1.4
      c0,0.4,0.1,0.7,0.4,0.9c0.3,0.2,0.6,0.4,1,0.4c0.4,0,0.8-0.1,1-0.4c0.3-0.2,0.4-0.6,0.4-1c0-0.4-0.1-0.8-0.4-1
      c-0.3-0.2-0.7-0.4-1.2-0.4h-0.8V291z M301.5,297.8l-0.8-0.5c0.2-0.4,0.4-0.7,0.5-0.9c0.1-0.3,0.1-0.5,0.1-0.8v-1.1h1.3l0,1
      c0,0.4-0.1,0.8-0.3,1.2C302.1,297.2,301.9,297.5,301.5,297.8z M309,292.8h1.1v1.1H309v1.9h-1.4V294h-3.7l0-0.9l3.7-5.8h1.5V292.8z
        M305.3,292.8h2.2v-3.6l-0.1,0.2L305.3,292.8z M311.6,291.7l0.5-4.3h4.6v1.2h-3.4l-0.2,2c0.4-0.2,0.8-0.3,1.3-0.3
      c0.8,0,1.4,0.3,1.9,0.8c0.5,0.5,0.7,1.2,0.7,2.1c0,0.9-0.2,1.6-0.8,2.1c-0.5,0.5-1.2,0.8-2.1,0.8c-0.8,0-1.4-0.2-1.9-0.7
      c-0.5-0.4-0.8-1-0.8-1.8h1.4c0.1,0.4,0.2,0.7,0.4,1c0.2,0.2,0.6,0.3,1,0.3c0.4,0,0.8-0.2,1-0.5c0.2-0.3,0.4-0.7,0.4-1.3
      c0-0.5-0.1-0.9-0.4-1.2c-0.3-0.3-0.6-0.5-1.1-0.5c-0.3,0-0.5,0-0.7,0.1c-0.2,0.1-0.4,0.2-0.6,0.4L311.6,291.7z M322.7,287.3v1.2
      h-0.2c-0.8,0-1.4,0.2-1.9,0.7c-0.5,0.4-0.8,1-0.9,1.8c0.5-0.5,1-0.7,1.8-0.7c0.8,0,1.3,0.3,1.8,0.8c0.4,0.5,0.7,1.2,0.7,2.1
      c0,0.9-0.3,1.6-0.8,2.1c-0.5,0.5-1.2,0.8-2,0.8c-0.9,0-1.5-0.3-2.1-0.9c-0.5-0.6-0.8-1.5-0.8-2.5v-0.5c0-1.5,0.4-2.7,1.1-3.5
      c0.7-0.8,1.8-1.3,3.1-1.3H322.7z M321.2,291.4c-0.3,0-0.6,0.1-0.9,0.3c-0.3,0.2-0.4,0.4-0.6,0.7v0.4c0,0.6,0.1,1.1,0.4,1.5
      c0.3,0.4,0.6,0.6,1,0.6c0.4,0,0.8-0.2,1-0.5c0.2-0.3,0.4-0.7,0.4-1.2s-0.1-0.9-0.4-1.3C321.9,291.5,321.6,291.4,321.2,291.4z"/>
        <g>
          <rect x="296.5" y="351.9" fill="none" width="19.5" height="24"/>
        </g>
      </g>
    </g>
    <path fill="#F8F8FA" d="M448.5,511.8H342.7c-4.1,0-7.2-3.1-7.2-7.2V365.1c0-4.1,3.1-7.2,7.2-7.2h105.8c4.1,0,7.2,3.1,7.2,7.2v139.5
  C455.7,508.7,452.6,511.8,448.5,511.8z"/>
    <path fill="#D6EDF1" d="M448.5,511.8h-91.4v-50.5c0-41.1,33.4-74.6,74.6-74.6h24.1v117.8C455.7,508.7,452.6,511.8,448.5,511.8z"/>
    <path fill="#004248" d="M448.5,513.8H342.7c-5.1,0-9.1-4.1-9.1-9.1V365.1c0-5,4.1-9.1,9.1-9.1h105.8c5.1,0,9.1,4.1,9.1,9.1v139.5
  C457.7,509.7,453.6,513.8,448.5,513.8z M342.7,359.9c-2.9,0-5.5,2.4-5.5,5.5v139.5c0,2.9,2.4,5.5,5.5,5.5h105.8
  c2.9,0,5.5-2.4,5.5-5.5V365.4c0-2.9-2.4-5.5-5.5-5.5H342.7z"/>
    <path fill="#F8F8FA" d="M419.7,377.2h-48.1c-4.1,0-7.2-3.1-7.2-7.2v-12h62.5v12C426.9,374,423.7,377.2,419.7,377.2z"/>
    <path fill="#D6EDF1" d="M419.7,377.2h-40.9c0-5.3,4.3-9.6,9.6-9.6h38.5v2.4C426.9,374,423.7,377.2,419.7,377.2z"/>
    <path fill="#004248" d="M419.7,379.1h-48.1c-5.1,0-9.1-4.1-9.1-9.1v-12c0-1,0.7-1.9,1.9-1.9h62.5c1,0,1.9,0.7,1.9,1.9v12
  C428.8,375,424.7,379.1,419.7,379.1z M366.3,359.9v10.3c0,2.9,2.4,5.5,5.5,5.5h48.1c2.9,0,5.5-2.4,5.5-5.5v-10.3H366.3z"/>
    <path fill="#F8F8FA" d="M441.3,490.2c26.6,0,48.1-21.5,48.1-48.1S467.9,394,441.3,394s-48.1,21.5-48.1,48.1
  C393.2,468.7,414.7,490.2,441.3,490.2z"/>
    <path fill="#D6EDF1" d="M489.4,442.1c0-15.4-7.2-29.1-18.8-38c-23.3,3.1-41.4,23.3-41.4,47.6c0,15.4,7.2,29.1,18.8,38
  C471.4,486.4,489.4,466.4,489.4,442.1z"/>
    <path fill="#004248" d="M441.3,492.1c-27.4,0-50-22.4-50-50c0-27.7,22.4-50,50-50c27.7,0,50,22.4,50,50
  C491.3,469.8,469,492.1,441.3,492.1z M441.3,395.9c-25.5,0-46.4,20.7-46.4,46.4c0,25.5,20.7,46.4,46.4,46.4s46.4-20.7,46.4-46.4
  C487.7,416.6,466.8,395.9,441.3,395.9z"/>
    <path fill="#004248" d="M405,431.3c-0.2,0-0.5,0-0.5,0c-1-0.2-1.4-1.4-1.2-2.4c3.8-11.1,12.3-19.7,22.8-24.3c1-0.5,1.9,0,2.4,1
  s0,1.9-1,2.4c-9.9,4.1-17.6,12-20.9,22.1C406.4,430.8,405.7,431.3,405,431.3z"/>
    <path fill="#004248" d="M402.8,446c-1,0-1.7-0.7-1.7-1.7c0-0.7,0-1.4,0-1.9c0-1.4,0-2.9,0.2-4.3c0-1,1-1.7,1.9-1.7c1,0,1.7,1,1.7,1.9
  c-0.2,1.2-0.2,2.6-0.2,3.8c0,0.7,0,1.2,0,1.9C404.7,445,404,445.7,402.8,446C403.1,446,403.1,446,402.8,446z"/>
    <path fill="#004248" d="M441.3,444c-0.2,0-0.5,0-0.7-0.2c-0.7-0.2-1.2-1-1.2-1.7V394c0-1,0.7-1.9,1.9-1.9c1.2,0,1.9,0.7,1.9,1.9v43.8
  l31-30.8c0.7-0.7,1.9-0.7,2.6,0s0.7,1.9,0,2.6l-34.2,33.9C442.3,443.8,441.8,444,441.3,444z"/>
    <path fill="#004248" d="M410,480.3c-0.5,0-0.7-0.2-1.2-0.5c-0.7-0.7-0.7-1.7-0.2-2.6l31.3-36.6c0.5-0.7,1.4-0.7,2.2-0.5l41.6,24
  c1,0.5,1.2,1.7,0.7,2.4c-0.5,0.7-1.7,1.2-2.4,0.7L441.5,444l-30.3,35.4C411,480.1,410.5,480.3,410,480.3z"/>
    <path fill="#F8F8FA" d="M441.3,464c12.1,0,21.9-9.8,21.9-21.9s-9.8-21.9-21.9-21.9s-21.9,9.8-21.9,21.9S429.2,464,441.3,464z"/>
    <path fill="#D6EDF1" d="M463,438.3c-0.5-3.1-1.9-6-3.6-8.7c-1.7-0.2-3.6,0-5.5,0.2c-11.8,2.2-19.7,13.5-17.6,25.5
  c0.5,3.1,1.9,6,3.6,8.7c1.7,0.2,3.6,0,5.5-0.2C457.2,461.6,465.1,450,463,438.3z"/>
    <path fill="#004248" d="M441.3,465.7c-13,0-23.6-10.6-23.6-23.6s10.6-23.6,23.6-23.6s23.6,10.6,23.6,23.6S454.5,465.7,441.3,465.7z
    M441.3,422.1c-11.1,0-20,8.9-20,20s8.9,20,20,20s20-8.9,20-20S452.4,422.1,441.3,422.1z"/>
    <path fill="#F8F8FA" d="M361.9,406h-9.6c-1.4,0-2.4-1-2.4-2.4V394c0-1.4,1-2.4,2.4-2.4h9.6c1.4,0,2.4,1,2.4,2.4v9.6
  C364.3,405.1,363.4,406,361.9,406z"/>
    <path fill="#F8F8FA" d="M361.9,434.9h-9.6c-1.4,0-2.4-1-2.4-2.4v-9.6c0-1.4,1-2.4,2.4-2.4h9.6c1.4,0,2.4,1,2.4,2.4v9.6
  C364.3,433.9,363.4,434.9,361.9,434.9z"/>
    <path fill="#F8F8FA" d="M361.9,463.8h-9.6c-1.4,0-2.4-1-2.4-2.4v-9.6c0-1.4,1-2.4,2.4-2.4h9.6c1.4,0,2.4,1,2.4,2.4v9.6
  C364.3,462.8,363.4,463.8,361.9,463.8z"/>
    <path fill="#F8F8FA" d="M361.9,492.6h-9.6c-1.4,0-2.4-1-2.4-2.4v-9.6c0-1.4,1-2.4,2.4-2.4h9.6c1.4,0,2.4,1,2.4,2.4v9.6
  C364.3,491.6,363.4,492.6,361.9,492.6z"/>
    <path fill="#D6EDF1" d="M361.9,406h-7.2v-4.8c0-1.4,1-2.4,2.4-2.4h7.2v4.8C364.3,405.1,363.4,406,361.9,406z"/>
    <path fill="#D6EDF1" d="M361.9,492.6h-7.2v-4.8c0-1.4,1-2.4,2.4-2.4h7.2v4.8C364.3,491.6,363.4,492.6,361.9,492.6z"/>
    <path fill="#D6EDF1" d="M361.9,434.9h-7.2v-4.8c0-1.4,1-2.4,2.4-2.4h7.2v4.8C364.3,433.9,363.4,434.9,361.9,434.9z"/>
    <path fill="#D6EDF1" d="M361.9,463.8h-7.2V459c0-1.4,1-2.4,2.4-2.4h7.2v4.8C364.3,462.8,363.4,463.8,361.9,463.8z"/>
    <path fill="#004248" d="M361.9,408h-9.6c-2.4,0-4.3-1.9-4.3-4.3V394c0-2.4,1.9-4.3,4.3-4.3h9.6c2.4,0,4.3,1.9,4.3,4.3v9.6
  C366.3,406,364.3,408,361.9,408z M352.3,393.5c-0.2,0-0.7,0.2-0.7,0.7v9.6c0,0.2,0.2,0.7,0.7,0.7h9.6c0.2,0,0.7-0.2,0.7-0.7v-9.6
  c0-0.2-0.2-0.7-0.7-0.7H352.3z"/>
    <path fill="#004248" d="M361.9,436.8h-9.6c-2.4,0-4.3-1.9-4.3-4.3v-9.6c0-2.4,1.9-4.3,4.3-4.3h9.6c2.4,0,4.3,1.9,4.3,4.3v9.6
  C366.3,434.9,364.3,436.8,361.9,436.8z M352.3,422.4c-0.2,0-0.7,0.2-0.7,0.7v9.6c0,0.2,0.2,0.7,0.7,0.7h9.6c0.2,0,0.7-0.2,0.7-0.7
  v-9.6c0-0.2-0.2-0.7-0.7-0.7H352.3z"/>
    <path fill="#004248" d="M361.9,465.7h-9.6c-2.4,0-4.3-1.9-4.3-4.3v-9.6c0-2.4,1.9-4.3,4.3-4.3h9.6c2.4,0,4.3,1.9,4.3,4.3v9.6
  C366.3,463.8,364.3,465.7,361.9,465.7z M352.3,451.2c-0.2,0-0.7,0.2-0.7,0.7v9.6c0,0.2,0.2,0.7,0.7,0.7h9.6c0.2,0,0.7-0.2,0.7-0.7
  V452c0-0.2-0.2-0.7-0.7-0.7L352.3,451.2L352.3,451.2z"/>
    <path fill="#004248" d="M361.9,494.5h-9.6c-2.4,0-4.3-1.9-4.3-4.3v-9.6c0-2.4,1.9-4.3,4.3-4.3h9.6c2.4,0,4.3,1.9,4.3,4.3v9.6
  C366.3,492.6,364.3,494.5,361.9,494.5z M352.3,480.1c-0.2,0-0.7,0.2-0.7,0.7v9.6c0,0.2,0.2,0.7,0.7,0.7h9.6c0.2,0,0.7-0.2,0.7-0.7
  v-9.6c0-0.2-0.2-0.7-0.7-0.7H352.3z"/>
    <path fill="#004248" d="M410,395.9h-17.8c-1,0-1.9-0.7-1.9-1.9c0-1.2,0.7-1.9,1.9-1.9H410c1,0,1.9,0.7,1.9,1.9
  C412,395.2,411,395.9,410,395.9z"/>
    <path fill="#004248" d="M382.6,395.9H374c-1,0-1.9-0.7-1.9-1.9c0-1.2,0.7-1.9,1.9-1.9h8.7c1,0,1.9,0.7,1.9,1.9
  C384.5,395.2,383.6,395.9,382.6,395.9z"/>
    <path fill="#004248" d="M400.4,405.6H374c-1,0-1.9-0.7-1.9-1.9s0.7-1.9,1.9-1.9h26.5c1,0,1.9,0.7,1.9,1.9S401.4,405.6,400.4,405.6z"/>
    <path fill="#004248" d="M388.4,424.8H374c-1,0-1.9-0.7-1.9-1.9c0-1.2,0.7-1.9,1.9-1.9h14.4c1,0,1.9,0.7,1.9,1.9
  C390.3,424.1,389.3,424.8,388.4,424.8z"/>
    <path fill="#004248" d="M386,434.4h-12c-1,0-1.9-0.7-1.9-1.9c0-1.2,0.7-1.9,1.9-1.9h12c1,0,1.9,0.7,1.9,1.9
  C387.9,433.7,386.9,434.4,386,434.4z"/>
    <path fill="#004248" d="M386,453.7h-12c-1,0-1.9-0.7-1.9-1.9c0-1.2,0.7-1.9,1.9-1.9h12c1,0,1.9,0.7,1.9,1.9
  C387.9,452.9,386.9,453.7,386,453.7z"/>
    <path fill="#004248" d="M388.4,463.3H374c-1,0-1.9-0.7-1.9-1.9c0-1.2,0.7-1.9,1.9-1.9h14.4c1,0,1.9,0.7,1.9,1.9
  C390.3,462.5,389.3,463.3,388.4,463.3z"/>
    <path fill="#004248" d="M400.4,482.5H374c-1,0-1.9-0.7-1.9-1.9s0.7-1.9,1.9-1.9h26.5c1,0,1.9,0.7,1.9,1.9S401.4,482.5,400.4,482.5z"/>
    <path fill="#004248" d="M410,492.1h-12c-1,0-1.9-0.7-1.9-1.9c0-1.2,0.7-1.9,1.9-1.9h12c1,0,1.9,0.7,1.9,1.9
  C412,491.4,411,492.1,410,492.1z"/>
    <path fill="#004248" d="M388.4,492.1H374c-1,0-1.9-0.7-1.9-1.9c0-1.2,0.7-1.9,1.9-1.9h14.4c1,0,1.9,0.7,1.9,1.9
  S389.3,492.1,388.4,492.1z"/>
    <g>
      <path fill="#00838F" d="M194.5,430h179.6c2.8,0,5,2.2,5,5v55.7c0,2.8-2.2,5-5,5H194.5c-2.8,0-5-2.2-5-5V435
    C189.5,432.2,191.7,430,194.5,430z"/>
    </g>
    <g>
      <path fill="#1DB3BB" d="M74.6,59.8c-5.2,0-9.3-2.6-9.5-7.5h6.3c0.2,1.9,1.3,2.8,3,2.8c1.7,0,2.8-0.9,2.8-2.3
    c0-4.5-12.1-2.1-12.1-10.7c0-4.6,3.8-7.2,8.9-7.2c5.3,0,8.9,2.6,9.1,7.3h-6.4c-0.1-1.6-1.2-2.5-2.8-2.5c-1.4,0-2.5,0.7-2.5,2.2
    c0,4.2,12,2.3,12,10.5C83.5,56.4,80.3,59.8,74.6,59.8z"/>
      <path fill="#1DB3BB" d="M87.1,35.3h7l6.8,16.8l6.7-16.8h7v24.3h-5.9V45l-5.4,14.6h-4.8L93,45v14.6h-5.9V35.3z"/>
      <path fill="#1DB3BB" d="M134,55.3h-9.1l-1.5,4.3h-6.2l8.8-24.3h6.9l8.8,24.3h-6.3L134,55.3z M129.5,41.8l-3,8.9h6L129.5,41.8z"/>
      <path fill="#1DB3BB" d="M151,35.3V55h7.8v4.6h-13.7V35.3H151z"/>
      <path fill="#1DB3BB" d="M168.2,35.3V55h7.8v4.6h-13.7V35.3H168.2z"/>
      <path fill="#FFFFFF" d="M77.1,70c5.8,0,10.1,3.3,11.5,8.5h-6.5c-1-2.1-2.8-3.1-5.1-3.1c-3.6,0-6.1,2.7-6.1,7s2.5,7,6.1,7
    c2.2,0,4.1-1,5.1-3.1h6.5c-1.4,5.3-5.7,8.5-11.5,8.5c-7.2,0-12.2-5.1-12.2-12.4S69.9,70,77.1,70z"/>
      <path fill="#FFFFFF" d="M103.7,94.8c-6.9,0-12.5-5.2-12.5-12.5s5.6-12.4,12.5-12.4c6.9,0,12.4,5.1,12.4,12.4S110.6,94.8,103.7,94.8z
      M103.7,89.4c3.9,0,6.4-2.8,6.4-7.1c0-4.3-2.5-7.1-6.4-7.1c-3.9,0-6.4,2.7-6.4,7.1C97.3,86.6,99.8,89.4,103.7,89.4z"/>
      <path fill="#FFFFFF" d="M141.1,70.3v24.3h-5.9l-9.9-15v15h-5.9V70.3h5.9l9.9,15.1V70.3H141.1z"/>
      <path fill="#FFFFFF" d="M145.4,70.3h15.8V75h-9.9v5.1h7.4v4.6h-7.4v9.8h-5.9V70.3z"/>
      <path fill="#FFFFFF" d="M164.4,70.3h5.9v24.3h-5.9V70.3z"/>
      <path fill="#FFFFFF" d="M197.2,78h-6.5c-0.9-1.6-2.6-2.5-4.8-2.5c-3.8,0-6.3,2.7-6.3,6.9c0,4.5,2.6,7.1,6.8,7.1c2.9,0,5-1.6,5.9-4.3
    h-7.5v-4.4h12.9v5.5c-1.2,4.2-5.3,8.4-11.7,8.4c-7.3,0-12.4-5.1-12.4-12.4S178.7,70,185.9,70C191.7,70,196,73,197.2,78z"/>
      <path fill="#FFFFFF" d="M200.8,70.3h5.9v14.5c0,2.9,1.4,4.5,4.2,4.5s4.3-1.7,4.3-4.5V70.3h5.9v14.5c0,6.7-4.7,10-10.3,10
    c-5.6,0-10-3.3-10-10V70.3z"/>
      <path fill="#FFFFFF" d="M235.3,70.3c6,0,8.9,3.4,8.9,7.6c0,3-1.7,5.9-5.4,7l5.6,9.6h-6.7l-5.1-9.2h-1.4v9.2h-5.9V70.3H235.3z
      M234.9,75.2h-3.7v6h3.7c2.2,0,3.3-1.1,3.3-3C238.2,76.4,237.1,75.2,234.9,75.2z"/>
      <path fill="#FFFFFF" d="M263,90.3H254l-1.5,4.3h-6.2l8.8-24.3h6.9l8.8,24.3h-6.3L263,90.3z M258.5,76.8l-3,8.9h6L258.5,76.8z"/>
      <path fill="#FFFFFF" d="M268.6,70.3h18.8V75H281v19.6h-5.9V75h-6.4V70.3z"/>
      <path fill="#FFFFFF" d="M291.1,70.3h5.9v24.3h-5.9V70.3z"/>
      <path fill="#FFFFFF" d="M312.8,94.8c-6.9,0-12.5-5.2-12.5-12.5s5.6-12.4,12.5-12.4c6.9,0,12.4,5.1,12.4,12.4S319.7,94.8,312.8,94.8z
      M312.8,89.4c3.9,0,6.4-2.8,6.4-7.1c0-4.3-2.5-7.1-6.4-7.1c-3.9,0-6.4,2.7-6.4,7.1C306.4,86.6,308.8,89.4,312.8,89.4z"/>
      <path fill="#FFFFFF" d="M350.2,70.3v24.3h-5.9l-9.9-15v15h-5.9V70.3h5.9l9.9,15.1V70.3H350.2z"/>
      <path fill="#FFFFFF" d="M363.3,94.8c-5.2,0-9.3-2.6-9.5-7.5h6.3c0.2,1.9,1.3,2.8,3,2.8c1.7,0,2.8-0.9,2.8-2.3
    c0-4.5-12.1-2.1-12.1-10.7c0-4.6,3.8-7.2,8.9-7.2c5.3,0,8.9,2.6,9.1,7.3h-6.4c-0.1-1.6-1.2-2.5-2.8-2.5c-1.4,0-2.5,0.7-2.5,2.2
    c0,4.2,12,2.3,12,10.5C372.2,91.4,369,94.8,363.3,94.8z"/>
      <path fill="#FFFFFF" d="M378.6,94.8c-2.1,0-3.5-1.4-3.5-3.2c0-1.8,1.4-3.3,3.5-3.3c2.1,0,3.5,1.4,3.5,3.3
    C382.1,93.4,380.7,94.8,378.6,94.8z"/>
      <path fill="#1DB3BB" d="M77.2,129.6H65.8v-24.3h10.9c4.9,0,7.9,2.5,7.9,6.3c0,2.9-1.8,4.8-4.1,5.5c2.8,0.6,4.7,3.1,4.7,5.8
    C85.2,127,82.3,129.6,77.2,129.6z M75.6,110h-3.9v5.1h3.9c1.9,0,3-0.9,3-2.5S77.6,110,75.6,110z M76,119.4h-4.3v5.4h4.4
    c2,0,3.1-0.9,3.1-2.6C79.2,120.4,78,119.4,76,119.4z"/>
      <path fill="#1DB3BB" d="M88.6,105.3h5.9v24.3h-5.9V105.3z"/>
      <path fill="#1DB3BB" d="M121.5,113H115c-0.9-1.6-2.6-2.5-4.8-2.5c-3.8,0-6.3,2.7-6.3,6.9c0,4.5,2.6,7.1,6.8,7.1c2.9,0,5-1.6,5.9-4.3
    h-7.5v-4.4h12.9v5.5c-1.2,4.2-5.3,8.4-11.7,8.4c-7.3,0-12.4-5.1-12.4-12.4s5.1-12.4,12.4-12.4C116,105,120.2,108,121.5,113z"/>
      <path fill="#FFFFFF" d="M155.2,113h-6.5c-0.9-1.6-2.6-2.5-4.8-2.5c-3.8,0-6.3,2.7-6.3,6.9c0,4.5,2.6,7.1,6.8,7.1c2.9,0,5-1.6,5.9-4.3
    h-7.5v-4.4h12.9v5.5c-1.2,4.2-5.3,8.4-11.7,8.4c-7.3,0-12.4-5.1-12.4-12.4s5.1-12.4,12.4-12.4C149.7,105,153.9,108,155.2,113z"/>
      <path fill="#FFFFFF" d="M172.7,125.3h-9.1l-1.5,4.3H156l8.8-24.3h6.9l8.8,24.3h-6.3L172.7,125.3z M168.1,111.8l-3,8.9h6L168.1,111.8z"/>
      <path fill="#FFFFFF" d="M183.7,105.3h5.9v24.3h-5.9V105.3z"/>
      <path fill="#FFFFFF" d="M215.7,105.3v24.3h-5.9l-9.9-15v15H194v-24.3h5.9l9.9,15.1v-15.1H215.7z"/>
      <path fill="#FFFFFF" d="M228.8,129.8c-5.2,0-9.3-2.6-9.5-7.5h6.3c0.2,1.9,1.3,2.8,3,2.8c1.7,0,2.8-0.9,2.8-2.3
    c0-4.5-12.1-2.1-12.1-10.7c0-4.6,3.8-7.2,8.9-7.2c5.3,0,8.9,2.6,9.1,7.3h-6.4c-0.1-1.6-1.2-2.5-2.8-2.5c-1.4,0-2.5,0.7-2.5,2.2
    c0,4.2,12,2.3,12,10.5C237.6,126.4,234.4,129.8,228.8,129.8z"/>
      <path fill="#FFFFFF" d="M244,129.8c-2.1,0-3.5-1.4-3.5-3.2c0-1.8,1.4-3.3,3.5-3.3c2.1,0,3.5,1.4,3.5,3.3
    C247.5,128.4,246.1,129.8,244,129.8z"/>
    </g>
    <g>
      <g id="gb_Symbol_00000061461281439815224800000005970470966895381892_">
        <path fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M244.9,467.5L230.4,482c-2.6,2.6-6.7,2.6-9.3,0l-7.7-7.7h8.6c1.8,0,3-1.2,3-3V465c0-2.1,0-4.3,0-6.4
      c0-1.6-1.3-2.8-2.8-2.8c-1.9,0-3.9,0-5.8,0c-1.5,0-2.8,1.3-2.8,2.8c0,2.9,0,5.8,0,8.7c0,1.4,1.2,2.7,2.7,2.7c1.4,0,2.7,0,4.1,0
      c0.3,0,0.3-0.1,0.3-0.3c0-0.7,0-1.5,0-2.2c0-0.3-0.1-0.4-0.4-0.4c-0.9,0-1.7,0-2.6,0c-0.8,0-1.2-0.5-1.2-1.2c0-2,0-4,0-6.1
      c0-0.7,0.4-1.2,1.2-1.2c1.1,0,2.2,0,3.3,0c0.7,0,1.2,0.5,1.2,1.2c0,3.5,0,7,0,10.5c0,0.8-0.5,1.2-1.2,1.2h-10.4l-4-4
      c-2.5-2.5-2.5-6.7,0-9.3l14.5-14.5c1.5-1.5,3.4-2.1,5.4-1.9v25.3c0,1.7,1.2,2.9,2.9,2.9h5.7c1.3,0,2.4-0.8,2.7-2
      c0.1-0.4,0.2-0.8,0.2-1.2c0-2.6,0-5.1,0-7.7c0-0.3,0-0.6-0.1-0.9c-0.2-1.3-1.4-2.3-2.6-2.4c-1.9,0-2.2,0-4.1,0
      c-0.3,0-0.3,0.1-0.3,0.3c0,0.7,0,1.4,0,2.1c0,0.3,0.1,0.5,0.4,0.4c1.3,0,1.1,0,2.4,0c0.8,0,1.3,0.3,1.3,1.3c0,2,0,3.9,0,5.9
      c0,0.8-0.4,1.2-1.2,1.2c-1.6,0-1.8,0-3.3,0c-0.6,0-1-0.4-1.1-1c0-0.2,0-0.4,0-0.6c0-3.1,0-15,0-22.7c0.4,0.2,0.7,0.5,1.1,0.9
      l14.5,14.5C247.5,460.8,247.5,464.9,244.9,467.5z"/>
      </g>
      <g id="GroupBy_00000059273846456566403070000012468353370319586704_">
        <path id="g_00000010309482939617150800000010479571716758053781_" fill="#FFFFFF" d="M269.4,465.8c0,2.8,0,5.7,0,8.4
      c0,2.3-1.6,3.9-3.9,3.9s-4.6,0-6.9,0c-0.4,0-0.5-0.1-0.5-0.5c0-1,0-2,0-2.9c0-0.4,0.1-0.5,0.5-0.5c1.8,0,3.6,0,5.4,0
      c1,0,1.6-0.6,1.6-1.6c0-4.6,0-9.4,0-14c0-0.9-0.6-1.5-1.5-1.5c-1.5,0-2.9,0-4.4,0c-1,0-1.5,0.6-1.5,1.6c0,2.7,0,5.5,0,8.2
      c0,1,0.6,1.6,1.6,1.6c1.2,0,2.3,0,3.4,0c0.4,0,0.5,0.1,0.5,0.5c0,1,0,1.9,0,2.9c0,0.3-0.1,0.4-0.4,0.4c-1.8,0-3.6,0-5.4,0
      c-1.9,0-3.5-1.7-3.5-3.6c0-3.8,0-7.8,0-11.6c0-2,1.7-3.7,3.7-3.7c2.6,0,5.1,0,7.7,0c2.1,0,3.7,1.7,3.7,3.8
      C269.4,459.9,269.4,462.9,269.4,465.8L269.4,465.8z"/>
        <path id="b_00000003820342606232251870000007809289390981992068_" fill="#FFFFFF" d="M331.9,457.5c0-3.7,0-7.4,0-11.2
      c0-0.4,0.1-0.5,0.5-0.5c0.9,0,1.8,0,2.7,0c0.5,0,0.6,0.1,0.6,0.6c0,6.6,0,13.4,0,20c0,0.3,0,0.5,0,0.8c0.1,0.8,0.6,1.3,1.4,1.3
      c2.1,0,2.3,0,4.4,0c1.1,0,1.5-0.6,1.5-1.7c0-2.6,0-5.4,0-8c0-1.3-0.7-1.7-1.7-1.7c-1.7,0-1.6,0-3.3,0c-0.5,0-0.6-0.1-0.6-0.6
      c0-0.9,0-1.9,0-2.8c0-0.3,0.1-0.4,0.4-0.4c2.4,0,3,0,5.5,0c1.6,0,3.2,1.4,3.4,3.1c0.1,0.4,0.1,0.8,0.1,1.2c0,3.4,0,6.9,0,10.3
      c0,0.6-0.1,1.1-0.2,1.6c-0.5,1.6-1.9,2.7-3.6,2.7c-3.1,0-4.4,0-7.5,0c-2.2,0-3.8-1.6-3.8-3.8C331.9,464.9,331.9,461.1,331.9,457.5
      L331.9,457.5z"/>
        <path id="p_00000164495788240123776520000017528436881065548437_" fill="#FFFFFF" d="M314.8,467.2c0-3.3,0-6.7,0-10
      c0-2.3,1.6-3.9,3.9-3.9c3,0,4.2,0.1,7.2,0c2.2-0.1,3.9,1.7,4,3.8c0,3.7,0,7.6,0,11.3c0,2.2-1.7,3.8-3.9,3.8c-2.3,0-2.7,0-5,0
      c-0.3,0-0.4-0.1-0.4-0.4c0-1,0-2,0-2.9c0-0.4,0.1-0.4,0.4-0.4c1.7,0,1.5,0,3.2,0c0.6,0,1.2-0.1,1.6-0.7c0.2-0.3,0.3-0.6,0.3-1
      c0-2.7,0-5.5,0-8.2c0-0.9-0.3-1.3-1.1-1.5c-0.2-0.1-0.3-0.1-0.5-0.1c-2.1,0-2.3,0-4.4,0c-0.7,0-1.2,0.6-1.4,1.3c0,0.2,0,0.5,0,0.7
      c0,6,0,12.4,0,18.4c0,0.5-0.1,0.6-0.6,0.6c-0.9,0-1.8,0-2.7,0c-0.4,0-0.5-0.1-0.5-0.5C314.8,474.2,314.8,470.7,314.8,467.2
      L314.8,467.2z"/>
        <path id="y_00000137119207146626532210000014731520893817815452_" fill="#FFFFFF" d="M364,465.4c0,3.3,0,5.7,0,9
      c0,2.1-1.7,3.8-3.7,3.8c-2.4,0-4.8,0-7.1,0c-0.3,0-0.4-0.1-0.4-0.4c0-1,0-2,0-2.9c0-0.4,0.1-0.5,0.5-0.5c1.8,0,4.1,0,5.9,0
      c0.9,0,1.1-0.3,1.1-1.2c0-0.3,0-0.8-0.4-0.8c-2.3,0-4.7,0-7,0c-2.2,0-3.9-1.6-3.9-3.9c0-4.8,0-9.7,0-14.5c0-0.5,0.1-0.6,0.6-0.6
      c0.9,0,1.8,0,2.7,0c0.4,0,0.5,0.1,0.5,0.5c0,4.2,0,8.6,0,12.8c0,1.3,0.6,1.9,1.9,1.9c1.7,0,3.3,0,5,0c0.5,0,0.6-0.1,0.6-0.6
      c0-4.6,0-9.4,0-14c0-0.5,0.1-0.6,0.6-0.6c0.9,0,1.9,0,2.8,0c0.4,0,0.5,0.1,0.5,0.5c0,3.4,0,5.8,0,9.2v2.3H364z"/>
        <path id="u_00000075848276926523809440000017991315733103469242_" fill="#FFFFFF" d="M312.9,461.3c0,2.4,0,4.9,0,7.3
      c0,2-1.7,3.7-3.7,3.7c-2.6,0-5.1,0-7.7,0c-1,0-1.9-0.4-2.6-1.1s-1.1-1.6-1.1-2.6c0-4.9,0-9.9,0-14.7c0-0.4,0.1-0.5,0.6-0.5
      c0.9,0,1.7,0,2.6,0c0.7,0,0.7-0.1,0.7,0.6c0,4.2,0,8.6,0,12.8c0,0.7,0.2,1.2,0.8,1.5c0.3,0.2,0.6,0.2,1,0.2c1.3,0,2.6,0,3.9,0
      s1.8-0.6,1.8-1.9c0-4.2,0-8.6,0-12.8c0-0.4,0.1-0.6,0.6-0.6c0.9,0,1.8,0,2.7,0c0.4,0,0.5,0.1,0.5,0.5
      C312.9,456.3,312.9,458.8,312.9,461.3L312.9,461.3z"/>
        <path id="r_00000091705985005929903770000007765483800795205009_" fill="#FFFFFF" d="M271.3,465.6c0-2.4,0-6,0-8.3
      c0-2.2,1.7-3.9,3.9-3.9c1.1,0,2.1,0,3.2,0c0.3,0,0.5,0.1,0.5,0.4c0,1,0,2,0,2.9c0,0.4-0.1,0.4-0.4,0.4c-0.6,0-1.1,0-1.7,0
      c-1.1,0-1.7,0.6-1.7,1.7c0,4.3,0,8.7,0,12.9c0,0.5-0.1,0.6-0.6,0.6c-0.9,0-1.8,0-2.7,0c-0.4,0-0.5-0.1-0.5-0.5c0-2.4,0-6.1,0-8.6
      L271.3,465.6L271.3,465.6z"/>
        <path id="o_00000060024834673451556590000007971716210163587741_" fill="#FFFFFF" d="M295.9,462.8c0-1.9,0-3.8,0-5.7
      c0-2.1-1.6-3.7-3.7-3.7c-2.5,0-5.1,0-7.6,0c-2,0-3.7,1.7-3.7,3.7c0,3.8,0,7.7,0,11.5c0,2.1,1.6,3.7,3.7,3.7c2.6,0,5.1,0,7.7,0
      c2,0,3.6-1.7,3.6-3.6S295.9,464.7,295.9,462.8L295.9,462.8z M292.1,466.9c0,1-0.5,1.6-1.5,1.6c-1.5,0.1-3,0-4.4,0
      c-1,0-1.5-0.6-1.5-1.6c0-1.1,0-2.3,0-3.3c0-1.6,0-3.1,0-4.7c0-1.2,0.6-1.7,1.8-1.7c1.3,0,2.6,0,3.9,0c1.2,0,1.8,0.6,1.8,1.8
      c0,1.3,0,2.5,0,3.8C292.1,464.1,292.1,465.6,292.1,466.9L292.1,466.9z"/>
      </g>
    </g>
  </SvgIcon>);
};
export default LoginPageGraphic;

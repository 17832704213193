import { __rest } from "tslib";
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { baseColors } from '@groupby/ui-components';
export const AdminNavLinkIcon = (_a) => {
    var { height = 24, width = 24, color = baseColors.primaryBlueMed400 } = _a, props = __rest(_a, ["height", "width", "color"]);
    return (<SvgIcon width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M4 6.00004C4 4.34317 5.34317 3.00002 7.00004 3.00004L12 3.00011L17 3.00004C18.6568 3.00002 20 4.34298 20 5.99985C20 8.77752 20 11.8402 20 14.6139C20 16.4297 19.0155 18.1024 17.4282 18.9842L12.4856 21.7301C12.1836 21.8979 11.8164 21.8979 11.5144 21.7301L6.57179 18.9842C4.98446 18.1024 4 16.4293 4 14.6134V6.00004Z" stroke={color} strokeWidth="1.5"/>
  </SvgIcon>);
};
export default AdminNavLinkIcon;

import React from 'react';
import ListItemText from '@mui/material/ListItemText';
import { IconButton } from '@mui/material';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { StyledNavigationSectionHeader } from './drawer-section-header.styles';
export const NavigationDrawerSectionHeader = ({ text, onClose, }) => (<StyledNavigationSectionHeader>
    <IconButton size="small" onClick={onClose}>
      <KeyboardBackspaceIcon />
    </IconButton>
    <ListItemText primary={text} sx={{ ml: 1 }}/>
  </StyledNavigationSectionHeader>);
export default NavigationDrawerSectionHeader;

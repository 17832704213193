import { __rest } from "tslib";
import React from 'react';
import { baseColors } from '@groupby/ui-components';
import SvgIcon from '@mui/material/SvgIcon';
export const MerchandisingNavLinkIcon = (_a) => {
    var { height = 24, width = 24, color = baseColors.primaryBlueMed400 } = _a, props = __rest(_a, ["height", "width", "color"]);
    return (<SvgIcon width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M3 21H5M21 21H19M5 21H19M5 21V17.5M19 21V9.32837M9 8L7.1094 9.2604C6.47351 9.68433 5.65537 9.70699 5 9.32837M9 8L10.8906 9.2604C11.5624 9.70827 12.4376 9.70827 13.1094 9.2604L15 8M9 8V3M15 8L16.8906 9.2604C17.5265 9.68433 18.3446 9.70699 19 9.32837M15 8V3M5 9.32837C4.96299 9.307 4.92651 9.28434 4.8906 9.2604L4.35024 8.90016C3.56331 8.37554 3.25144 7.37141 3.60269 6.49328L4.49711 4.25722C4.80084 3.4979 5.53626 3 6.35407 3H9M5 9.32837V17.5M19 9.32837C19.037 9.307 19.0735 9.28434 19.1094 9.2604L19.6498 8.90016C20.4367 8.37554 20.7486 7.37141 20.3973 6.49328L19.5029 4.25722C19.1992 3.4979 18.4637 3 17.6459 3H15M9 3H15M5 17.5H12" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M16 21V16C16 14.8954 15.1046 14 14 14V14C12.8954 14 12 14.8954 12 16V21" stroke={color} strokeWidth="1.5"/>
  </SvgIcon>);
};
export default MerchandisingNavLinkIcon;

import { createAction } from '@/store/actions/utils';
import { ACTION_PREFIX } from '../evaluate.constants';
export const SET_REQUEST_OPTIONS = `${ACTION_PREFIX}SET_REQUEST_OPTIONS`;
export const setRequestOptions = (requestOptions) => createAction(SET_REQUEST_OPTIONS, requestOptions);
export const RESET_REQUEST_OPTIONS = `${ACTION_PREFIX}RESET_REQUEST_OPTIONS`;
export const resetRequestOptions = () => createAction(RESET_REQUEST_OPTIONS);
export const SET_SEARCH_RESPONSE = `${ACTION_PREFIX}SET_SEARCH_RESPONSE`;
export const setSearchResponse = (search) => createAction(SET_SEARCH_RESPONSE, search);
export const CLEAR_SEARCH_RESPONSE = `${ACTION_PREFIX}CLEAR_SEARCH_RESPONSE`;
export const clearSearchResponse = () => createAction(CLEAR_SEARCH_RESPONSE);
export const SET_IS_LOADING = `${ACTION_PREFIX}SET_IS_LOADING`;
export const setIsLoading = (isLoading) => createAction(SET_IS_LOADING, { isLoading });
export const SET_SELECTED_REFINEMENTS = `${ACTION_PREFIX}SET_SELECTED_REFINEMENTS`;
export const setSelectedRefinements = (selectedRefinements) => createAction(SET_SELECTED_REFINEMENTS, { selectedRefinements });
export const SET_OPEN_NAVIGATIONS = `${ACTION_PREFIX}SET_OPEN_NAVIGATIONS`;
export const setOpenNavigations = (openNavigations) => createAction(SET_OPEN_NAVIGATIONS, { openNavigations });
export const SET_IMAGE_SETTINGS = `${ACTION_PREFIX}SET_IMAGE_SETTINGS`;
export const setImageSettings = (imageMetaDataSettings) => createAction(SET_IMAGE_SETTINGS, imageMetaDataSettings);
export const SET_IS_ANALYTICS_ENABLED = `${ACTION_PREFIX}SET_IS_ANALYTICS_ENABLED`;
export const setIsAnalyticsEnabled = (modelType, isAnalyticsEnabled) => {
    const settings = JSON.parse(localStorage.getItem('isAnalyticsEnabled') || '{}');
    settings[modelType] = isAnalyticsEnabled;
    localStorage.setItem('isAnalyticsEnabled', JSON.stringify(settings));
    return createAction(SET_IS_ANALYTICS_ENABLED, { isAnalyticsEnabled: settings });
};
export const SET_EVALUATION_TYPE = `${ACTION_PREFIX}SET_EVALUATION_TYPE`;
export const setEvaluationType = (evaluationType) => createAction(SET_EVALUATION_TYPE, evaluationType);
export const RESET_STATE = `${ACTION_PREFIX}RESET_STATE`;
export const resetState = () => createAction(RESET_STATE);
export const RESET_NAVIGATIONS = `${ACTION_PREFIX}RESET_NAVIGATIONS`;
export const resetNavigations = () => createAction(RESET_NAVIGATIONS);

import React from 'react';
import { isEmpty } from 'ramda';
import { Icon, Typography } from '@groupby/ui-components';
import { Button } from '@/components/button';
import { Tooltip } from '@/search-for-retail/generic';
import { StyledBulkActionsRowContainer } from './bulk-actions-menu.styles';
import { useLocalization } from '@/localization';
export const BulkActionsMenu = ({ handleDeleteSelected, handlePromoteToArea, isEditingAllowed, selectionLabel = '0 Rules selected', disableBulkCopy, disableBulkDelete = false, deleteTooltipProps, }) => {
    const { formatMessage: t } = useLocalization();
    const deleteButton = (<Button disabled={disableBulkDelete} error={!disableBulkDelete} rounded onClick={handleDeleteSelected} startIcon={<Icon variant="delete" tooltipText={t({ key: 'DELETE' })}/>} title={t({ key: 'DELETE' })}/>);
    return (<StyledBulkActionsRowContainer>
    <div>
      <Typography noWrap strong variant="subtitle1">
        {selectionLabel}
      </Typography>
    </div>
    {isEditingAllowed
        && (<div>
          {handlePromoteToArea && (<Button disabled={disableBulkCopy} onClick={handlePromoteToArea} startIcon={<Icon variant="moveFolder" tooltipText={t({ key: 'BULK_COPY_CONFIGS_TO_AREA' })}/>} title={t({ key: 'COPY_TO_AREA' })} rounded/>)}
          {handleDeleteSelected && isEmpty(deleteTooltipProps) ? (deleteButton) : (<Tooltip {...deleteTooltipProps}>
                <span>
                  {deleteButton}
                </span>
              </Tooltip>)}
          </div>)}
    </StyledBulkActionsRowContainer>);
};
export default BulkActionsMenu;

import React from 'react';
import { useNextPath } from '@/routing/hooks/utils';
import AuthGuard from '../auth-guard';
export const LoggedOutGuard = (props) => {
    const { children, authNavigateTo } = props;
    const nextPath = useNextPath();
    const path = nextPath && nextPath !== '/' && nextPath !== '/login' ? nextPath : authNavigateTo;
    return <AuthGuard pathType="public" fallbackPath={path}>{children}</AuthGuard>;
};
export default LoggedOutGuard;

import { __rest } from "tslib";
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
export const ExperimentIcon = (_a) => {
    var { width = 24, height = 24 } = _a, props = __rest(_a, ["width", "height"]);
    return (<SvgIcon width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M9.51767 3.50195C9.51767 2.67353 10.1853 2.00195 11.0088 2.00195C11.8324 2.00195 12.5 2.67353 12.5 3.50195C12.5 4.33038 11.8324 5.00195 11.0088 5.00195C10.1853 5.00195 9.51767 4.33038 9.51767 3.50195ZM9.15142 9.00195H9.02062C8.47159 9.00195 8.02651 8.55424 8.02651 8.00195C8.02651 7.44967 8.47159 7.00195 9.02062 7.00195H10.1455H14.8545H15.9794C16.5284 7.00195 16.9735 7.44967 16.9735 8.00195C16.9735 8.55424 16.5284 9.00195 15.9794 9.00195H15.8486V11.6733L19.9133 17.2224C21.3642 19.2031 19.9583 22.002 17.5123 22.002H7.48769C5.04174 22.002 3.63581 19.2031 5.08675 17.2224L9.15142 11.6733V9.00195ZM11.1396 9.00195V12.002C11.1396 12.2154 11.0717 12.4233 10.9458 12.5952L10.6479 13.002H14.3521L14.0542 12.5952C13.9283 12.4233 13.8604 12.2154 13.8604 12.002V9.00195H11.1396ZM6.68737 18.4088L9.18286 15.002H15.8171L18.3126 18.4088C18.7963 19.069 18.3276 20.002 17.5123 20.002H7.48769C6.67237 20.002 6.20373 19.069 6.68737 18.4088ZM10.5118 16.002C9.96275 16.002 9.51767 16.4497 9.51767 17.002C9.51767 17.5542 9.96275 18.002 10.5118 18.002C11.0608 18.002 11.5059 17.5542 11.5059 17.002C11.5059 16.4497 11.0608 16.002 10.5118 16.002ZM14.4882 4.00195C13.9392 4.00195 13.4941 4.44967 13.4941 5.00195C13.4941 5.55424 13.9392 6.00195 14.4882 6.00195C15.0372 6.00195 15.4823 5.55424 15.4823 5.00195C15.4823 4.44967 15.0372 4.00195 14.4882 4.00195Z" fill="currentColor"/>
  </SvgIcon>);
};
export default ExperimentIcon;

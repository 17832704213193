import styled from 'styled-components';
import { IconButton } from '@mui/material';
import { baseColors } from '@groupby/ui-components';
export const StyledUserDisplayContainer = styled.div `
  && {
    height: 6rem;
    width:  ${({ theme }) => theme.s4rAppNavigation.expandedWidth};
    margin-top: auto;
    color: ${baseColors.white};
    transition: width  ${({ theme }) => theme.s4rAppNavigation.transitionSettings};

    .user-information-container {
      align-items: center;
      display: flex;
      height: 70%;
      justify-content: space-between;
      padding: ${({ theme }) => theme.spacing(0, 1, 0, 2)};
    }

    .divider {
      background-color: ${baseColors.background800};
    }

    .user-information {
      display: flex;
      align-items: center;
      gap: ${({ theme }) => theme.spacing(1)};

      &-name {
        word-break: break-word;
        margin-right: 0.5rem;
      }
    }

    &.collapsed: {
      width:  ${({ theme }) => theme.s4rAppNavigation.collapsedWidth};

      .divider {
        margin: 0 0.375;
      }

      .user-information-container {
        flex-direction: column;
        height: 100%;
        justify-content: center;
        padding: 0;
      }
    }
  }
`;
export const StyledAvatarIconButton = styled(IconButton) `
  border: ${({ $open, theme }) => ($open ? `2px solid ${theme.palette.primary.main}` : '2px solid white')};
  border-radius: 50%;
  padding: 0;
`;
export const StyledAvatarWrapper = styled.div `
  height: 3.75rem;
  width: 3.75rem;
  display: flex;
  margin-right: 24px;
  justify-content: center;
  align-items: center;
  ${({ theme, $open }) => $open && `background: ${theme.palette.background.pageContextControl}`};
`;
export default StyledUserDisplayContainer;

import { __awaiter } from "tslib";
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import * as ActionCreators from '@/store/actions/creators';
import * as Selectors from '@/store/selectors';
import rootClient from '@/client/api';
import { convertS4RRoleToMerchandisingRoles } from '@/utils';
import { useExpireToken } from '@/auth/hooks/use-expire-token';
import { LOGIN_ERROR_KEY } from './constants';
// TODO: ask for a help with such kind of mapping. Where to place it and do we really need both these models?
const mapUserToActiveUser = (user) => ({
    id: '',
    name: '',
    tenantName: '',
    email: user.email,
    role: user.role,
    areaPermissions: user.areaPermissions,
    permissions: [],
    allowedAccess: user.allowedAccess,
    settings: user.settings,
    tenantId: user.tenantId,
});
export const useValidateToken = () => {
    const dispatch = useDispatch();
    const refreshToken = useSelector(Selectors.authRefreshTokenSelector);
    const token = useSelector(Selectors.authTokenSelector);
    const expireToken = useExpireToken();
    const location = useLocation();
    return (pathname = location.pathname, search = location.search) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const resp = yield rootClient.auth(token).s4rValidate(refreshToken);
            const { access_token: newToken, refresh_token: newRefreshToken, user, } = resp.result;
            const merchandisingRoles = convertS4RRoleToMerchandisingRoles(user.role);
            dispatch(ActionCreators.acknowledgeAuthentication(newToken, Object.assign(Object.assign({}, mapUserToActiveUser(user)), { allowedAccess: merchandisingRoles }), newRefreshToken));
            dispatch(ActionCreators.clearError(LOGIN_ERROR_KEY));
        }
        catch (e) {
            expireToken(pathname, search);
        }
    });
};

import React from 'react';
// NOTE: Used everywhere (as of 2022-05-04)
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import classNames from 'classnames';
import { StyledLoading } from './loading.styles';
export const ELLIPSES_TIMEOUT = 250;
class Loading extends React.PureComponent {
    constructor() {
        super(...arguments);
        this.state = { ellipses: '', loading: false };
        this.timer = null;
        this.ellipsesTimer = null;
        this.showLoading = () => this.setState({ loading: true });
        this.updateEllipses = () => {
            if (this.state.ellipses.length < 3) {
                this.setState({ ellipses: `${this.state.ellipses}.` });
            }
            else {
                this.setState({ ellipses: '' });
            }
        };
    }
    render() {
        const { iconOrientation = 'bottom', isLoading, label = 'Loading', progressSize = 40, rootClass, showEllipsis = true, } = this.props;
        if (!isLoading || !this.state.loading) {
            return null;
        }
        return (<StyledLoading className={classNames(iconOrientation, rootClass)}>
        {label && (<Typography variant="caption">
            {label}
            {showEllipsis && this.state.ellipses}
          </Typography>)}
        <CircularProgress size={progressSize}/>
      </StyledLoading>);
    }
    componentDidMount() {
        this.updateTimers(this.props.isLoading);
    }
    componentWillUnmount() {
        this.clearTimers();
    }
    componentDidUpdate() {
        this.updateTimers(this.props.isLoading);
    }
    updateTimers(isLoading) {
        const hasTimers = this.timer !== null && this.ellipsesTimer !== null;
        if (isLoading && !hasTimers) {
            this.setTimers();
        }
        else if (!isLoading) {
            this.clearTimers();
        }
    }
    setTimers() {
        this.timer = setTimeout(this.showLoading, this.props.timeout);
        this.ellipsesTimer = setInterval(this.updateEllipses, ELLIPSES_TIMEOUT);
    }
    clearTimers() {
        if (this.timer) {
            clearTimeout(this.timer);
            this.timer = null;
        }
        if (this.ellipsesTimer) {
            clearInterval(this.ellipsesTimer);
            this.ellipsesTimer = null;
        }
    }
}
Loading.defaultProps = {
    timeout: 0,
};
export default Loading;
export function SimpleLoading() {
    return <Loading isLoading label="loading"/>;
}

import { __awaiter } from "tslib";
import { SearchForRetail } from '@/client/api';
import { uncachedFetch } from '@/client/fetch';
export const listAdhocMessages = (clientId) => __awaiter(void 0, void 0, void 0, function* () {
    const { result } = yield uncachedFetch(`${SearchForRetail.API_URL}/system-notifications/${clientId}`, {
        method: 'GET',
        skipCache: true,
    });
    return result.messages;
});
export default listAdhocMessages;

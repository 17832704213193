import { __rest } from "tslib";
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
export const SearchRevenueIcon = (_a) => {
    var { width = 84, height = 79 } = _a, props = __rest(_a, ["width", "height"]);
    return (<SvgIcon xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 84 79" fill="none" {...props}>
    <path d="M14.75 35.5978C22.482 35.5978 28.75 29.3298 28.75 21.5978C28.75 13.8658 22.482 7.59778 14.75 7.59778C7.01801 7.59778 0.75 13.8658 0.75 21.5978C0.75 29.3298 7.01801 35.5978 14.75 35.5978Z" fill="#E8EBEC"/>
    <path d="M39.35 78.0978C45.9774 78.0978 51.35 72.7252 51.35 66.0978C51.35 59.4704 45.9774 54.0978 39.35 54.0978C32.7226 54.0978 27.35 59.4704 27.35 66.0978C27.35 72.7252 32.7226 78.0978 39.35 78.0978Z" fill="#E8EBEC"/>
    <path d="M63.25 40.5978C74.2957 40.5978 83.25 31.6435 83.25 20.5978C83.25 9.55208 74.2957 0.597778 63.25 0.597778C52.2043 0.597778 43.25 9.55208 43.25 20.5978C43.25 31.6435 52.2043 40.5978 63.25 40.5978Z" fill="#E8EBEC"/>
    <path d="M22.75 18.7978L14.75 26.7978H18.75V49.7978H26.75V26.7978H30.75L22.75 18.7978Z" fill="#BDC3D8"/>
    <path d="M22.75 18.7978L14.75 26.7978H18.85V49.7978H23.85V24.7978C23.85 24.1978 24.25 23.7978 24.85 23.7978H27.75L22.75 18.7978Z" fill="white"/>
    <path d="M26.7499 50.5978H18.7499C18.3499 50.5978 17.9499 50.2978 17.9499 49.7978V27.5978H14.6499C14.3499 27.5978 14.0499 27.3978 13.9499 27.0978C13.8499 26.7978 13.8499 26.4978 14.1499 26.2978L22.1499 18.2978C22.4499 17.9978 22.9499 17.9978 23.2499 18.2978L31.2499 26.2978C31.4499 26.4978 31.5499 26.7978 31.4499 27.0978C31.3499 27.3978 31.0499 27.5978 30.7499 27.5978H27.55V49.7978C27.55 50.1978 27.2499 50.5978 26.7499 50.5978ZM19.55 49.0978H26.05V26.8978C26.05 26.4978 26.3499 26.0978 26.8499 26.0978H28.95L22.7499 19.8978L16.55 26.0978H18.8499C19.2499 26.0978 19.6499 26.3978 19.6499 26.8978V49.0978H19.55Z" fill="#21365F"/>
    <path d="M66.75 23.7978L58.75 31.7978H62.85V54.7978H70.85V31.7978H74.75L66.75 23.7978Z" fill="#BDC3D8"/>
    <path d="M66.75 23.7978L58.75 31.7978H62.85V54.7978H67.85V29.7978C67.85 29.1978 68.25 28.7978 68.85 28.7978H71.75L66.75 23.7978Z" fill="white"/>
    <path d="M70.8499 55.5978H62.8499C62.4499 55.5978 62.0499 55.2978 62.0499 54.7978V32.5978H58.7499C58.4499 32.5978 58.1499 32.3978 58.0499 32.0978C57.9499 31.7978 57.9499 31.4978 58.2499 31.2978L66.2499 23.2978C66.5499 22.9978 67.0499 22.9978 67.3499 23.2978L75.3499 31.2978C75.5499 31.4978 75.6499 31.7978 75.5499 32.0978C75.4499 32.3978 75.1499 32.5978 74.8499 32.5978H71.6499V54.7978C71.5499 55.1978 71.2499 55.5978 70.8499 55.5978ZM63.5499 54.0978H70.0499V31.8978C70.0499 31.4978 70.3499 31.0978 70.8499 31.0978H72.9499L66.7499 24.8978L60.5499 31.0978H62.8499C63.2499 31.0978 63.6499 31.3978 63.6499 31.8978V54.0978H63.5499Z" fill="#21365F"/>
    <path d="M32.75 64.7978H16.75C16.15 64.7978 15.75 65.1978 15.75 65.7978V68.7978C15.75 69.3978 16.15 69.7978 16.75 69.7978H32.75C33.35 69.7978 33.75 69.3978 33.75 68.7978V65.7978C33.75 65.2978 33.25 64.7978 32.75 64.7978Z" fill="white"/>
    <path d="M32.75 70.5978H16.7499C15.7499 70.5978 14.95 69.7978 14.95 68.7978V65.7978C14.95 64.7978 15.7499 63.9978 16.7499 63.9978H32.75C33.75 63.9978 34.55 64.7978 34.55 65.7978V68.7978C34.45 69.7978 33.65 70.5978 32.75 70.5978ZM16.7499 65.5978C16.6499 65.5978 16.45 65.6978 16.45 65.8978V68.8978C16.45 68.9978 16.5499 69.1978 16.7499 69.1978H32.75C32.85 69.1978 33.05 69.0978 33.05 68.8978V65.8978C33.05 65.7978 32.95 65.5978 32.75 65.5978H16.7499Z" fill="#21365F"/>
    <path d="M34.75 59.7978H18.75C18.15 59.7978 17.75 60.1978 17.75 60.7978V63.7978C17.75 64.3978 18.15 64.7978 18.75 64.7978H34.75C35.35 64.7978 35.75 64.3978 35.75 63.7978V60.7978C35.75 60.2978 35.25 59.7978 34.75 59.7978Z" fill="white"/>
    <path d="M34.75 65.5978H18.7499C17.7499 65.5978 16.95 64.7978 16.95 63.7978V60.7978C16.95 59.7978 17.7499 58.9978 18.7499 58.9978H34.75C35.75 58.9978 36.55 59.7978 36.55 60.7978V63.7978C36.45 64.7978 35.65 65.5978 34.75 65.5978ZM18.7499 60.5978C18.6499 60.5978 18.45 60.6978 18.45 60.8978V63.8978C18.45 63.9978 18.5499 64.1978 18.7499 64.1978H34.75C34.85 64.1978 35.05 64.0978 35.05 63.8978V60.8978C35.05 60.7978 34.95 60.5978 34.75 60.5978H18.7499Z" fill="#21365F"/>
    <path d="M33.75 54.7978H17.75C17.15 54.7978 16.75 55.1978 16.75 55.7978V58.7978C16.75 59.3978 17.15 59.7978 17.75 59.7978H33.75C34.35 59.7978 34.75 59.3978 34.75 58.7978V55.7978C34.75 55.2978 34.25 54.7978 33.75 54.7978Z" fill="white"/>
    <path d="M33.75 60.5978H17.7499C16.7499 60.5978 15.95 59.7978 15.95 58.7978V55.7978C15.95 54.7978 16.7499 53.9978 17.7499 53.9978H33.75C34.75 53.9978 35.55 54.7978 35.55 55.7978V58.7978C35.45 59.7978 34.65 60.5978 33.75 60.5978ZM17.7499 55.5978C17.6499 55.5978 17.45 55.6978 17.45 55.8978V58.8978C17.45 58.9978 17.5499 59.1978 17.7499 59.1978H33.75C33.85 59.1978 34.05 59.0978 34.05 58.8978V55.8978C34.05 55.7978 33.95 55.5978 33.75 55.5978H17.7499Z" fill="#21365F"/>
    <path d="M32.75 49.7978H16.75C16.15 49.7978 15.75 50.1978 15.75 50.7978V53.7978C15.75 54.3978 16.15 54.7978 16.75 54.7978H32.75C33.35 54.7978 33.75 54.3978 33.75 53.7978V50.7978C33.75 50.2978 33.25 49.7978 32.75 49.7978Z" fill="white"/>
    <path d="M32.75 55.5978H16.7499C15.7499 55.5978 14.95 54.7978 14.95 53.7978V50.7978C14.95 49.7978 15.7499 48.9978 16.7499 48.9978H32.75C33.75 48.9978 34.55 49.7978 34.55 50.7978V53.7978C34.45 54.7978 33.65 55.5978 32.75 55.5978ZM16.7499 50.5978C16.6499 50.5978 16.45 50.6978 16.45 50.8978V53.8978C16.45 53.9978 16.5499 54.1978 16.7499 54.1978H32.75C32.85 54.1978 33.05 54.0978 33.05 53.8978V50.8978C33.05 50.7978 32.95 50.5978 32.75 50.5978H16.7499Z" fill="#21365F"/>
    <path d="M21.2499 70.5978C20.8499 70.5978 20.45 70.2978 20.45 69.7978V64.7978C20.45 64.3978 20.7499 63.9978 21.2499 63.9978C21.7499 63.9978 22.05 64.2978 22.05 64.7978V69.7978C21.95 70.1978 21.6499 70.5978 21.2499 70.5978Z" fill="#21365F"/>
    <path d="M28.2499 70.5978C27.8499 70.5978 27.45 70.2978 27.45 69.7978V64.7978C27.45 64.3978 27.7499 63.9978 28.2499 63.9978C28.7499 63.9978 29.05 64.2978 29.05 64.7978V69.7978C28.95 70.1978 28.6499 70.5978 28.2499 70.5978Z" fill="#21365F"/>
    <path d="M23.2499 65.5978C22.8499 65.5978 22.45 65.2978 22.45 64.7978V59.7978C22.45 59.3978 22.7499 58.9978 23.2499 58.9978C23.7499 58.9978 24.05 59.2978 24.05 59.7978V64.7978C23.95 65.1978 23.6499 65.5978 23.2499 65.5978Z" fill="#21365F"/>
    <path d="M30.2499 65.5978C29.8499 65.5978 29.45 65.2978 29.45 64.7978V59.7978C29.45 59.3978 29.7499 58.9978 30.2499 58.9978C30.7499 58.9978 31.05 59.2978 31.05 59.7978V64.7978C30.95 65.1978 30.6499 65.5978 30.2499 65.5978Z" fill="#21365F"/>
    <path d="M21.2499 60.5978C20.8499 60.5978 20.45 60.2978 20.45 59.7978V54.7978C20.45 54.3978 20.7499 53.9978 21.2499 53.9978C21.7499 53.9978 22.05 54.2978 22.05 54.7978V59.7978C21.95 60.1978 21.6499 60.5978 21.2499 60.5978Z" fill="#21365F"/>
    <path d="M28.2499 60.5978C27.8499 60.5978 27.45 60.2978 27.45 59.7978V54.7978C27.45 54.3978 27.7499 53.9978 28.2499 53.9978C28.7499 53.9978 29.05 54.2978 29.05 54.7978V59.7978C28.95 60.1978 28.6499 60.5978 28.2499 60.5978Z" fill="#21365F"/>
    <path d="M22.2499 55.5978C21.8499 55.5978 21.45 55.2978 21.45 54.7978V49.7978C21.45 49.3978 21.7499 48.9978 22.2499 48.9978C22.7499 48.9978 23.05 49.2978 23.05 49.7978V54.7978C22.95 55.1978 22.6499 55.5978 22.2499 55.5978Z" fill="#21365F"/>
    <path d="M29.2499 55.5978C28.8499 55.5978 28.45 55.2978 28.45 54.7978V49.7978C28.45 49.3978 28.7499 48.9978 29.2499 48.9978C29.7499 48.9978 30.05 49.2978 30.05 49.7978V54.7978C29.95 55.1978 29.6499 55.5978 29.2499 55.5978Z" fill="#21365F"/>
    <path d="M72.75 64.7978H56.75C56.15 64.7978 55.75 65.1978 55.75 65.7978V68.7978C55.75 69.3978 56.15 69.7978 56.75 69.7978H72.75C73.35 69.7978 73.75 69.3978 73.75 68.7978V65.7978C73.75 65.2978 73.25 64.7978 72.75 64.7978Z" fill="white"/>
    <path d="M72.75 70.5978H56.75C55.75 70.5978 54.95 69.7978 54.95 68.7978V65.7978C54.95 64.7978 55.75 63.9978 56.75 63.9978H72.75C73.75 63.9978 74.55 64.7978 74.55 65.7978V68.7978C74.45 69.7978 73.65 70.5978 72.75 70.5978ZM56.75 65.5978C56.65 65.5978 56.45 65.6978 56.45 65.8978V68.8978C56.45 68.9978 56.55 69.1978 56.75 69.1978H72.75C72.85 69.1978 73.05 69.0978 73.05 68.8978V65.8978C73.05 65.7978 72.95 65.5978 72.75 65.5978H56.75Z" fill="#21365F"/>
    <path d="M71.75 59.7978H55.75C55.15 59.7978 54.75 60.1978 54.75 60.7978V63.7978C54.75 64.3978 55.15 64.7978 55.75 64.7978H71.75C72.35 64.7978 72.75 64.3978 72.75 63.7978V60.7978C72.75 60.2978 72.25 59.7978 71.75 59.7978Z" fill="white"/>
    <path d="M71.75 65.5978H55.75C54.75 65.5978 53.95 64.7978 53.95 63.7978V60.7978C53.95 59.7978 54.75 58.9978 55.75 58.9978H71.75C72.75 58.9978 73.55 59.7978 73.55 60.7978V63.7978C73.45 64.7978 72.65 65.5978 71.75 65.5978ZM55.75 60.5978C55.65 60.5978 55.45 60.6978 55.45 60.8978V63.8978C55.45 63.9978 55.55 64.1978 55.75 64.1978H71.75C71.85 64.1978 72.05 64.0978 72.05 63.8978V60.8978C72.05 60.7978 71.95 60.5978 71.75 60.5978H55.75Z" fill="#21365F"/>
    <path d="M72.75 54.7978H56.75C56.15 54.7978 55.75 55.1978 55.75 55.7978V58.7978C55.75 59.3978 56.15 59.7978 56.75 59.7978H72.75C73.35 59.7978 73.75 59.3978 73.75 58.7978V55.7978C73.75 55.2978 73.25 54.7978 72.75 54.7978Z" fill="white"/>
    <path d="M72.75 60.5978H56.75C55.75 60.5978 54.95 59.7978 54.95 58.7978V55.7978C54.95 54.7978 55.75 53.9978 56.75 53.9978H72.75C73.75 53.9978 74.55 54.7978 74.55 55.7978V58.7978C74.45 59.7978 73.65 60.5978 72.75 60.5978ZM56.75 55.5978C56.65 55.5978 56.45 55.6978 56.45 55.8978V58.8978C56.45 58.9978 56.55 59.1978 56.75 59.1978H72.75C72.85 59.1978 73.05 59.0978 73.05 58.8978V55.8978C73.05 55.7978 72.95 55.5978 72.75 55.5978H56.75Z" fill="#21365F"/>
    <path d="M60.25 70.5978C59.85 70.5978 59.45 70.2978 59.45 69.7978V64.7978C59.45 64.3978 59.75 63.9978 60.25 63.9978C60.75 63.9978 61.05 64.2978 61.05 64.7978V69.7978C60.95 70.1978 60.65 70.5978 60.25 70.5978Z" fill="#21365F"/>
    <path d="M67.25 70.5978C66.85 70.5978 66.45 70.2978 66.45 69.7978V64.7978C66.45 64.3978 66.75 63.9978 67.25 63.9978C67.75 63.9978 68.05 64.2978 68.05 64.7978V69.7978C67.95 70.1978 67.65 70.5978 67.25 70.5978Z" fill="#21365F"/>
    <path d="M61.25 65.5978C60.85 65.5978 60.45 65.2978 60.45 64.7978V59.7978C60.45 59.3978 60.75 58.9978 61.25 58.9978C61.75 58.9978 62.05 59.2978 62.05 59.7978V64.7978C61.95 65.1978 61.65 65.5978 61.25 65.5978Z" fill="#21365F"/>
    <path d="M68.25 65.5978C67.85 65.5978 67.45 65.2978 67.45 64.7978V59.7978C67.45 59.3978 67.75 58.9978 68.25 58.9978C68.75 58.9978 69.05 59.2978 69.05 59.7978V64.7978C68.95 65.1978 68.65 65.5978 68.25 65.5978Z" fill="#21365F"/>
    <path d="M60.25 60.5978C59.85 60.5978 59.45 60.2978 59.45 59.7978V54.7978C59.45 54.3978 59.75 53.9978 60.25 53.9978C60.75 53.9978 61.05 54.2978 61.05 54.7978V59.7978C60.95 60.1978 60.65 60.5978 60.25 60.5978Z" fill="#21365F"/>
    <path d="M67.25 60.5978C66.85 60.5978 66.45 60.2978 66.45 59.7978V54.7978C66.45 54.3978 66.75 53.9978 67.25 53.9978C67.75 53.9978 68.05 54.2978 68.05 54.7978V59.7978C67.95 60.1978 67.65 60.5978 67.25 60.5978Z" fill="#21365F"/>
    <path d="M76.75 70.5978H12.7499C12.3499 70.5978 11.95 70.2978 11.95 69.7978C11.95 69.2978 12.2499 68.9978 12.7499 68.9978H76.75C77.15 68.9978 77.55 69.2978 77.55 69.7978C77.55 70.2978 77.15 70.5978 76.75 70.5978Z" fill="#21365F"/>
    <path d="M30.75 31.7978H24.75C24.75 39.4978 31.05 45.7978 38.75 45.7978H44.75C44.75 38.0978 38.45 31.7978 30.75 31.7978Z" fill="white"/>
    <path d="M44.75 45.7978H38.75C31.05 45.7978 24.75 39.4978 24.75 31.7978C36.65 36.0978 44.75 45.7978 44.75 45.7978Z" fill="#BDC3D8"/>
    <path d="M44.75 46.5978H38.75C30.65 46.5978 23.95 39.9978 23.95 31.7978C23.95 31.3978 24.2499 30.9978 24.7499 30.9978H30.7499C38.8499 30.9978 45.55 37.5978 45.55 45.7978C45.45 46.1978 45.15 46.5978 44.75 46.5978ZM25.45 32.5978C25.85 39.5978 31.6499 45.0978 38.6499 45.0978H43.8499C43.4499 38.0978 37.6499 32.5978 30.6499 32.5978H25.45Z" fill="#21365F"/>
    <path d="M44.7499 46.5978C44.5499 46.5978 44.3499 46.4978 44.1499 46.2978C44.1499 46.1978 39.1499 40.3978 31.5499 35.8978C31.1499 35.6978 31.0499 35.1978 31.2499 34.8978C31.4499 34.4978 31.9499 34.3978 32.2499 34.5978C40.1499 39.2978 45.0499 45.0978 45.2499 45.2978C45.5499 45.5978 45.4499 46.0978 45.1499 46.3978C45.0499 46.4978 44.8499 46.5978 44.7499 46.5978Z" fill="#21365F"/>
    <path d="M58.75 36.7978H64.75C64.75 44.4978 58.45 50.7978 50.75 50.7978H44.75C44.75 43.0978 50.95 36.7978 58.75 36.7978Z" fill="white"/>
    <path d="M44.75 50.7978H50.75C58.45 50.7978 64.75 44.4978 64.75 36.7978C52.85 41.0978 44.75 50.7978 44.75 50.7978Z" fill="#BDC3D8"/>
    <path d="M50.75 51.5978H44.75C44.35 51.5978 43.95 51.2978 43.95 50.7978C43.95 42.6978 50.55 35.9978 58.65 35.9978H64.65C65.05 35.9978 65.45 36.2978 65.45 36.7978C65.45 44.9978 58.85 51.5978 50.75 51.5978ZM45.45 50.0978H50.65C57.75 50.0978 63.45 44.5978 63.85 37.5978H58.65C51.65 37.5978 45.85 43.0978 45.45 50.0978Z" fill="#21365F"/>
    <path d="M44.7499 51.5978C44.5499 51.5978 44.4499 51.4978 44.2499 51.3978C43.9499 51.0978 43.8499 50.6978 44.1499 50.2978C44.3499 50.0978 49.2499 44.1978 57.1499 39.5978C57.5499 39.3978 57.9499 39.4978 58.1499 39.8978C58.3499 40.2978 58.2499 40.6978 57.8499 40.8978C50.2499 45.3978 45.2499 51.2978 45.2499 51.2978C45.1499 51.4978 44.9499 51.5978 44.7499 51.5978Z" fill="#21365F"/>
    <path d="M44.75 29.7978C51.3774 29.7978 56.75 24.4252 56.75 17.7978C56.75 11.1704 51.3774 5.79779 44.75 5.79779C38.1226 5.79779 32.75 11.1704 32.75 17.7978C32.75 24.4252 38.1226 29.7978 44.75 29.7978Z" fill="white"/>
    <path d="M51.55 7.9978C52.95 9.8978 53.75 12.2978 53.75 14.7978C53.75 21.3978 48.35 26.7978 41.75 26.7978C39.25 26.7978 36.85 25.9978 34.95 24.5978C37.15 27.6978 40.75 29.7978 44.75 29.7978C51.35 29.7978 56.75 24.3978 56.75 17.7978C56.75 13.7978 54.65 10.1978 51.55 7.9978Z" fill="#BDC3D8"/>
    <path d="M44.75 30.5978C37.75 30.5978 31.95 24.8978 31.95 17.7978C31.95 10.6978 37.65 4.9978 44.75 4.9978C51.85 4.9978 57.55 10.6978 57.55 17.7978C57.55 24.8978 51.75 30.5978 44.75 30.5978ZM44.75 6.59779C38.55 6.59779 33.45 11.5978 33.45 17.8978C33.45 24.1978 38.45 29.1978 44.75 29.1978C51.05 29.1978 56.05 24.1978 56.05 17.8978C56.05 11.5978 50.95 6.59779 44.75 6.59779Z" fill="#21365F"/>
    <path d="M52.25 18.7978C52.8023 18.7978 53.25 18.3501 53.25 17.7978C53.25 17.2455 52.8023 16.7978 52.25 16.7978C51.6977 16.7978 51.25 17.2455 51.25 17.7978C51.25 18.3501 51.6977 18.7978 52.25 18.7978Z" fill="#21365F"/>
    <path d="M37.25 18.7978C37.8023 18.7978 38.25 18.3501 38.25 17.7978C38.25 17.2455 37.8023 16.7978 37.25 16.7978C36.6977 16.7978 36.25 17.2455 36.25 17.7978C36.25 18.3501 36.6977 18.7978 37.25 18.7978Z" fill="#21365F"/>
    <path d="M44.75 24.8978C42.95 24.8978 41.45 23.8978 40.75 22.3978C40.55 21.9978 40.75 21.5978 41.15 21.3978C41.55 21.1978 41.95 21.3978 42.15 21.7978C42.55 22.6978 43.55 23.3978 44.75 23.3978C46.25 23.3978 47.45 22.2978 47.45 20.9978C47.45 19.8978 46.95 18.5978 44.65 18.5978C41.55 18.5978 40.45 16.5978 40.45 14.6978C40.45 12.4978 42.35 10.7978 44.65 10.7978C46.45 10.7978 47.95 11.7978 48.65 13.2978C48.85 13.6978 48.65 14.0978 48.25 14.2978C47.85 14.4978 47.45 14.2978 47.25 13.8978C46.85 12.9978 45.85 12.2978 44.65 12.2978C43.15 12.2978 41.95 13.3978 41.95 14.6978C41.95 15.7978 42.45 17.0978 44.65 17.0978C47.75 17.0978 48.95 19.0978 48.95 20.9978C48.95 23.1978 47.05 24.8978 44.75 24.8978Z" fill="#21365F"/>
    <path d="M44.75 12.1978C44.35 12.1978 43.95 11.8978 43.95 11.3978V9.69783C43.95 9.29783 44.25 8.89783 44.75 8.89783C45.25 8.89783 45.55 9.19783 45.55 9.69783V11.3978C45.45 11.8978 45.15 12.1978 44.75 12.1978Z" fill="#21365F"/>
    <path d="M44.75 26.6978C44.35 26.6978 43.95 26.3978 43.95 25.8978V24.1978C43.95 23.7978 44.25 23.3978 44.75 23.3978C45.25 23.3978 45.55 23.6978 45.55 24.1978V25.8978C45.45 26.2978 45.15 26.6978 44.75 26.6978Z" fill="#21365F"/>
    <path d="M44.75 70.5978C44.35 70.5978 43.95 70.2978 43.95 69.7978V29.7978C43.95 29.3978 44.25 28.9978 44.75 28.9978C45.25 28.9978 45.55 29.2978 45.55 29.7978V69.7978C45.45 70.1978 45.15 70.5978 44.75 70.5978Z" fill="#21365F"/>
    <path d="M29.7499 8.59779H25.7499C25.3499 8.59779 24.95 8.29779 24.95 7.79779C24.95 7.29779 25.2499 6.9978 25.7499 6.9978H29.7499C30.1499 6.9978 30.55 7.29779 30.55 7.79779C30.55 8.29779 30.1499 8.59779 29.7499 8.59779Z" fill="#21365F"/>
    <path d="M27.7499 10.5978C27.3499 10.5978 26.95 10.2978 26.95 9.79779V5.79779C26.95 5.39779 27.2499 4.9978 27.7499 4.9978C28.2499 4.9978 28.55 5.29779 28.55 5.79779V9.79779C28.45 10.1978 28.1499 10.5978 27.7499 10.5978Z" fill="#21365F"/>
    <path d="M65.75 18.5978H61.75C61.35 18.5978 60.95 18.2978 60.95 17.7978C60.95 17.2978 61.25 16.9978 61.75 16.9978H65.75C66.15 16.9978 66.55 17.2978 66.55 17.7978C66.55 18.2978 66.15 18.5978 65.75 18.5978Z" fill="#21365F"/>
    <path d="M63.75 20.5978C63.35 20.5978 62.95 20.2978 62.95 19.7978V15.7978C62.95 15.3978 63.25 14.9978 63.75 14.9978C64.25 14.9978 64.55 15.2978 64.55 15.7978V19.7978C64.45 20.1978 64.15 20.5978 63.75 20.5978Z" fill="#21365F"/>
    <path d="M73.75 11.5978H69.75C69.35 11.5978 68.95 11.2978 68.95 10.7978C68.95 10.2978 69.25 9.9978 69.75 9.9978H73.75C74.15 9.9978 74.55 10.2978 74.55 10.7978C74.55 11.2978 74.15 11.5978 73.75 11.5978Z" fill="#21365F"/>
    <path d="M71.75 13.5978C71.35 13.5978 70.95 13.2978 70.95 12.7978V8.79779C70.95 8.39779 71.25 7.9978 71.75 7.9978C72.25 7.9978 72.55 8.29779 72.55 8.79779V12.7978C72.45 13.1978 72.15 13.5978 71.75 13.5978Z" fill="#21365F"/>
    <path d="M17.7499 14.5978H13.7499C13.3499 14.5978 12.95 14.2978 12.95 13.7978C12.95 13.2978 13.2499 12.9978 13.7499 12.9978H17.7499C18.1499 12.9978 18.55 13.2978 18.55 13.7978C18.55 14.2978 18.1499 14.5978 17.7499 14.5978Z" fill="#21365F"/>
    <path d="M15.7499 16.5978C15.3499 16.5978 14.95 16.2978 14.95 15.7978V11.7978C14.95 11.3978 15.2499 10.9978 15.7499 10.9978C16.2499 10.9978 16.55 11.2978 16.55 11.7978V15.7978C16.45 16.1978 16.1499 16.5978 15.7499 16.5978Z" fill="#21365F"/>
  </SvgIcon>);
};
export default SearchRevenueIcon;

import { isNil } from 'ramda';
export const canUserViewRoute = (roles, activeUser) => {
    if (isNil(activeUser)) {
        return false;
    }
    return !roles.length || roles.some((role) => activeUser.allowedAccess.includes(role));
};
export const filterRoutesByUserAccess = (routes, activeUser) => routes.reduce((filteredRoutes, route) => {
    var _a;
    const subroutes = ((_a = route.subroutes) === null || _a === void 0 ? void 0 : _a.length) ? filterRoutesByUserAccess(route.subroutes, activeUser) : undefined;
    if (canUserViewRoute(route.roles, activeUser)) {
        filteredRoutes.push(Object.assign(Object.assign({}, route), { subroutes }));
    }
    return filteredRoutes;
}, []);
export const patchDrawerRoutesWithFields = (routes, routeFields) => (routeFields ? routes.reduce((patchedRoutes, route) => {
    var _a;
    const subroutes = ((_a = route.subroutes) === null || _a === void 0 ? void 0 : _a.length) ? patchDrawerRoutesWithFields(route.subroutes, routeFields) : undefined;
    patchedRoutes.push(Object.assign(Object.assign(Object.assign({}, route), routeFields), { subroutes }));
    return patchedRoutes;
}, []) : routes);

import { __rest } from "tslib";
import React from 'react';
import { ConfirmationAlertContext } from './confirmation-alert.provider';
export const useConfirmationAlert = () => {
    const { openDialog } = React.useContext(ConfirmationAlertContext);
    const getConfirmation = (_a) => {
        var options = __rest(_a, []);
        return new Promise((resolve) => {
            openDialog(Object.assign({ actionCallback: resolve }, options));
        });
    };
    return { getConfirmation };
};
export default useConfirmationAlert;

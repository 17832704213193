import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useField } from 'formik';
import { AttributeAutocompleteControlComponent } from './attribute-autocomplete-control.component';
import { attributeShortSelector } from '@/store/selectors';
import { useLocalization } from '@/localization';
import { FeatureFlag, useIsFeatureFlagEnabled } from '@/search-for-retail/feature-flags';
export const ATTRIBUTE_FILTERS = {
    INDEXABLE: (attrs) => attrs.filter((attr) => attr.indexable),
    FILTERABLE: (attrs) => attrs.filter((attr) => attr.filterable),
    RETRIEVABLE: (attrs) => attrs.filter((attr) => attr.retrievable),
    NOOP: (attrs) => attrs,
};
export const AttributeAutocompleteControl = ({ name, value, disabled, helperText, label, error, clearAfterSelect, filteredByAttributeType, excludeList, handleChange, handleBlur, variant, filterAttributes = ATTRIBUTE_FILTERS.NOOP, }) => {
    const { formatMessage: t } = useLocalization();
    const enableAttributeFiltering = useIsFeatureFlagEnabled(FeatureFlag.EnableAttributeFiltering);
    const unFilteredAttributes = useSelector(attributeShortSelector);
    const attributes = enableAttributeFiltering ? filterAttributes(unFilteredAttributes) : unFilteredAttributes;
    const [{ name: formName }, { value: formValue = '' }, { setValue, setTouched }] = useField(name);
    // the default blur handler isn't setting `touched` for some reason, so set it manually
    const onBlur = () => {
        setTouched(true);
        if (handleBlur) {
            handleBlur();
        }
    };
    const options = useMemo(() => attributes === null || attributes === void 0 ? void 0 : attributes.reduce((attrList, attr) => {
        const notInUseLabel = !attr.inUse ? ` - ${t({ key: 'DISABLED' })}` : '';
        const item = {
            value: attr.key,
            label: `${attr.key} (${attr.displayName})${notInUseLabel}`,
            inUse: attr.inUse,
        };
        if (filteredByAttributeType) {
            return filteredByAttributeType.includes(attr.type) ? [...attrList, item] : [...attrList];
        }
        if (excludeList) {
            return excludeList.includes(attr.key) ? [...attrList] : [...attrList, item];
        }
        return [...attrList, item];
    }, []), [t, attributes, filteredByAttributeType, excludeList]);
    return (<AttributeAutocompleteControlComponent name={formName} error={error} onBlur={onBlur} label={label} helperText={helperText} disabled={disabled} options={options} value={formName ? formValue : value} setValue={setValue} handleChange={handleChange} clearAfterSelect={clearAfterSelect} variant={variant}/>);
};
export default AttributeAutocompleteControl;

import * as UIReducers from './ui';
import * as Actions from '@/store/actions/types';
export const DEFAULT = {
    errors: {},
};
// eslint-disable-next-line complexity
export default (state = DEFAULT, action) => {
    switch (action.type) {
        case Actions.SHOW_ERROR:
            return UIReducers.showErrorReducer(state, action);
        case Actions.CLEAR_ERROR:
            return UIReducers.clearErrorReducer(state, action);
        case Actions.HIDE_ALERT:
            return UIReducers.hideAlertReducer(state);
        default:
            return state;
    }
};
export function showErrorReducer(state, { payload: { id, message } }) {
    return Object.assign(Object.assign({}, state), { errors: Object.assign(Object.assign({}, state.errors), { [id]: message }) });
}
export function clearErrorReducer(state, { payload: id }) {
    return Object.assign(Object.assign({}, state), { errors: Object.keys(state.errors)
            .filter((key) => key !== id)
            .reduce((errors, key) => {
            errors[key] = state.errors[key];
            return errors;
        }, {}) });
}
export function hideAlertReducer(state) {
    return Object.assign(Object.assign({}, state), { alert: null });
}

import { useContext } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { AuthContext } from '@/auth/auth.context';
import { getTenantName } from '@/utils';
import { FeatureFlag, useFeatureFlagsProvider } from '@/search-for-retail/feature-flags';
import { useLegacyAuth } from './authentication';
export const useShowAuthenticatedView = () => {
    const { featureFlagsService } = useFeatureFlagsProvider();
    const isAuth0Enabled = featureFlagsService.isFeatureEnabled(FeatureFlag.Auth0);
    const { isTokenPermissionsEmpty, isAppLoading } = useContext(AuthContext);
    const { isAuthenticated: isLegacyAuthenticated, isLoading: isLegacyLoading } = useLegacyAuth();
    const { isAuthenticated, isLoading } = useAuth0();
    const tenantName = getTenantName();
    // ensure authenticatedView is not shown when we are authenticated by Auth0 but do not have permissions in the token
    // or when we are authenticated by Auth0 but do not have a tenant name
    const showAuthenticatedView = isAuth0Enabled
        ? (isAuthenticated && tenantName && !isTokenPermissionsEmpty)
        : isLegacyAuthenticated;
    return {
        showAuthenticatedView,
        isLoading: isAppLoading || (isAuth0Enabled ? isLoading : isLegacyLoading),
    };
};

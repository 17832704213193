import { __rest } from "tslib";
import React from 'react';
import { ErrorAlertContext } from './error-alert.provider';
export const useErrorAlert = () => {
    const { openDialog } = React.useContext(ErrorAlertContext);
    const getError = (_a) => {
        var options = __rest(_a, []);
        return new Promise((resolve) => {
            openDialog(Object.assign({ actionCallback: resolve }, options));
        });
    };
    return { getError };
};
export default useErrorAlert;
